export const TodoListIcon = ({ fill = "#5C5B57" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
    >
      <rect width="32" height="32" fill="white" />
      <path
        d="M23.7719 6.87361H20.575V6.72361C20.575 5.75798 19.7875 4.97986 18.8219 4.97986H13.1687C12.2031 4.97986 11.4156 5.75798 11.4156 6.72361V6.87361H8.22812C7.49687 6.87361 6.90625 7.44548 6.90625 8.15798V25.7455C6.90625 26.4486 7.49687 27.0205 8.22812 27.0205H23.7719C24.5031 27.0205 25.0938 26.4486 25.0938 25.7455V8.15798C25.0938 7.44548 24.5031 6.87361 23.7719 6.87361ZM12.7375 6.72361C12.7375 6.48923 12.9344 6.29236 13.1687 6.29236H18.8219C19.0656 6.29236 19.2625 6.48923 19.2625 6.72361V8.33611C19.2625 8.57986 19.0656 8.77673 18.8219 8.77673H13.1687C12.9344 8.77673 12.7375 8.57986 12.7375 8.33611V6.72361ZM23.7719 25.708L8.21875 25.7455V8.18611H11.4156V8.33611C11.4156 9.30173 12.2031 10.0892 13.1687 10.0892H18.8219C19.7875 10.0892 20.575 9.30173 20.575 8.33611V8.17673L23.7812 8.15798V25.708H23.7719Z"
        fill={fill}
      />
      <path
        d="M11.34 14.66C11.13 14.66 10.92 14.59 10.76 14.44L9.57002 13.39C9.32002 13.17 9.30003 12.79 9.52003 12.54C9.74003 12.29 10.12 12.27 10.37 12.49L11.33 13.34L13.54 11.13C13.77 10.9 14.15 10.9 14.39 11.13C14.62 11.36 14.62 11.74 14.39 11.98L11.97 14.4C11.8 14.57 11.57 14.66 11.34 14.66Z"
        fill={fill}
      />
      <path
        d="M22.04 13.41H16.7C16.37 13.41 16.1 13.14 16.1 12.81C16.1 12.48 16.37 12.21 16.7 12.21H22.04C22.37 12.21 22.64 12.48 22.64 12.81C22.64 13.14 22.37 13.41 22.04 13.41Z"
        fill={fill}
      />
      <path
        d="M11.34 19.96C11.13 19.96 10.92 19.89 10.76 19.74L9.57002 18.69C9.32002 18.47 9.30003 18.09 9.52003 17.84C9.74003 17.59 10.12 17.57 10.37 17.79L11.33 18.64L13.54 16.43C13.77 16.2 14.15 16.2 14.39 16.43C14.62 16.66 14.62 17.04 14.39 17.28L11.97 19.7C11.8 19.87 11.57 19.96 11.35 19.96H11.34Z"
        fill={fill}
      />
      <path
        d="M22.04 18.71H16.7C16.37 18.71 16.1 18.44 16.1 18.11C16.1 17.78 16.37 17.51 16.7 17.51H22.04C22.37 17.51 22.64 17.78 22.64 18.11C22.64 18.44 22.37 18.71 22.04 18.71Z"
        fill={fill}
      />
      <path
        d="M11.34 25.26C11.13 25.26 10.92 25.19 10.76 25.04L9.57002 23.99C9.32002 23.77 9.30003 23.39 9.52003 23.14C9.74003 22.89 10.12 22.87 10.37 23.09L11.33 23.94L13.54 21.73C13.77 21.5 14.15 21.5 14.39 21.73C14.62 21.96 14.62 22.34 14.39 22.58L11.97 25C11.8 25.17 11.57 25.26 11.35 25.26H11.34Z"
        fill={fill}
      />
      <path
        d="M22.04 24H16.7C16.37 24 16.1 23.73 16.1 23.4C16.1 23.07 16.37 22.8 16.7 22.8H22.04C22.37 22.8 22.64 23.07 22.64 23.4C22.64 23.73 22.37 24 22.04 24Z"
        fill={fill}
      />
    </svg>
  );
};

export const HamBurgerMenuIcon = ({ fill = "#5C5B57" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M6.5 8.66669H26.5M6.5 16H26.5M6.5 23.3334H26.5"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const MenuBarSearchIcon = ({ fill = "#5C5B57" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill={fill}
    >
      <path
        d="M23.154 21.095L26.0835 24.023C26.2201 24.1645 26.2957 24.3539 26.294 24.5506C26.2923 24.7472 26.2134 24.9353 26.0744 25.0744C25.9353 25.2134 25.7472 25.2923 25.5506 25.294C25.3539 25.2957 25.1645 25.2201 25.023 25.0835L22.0935 22.154C20.1791 23.7942 17.7039 24.6291 15.1872 24.4836C12.6705 24.3382 10.308 23.2236 8.59537 21.3737C6.88275 19.5239 5.95317 17.0827 6.00169 14.5622C6.0502 12.0418 7.07305 9.63813 8.85559 7.85559C10.6381 6.07305 13.0418 5.0502 15.5622 5.00169C18.0827 4.95317 20.5239 5.88275 22.3737 7.59537C24.2236 9.308 25.3382 11.6705 25.4836 14.1872C25.6291 16.7039 24.7942 19.1791 23.154 21.0935V21.095ZM15.75 23C17.938 23 20.0365 22.1308 21.5836 20.5836C23.1308 19.0365 24 16.938 24 14.75C24 12.562 23.1308 10.4635 21.5836 8.91637C20.0365 7.36919 17.938 6.5 15.75 6.5C13.562 6.5 11.4635 7.36919 9.91637 8.91637C8.36919 10.4635 7.5 12.562 7.5 14.75C7.5 16.938 8.36919 19.0365 9.91637 20.5836C11.4635 22.1308 13.562 23 15.75 23Z"
        fill={fill}
      />
    </svg>
  );
};

export const MenuBarDashboardIcon = ({ fill = "#5C5B57" }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8954 15.7444C18.8021 15.7444 17.7088 15.7444 16.6243 15.7444C15.6622 15.7444 14.875 14.966 14.875 14.0039C14.875 12.9456 14.875 11.8785 14.875 10.8202V5.26622C14.875 4.30411 15.6622 3.51694 16.6243 3.51694C18.6709 3.50819 20.7176 3.49944 22.7643 3.51694C23.7176 3.52568 24.4961 4.30411 24.4961 5.26622C24.4961 8.17877 24.4961 11.0913 24.4961 14.0126C24.4961 14.9747 23.7089 15.7532 22.7468 15.7532C21.7847 15.7532 20.8575 15.7532 19.9129 15.7532L19.8954 15.7444ZM19.4319 5.24872C18.601 5.24872 17.7701 5.24872 16.9392 5.24872C16.7642 5.24872 16.6243 5.38867 16.6243 5.5636V10.8027C16.6243 11.7561 16.6243 12.7094 16.6243 13.6628C16.6243 13.8377 16.7642 13.9776 16.9392 13.9776C18.7672 13.9776 20.5864 13.9776 22.4144 13.9776C22.5893 13.9776 22.7293 13.8377 22.7293 13.6628C22.738 10.9601 22.738 8.25749 22.7293 5.5636C22.7293 5.38867 22.5893 5.24872 22.4144 5.24872C21.4173 5.24872 20.429 5.24872 19.4319 5.24872Z"
        fill={fill}
      />
      <path
        d="M8.52435 24.4905C7.43104 24.4905 6.33774 24.4905 5.25319 24.4905C4.29108 24.4905 3.50391 23.7121 3.50391 22.75C3.50391 21.6917 3.50391 20.6246 3.50391 19.5663V14.0123C3.50391 13.0502 4.29108 12.263 5.25319 12.263C7.29985 12.2543 9.34651 12.2455 11.3932 12.263C12.3465 12.2718 13.125 13.0502 13.125 14.0123C13.125 16.9249 13.125 19.8374 13.125 22.7587C13.125 23.7208 12.3378 24.4993 11.3757 24.4993C10.4136 24.4993 9.48645 24.4993 8.54184 24.4993L8.52435 24.4905ZM8.06079 13.9948C7.22988 13.9948 6.39897 13.9948 5.56806 13.9948C5.39313 13.9948 5.25319 14.1348 5.25319 14.3097V19.5488C5.25319 20.5021 5.25319 21.4555 5.25319 22.4089C5.25319 22.5838 5.39313 22.7237 5.56806 22.7237C7.39606 22.7237 9.21531 22.7237 11.0433 22.7237C11.2182 22.7237 11.3582 22.5838 11.3582 22.4089C11.3669 19.7062 11.3669 17.0036 11.3582 14.3097C11.3582 14.1348 11.2182 13.9948 11.0433 13.9948C10.0462 13.9948 9.05788 13.9948 8.06079 13.9948Z"
        fill={fill}
      />
      <path
        d="M22.7302 24.4913H16.6165C15.6544 24.4913 14.8672 23.7041 14.8672 22.742V19.4358C14.8672 18.3688 15.7331 17.4941 16.8089 17.4941C18.7244 17.4941 20.6486 17.4941 22.564 17.4941C23.6136 17.4941 24.4882 18.36 24.4882 19.4096C24.4882 20.5204 24.4882 21.6312 24.4882 22.742C24.4882 23.7041 23.7011 24.4825 22.739 24.4825L22.7302 24.4913ZM17.1762 19.2434C16.8701 19.2434 16.6165 19.4971 16.6165 19.8032V22.2872C16.6165 22.5408 16.8176 22.742 17.0625 22.742H22.2667C22.5203 22.742 22.7215 22.5408 22.7215 22.2872C22.7215 21.4213 22.7215 20.5641 22.7215 19.707C22.7215 19.4533 22.5203 19.2522 22.2667 19.2522C20.5611 19.2522 18.8643 19.2522 17.1675 19.2522L17.1762 19.2434Z"
        fill={fill}
      />
      <path
        d="M11.3591 10.4971H5.24538C4.28327 10.4971 3.49609 9.70995 3.49609 8.74785V5.4417C3.49609 4.37464 4.36199 3.5 5.4378 3.5C7.35326 3.5 9.27747 3.5 11.1929 3.5C12.2425 3.5 13.1171 4.36589 13.1171 5.41546C13.1171 6.52626 13.1171 7.63705 13.1171 8.74785C13.1171 9.70995 12.33 10.4884 11.3679 10.4884L11.3591 10.4971ZM5.80515 5.24928C5.49902 5.24928 5.24538 5.50293 5.24538 5.80905V8.29303C5.24538 8.54668 5.44654 8.74785 5.69144 8.74785H10.8956C11.1492 8.74785 11.3504 8.54668 11.3504 8.29303C11.3504 7.42714 11.3504 6.56999 11.3504 5.71284C11.3504 5.4592 11.1492 5.25803 10.8956 5.25803C9.19001 5.25803 7.4932 5.25803 5.7964 5.25803L5.80515 5.24928Z"
        fill={fill}
      />
    </svg>
  );
};
