import { isEmpty } from "lodash";
import React from "react";
import EmptyData from "../../globalComponents/EmptyData";
import TrusteeCard from "./TrusteeCard";
import TrusteeLoader from "./TrusteeLoader";

const TrusteesCardsListing = ({
  trusteesList,
  apiLoading,
  handleClickOption,
  search,
}) => {
  if (apiLoading) {
    return <TrusteeLoader />;
  }
  if (isEmpty(trusteesList)) {
    return (
      <EmptyData
        text={search ? "No search results found" : "No Trustees found"}
      />
    );
  }
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {trusteesList?.map((trustee) => (
        <TrusteeCard key={trustee?._id} {...{ trustee, handleClickOption }} />
      ))}
    </div>
  );
};

export default TrusteesCardsListing;
