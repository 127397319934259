import { ApiRequest } from "../../../service";
import { ApiMethods, ApiPaths } from "../../../service/api-constants";

const courseAdhocUrls = {
  save: "/mySchedule/saveCourseAdhocSchedule",
  getBatches: "/myCourse/getCourseByBatchIdAndBatchName",
  update: "/mySchedule/updateCourseAdhocSchedule",
  delete: "mySchedule/deleteMySchedule?",
};

export function getCourseByCourseId(id, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCourse",
    method: "GET",
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getCloneCourseById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/cloneCourse",
    method: "GET",
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const getCourseBatches = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: courseAdhocUrls.getBatches,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const saveCourseAdhoc = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: courseAdhocUrls.save,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const updateCourseAdhoc = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: courseAdhocUrls.update,
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const cancelCourse = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: "myCourse/cancelCourse",
    method: ApiMethods.DELETE,
    params,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export function getCourseDetailsById(
  courseId,
  endUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "myCourse/getCompleteCourseDetails",
    method: ApiMethods.GET,
    params: { courseId, endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getBatchStudents(courseId, batchId, onSuccess, onFailure) {
  ApiRequest({
    url: "invite/getInviteUsersByCourseIdAndBatchId",
    method: ApiMethods.GET,
    params: { courseId, batchId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function saveDraftCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: "draftCourse/saveDraftCourse",
    method: "POST",
    data: data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getDraftedCourses(data, onSuccess, onFailure) {
  ApiRequest({
    url: "draftCourse/getDraftCourseByServiceProviderId",
    method: "GET",
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function DeleteDraftedCourses(id, onSuccess, onFailure) {
  ApiRequest({
    url: "draftCourse/deleteDraftCourse",
    method: ApiMethods.DELETE,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getDraftCourseById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "draftCourse/getDraftCourse",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const getGroupBatchesByCourseId = (courseId, onSuccess, onFailure) => {
  ApiRequest({
    url: "mycourse/groupBatchesbyCourseId?",
    method: ApiMethods.GET,
    params: { courseId },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};

export const searchUsers = (searchKey, userId, onSuccess, onFailure) => {
  ApiRequest({
    url: "user/searchUser",
    method: ApiMethods.GET,
    params: { searchKey, userId },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};

export const saveInternalTransfer = (transferData, onSuccess, onFailure) => {
  ApiRequest({
    url: "coursetransfer/courseTransferForEndUserList",
    method: ApiMethods.POST,
    data: transferData,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};

export const saveRequestBatchChange = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: "coursetransfer/save",
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getCourseHistory = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: "activityLog/getActivityLogByCourseId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
export const userInviteBySp = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: "user/inviteUserByServiceProvider",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const moveToDrafts = (params, onSuccess, onFail) => {
  ApiRequest({
    url: "myCourse/moveToDrafts",
    method: ApiMethods.DELETE,
    params,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFail && onFail(error);
    }
  );
};

export function checkMobileIfUserHasAnyData(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getUserByMobile",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getUserByMail(email, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_USERBY_MAIL + email,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}
