import React from "react";
import { VerifyUserStrings } from "../VerifyUserStrings";
import "../index.scss";
import Layout from "../Layout";
import AautiCheckbox from "../../../components/globalComponents/AautiCheckbox";

const VerificationMethods = ({ verificationMethod, setVerificationMethod }) => {
  const methods = [
    { title: "Verify by OTP", base: "otp" },
    { title: "Verify by Call", base: "call" },
  ];

  return (
    <Layout title={VerifyUserStrings.preferMethod}>
      <div className="verify-flex-items gap_20">
        {methods?.map((method, index) => {
          return (
            <AautiCheckbox
              inputId={method.base}
              onChange={() => {
                setVerificationMethod(method?.base);
              }}
              checked={method?.base === verificationMethod}
              textStyle={{ color: "#1E1E1E" }}
              key={index}
              type="radio"
              inputLabel={method?.title || ""}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default VerificationMethods;
