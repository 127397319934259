import { Avatar } from "@mui/material";
import React from "react";
import AautiMenu from "../../globalComponents/AautiMenu";
import AautiText from "../../globalComponents/AautiText";
import { useSelector } from "react-redux";

const TrusteeCard = ({ trustee = {}, handleClickOption = () => {} }) => {
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const returnTrusteeStatus = () => {
    switch (trustee?.status?.toLowerCase()) {
      case "approved":
        return (
          <AautiText
            size={"semi"}
            textStyle={{ color: "green" }}
            title="Approved"
          />
        );
      case "pending":
        return (
          <AautiText
            size={"semi"}
            textStyle={{ color: "orange" }}
            title="Pending"
          />
        );
      case "rejected":
        return (
          <AautiText
            size={"semi"}
            textStyle={{ color: "red" }}
            title="Rejected"
          />
        );
      default:
        return (
          <AautiText
            size={"semi"}
            textStyle={{ color: "gray" }}
            title="Unknown Status"
          />
        );
    }
  };

  return (
    <div
      key={trustee?._id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        width: mobileDevices
          ? "100%"
          : ipodDevices
          ? "calc(50% - 10px)"
          : "calc(33% - 10px)",
        display: "flex",
        gap: "10px",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", right: 10, top: 10 }}>
        <AautiMenu
          options={["Delete"]}
          handleClickOption={(option) => {
            console.log({ option, trustee });
            handleClickOption(option, trustee);
          }}
        />
      </div>
      <Avatar src="" alt={trustee?.displayName || "Trustee"} />
      <div style={{ width: "100%" }}>
        <AautiText
          textStyle={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 1,
            textOverflow: "ellipsis",
            maxWidth: "80%",
          }}
          title={trustee?.displayName}
        />
        {returnTrusteeStatus(trustee)}
      </div>
    </div>
  );
};

export default TrusteeCard;
