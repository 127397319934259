import moment from 'moment'
import * as React from 'react'
import {  isSameDay, isSamePublishedDay } from '../../utils'

export function Day({
  dateFormat = 'll',
  currentMessage,
  previousMessage,
}) {
  if (currentMessage == null || isSameDay(currentMessage, previousMessage)) {
    return null
  }

  if (
    currentMessage == null ||
    isSamePublishedDay(currentMessage, previousMessage)
  ) {
    return null
  }
  const isToday = moment().isSame(moment(currentMessage.createdAt), 'date')
  return (
    <div className='day-wrapper'>
        <p className='day-text'>
          {isToday
            ? 'Today'
            : moment(currentMessage.createdAt).format(dateFormat)}
        </p>
    </div>
  )
}
