import Badge from "@mui/material/Badge";
import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import AautiText from "../../../globalComponents/AautiText";
import { conversationTypes, messageTypes } from "../../constants";
import { getDateTimeDifference } from "../../../../utils/Helpers";
import { useSelector } from "react-redux";
import AautiAvatar from "../AautiAvatar";
import { StyledBadge } from "../styled_components";
import "./styles.scss";

const getMessage = (latestMsg) => {
  let message = null;
  let text = latestMsg?.text;
  switch (latestMsg?.type) {
    case messageTypes.IMAGE:
      message = `📷 ${text ?? "Image"}`;
      break;
    case messageTypes.DOCUMENT:
      message = `📁 ${text ?? "Document"}`;
      break;
    case messageTypes.VIDEO:
      message = `🎥 ${text ?? "Video"}`;
      break;
    case messageTypes.AUDIO:
      message = `🎵 ${text ?? "Audio"}`;
      break;
    default:
      message = text;
      break;
  }
  return message;
};

export default function ChatListItem({ conversation, onClickChat, selected }) {
  const { message = {}, type } = conversation ?? {};
  let messageText = getMessage(message);
  const [showAvatarView, setShowAvatarView] = useState(false);

  const isOnToOne = type === conversationTypes.ONE_TO_ONE;
  const isInstituteAdmin = useSelector(
    (state) => state.app.userRole === "instituteAdmin"
  );

  const hasUnreadCount = !!conversation?.unreadCount

  const key = conversation?._id;

  const avatar = isOnToOne
    ? conversation?.user?.profileImage
    : conversation?.avatar;

  const name = isOnToOne
    ? conversation?.user?.displayName ?? ""
    : conversation?.name ?? "";

  const renderAvatar = () => {
    if (!name) return;
    let Avatar = () => (
      <AautiAvatar {...{ height: 36, width: 36, src: avatar, alt: name }} />
    );
    if (isOnToOne) {
      return (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant={"dot"}
          onlineStatus={conversation?.user?.onlineStatus}
        >
          <Avatar />
        </StyledBadge>
      );
    }
    return <Avatar />;
  };

  const showInstituteIcon =
    conversation?.instituteId &&
    !isInstituteAdmin &&
    conversation?.type !== conversationTypes.ONE_TO_ONE;

  return (
    <li
      className="chat-list-item-container"
      style={selected ? { backgroundColor: "#e6ebff" } : {}}
      onClick={onClickChat}
    >
      <div className="item-left-wrapper">
      {renderAvatar()}
      </div>
      <div className={"item-right-wrapper"}>
        <div className="item-content-wrapper">
          <div className={"texts-wrapper"}>
            <Tooltip
              placement="right"
              arrow
              title={name}
              style={{ width: "73%" }}
            >
              <span className="item-text room-name">
              {name}
              </span>
            </Tooltip>
            <span className={`item-text message-time ${hasUnreadCount?'unread-message-time':''}`}>
              {
                message?.createdAt && getDateTimeDifference(message?.createdAt)
              }
            </span>
          </div>
          <div className={"texts-wrapper"}>

          <span className={`item-text message-text ${hasUnreadCount?'unread-message-text':''}`}>{messageText}</span>
          {hasUnreadCount && (
            <Badge
              color="primary"
              badgeContent={conversation?.unreadCount}
              sx={{ top: 0, right: 8 }}
              style={{ padding: 0 }}
            />
          )}
        </div>
        </div>
        <hr
          className="chats-list-border"
        />
      </div>
    </li>
  );
}
