import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, IconButton, Tooltip, Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAautiLoader } from "../../../redux/reducer/appReducer";
import {
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "../../../service/api-constants";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import AautiUpload from "../../globalComponents/AautiUpload";
import { showToast } from "../../globalComponents/Toast";
import constantSizes from "../commonInstitutes/CommonConstants";
import DialogTopBar from "../commonInstitutes/DialogTopBar";
import { InstituteAuthService } from "../instituteServices/institute-api-services";
import ButtonsComponent from "../commonInstitutes/ButtonsComponent";
import AautiDialog from "../../globalComponents/AautiDialog";

const CreateOrEditInstituteInformation = ({
  data,
  onUpdateList,
  setEditInstituteData,
  addNewSectioninCreate,
  removeSection,
}) => {
  const dispatch = useDispatch();
  const { instituteId } = useSelector((state) => state.app);
  const [previewImage, setPreviewImage] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [createItem, setCreateItem] = useState([]);
  const isCreate = Array.isArray(data);

  const MainHeading = !isCreate
    ? "Update Institute Information"
    : "Add Institute Information";

  useEffect(() => {
    if (isCreate) {
      setCreateItem(data);
    } else {
      setEditItem(data);
    }
  }, [data]);

  const onChangeFeilds = (feild, value, position) => {
    if (isCreate) {
      const newList = createItem?.map((item, index) => {
        if (index == position) {
          return { ...item, [feild]: value };
        } else {
          return item;
        }
      });
      setCreateItem(newList);
    } else {
      setEditItem({ ...editItem, [feild]: value });
    }
  };

  const checkValidations = () => {
    let isValid = true;
    (isCreate ? createItem : [editItem]).forEach((each) => {
      if (
        each?.header?.length < 3 ||
        each?.description?.length < 50 ||
        each?.description?.length > 1000 ||
        !each?.uploadImage
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  const uploadImage = async (file, index, field) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD;

    try {
      const res = await fetch(url, { method: "post", body: formData });
      const responseJson = await res.json();
      if (responseJson.result) {
        onChangeFeilds(
          field,
          isCreate
            ? [...createItem[index][field], responseJson.result]
            : [...editItem.uploadImage, responseJson.result],
          index
        );
      } else {
        showToast("error", "Image upload failed. Please try again.");
      }
    } catch (error) {
      showToast("error", "Image upload failed. Please try again.");
    }
  };

  const onChangeFun = (val, field, index) => {
    const file = val.target.files[0];
    uploadImage(file, index, field);
  };

  const closeDialog = () => {
    setEditInstituteData(null);
  };

  const handleSaveAndAdd = () => {
    isCreate ? createBranch() : updateBranch();
  };

  const updateBranch = () => {
    dispatch(setAautiLoader(true));
    InstituteAuthService.updateInstituteInformation(
      editItem,
      onSuccessUpdateInstituteBranch,
      onFailureUpdateInstituteBranch
    );
  };

  const onSuccessUpdateInstituteBranch = (response) => {
    dispatch(setAautiLoader(false));
    showToast("success", response?.message);
    if (onUpdateList) onUpdateList();
    closeDialog();
  };

  const onFailureUpdateInstituteBranch = (error) => {
    dispatch(setAautiLoader(false));
    showToast("error", error);
    closeDialog();
  };

  const createBranch = () => {
    dispatch(setAautiLoader(true));
    InstituteAuthService.createInstituteInformation(
      createItem,
      onSuccessCreateInstituteBranch,
      onFailureCreateInstituteBranch
    );
  };

  const onSuccessCreateInstituteBranch = (response) => {
    dispatch(setAautiLoader(false));
    showToast("success", response?.message);
    if (onUpdateList) onUpdateList();
    closeDialog();
  };

  const onFailureCreateInstituteBranch = (error) => {
    dispatch(setAautiLoader(false));
    showToast("error", error);
    closeDialog();
  };

  const handlePreviewClose = () => {
    setPreviewImage(null);
    setOpenPreview(false);
  };

  return (
    <>
      <AautiDialog
        keepMounted
        open={data}
        onClose={closeDialog}
        maxWidth="500px"
      >
        <AautiText
          size="medium"
          weight={"bold"}
          title={MainHeading}
          textAlign={"center"}
          textStyle={{ marginBottom: 5 }}
        />
        {isCreate && (
          <Button
            variant="outlined"
            size="small"
            sx={{ width: "100%", flex: 1, marginY: 1 }}
            onClick={() => {
              setCreateItem([
                ...createItem,
                {
                  header: "",
                  description: "",
                  uploadImage: [],
                  instituteId: instituteId,
                  deleted: false,
                },
              ]);
            }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Section
          </Button>
        )}

        <Box sx={{ overflow: "scroll", width: "100%" }}>
          {(isCreate ? createItem : [editItem])?.map((item, index) => (
            <Card
              sx={{ marginY: 1, width: "100%", borderColor: "red", p: 1.5 }}
              key={index}
            >
              {isCreate && (
                <DialogTopBar
                  textAlign={"center"}
                  textStyle={{ marginBottom: 5 }}
                  onClose={() => removeSection(index)}
                />
              )}
              <AautiTextInput
                text={"Title"}
                important
                style={{ width: "100%" }}
                value={item?.header}
                onChange={(e) =>
                  onChangeFeilds("header", e.target.value, index)
                }
              />
              <AautiText
                size={constantSizes.aautiTextSmall}
                weight={constantSizes.aautiTextWeightNormal}
                title={`(Minimum 3 Characters)`}
                textStyle={{ marginBottom: 5 }}
              />
              <AautiTextInput
                text={"Description"}
                important
                multiline
                rows={3}
                style={{ width: "100%", marginBottom: 1 }}
                value={item?.description}
                onChange={(e) =>
                  onChangeFeilds("description", e.target.value, index)
                }
              />
              <AautiText
                size={constantSizes.aautiTextSmall}
                weight={constantSizes.aautiTextWeightNormal}
                title={`${item?.description?.length}/1000 (Minimum 50 Characters)`}
                textStyle={{ marginBottom: 5 }}
              />
              <AautiUpload
                accept="image/*"
                iconName="Upload Image"
                important
                id={`UploadImage-${index}`}
                onChange={(e) => onChangeFun(e, "uploadImage", index)}
              />
              {Array.isArray(item?.uploadImage) &&
                item?.uploadImage?.map((each, imgIndex) => (
                  <Tooltip title="Preview" placement="bottom" key={imgIndex}>
                    <div
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: 6,
                        padding: 5,
                        position: "relative",
                        height: 110,
                        width: 140,
                        marginTop: 10,
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const imageUrl = each.startsWith("http")
                          ? each
                          : `${UploadBaseUrl}/${each}`;
                        setPreviewImage(imageUrl);
                        setOpenPreview(true);
                      }}
                    >
                      <img
                        src={
                          each.startsWith("http")
                            ? each
                            : `${UploadBaseUrl}/${each}`
                        }
                        alt="Uploaded"
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "6px",
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onChangeFeilds(
                            "uploadImage",
                            isCreate
                              ? createItem[index].uploadImage.filter(
                                  (_, i) => i !== imgIndex
                                )
                              : editItem.uploadImage.filter(
                                  (_, i) => i !== imgIndex
                                ),
                            index
                          );
                        }}
                        sx={{
                          position: "absolute",
                          right: 1,
                          background: "#fff",
                          "&:hover": {
                            background: "#fff",
                          },
                        }}
                      >
                        <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                ))}
            </Card>
          ))}
        </Box>
        <ButtonsComponent
          onClickButton1={() => closeDialog()}
          onClickButton2={() => handleSaveAndAdd()}
          btnOneText={"Cancel"}
          btnTwoText={!isCreate ? "Update" : "Submit"}
          btnTwodisabled={!checkValidations()}
        />
      </AautiDialog>

      <Dialog open={openPreview} onClose={handlePreviewClose}>
        {previewImage && (
          <img
            src={previewImage}
            alt="Preview"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default CreateOrEditInstituteInformation;
