import React, { useEffect, useState } from "react";
import { PartType } from "aauti_lessonplan";
import "./style.scss";
import { useParams } from "react-router-dom";
import { getLessonPlanById } from "../createLessonPlan/Services";
import { getCourseDetails } from "../course/courseDetails/Services";
import { getDraftCourseById } from "../course/myCourses/Services";
import CircularProgress from "@mui/material/CircularProgress";

const PartTypeComponent = (props) => {
  const params = useParams();
  const {
    id,
    noOfSessions = 0,
    isCourseId = false,
    isDraftId = false,
    category = undefined,
    subCategory = undefined,
  } = params;
  const [nativeData, setNativeData] = useState();
  const [loader, setLoader] = useState(false);
  const [localCategory, setLocalCategory] = useState(category);
  const [localSubCategory, setLocalSubCategory] = useState(subCategory);

  const onSuccess = (res) => {
    if (isCourseId === "true" || isDraftId === "true") {
      let data = res?.result?.[0]?.lessonPlan;
      setNativeData(data);
      setLocalCategory(data?.category);
      setLocalSubCategory(data?.subCategory);
    } else {
      setNativeData(res?.result);
      setLocalCategory(res?.result?.category);
      setLocalSubCategory(res?.result?.subCategory);
    }
    setLoader(false);
  };

  const onFailure = (err) => {
    setLoader(false);
  };

  const ApiCall = (id) => {
    if (isCourseId === "true") {
      getCourseDetails(false, id, "", onSuccess, onFailure);
    } else if (isDraftId === "true") {
      getDraftCourseById(id, onSuccess, onFailure);
    } else {
      getLessonPlanById(id, onSuccess, onFailure);
    }
  };

  useEffect(() => {
    if (id != null) {
      //loader should be here
      setLoader(true);
      ApiCall(id);
    }
    console.log("in useefffect?.......");
  }, [params]);

  console.log("im in?.......");

  const sendMessage = (data) => {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  };

  return (
    <div className="main-div-web">
      {loader ? (
        <div className="loader-div">
          <CircularProgress />
        </div>
      ) : (
        <PartType
          fromWeb={false}
          onSubmit={(data) => sendMessage(data)}
          data={nativeData}
          noOfSessions={Number(noOfSessions)}
          category={localCategory}
          subCategory={localSubCategory}
        />
      )}
    </div>
  );
};

export default PartTypeComponent;
