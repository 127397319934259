import React, { useEffect, useRef, useState } from "react";
import Offline from "./components/offline/Offline";
import Router from "./routes/Router";
import "./App.css";
import { AppProvider } from "./utils/AppContext";
import Header from "./components/header/Header";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css";
import { updateMediaQueries } from "./redux/reducer/responsiveReducer";
import { ToastProvider } from "./reused/Provider";
import ScrollToTop from "./components/globalComponents/ScrollToTop";
import {
  setActiveGigObject,
  setUserLocation,
} from "./redux/reducer/appReducer";
import { ErrorBoundary } from "react-error-boundary";
import { CommonFunctions } from "./components/CommonFunctions";
import InComingCallCompo from "./components/inComingCallCompo";
import { AuthService } from "./service";
import ErrorAauti from "./components/globalComponents/ErrorAauti";
// import { chatUrl } from "./service/api-constants";
import AautiDialog from "./components/globalComponents/AautiDialog";
import { isEmpty, isObject } from "lodash";
import GigComments from "./components/gig/gigComments/GigComments";
// import JoinButton from "./components/globalComponents/JoinButton";
import { useMediaQuery } from "@mui/material";
import HeaderBeforeLogin from "./components/homeScreen/header/header";
import TrasactionCountry from "./components/globalComponents/TrasactionCountry";
import SocketProvider from "./components/chat/SocketProvider";
import { checkNull } from "./utils/Helpers";
import {
  startVersionChecker,
  stopVersionChecker,
} from "./utils/AppVersionCheck";
// const loggedinUserId = localStorage.getItem("USER_ID");
// const isUserLoggedIn = localStorage.getItem("Logged In") === "true";

// export const socket =
//   isUserLoggedIn && new WebSocket(`${chatUrl + loggedinUserId}`);
// const renderSocketConnection = () => {
//   const loggedinUserId = localStorage.getItem("USER_ID");
//   const isUserLoggedIn = localStorage.getItem("Logged In") === "true";

//   const connect = () => {
//     if (!isUserLoggedIn) return null; // If the user is not logged in, return null

//     const socket = new WebSocket(`${chatUrl + loggedinUserId}`);

//     socket.onopen = () => {
//       console.log("WebSocket connected");
//     };

//     socket.onmessage = (event) => {
//       const message = JSON.parse(event.data);
//       if (message.event === "PING") {
//         const response = { event: "PONG" };
//         socket.send(JSON.stringify(response));
//       }
//     };

//     socket.onclose = () => {
//       console.log("WebSocket closed. Reconnecting...");
//       setTimeout(connect, 1000); // Reconnect after 1 second
//     };

//     socket.onerror = (error) => {
//       console.error("WebSocket error:", error);
//       socket.close(); // Close the socket and reconnect
//     };

//     return socket; // Return the socket instance
//   };

//   return connect(); // Connect and return the socket
// };

// Initialize and export the socket
// export const socket = renderSocketConnection();

const App = () => {
  const windowLocation = window.location.href;
  const [online, setOnline] = useState(true);
  const [callData, setCallData] = useState({});
  const [calDialogOpen, setCallDialogOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const dispatch = useDispatch();
  const {
    getCurrencyGeneratorApi,
    fetchUserDetails,
    fetchInstituteDetails,
    GetCountriesApi,
    storeAllLanguages,
    getAllCategories,
    getUserLocation,
    getIpbaseLocation,
    getAautiProperties,
    getTrusteeRecords,
  } = CommonFunctions();
  // const [cookiesPop, setCookiesPop] = useState(false);
  const {
    isUserLoggedIn,
    loggedinUserId,
    paymentLoading,
    countriesArray,
    instituteId,
    UserRole,
    activeGigObject,
    userDetails,
    userLocation,
    countryObject,
    FirstName,
  } = useSelector((state) => state.app);

  const { mobileDevices, lapy } = useSelector((state) => state.responsive);

  const { pathname } = window.location;
  const socketRef = useRef(null);

  const show = !isUserLoggedIn && pathname?.includes("/institute/profile");

  const ShowHeader =
    pathname !== "/join_class/aautimeet" &&
    !paymentLoading &&
    // pathname !== "/institute/sign-up" &&
    !pathname?.includes("/institute/webDesign") &&
    !pathname?.includes("/part-types") &&
    !pathname?.includes("/part-type-view") &&
    !show;
  // &&
  // !pathname?.includes("/register/select-profession") &&
  // !pathname?.includes("/register/select-subcategories")

  // const connect = () => {
  //   const socket =
  //     isUserLoggedIn && new WebSocket(`${chatUrl + loggedinUserId}`);
  //   socketRef.current = socket;

  //   socket.onopen = (event) => {
  //     console.log("webcosket conneted");
  //   };

  //   socket.onmessage = (event) => {
  //     const message = JSON.parse(event.data);
  //     if (message.event === "PING") {
  //       const response = { event: "PONG" };
  //       socket.send(JSON.stringify(response));
  //     } else if (message.event === "CALL_REQUEST") {
  //       setCallData(message?.data);
  //       setCallDialogOpen(true);
  //       if (message?.data?._id) {
  //         const onSuccessData = (response) => {
  //           if (response.status === "Success") {
  //             setUserData(response?.result);
  //           }
  //         };
  //         const onFailureData = (error) => {
  //           console.error("Error fetching user data", error);
  //         };
  //         AuthService.GetuserDataById(
  //           message?.data?._id,
  //           onSuccessData,
  //           onFailureData
  //         );
  //       }
  //     }
  //   };

  //   socket.onclose = (event) => {
  //     setTimeout(connect, 1000);
  //   };

  //   socket.onerror = (error) => {
  //     setTimeout(connect, 1000);
  //   };
  // };
  // useEffect(() => {
  //   if (isUserLoggedIn) {
  //     connect();
  //   }
  //   return () => {
  //     if (socketRef.current) {
  //       socketRef.current.close();
  //     }
  //   };
  // }, [isUserLoggedIn]);

  useEffect(() => {
    GetCountriesApi();
    storeAllLanguages();
    getAllCategories();
    if (ShowHeader) {
      // getUserLocation()
      //   .then((location) => {
      //     dispatch(setUserLocation(location));
      //     GetCountriesApi(location?.country);
      //   })
      //   .catch((error) => {
      //     console.error("Error getting user location:", error);
      //   });
      getIpbaseLocation();
    }

    if (isUserLoggedIn) {
      fetchUserDetails(loggedinUserId, countriesArray);
      getTrusteeRecords(loggedinUserId);
      if (UserRole.includes("instituteAdmin")) {
        fetchInstituteDetails(instituteId);
      }
      getAautiProperties();
    }

    getCurrencyGeneratorApi();
    const onlineHandler = () => {
      setOnline(true);
    };
    const offlineHandler = () => {
      setOnline(false);
    };
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);
    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, [loggedinUserId]);

  useEffect(() => {
    startVersionChecker();
    return () => {
      stopVersionChecker();
    };
  }, []);

  const isMobile = useMediaQuery("(max-width: 480px)");
  const isAboveLargeMobileDevices = useMediaQuery(
    "(min-width: 481px) and (max-width: 600px)"
  );
  const isIpodDevices = useMediaQuery(
    "(min-width: 601px) and (max-width: 980px)"
  );
  const isMiniLaptop = useMediaQuery(
    "(min-width: 981px) and (max-width: 1300px)"
  );
  const isLaptop = useMediaQuery("(min-width: 1301px) and (max-width: 1500px)");
  const isDesktop = useMediaQuery(
    "(min-width: 1501px) and (max-width: 2700px)"
  );

  useEffect(() => {
    const updateMedia = () => {
      dispatch(
        updateMediaQueries({
          mobileDevices: isMobile,
          aboveLargeMobileDevices: isAboveLargeMobileDevices,
          ipodDevices: isIpodDevices,
          miniLapys: isMiniLaptop,
          lapy: isLaptop,
          desktop: isDesktop,
        })
      );
    };

    updateMedia();
  }, [
    dispatch,
    isMobile,
    isAboveLargeMobileDevices,
    isIpodDevices,
    isMiniLaptop,
    isLaptop,
    isDesktop,
  ]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setCookiesPop(true);
  //   }, 10000); // 60000 milliseconds = 1 minute

  //   return () => clearTimeout(timer);
  // }, []);

  // const handleAcceptCookies = () => {
  //   Cookies.set("searchHistory", [], { expires: 365 });
  //   setCookiesPop(false);
  // };

  return (
    <div className="App">
      <SocketProvider>
        <ErrorBoundary fallback={<ErrorAauti />}>
          <AppProvider>
            <BrowserRouter>
              <ScrollToTop />
              <ToastProvider>
                {online ? (
                  <div>
                    {calDialogOpen && (
                      <InComingCallCompo
                        {...{
                          callData,
                          calDialogOpen,
                          setCallDialogOpen,
                          userData,
                        }}
                      />
                    )}
                    {/* {cookiesPop &&
                    pathname !== "/myLesson-plans" &&
                    pathname !== "/create-lesson-plan" &&
                    Cookies.get("searchHistory") === undefined && (
                      <CookiePop
                        onReject={() => {
                          Cookies.set("searchHistory", null, { expires: 365 });
                          setCookiesPop(false);
                        }}
                        onAccept={() => {
                          handleAcceptCookies();
                        }}
                      />
                    )} */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <>
                        {isUserLoggedIn
                          ? ShowHeader && <Header />
                          : ShowHeader && (
                              <HeaderBeforeLogin {...{ ShowHeader }} />
                            )}

                        <div
                          style={{
                            marginTop: ShowHeader
                              ? isUserLoggedIn
                                ? 60
                                : 70
                              : "",

                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Router />
                          {/* {returnPath() && <Footer />} */}
                        </div>
                      </>
                    </div>
                    <AautiDialog
                      sxStyles={{ padding: "0px !important" }}
                      showHeader={false}
                      maxWidth={"80vw"}
                      dialogTitle={"Comments"}
                      open={!isEmpty(activeGigObject)}
                      onClose={(e) => {
                        e.stopPropagation();
                        dispatch(setActiveGigObject({}));
                      }}
                    >
                      <GigComments
                        gigObject={activeGigObject}
                        handleClose={(e) => {
                          e.stopPropagation();
                          dispatch(setActiveGigObject({}));
                        }}
                      />
                    </AautiDialog>
                    <AautiDialog
                      sxStyles={{ padding: "10px !important" }}
                      showHeader={false}
                      showCloseIcons={false}
                      // maxWidth={mobileDevices ? "50vw" : "35vw"}
                      dialogWidth={mobileDevices ? "80vw" : "38vw"}
                      dialogTitle={"Comments"}
                      open={
                        !isEmpty(userDetails) &&
                        isObject(userDetails) &&
                        isUserLoggedIn &&
                        !userDetails?.transactionCountry &&
                        !userDetails?.parentId &&
                        localStorage.getItem("Logged In") === "true" &&
                        FirstName &&
                        loggedinUserId
                      }
                    >
                      <TrasactionCountry />
                    </AautiDialog>
                  </div>
                ) : (
                  <Offline />
                )}
              </ToastProvider>
            </BrowserRouter>
          </AppProvider>
        </ErrorBoundary>
      </SocketProvider>
    </div>
  );
};

export default App;
