import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./styles.scss";
import { Box, Button } from "@mui/material";
import { VectorUnderLineIcon } from "../container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../reducer";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: "solid 1px #dfdfdf",
    paddingTop: 7,
    paddingBottom: 7,
    width: "100%",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "30px" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#ffffff",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FaqAccordion({ type = "regular" }) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    dispatch(getFaq({ type }));
  }, [type]);

  const { faqData } = useSelector((state) => state.homePageReducer);
  console.log(faqData);

  const handleExpandAll = () => {
    setExpanded(expanded === "all" ? false : "all");
  };

  return (
    <section id="FAQ_CONTAINER">
      <Box className="faq_label_container">
        <p className="faq_label">Frequently Asked <span className="faq_label_span">Questions</span></p>
        <VectorUnderLineIcon />
      </Box>

      <Box className="faq_accordion">
        {faqData?.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === "all" || expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography className="faq_title">{faq?.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq_description">{faq?.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box className="view_all_faqs_container">
          <Button className="view_all_faqs" onClick={handleExpandAll}>
            {expanded === "all" ? "Collapse All FAQ's" : "View All FAQ's"}
          </Button>
        </Box>
      </Box>
    </section>
  );
}
