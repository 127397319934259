const today = new Date();

export const empty = {
  title: "",
  questions: [
    {
      type: "Question",
      description: "",
      points: 0,
      uploadedFiles: [],
    },
  ],
  startDate: today,
  enablePoints: false,
  totalPoints: 0,
  endDate: today,
  assignedTo: "Everyone",
  assignedUsers: [],
};

export const AssignmentApiPaths = {
  CREATE_ASSIGNMENT: "assignmentCreation/save",
  GET_ASSIGNMENT_CREATION_BY_ID: "assignmentCreation/getById",
  GET_DRAFT_ASSIGNMENT_CREATION_BY_ID:
    "draftAssignment/getDraftAssignmentCreationById",
  UPDATE_ASSIGNMENT: "assignmentCreation/update",
  UPDATE_ASSIGNMENT_CORRECTION:
    "assignmentCreation/updateAssignmentCorrectionPoints",
  UPDATE_DRAFT_ASSIGNMENT: "draftAssignment/updateDraftAssignmentCreation",
  DELETE_ASSIGNMENT: "assignmentCreation/delete",
  DELETE_DRAFT_ASSIGNMENT: "draftAssignment/deleteDraftAssignmentCreation",
  GET_ASSIGNMENT_USERLIST_BY_ID:
    "assignmentCreation/getAssignmentUsersListById",
  GET_ASSIGNMENTDETAILS_BY_SPID: "assignmentCreation/getUserAssignmentBySpId",
  GET_ASSIGNMENTDETAILS_BY_EUID:
    "assignmentCreation/getassignmentDetailsByendUserId",
  GET_ASSIGNMENT_DETAILS_BY_BATCHID_SESSIONID:
    "assignmentCreation/getAssignmentDetailsByBatchIdOrSessionId",
  GET_ENDUSER_ASSIGNMENTS_LIST_BY_ID:
    "assignmentCreation/getAssignmentsListByEndUserId?",
  ENDUSER_ASSIGNMENT_SUBMISSION: "assignmentCreation/submitAssignment",
  GET_ASSIGNMENTDETAILS_ANSWERS_EU:
    "assignmentCreation/getassignmentDetailsByendUserIdAnswers",
  SAVE_DRAFT_ASSIGNMENT: "draftAssignment/saveDraftAssignmentCreation",
  GET_ALL_DRAFTS_BY_BATCHID_SPID_TYPE:
    "draftAssignment/getDraftAssignmentDetailsByBatchIdOrSessionIdOrServiceProviderId",

  GET_COMPLETE_ASSIGNMENT_BYEUID:
    "assignmentCreation/getCompleteAssignmentOrTaskOfEndUser",
};
export const options = ["Edit", "Delete"];
