import { AuthService } from "../../service";
import { IPStickAccessKey } from "../../service/api-constants";

export const getUserCurrentLocation = async () => {
  return new Promise(async (resolve, reject) => {
    const fetchIpLocation = async () => {
      try {
        const ipRes = await fetch("https://api.ipify.org?format=json");
        const { ip } = await ipRes.json();
        const coordsRes = await fetch(
          `https://api.ipstack.com/${ip}?access_key=${IPStickAccessKey}`
        );
        const { latitude, longitude } = await coordsRes.json();

        if (latitude && longitude) {
          return new Promise((ipResolve, ipReject) => {
            AuthService.getUserLocationByLatLang(
              latitude,
              longitude,
              (response) => {
                if (response?.result) {
                  const locationData = {
                    ...response.result,
                    latitude,
                    longitude,
                  };
                  ipResolve(locationData);
                } else {
                  ipReject("User location not found");
                }
              },
              (err) => {
                ipReject(err);
              }
            );
          });
        } else {
          throw new Error("Invalid coordinates from IP lookup");
        }
      } catch (error) {
        throw error;
      }
    };

    if (!navigator.geolocation) {
      try {
        const ipLocation = await fetchIpLocation();
        resolve(ipLocation);
        return;
      } catch (error) {
        reject("Navigator and IP location unavailable");
        return;
      }
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        if (latitude && longitude) {
          AuthService.getUserLocationByLatLang(
            latitude,
            longitude,
            (response) => {
              if (response?.result !== null) {
                const obj = {
                  ...response.result,
                  latitude,
                  longitude,
                };
                resolve(obj);
              } else {
                reject("User location not found");
              }
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject("Latitude and longitude not found");
        }
      },
      async (error) => {
        try {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              const ipLocation = await fetchIpLocation();
              resolve(ipLocation);
              break;
            case error.POSITION_UNAVAILABLE:
              throw new Error("Location information is unavailable.");
            case error.TIMEOUT:
              throw new Error("Location request timed out.");
            default:
              throw new Error("Unknown location error");
          }
        } catch (ipError) {
          reject(ipError.message);
        }
      }
    );
  });
};
