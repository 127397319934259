export const chatbotConstants = {
  wouldYouLikeToContinueOrEndTheConversation:
    "Would you like to continue or end the conversation?",
  noCoursesAvailable: "No courses available",
  failedToFetchCourses: "Failed to fetch courses",
  failedToFetchGigs: "Failed to fetch gigs",
  noGigsAvailable: "No gigs available",
  continue: "Continue",
  end: "End",
  noLearningsAvailable: "No learnings available",
  pastelRed: "pastelRed",
  failedToFetchLearnings: "Failed to fetch learnings",
  thankYouTheChatWillNowRestart: "Thank you! The chat will now restart.",
  howCanIHelpYouPleaseSelectTheBelowOptions:
    "How can I help you? Please select the below options:",
  myLearnings: "My Learnings",
  myGigs: "My Gigs",
  refundStatus: "Refund Status",
  support: "Support",
};
