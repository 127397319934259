export const CalenderStrings = {
  classDocuments: "Class Documents",
  altText: "related-image",
  batchDetails: "Batch Details",
  joinWillbeEnabled:
    "Join will be enabled 15 minutes prior to the announcement of the class",
  courseReviews: "Course Reviews",
  fileUploadFailed: "File upload failed",
  errorDuringUpload: "Error during file upload",
  nodata: "No Data",
  agenda: "Create Agenda",
  selectSession: "Select Session",
  addMore: "Add More",
  descrioption: "Description",
  addFiles: "Add Files",
  publish: "Publish",
  update: "Update",
  updateToDraft: "Update to Drafts",
  saveToDraft: "Save to Drafts",
  areYouSure: "Are you sure you want to delete?",
  yes: "Yes",
  no: "No",
  agendaDetails: "Agenda Details",
  noDataFound: "No Data Found",
  howCanIHelpYou: "How can i help you today? 😄",
  asYourQurey: "Ask your query",
  subMissionDateExp: "Submission date expired",
  haveNotSubmittedAsst: "Haven't submitted the Assignment",
  haveNotSubmittedTask: "Haven't submitted the Task",
  haveNotSubmittedQuiz: "Haven't submitted the Quiz",
  assignedTo: "Assigned to",
  assigned: "Assigned",
  startDate: "Start Date",
  endDate: "End Date",
  results: "Results",
  search: "Search",
  cancel: "Cancel",
  done: "Done",
  points: "Points",
  addQuestions: "Add Questions",
  addOptions: "Add Options",
  answer: "Answer",
  exceedOption: "Exceeds the options",
  enablePoints: "Enable Points",
  options: "Options",
  students: "Students",
  yourAnswer: "Your Answer",
  enterHere: "Enter Here",
  files: "Files",
  submit: "Submit",
  question: "Question",
  close: "Close",
  title: "Title",
  materialUploaded: "Material Uploaded",
  addFile: "Add File*",
  uploadYourFile: "Upload Media&Files*",
  noMedia: "No Media",
  noLessonPlanFound: "No Lesson Plan Found",
  descriptionReq: "Description :",
  content: "Content :",
  generateUsingAi: "Generate using AI",
};
