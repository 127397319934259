// ToastProvider.js
import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ToastContext = createContext();
export function ToastProvider({ children }) {
  const showToast = (message, options) => {
    toast(message, options);
  };

  return (
    <ToastContext.Provider value={showToast}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
}
export function Alerts() {
  return useContext(ToastContext);
}
