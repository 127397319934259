
const  constantSizes = {

 small:'small',
 large:'large',
 extraLarge:'extralarge',
 big:'big',

 bold:'bold',
 normal:'normal',

 aautiTextSmall :'small',
 aautiTextNormal:'normal0',
 aautiTextWeightNormal:'normal'
}

export default constantSizes;