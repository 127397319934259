import React from "react";

import Icon from "../../../assets/images/Share.svg";
import { RWebShare } from "react-web-share";
import { IconButton, Tooltip } from "@mui/material";

const ShareIcon = ({
  height = "20px",
  width = "20px",
  shareUrl,
  icon = Icon,
  id = "share-icon",
  noShare = false,
}) => {
  const url = shareUrl || window.location.origin;

  const IconButtonContent = (
    <Tooltip title="Share">
      <IconButton
        id={id}
        sx={{
          height,
          width,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          p: 0,
        }}
      >
        <img
          src={icon}
          alt="share"
          style={{
            height: "100%",
            width: "100%",
            zIndex: 100,
          }}
        />
      </IconButton>
    </Tooltip>
  );

  return !noShare ? (
    <RWebShare
      onClick={(e) => {
        e?.stopPropagation();
      }}
      data={{
        text: "Aauti Marketplace",
        url,
        title: "Aauti",
      }}
    >
      {IconButtonContent}
    </RWebShare>
  ) : (
    IconButtonContent
  );
};

export default ShareIcon;
