import { useState, useRef, useEffect } from "react";
import { HiBuildingOffice } from "react-icons/hi2";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Slider from "@mui/material/Slider";
import JoinButton from "../globalComponents/JoinButton";
import { useDispatch, useSelector } from "react-redux";
import { BiCurrentLocation, BiSolidHome } from "react-icons/bi";
import MenuItem from "@mui/material/MenuItem";
import AautiText from "../globalComponents/AautiText";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { Box, FormControl, IconButton, Select, Tooltip } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { AuthService } from "../profile/Service";
import { showToast } from "../globalComponents/Toast";
import MapsComponent from "./MapsComponent";
import GoogleMaps from "./GoogleAutocomplete";
import { ImFlag } from "react-icons/im";
import _, { isEmpty } from "lodash";
import { Edit } from "@mui/icons-material";
import TrashIcon from "../globalComponents/icons/TrashIcon";
import { useLocation } from "react-router-dom";
import { CommonFunctions, IdGenerator } from "../CommonFunctions";
import { AautiStrings } from "../globalComponents/AautiStrings";
import { globalColors } from "../../themes/GlobalColors";
import { checkNull } from "../../utils/Helpers";
import { getUserCurrentLocation } from "./locationHelpers";
import { setUserdetails } from "../../redux/reducer/appReducer";
import { createCourseStrings } from "../course/courseCreation/constants";

const Address = [
  { id: 0, categoryName: "Home" },
  { id: 1, categoryName: "Office" },
  { id: 2, categoryName: "Other" },
];

const Location1 = (props) => {
  const {
    edit,
    handleSaveEditAddress,
    add,
    setActiveIndex,
    coordinates,
    setCoordinates,
    setCurrentLocClicked,
    setAddresses,
    errorMessage,
    titleMaximumLength,
    mobileDevices,
    onlyAddAddress,
    handleShowAdhocAddListing,
    editAddress,
  } = props;
  const header = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: mobileDevices ? "column" : "row",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: mobileDevices ? "flex-start" : "center",
          width: "100%",
          marginTop: 6,
          gap: mobileDevices && 8,
        }}
      >
        <AautiText
          textAlign={"left"}
          weight={"bold"}
          title={AautiStrings?.chooseYourLocationString}
        />
        <div
          style={{
            width: mobileDevices ? "100%" : "60%",
            position: "relative",
          }}
        >
          <GoogleMaps {...{ setCoordinates, getLocationByLatLan }} />
        </div>
      </div>
    );
  };
  const adressTypeList = add
    ?.map((each) => each.addressType)
    .filter((each) => each !== "Other");

  const getLocationByLatLan = (coordinates) => {
    const { lat, lng, formattedAddress } = coordinates;
    if (!checkNull(formattedAddress)) {
      props?.handleSetAddress({
        formattedAddress: formattedAddress?.description,
        latitude: lat,
        longitude: lng,
      });
      const obj = { lat, lng };
      setCoordinates(obj);
    } else {
      AuthService.getUserLocationByLatLang(
        lat,
        lng,
        (response) => {
          if (response?.result !== null) {
            let location = response?.result;
            props?.handleSetAddress({
              ...location,
              latitude: lat,
              longitude: lng,
            });
            const obj = { lat, lng };
            setCoordinates(obj);
          } else {
            showToast("info", response?.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const disableSave =
    props.addresses.addressType === "" ||
    props.addresses.address === "" ||
    (props?.addresses?.addressType === "Other" &&
      props.addresses?.name === "") ||
    errorMessage;

  const handleGetCoor = async () => {
    getUserCurrentLocation()
      .then((location) => {
        props?.handleSetAddress(location);
        const obj = { lat: location?.latitude, lng: location?.longitude };
        setCoordinates(obj);
        setCurrentLocClicked(true);
      })
      .catch((error) => {
        console.error("Error getting user location:", error);
      });
  };

  return (
    <div style={{ height: "97vh", overflow: "hidden", overflowY: "scroll" }}>
      {header()}
      <div style={{ background: "#fff" }}>
        <MapsComponent
          {...{ getLocationByLatLan, coordinates, setCoordinates }}
        />
      </div>
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 15,
            justifyContent: "flex-end",
            gap: 5,
          }}
        >
          <BiCurrentLocation color="#316bba" />
          <AautiText
            onClick={() => {
              handleGetCoor();
            }}
            title={AautiStrings?.useCurrentLocationString}
            textAlign={"right"}
            weight={"bold"}
            textStyle={{
              color: "#316bba",
              cursor: "pointer",
              zIndex: 20000000,
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginBottom: "10px",
            borderRadius: "8px",
          }}
        >
          <AautiText
            textStyle={{
              textAlign: "left",
              fontSize: "18px",
              marginBottom: "10px",
            }}
            title={createCourseStrings?.saveAddressAs}
          />
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              marginLeft: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginBottom: 5,
              }}
            >
              {Address.map((each, id) => {
                const disableButton = adressTypeList?.includes(
                  each?.categoryName
                );
                return (
                  <button
                    disabled={disableButton}
                    style={{
                      width: "45%",
                      height: 35,
                      padding: "2px 2px 2px 2px",
                      borderRadius: 5,
                      display: "flex",
                      border:
                        props.addresses.addressType === each.categoryName
                          ? `1px solid ${globalColors?.AAUTI_THEME_COLOR}`
                          : "1px solid #dddddd",
                      backgroundColor: disableButton
                        ? "lightgrey"
                        : props.addresses.addressType === each.categoryName
                        ? globalColors.AAUTI_THEME_COLOR
                        : "#ffffff",
                      boxSizing: "border-box",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      props.changeAddress(each.categoryName, "addressType")
                    }
                    key={id}
                  >
                    <AautiText
                      textStyle={{
                        fontWeight: 500,
                        color:
                          props.addresses?.addressType === each.categoryName
                            ? "#ffffff"
                            : "#1e1e1e",
                        fontSize: 14,
                      }}
                      title={each.categoryName}
                    />
                  </button>
                );
              })}
            </div>
          </div>
          {props.addresses?.addressType === "Other" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                marginBottom: "5px",
                //   alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AautiTextInput
                maxLength={50}
                important={true}
                style={{
                  width: "100%",
                }}
                value={props?.addresses?.name || ""}
                onChange={(e) => {
                  const value = e.target.value.trimStart().replace(/\s+/g, " ");
                  props.changeAddress(value, "name");
                }}
                text={createCourseStrings?.addressName}
              />
            </div>
          )}
          {props.addresses.address !== "" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                //   alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AautiTextInput
                labelStyle={{ zIndex: 201 }}
                important={true}
                style={{
                  width: "100%",
                }}
                readOnly={true}
                value={props.addresses.address}
                onChange={(e) => {
                  const value = e.target.value.trimStart().replace(/\s+/g, " ");
                  props.changeAddress(value, "address");
                }}
                text={createCourseStrings?.completeAddress}
              />
            </div>
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              //   alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AautiTextInput
              style={{
                width: "100%",
                // marginBottom: 10,
              }}
              maxLength={titleMaximumLength}
              value={props.addresses?.floor}
              onChange={(e) => {
                const value = e.target.value.trimStart().replace(/\s+/g, " ");
                props.changeAddress(value, "floor");
              }}
              text={createCourseStrings?.floorOptional}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AautiTextInput
              style={{
                width: "100%",
              }}
              value={props.addresses?.landMark}
              maxLength={titleMaximumLength}
              onChange={(e) => {
                const value = e.target.value.trimStart().replace(/\s+/g, " ");
                props.changeAddress(value, "landMark");
              }}
              text={createCourseStrings?.nearByLandMarkOptional}
            />
          </div>
          {errorMessage && (
            <AautiText
              title={errorMessage}
              size={"small"}
              textAlign={"center"}
              textStyle={{ color: "red", marginTop: 5 }}
            />
          )}
          <button
            onClick={() => {
              edit === true
                ? handleSaveEditAddress()
                : props.handleSaveaddress(props.addresses);
            }}
            disabled={disableSave}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              marginTop: 10,
              backgroundColor: disableSave
                ? "lightgrey"
                : globalColors.AAUTI_THEME_COLOR,
              boxSizing: "border-box",
              border: "0px",
              color: "white",
              fontSize: "16px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AautiText
              textStyle={{ color: "white", fontSize: "16px" }}
              title={createCourseStrings?.saveAddress}
            />
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <AautiText
              onClick={() => {
                if (onlyAddAddress) {
                  handleShowAdhocAddListing();
                } else {
                  setActiveIndex(0);
                }
                setAddresses({
                  id: IdGenerator(),
                  addressType: "",
                  address: "",
                  floor: "",
                  landMark: "",
                  name: "",
                  isDefault: false,
                });
                setCurrentLocClicked(false);
              }}
              title={AautiStrings?.backString}
              // textAlign={"right"}
              size={"small"}
              textStyle={{
                marginTop: "10px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseInPerson = (props) => {
  const {
    clientLocation = true,
    selectedAddress,
    CreateCourseObj = {},
    onlyAddAddress = false,
    handleShowAdhocAddListing = () => {},
    editAddress = {},
    isCourse = false,
  } = props;
  const [isVisible, setMapVisible] = useState(false);
  const [add, setadd] = useState([]);
  const [edit, setEdit] = useState(false);
  const {
    userDetails,
    userLocation,
    loggedinUserId,
    countryObject,
    properties,
  } = useSelector((state) => state.app);
  const [locationRadius, setLocationRadius] = useState(
    props?.CreateCourseObj?.radius || 0
  );
  const [radiusType, setRadiusType] = useState(
    CreateCourseObj?.radiusType || CreateCourseObj?.radiusUnits || "Km"
  );

  const { titleMaximumLength = 70 } = properties;
  // const {titleMax}=properties
  const { fetchUserDetails, getUserLocation } = CommonFunctions();
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [currentLocClicked, setCurrentLocClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [addressSelect, setAddressSelect] = useState(selectedAddress);

  const [coordinates, setCoordinates] = useState();
  const { pathname } = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const isProfile = pathname.includes("/profile");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [index, setActiveIndex] = useState(onlyAddAddress ? 1 : 0);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setDefaultFields = (userDetails) => {
    let filteredAddress = userDetails?.addressList
      ? [...userDetails?.addressList]
      : [];
    filteredAddress = filteredAddress?.filter((each) => each?.id);

    if (!isEmpty(selectedAddress)) {
      filteredAddress = filteredAddress?.map((item) => {
        if (selectedAddress?.id) {
          if (selectedAddress?.id === item?.id) {
            return { ...item, isDefault: true };
          } else {
            return { ...item, isDefault: false };
          }
        } else {
          return item;
        }
      });
    }

    setadd(filteredAddress);
    let data = filteredAddress?.find((each) => each.isDefault === true);

    if (isEmpty(addressSelect)) {
      isCourse && props.handleSelectAddress(data || filteredAddress[0]);
      setAddressSelect(data || filteredAddress[0]);
    }

    const coords = filteredAddress?.[0]?.latLong;
    const initialCoords = {
      lat: coords?.latitude ?? userLocation?.latitude ?? "",
      lng: coords?.longitude ?? userLocation?.longitude ?? "",
    };
    setCoordinates(initialCoords);
  };

  useEffect(() => {
    setDefaultFields(userDetails);
    if (!isEmpty(editAddress)) {
      setEdit(true);
      setMapVisible(true);
      setAddresses(editAddress);
    }
  }, [userDetails]);

  const defautlFields = {
    id: IdGenerator(),
    addressType: "",
    address: "",
    floor: "",
    landMark: "",
    name: "",
    isDefault: false,
  };
  const [addresses, setAddresses] = useState(defautlFields);
  const handleDeleteAddress = (item, i) => {
    const newlist = [...add];
    const filtered = _.reject(newlist, { id: item.id });
    const updateUser = { ...userDetails, addressList: filtered };
    handleUpdateAddress(updateUser);
    handleClose();
    // newlist?.splice(i, 1);
    // setadd(newlist);
  };

  const isAddressAvailable = add?.length > 0;
  // const handleDefaultAddress = (item) => {
  //   const newList = [...add];
  //   newList.map((each) => {
  //     if (each.id == item?.id) {
  //       return { ...each, isDefault: true };
  //     } else return { ...each, isDefault: false };
  //   });
  //   const updateUser = { ...userDetails, addressList: newList };
  //   handleUpdateAddress(updateUser);
  //   handleClose();
  // };
  const handleSaveEditAddress = () => {
    const newList = add.map((each) => {
      if (each?.id === addresses?.id) {
        let newAddress = { ...addresses };
        if (newAddress?.name === "") {
          newAddress.name = addresses.addressType;
        }
        return newAddress;
      } else {
        return each;
      }
    });

    setadd(newList);
    setEdit(false);
    setMapVisible(false);
    setActiveIndex(0);
    const updateUser = { ...userDetails, addressList: newList };
    handleUpdateAddress(updateUser);
    setAddresses(defautlFields);
  };

  const handleUpdateAddress = (updateUser) => {
    AuthService.updateUserDetails(
      updateUser,
      (res) => {
        fetchUserDetails(loggedinUserId);
        dispatch(setUserdetails(res?.result));
        setDefaultFields(res.result);
        setAddressSelect(
          res?.result?.addressList?.find((item) => item?.isDefault === true)
        );
        if (onlyAddAddress) {
          handleShowAdhocAddListing();
        } else {
          setActiveIndex(0);
        }

        setAddresses({
          id: IdGenerator(),
          addressType: "",
          address: "",
          floor: "",
          landMark: "",
          name: "",
        });
        setMapVisible(false);
      },
      (error) => {
        console.log(error, "error-updating address");
      }
    );
  };

  const handleSaveaddress = (obj) => {
    const { latLong } = obj;
    if (latLong?.latitude && latLong?.longitude) {
      let addObjec = obj;
      if (addObjec.name === "") {
        addObjec.name = addresses?.addressType;
      }
      if (isEmpty(add)) {
        addObjec.isDefault = true;
      }
      const addresList = add ? [...add, addObjec] : [addObjec];
      const updateUser = { ...userDetails, addressList: addresList };
      handleUpdateAddress(updateUser);
    } else {
      showToast("info", "Latitute & Longitudes not found");
    }
  };

  const menu1 = useRef(null);
  const menu2 = useRef(null);
  const defaulAddTypes = ["Home", "Office", "Other"];
  const changeAddress = (value, field) => {
    if (field == "name") {
      if (defaulAddTypes.includes(value)) {
        setErrorMessage(`${value} name not accepted`);
      } else {
        setErrorMessage("");
        setAddresses((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      }
    } else {
      setAddresses((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
    setAddresses((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleMapVisible = (val) => {
    setActiveIndex(1);
  };
  const dispatch = useDispatch();
  const handleSetAddress = (addre) => {
    const { formattedAddress, latitude, longitude } = addre;
    setAddresses((prevState) => ({
      ...prevState,
      address: formattedAddress,
      latLong: {
        latitude,
        longitude,
      },
    }));
  };
  const returnIcon = (each) => {
    if (each?.addressType === "Office") {
      return (
        <HiBuildingOffice
          style={{
            fontSize: "15px",
            marginTop: "2px",
            color: globalColors.AAUTI_THEME_COLOR,
          }}
        />
      );
    } else if (each?.addressType === "Home") {
      return (
        <BiSolidHome
          style={{
            fontSize: "15px",
            marginTop: "2px",
            color: globalColors.AAUTI_THEME_COLOR,
          }}
        />
      );
    } else {
      return (
        <ImFlag
          style={{
            fontSize: "15px !important",
            marginTop: "3px",
            color: globalColors.AAUTI_THEME_COLOR,
          }}
        />
      );
    }
  };
  const handleClientLocation = (check) => {
    const { changeCreateObj } = props;
    changeCreateObj(check, props?.isClientLocation);
  };

  const updateDefaultAddress = (each) => {
    let adresses = userDetails?.addressList
      ? [...userDetails?.addressList]
      : [];
    const newAarray = adresses?.map((add) => {
      if (add.id == each?.id) {
        return { ...add, isDefault: true };
      } else {
        return { ...add, isDefault: false };
      }
    });
    if (isProfile) {
      setadd(newAarray);
    } else {
      handleUpdateAddress({ ...userDetails, addressList: newAarray });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "100%",
        padding: "3%",
        height: "100%",
      }}
    >
      <Carousel
        sx={{
          "&.css-1f8sh1y": {
            height: "94vh",
          },
          height: "94vh",
        }}
        duration={200}
        swipe={false}
        indicators={false}
        autoPlay={false}
        animation="slide"
        index={index}
        stopAutoPlayOnHover={false}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={true}
        // className="nextbutton"
      >
        <div
          style={{
            width: "100%",
            height: mobileDevices ? "70vh" : "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                borderRadius: "6px",
              }}
            >
              <JoinButton
                className="disable-hover-join"
                buttonStyle={{
                  width: "100%",
                  marginTop: 10,

                  // marginRight: 10,
                  // marginLeft: 10,
                }}
                outlined
                title={createCourseStrings?.addNewAddress}
                onClick={() => handleMapVisible(true)}
                icon={<AddCircleOutlineOutlinedIcon />}
              />

              {isAddressAvailable && (
                <AautiText
                  title={createCourseStrings?.myAddress}
                  textStyle={{
                    fontSize: "16px",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                />
              )}
              {isAddressAvailable && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // flexWrap: "wrap",
                    // justifyContent: "space-between",
                    width: "100%",
                    gap: 10,
                    overflow: "scroll",
                  }}
                >
                  {add?.map((each, index) => {
                    return (
                      <Box
                        key={index}
                        display={"flex"}
                        flexDirection={"row"}
                        gap={1}
                        alignItems={"flex-start"}
                        mb={1}
                      >
                        <input
                          onChange={() => {
                            if (
                              !each?.isDefault ||
                              addressSelect?.id != each?.id
                            ) {
                              isCourse && props.handleSelectAddress(each);
                              setAddressSelect(each);
                              // updateDefaultAddress(each);
                            }
                          }}
                          checked={addressSelect?.id === each?.id}
                          type="radio"
                          style={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                        />
                        <button
                          onClick={() => {
                            (isCourse || isProfile) &&
                              props.handleSelectAddress(each);
                            setAddressSelect(each);
                            // updateDefaultAddress(each);
                          }}
                          style={{
                            width: "100%",

                            borderRadius: "4px",
                            border: "none",
                            backgroundColor: "#ffffff",
                            boxSizing: "border-box",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "85%",
                                flexDirection: "row",
                                gap: 6,
                                alignItems: "flex-start",
                              }}
                            >
                              {returnIcon(each)}
                              <AautiText
                                textStyle={{
                                  color: globalColors.AAUTI_THEME_COLOR,
                                  overflowWrap: "anywhere",
                                  width: "93%",
                                }}
                                title={
                                  each?.addressType == "Other"
                                    ? each?.name
                                    : each?.addressType
                                }
                                size={"semi"}
                                textAlign={"left"}
                              />
                            </div>
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                sx={{ p: 0.5 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEdit(true);
                                  setAddresses(each);
                                  handleMapVisible(true);
                                  handleClose();
                                }}
                              >
                                <Tooltip title="Edit">
                                  <Edit fontSize="small" />
                                </Tooltip>
                              </IconButton>

                              {add.length > 1 &&
                                addresses.addressType !== each.categoryName && (
                                  <TrashIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // handleClose();
                                      if (each?.isDefault) {
                                        showToast(
                                          "info",
                                          "Default address cannot be deleted"
                                        );
                                      } else {
                                        handleDeleteAddress(each, index);
                                      }
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                          <AautiText
                            title={each?.address}
                            size={"semi"}
                            textAlign={"left"}
                            textStyle={{ marginTop: 6, color: "#000" }}
                          />
                          <AautiText
                            title={`Floor: ${each?.floor}`}
                            size={"semi"}
                            textAlign={"left"}
                            textStyle={{
                              display: !each?.floor && "none",
                              marginTop: 6,
                              color: "#000",
                            }}
                          />
                          <AautiText
                            title={`Landmark: ${each?.landMark}`}
                            size={"semi"}
                            textAlign={"left"}
                            textStyle={{
                              display: !each?.landMark && "none",
                              marginTop: 6,
                              color: "#000",
                            }}
                          />
                        </button>
                      </Box>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              position: "sticky",
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              background: "#fff",
              zIndex: 202,
              boxShadow:
                "rgba(17, 17, 26, 0.05) 0px -1px 0px, rgba(17, 17, 26, 0.1) 0px -8px 8px",
            }}
          >
            {isAddressAvailable && (
              <div
                style={{
                  display: !clientLocation && "none",
                  width: "100%",
                  backgroundColor: "#ffffff",
                  marginLeft: "5",
                  marginTop: 15,
                  //   border: "1px solid #dddddd",
                  borderRadius: "5px",
                }}
              >
                {/* {false && ( */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 6,
                      marginBottom: 8,
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        props?.CreateCourseObj?.[props?.isClientLocation] ||
                        false
                      }
                      id="client-location"
                      style={{ height: "16px", width: "16px" }}
                      onChange={(e) => {
                        handleClientLocation(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="client-location"
                      style={{ cursor: "pointer" }}
                    >
                      <AautiText
                        title={createCourseStrings?.clientLocation}
                        textStyle={{ fontSize: "16px", userSelect: "none" }}
                      />
                    </label>
                  </div>
                  {props?.CreateCourseObj?.[props.isClientLocation] && (
                    <div style={{ width: "20%" }}>
                      <FormControl fullWidth size="small">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={radiusType}
                          onChange={(e) => {
                            setRadiusType(e.target.value);
                          }}
                        >
                          <MenuItem value={"Km"}>Km</MenuItem>
                          <MenuItem value={"Mile"}>Mile</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </div>
                {/* )} */}
              </div>
            )}
            {props?.CreateCourseObj?.[props?.isClientLocation] && (
              <div
                style={{
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingHorizontal: 20,
                  marginTop: "10px",
                  display: clientLocation ? "flex" : "none",
                }}
              >
                <Slider
                  max={50}
                  sx={{
                    width: "96%",
                    marginLeft: "11px",
                    ".css-cp2j25-MuiSlider-thumb": {
                      backgroundColor: globalColors.AAUTI_THEME_COLOR,
                    },
                    ".css-ttgsjq-MuiSlider-track": {
                      backgroundColor: globalColors.AAUTI_THEME_COLOR,
                      border: `1px solid ${globalColors.AAUTI_THEME_COLOR}`,
                    },
                  }}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  value={locationRadius || 0}
                  onChange={(e) => {
                    setLocationRadius(e.target.value);
                  }}
                />
                <AautiText
                  textAlign={"left"}
                  title={`Radius: ${locationRadius || 0} ${radiusType}s`}
                  textStyle={{ marginTop: "10" }}
                />
              </div>
            )}
            {isAddressAvailable && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <JoinButton
                  disabled={
                    props?.CreateCourseObj?.[props?.isClientLocation] &&
                    !locationRadius
                  }
                  buttonStyle={{
                    marginTop: 20,
                    width: 200,
                  }}
                  title={AautiStrings?.continueString}
                  onClick={() => {
                    if (
                      props?.CreateCourseObj?.[props?.isClientLocation] &&
                      !locationRadius
                    ) {
                      showToast("info", "radius cannot be 0");
                    } else {
                      if (clientLocation) {
                        props.changeCreateObj(locationRadius, "radius");
                        props.changeCreateObj(radiusType, "radiusType");
                      }
                      props?.handleSetSelectAddress(radiusType, add);
                      updateDefaultAddress(addressSelect);
                    }
                  }}
                  width="50%"
                />
              </div>
            )}
          </div>
        </div>
        <Location1
          {...{
            isVisible,
            add,
            menu2,
            menu1,
            edit,
            setEdit,
            currentLocClicked,
            setCurrentLocClicked,
            handleSaveEditAddress,
            handleMapVisible,
            changeAddress,
            addresses,
            handleSaveaddress,
            dispatch,
            userLocation,
            handleSetAddress,
            setActiveIndex,
            coordinates,
            setCoordinates,
            errorMessage,
            setAddresses,
            getUserLocation,
            titleMaximumLength,
            mobileDevices,
            onlyAddAddress,
            handleShowAdhocAddListing,
            editAddress,
          }}
          selectedAddress={selectedAddress}
          handleSelectAddress={props.handleSelectAddress}
        />
      </Carousel>
    </div>
  );
};

export default CourseInPerson;
