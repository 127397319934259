import React from "react";
import AautiSearch from "./AautiSearch";
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import { HiPlus } from "react-icons/hi";
import "./styles.scss";

const ContainerLayout = ({
  search,
  onChangeSearch,
  setSearch,
  scheduleRef,
  children,
  tooltipTitle = "Add Trustee",
  buttonIcon = <HiPlus />,
  buttonAction = () => {},
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <div className="container-layout">
      <div className="container-layout-header">
        <AautiSearch
          inputRef={scheduleRef}
          onClose={() => {
            setSearch("");
          }}
          addStyle={{
            minWidth: mobileDevices ? "calc(100% - 49px)" : "30%",
            maxWidth: 400,
          }}
          searchValue={search}
          handleSearchChange={onChangeSearch}
        />
        <Tooltip title={tooltipTitle}>
          <IconButton sx={{ padding: 0 }} onClick={buttonAction}>
            {React.cloneElement(buttonIcon, {
              className: "add-button",
              size: 20,
            })}
          </IconButton>
        </Tooltip>
      </div>
      {children}
    </div>
  );
};

export default ContainerLayout;
