"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var DEFAULT = {
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 1000 + Math.random() * 4000,
    reconnectionDelayGrowFactor: 1.3,
    connectionTimeout: 4000,
    maxRetries: 10, // Added max retries to prevent infinite retry loops
    maxEnqueuedMessages: Infinity,
    debug: false,
};
var AMPWebSocket = /** @class */ (function () {
    function AMPWebSocket(url, protocols, options) {
        if (options === void 0) { options = {}; }
        this._listeners = {
            error: [],
            message: [],
            open: [],
            close: [],
        };
        this._retryCount = -1;
        this._shouldReconnect = true;
        this._connectLock = false;
        this._binaryType = 'blob';
        this._closeCalled = false;
        this._messageQueue = [];
        /**
         * An event listener to be called when the WebSocket connection's readyState changes to CLOSED
         */
        this.onclose = null;
        /**
         * An event listener to be called when an error occurs
         */
        this.onerror = null;
        /**
         * An event listener to be called when a message is received from the server
         */
        this.onmessage = null;
        /**
         * An event listener to be called when the WebSocket connection's readyState changes to OPEN;
         * this indicates that the connection is ready to send and receive data
         */
        this.onopen = null;
        this._url = url;
        this._protocols = protocols;
        this._options = options;
        this._connect();
    }
    Object.defineProperty(AMPWebSocket, "CONNECTING", {
        get: function () {
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket, "OPEN", {
        get: function () {
            return 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket, "CLOSING", {
        get: function () {
            return 2;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket, "CLOSED", {
        get: function () {
            return 3;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "CONNECTING", {
        get: function () {
            return AMPWebSocket.CONNECTING;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "OPEN", {
        get: function () {
            return AMPWebSocket.OPEN;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "CLOSING", {
        get: function () {
            return AMPWebSocket.CLOSING;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "CLOSED", {
        get: function () {
            return AMPWebSocket.CLOSED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "binaryType", {
        get: function () {
            return this._ws ? this._ws.binaryType : this._binaryType;
        },
        set: function (value) {
            this._binaryType = value;
            if (this._ws) {
                this._ws.binaryType = value;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "retryCount", {
        get: function () {
            return Math.max(this._retryCount, 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "bufferedAmount", {
        get: function () {
            var bytes = this._messageQueue.reduce(function (acc, message) {
                if (typeof message === 'string') {
                    acc += message.length; // not byte size
                }
                else if (message instanceof Blob) {
                    acc += message.size;
                }
                else {
                    acc += message.byteLength;
                }
                return acc;
            }, 0);
            return bytes + (this._ws ? this._ws.bufferedAmount : 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "extensions", {
        /**
         * The extensions selected by the server. This is currently only the empty string or a list of
         * extensions as negotiated by the connection
         */
        get: function () {
            return this._ws ? this._ws.extensions : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "protocol", {
        /**
         * A string indicating the name of the sub-protocol the server selected;
         * this will be one of the strings specified in the protocols parameter when creating the
         * WebSocket object
         */
        get: function () {
            return this._ws ? this._ws.protocol : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "readyState", {
        /**
         * The current state of the connection; this is one of the Ready state constants
         */
        get: function () {
                return this._ws?.readyState;
            
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AMPWebSocket.prototype, "url", {
        /**
         * The URL as resolved by the constructor
         */
        get: function () {
            return this._ws ? this._ws.url : '';
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Closes the WebSocket connection or connection attempt, if any. If the connection is already
     * CLOSED, this method does nothing
     */
    AMPWebSocket.prototype.close = function (code, reason) {
        if (code === void 0) { code = 1000; }
        this._closeCalled = true;
        this._shouldReconnect = false;
        this._clearTimeouts();
        if (!this._ws) {
            this._debug('close enqueued: no ws instance');
            return;
        }
        if (this._ws.readyState === this.CLOSED) {
            this._debug('close: already closed');
            return;
        }
        this._ws.close(code, reason);
    };
    /**
     * Closes the WebSocket connection or connection attempt and connects again.
     * Resets retry counter;
     */
    AMPWebSocket.prototype.reconnect = function (code, reason) {
        this._shouldReconnect = true;
        this._closeCalled = false;
        this._retryCount = -1;
        if (!this._ws || this._ws.readyState === this.CLOSED) {
            this._connect();
        }
        else {
            this._disconnect(code, reason);
            this._connect();
        }
    };
    /**
     * Enqueue specified data to be transmitted to the server over the WebSocket connection
     */
    AMPWebSocket.prototype.send = function (data) {
        if (this._ws && this._ws.readyState === this.OPEN) {
            this._debug('send', data);
            this._ws.send(data);
        }
        else {
            var _a = this._options.maxEnqueuedMessages, maxEnqueuedMessages = _a === void 0 ? DEFAULT.maxEnqueuedMessages : _a;
            if (this._messageQueue.length < maxEnqueuedMessages) {
                this._debug('enqueue', data);
                this._messageQueue.push(data);
            }
        }
    };
    /**
     * Register an event handler of a specific event type
     */
    AMPWebSocket.prototype.addEventListener = function (type, listener) {
        if (this._listeners[type]) {
            // @ts-ignore
            this._listeners[type].push(listener);
        }
    };
    AMPWebSocket.prototype.dispatchEvent = function (event) {
        var listeners = this._listeners[event.type];
        if (listeners) {
            listeners.forEach(function (listener) { return listener(event); });
        }
    };
    AMPWebSocket.prototype.removeEventListener = function (type, listener) {
        var listeners = this._listeners[type];
        if (listeners) {
            var index = listeners.indexOf(listener);
            if (index !== -1) {
                listeners.splice(index, 1);
            }
        }
    };
    AMPWebSocket.prototype._debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (this._options.debug) {
            console.debug.apply(console, __spreadArray(['[AMPWebSocket]'], args, false));
        }
    };
    AMPWebSocket.prototype._clearTimeouts = function () {
        if (this._uptimeTimeout) {
            clearTimeout(this._uptimeTimeout);
        }
        if (this._connectTimeout) {
            clearTimeout(this._connectTimeout);
        }
    };
    AMPWebSocket.prototype._connect = function () {
        if (this._connectLock)
            return;
        this._connectLock = true;
        var url = this._resolveUrl();
        this._debug('connecting to', url);
        try {
            this._ws = new WebSocket(url, this._protocols);
            this._ws.binaryType = this._binaryType;
            this._ws.onopen = this._onOpen.bind(this);
            this._ws.onmessage = this._onMessage.bind(this);
            this._ws.onerror = this._onError.bind(this);
            this._ws.onclose = this._onClose.bind(this);
        }
        catch (error) {
            this._handleConnectionError(error);
        }
    };
    AMPWebSocket.prototype._onOpen = function (event) {
        var _this = this;
        this._debug('open', event);
        this._clearTimeouts();
        this._retryCount = 0;
        if (this.onopen) {
            this.onopen(event);
        }
        // Drain the queue of messages to send
        this._messageQueue.forEach(function (message) { return _this.send(message); });
        this._messageQueue = [];
    };
    AMPWebSocket.prototype._onMessage = function (event) {
        this._debug('message', event);
        if (this.onmessage) {
            this.onmessage(event);
        }
    };
    AMPWebSocket.prototype._onError = function (event) {
        this._debug('error', event);
        if (this.onerror) {
            this.onerror(event);
        }
    };
    AMPWebSocket.prototype._onClose = function (event) {
        this._debug('close', event);
        if (this.onclose) {
            this.onclose(event);
        }
        if (this._shouldReconnect || [1006, 1012, 1013].includes(event.code)) {
            this._connectLock = false;
            this._reconnect();
        }
    };
    AMPWebSocket.prototype._handleConnectionError = function (error) {
        this._debug('connection error', error);
        if (this._shouldReconnect) {
            this._reconnect();
        }
    };
    AMPWebSocket.prototype._reconnect = function () {
        this._retryCount += 1;
        if (this._retryCount > (this._options.maxRetries || DEFAULT.maxRetries)) {
            this._debug('max retries reached, stopping reconnection');
            return;
        }
        this._waitForReconnection();
    };
    AMPWebSocket.prototype._waitForReconnection = function () {
        var _this = this;
        var delay = Math.min((this._options.minReconnectionDelay || DEFAULT.minReconnectionDelay) *
            Math.pow(this._options.reconnectionDelayGrowFactor || DEFAULT.reconnectionDelayGrowFactor, this._retryCount), this._options.maxReconnectionDelay || DEFAULT.maxReconnectionDelay);
            setTimeout(function () { return _this._connect(); }, delay);
    };
    AMPWebSocket.prototype._resolveUrl = function () {
        if (typeof this._url === 'string') {
            return this._url;
        }
        throw new Error('Invalid WebSocket URL');
    };
    AMPWebSocket.prototype._disconnect = function (code, reason) {
        this._clearTimeouts();
        if (this._ws) {
            this._ws.close(code, reason);
        }
        this._ws = undefined;
    };
    return AMPWebSocket;
}());
exports.default = AMPWebSocket;
