import React, { useEffect } from "react";
import moment from "moment";
import AautiText from "../../globalComponents/AautiText";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { MdCancel } from "react-icons/md";
import { useState } from "react";
import { AuthService } from "./Service";
import { useDispatch, useSelector } from "react-redux";
import LogoIcon from "../../../assets/images/aautiIcon.png";
import { showToast } from "../../globalComponents/Toast";
import { useNavigate } from "react-router-dom";
import "./Styles.scss";
import { setRefHeadNotifications } from "../../../redux/reducer/appReducer";
import { IoTrashOutline } from "react-icons/io5";
import { capitalizeWordFirstLetter, checkNull } from "../../../utils/Helpers";
import EmptyData from "../../globalComponents/EmptyData";
import { BsChevronUp } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import { globalColors } from "../../../themes/GlobalColors";
import { Container } from "./Container";
import NotificationButtons from "./notificationButtons/NotificationButtons";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import PopupDoc from "./PopUpoptions";
import { constants } from "./Constants";
import { formatDate } from "./notificationHelper";
import NotificationMessage from "./NotificationMessage";

const Notifications = ({
  handleCloseNot,
  unreadCount,
  responseType,
  height,
  showMorebtns,
  childId,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { loggedinUserId, isUserLoggedIn, UserRole, refHeadNotification } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [batchRSdata] = useState("");

  const {
    readRef,
    actionRef,
    actionRefs,
    readRefs,
    showCursor,
    formatTo12HourTime,
    unReadMessages,
    setUnReadMessages,
    AllNot,
    setAlNot,
    readNotifications,
    setReadNotifications,
    open,
    setOpen,
    loadingNotifi,
    setLoadingNotifi,
    selected,
    setSelected,
    accepteState,
    setAccepteState,
    clearNotification,
    setClearNotification,
    readOffset,
    setReadOffset,
    actionOffSet,
    setActionOffset,
    readLastItem,
    actionIndex,
    setActionIndex,
    actionLastItem,
    readIndex,
    setReadIndex,
    showReadScrollButton,
    setShowReadScrollButton,
    showActionScrollButton,
    setShowActionScrollButton,
    handleSelectEachNotification,
    isNotificationSelected,
    showSelection,
    setShowSelection,
    setSelectedNotifications,
    selectedNotifications,
    deleteSelectedNotifications,
    getNotificationData,
    cancelSelection,
    childNotifications,
    setChildNotifications,
    childRef,
    childRefs,
    childIndex,
    setChildIndex,
    childOffset,
    setChildOffset,
    setChildScrollBtn,
    childEndReach,
    handleClick,
  } = Container({
    responseType,
    childId,
    handleCloseNot,
    showMorebtns,
    unreadCount,
  });

  const handleClose = () => {
    setOpen(false);
    setClearNotification("");
  };

  useEffect(() => {
    if (!checkNull(loggedinUserId)) {
      if (responseType == "Read") {
        !readLastItem && getNotificationData();
        if (readRefs.current[readIndex]) {
          readRefs.current[readIndex].scrollIntoView({
            behavior: "auto",
            block: "end",
          });
        }
      } else if (responseType === "Child") {
        !childEndReach && getNotificationData();
        if (childRefs?.current[childIndex]) {
          childRefs?.current[childIndex]?.scrollIntoView({
            behavior: "auto",
            block: "end",
          });
        }
      } else {
        !actionLastItem && getNotificationData();
        if (actionRefs.current[actionIndex]) {
          actionRefs.current[actionIndex].scrollIntoView({
            behavior: "auto",
            block: "end",
          });
        }
      }
    }
  }, [readOffset, actionOffSet, responseType, childOffset, childId]);

  useEffect(() => {
    setSelectedNotifications([]);
    setShowSelection(false);
  }, [responseType]);

  useEffect(() => {
    setChildNotifications([]);
    setChildOffset(0);
  }, [childId]);

  const onClickCrosscon = (id) => {
    AuthService.deleteNotificationById(id, onSuccessDeleted, onFailure);
  };

  const onFailure = (error) => {
    setLoadingNotifi(false);
    setOpen(false);
    showToast(constants?.ERROR, error?.message);
  };
  const onSuccessDeleted = (response) => {
    if (response.status === constants?.SUCCESS) {
      showToast(constants?.SUCCESS_SMALL, response.message);
      setOpen(false);
      dispatch(setRefHeadNotifications(!refHeadNotification));
      let filtered = (
        responseType === "Read"
          ? readNotifications
          : responseType === "Child"
          ? childNotifications
          : AllNot
      )?.filter((each, index) => {
        return selected?._id !== each?._id;
      });
      if (responseType === "Read") {
        setReadNotifications(filtered);
      } else if (responseType === "Child") {
        setChildNotifications(filtered);
      } else {
        setAlNot(filtered);
      }
    }
  };

  const onSuccessDeletAllNot = (response) => {
    if (response.status === constants?.SUCCESS) {
      setOpen(false);
      showToast(constants?.SUCCESS_SMALL, response.message);
      dispatch(setRefHeadNotifications(!refHeadNotification));
      if (responseType == "Read") {
        setReadNotifications([]);
      } else if (responseType == "Child") {
        setChildNotifications([]);
      } else {
        setAlNot([]);
      }
    }
    setLoadingNotifi(false);
  };
  const handleMarkAsAllRead = () => {
    setLoadingNotifi(true);
    AuthService.clearAllNotificationsByUser(
      loggedinUserId,
      onSuccessClearAll,
      onFailure
    );
  };

  const handleDeleteAllSelected = () => {
    if (selectedNotifications.length > 0) {
      setClearNotification("Selected");
      setOpen(true);
    }
  };

  const onSuccessClearAll = (response) => {
    if (response.status === constants?.SUCCESS) {
      showToast(constants?.SUCCESS_SMALL, response.message);
      let mappedNotifi = (
        responseType === "Read" ? readNotifications : AllNot
      )?.map((each, index) => {
        return { ...each, status: "1" };
      });

      setUnReadMessages(0);
      dispatch(setRefHeadNotifications(!refHeadNotification));
      if (responseType === "Read") {
        setReadNotifications(mappedNotifi);
      } else {
        setAlNot(mappedNotifi);
      }
    } else {
      showToast(constants?.ERROR, response.message);
    }
    setLoadingNotifi(false);
  };

  const batchData = [
    {
      leftCont: "Batch Name",
      rightCont: batchRSdata.batchName,
    },
    {
      leftCont: "Date",
      rightCont:
        formatDate(batchRSdata?.startDate) +
        "-" +
        formatDate(batchRSdata?.endDate),
    },
    {
      leftCont: "Existing Timings",
      rightCont:
        (batchRSdata.type === "groupDaily" ||
        batchRSdata.type === "individualDaily"
          ? "Daily - "
          : "Weekly -") +
        "(" +
        formatTo12HourTime(batchRSdata?.startTime) +
        "-" +
        formatTo12HourTime(batchRSdata?.endTime) +
        ")",
    },
    {
      leftCont: "Proposed Timings",
      rightCont:
        (batchRSdata.type === "groupDaily" ||
        batchRSdata.type === "individualDaily"
          ? "Daily - "
          : "Weekly -") +
        "(" +
        formatTo12HourTime(batchRSdata?.reScheduleStartTime) +
        "-" +
        formatTo12HourTime(batchRSdata?.reScheduleStartTime) +
        ")",
    },
  ];
  const handleClearAll = () => {
    AuthService.deleteAllNotificationsByUserId(
      childId ?? loggedinUserId,
      onSuccessDeletAllNot,
      onFailure
    );
  };

  const onScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    if (responseType == "Read") {
      const { scrollTop, scrollHeight, clientHeight } = readRef.current;
      readRefs.current.forEach((item, index) => {
        if (item) {
          const { top, bottom } = item.getBoundingClientRect();
          if (top < scrollPosition && bottom > 0) {
            setReadIndex(index);
          }
        }
      });
      if ((scrollTop + clientHeight).toFixed(0) >= scrollHeight - 1) {
        setReadOffset(readOffset + 1);
      }
      if (scrollTop === 0) setShowReadScrollButton(false);
      else {
        setShowReadScrollButton(true);
      }
    } else if (responseType == "Child") {
      const { scrollTop, scrollHeight, clientHeight } = childRef?.current;
      childRefs?.current?.forEach((item, index) => {
        if (item) {
          const { top, bottom } = item.getBoundingClientRect();
          if (top < scrollPosition && bottom > 0) {
            setChildIndex(index);
          }
        }
      });

      if ((scrollTop + clientHeight).toFixed(0) >= scrollHeight - 1) {
        setChildOffset(childOffset + 1);
      }
      if (scrollTop === 0) setChildScrollBtn(false);
      else {
        setChildScrollBtn(true);
      }
    } else {
      const { scrollTop, scrollHeight, clientHeight } = actionRef.current;
      actionRefs.current.forEach((item, index) => {
        if (item) {
          const { top, bottom } = item.getBoundingClientRect();
          if (top < scrollPosition && bottom > 0) {
            setActionIndex(index);
          }
        }
      });

      if ((scrollTop + clientHeight).toFixed(0) >= scrollHeight - 1) {
        setActionOffset(actionOffSet + 1);
      }
      if (scrollTop === 0) setShowActionScrollButton(false);
      else {
        setShowActionScrollButton(true);
      }
    }
  };

  const scrollToTop = () => {
    if (responseType === "Read") {
      readRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });
    } else if (responseType == "Child") {
      childRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });
    } else {
      actionRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });
    }
  };

  function renderScrollToTopWrapper() {
    return (
      <button className={"scroll_btn_up"} onClick={scrollToTop}>
        <BsChevronUp fontSize={24} />
      </button>
    );
  }

  return (
    <div className="notification-div2">
      {accepteState ? (
        <div className="NFbatchRSdiv">
          <AautiText
            title={"Batch Details"}
            className={"NFcolor"}
            weight={"bold"}
          />
          <div className="NFdiv2">
            <img
              alt="images"
              src={batchRSdata?.courseImage}
              className="NFimagestyle"
            />
            <div className="NFstudents">
              <AautiText
                title={batchRSdata?.reScheduleBatchName}
                className={"NFcolor"}
                weight={"bold"}
              />
              <AautiText
                title={
                  batchRSdata?.acceptedUsers?.length + " Students" ||
                  0 + " Students"
                }
                className={"NFcolor"}
                weight={"medium"}
              />
            </div>
          </div>
          {batchData?.map((each, index) => (
            <div key={index} className="NFmapData">
              <AautiText
                title={each?.leftCont}
                weight={"medium"}
                textStyle={{ marginBottom: 3, color: "#1e1e1e" }}
              />
              <AautiText
                title={each?.rightCont}
                weight={"bold"}
                className={"NFcolor"}
              />
            </div>
          ))}
          <div className="NFbuttonStyle">
            <button
              className="buttonStyleNotif"
              style={{
                border: `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                marginRight: "10px",
                backgroundColor: "#ffffff",
              }}
              onClick={() => {
                setAccepteState(false);
              }}
            >
              <AautiText
                title={"Reject"}
                textStyle={{ color: globalColors.AAUTI_THEME_COLOR }}
                size={"semi"}
                className={"notification-text1"}
                weight={"medium"}
              />
            </button>
            <button
              className="buttonStyleNotif"
              onClick={() => {
                navigate("reschedule");
                if (showMorebtns) {
                  handleCloseNot();
                }
              }}
            >
              <AautiText
                title={"Accept"}
                size={"semi"}
                className={"notification-text1"}
                weight={"medium"}
              />
            </button>
          </div>
        </div>
      ) : (
        <>
          {!responseType && (
            <div className="notification-div3">
              <AautiText
                weight={"bold"}
                title={constants?.NOTIFICATIONS}
                className={"notification-text2"}
              />
              <button
                className="notification-button1"
                onClick={() => {
                  if (showMorebtns) {
                    handleCloseNot();
                  }
                }}
              >
                <MdCancel color="grey" size={20} />
              </button>
            </div>
          )}
          <div style={{ height: height ?? "75vh" }}>
            <Box sx={{ width: "100%" }}>
              {isUserLoggedIn &&
                (responseType === "Child"
                  ? childNotifications?.length > 0
                  : responseType == "Read"
                  ? readNotifications?.length > 0
                  : AllNot?.length > 0) && (
                  <div
                    className="notification-div4"
                    style={{
                      justifyContent: showSelection && "space-between",
                    }}
                  >
                    {showSelection && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AautiText
                          title={`Delete Selected(${selectedNotifications?.length})`}
                          textStyle={{
                            color:
                              selectedNotifications?.length > 0 &&
                              globalColors.AAUTI_THEME_COLOR,
                            cursor: "pointer",
                            marginRight: 15,
                          }}
                          onClick={handleDeleteAllSelected}
                        />
                        <AautiText
                          title={"Cancel"}
                          textStyle={{
                            cursor: "pointer",
                          }}
                          onClick={() => cancelSelection()}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        display: !responseType ? "none" : "flex",
                        alignItems: "center",
                      }}
                    >
                      {responseType !== "Child" && (
                        <AautiText
                          textStyle={{
                            display: unReadMessages === 0 && "none",
                          }}
                          onClick={() => handleMarkAsAllRead()}
                          title={constants?.MARK_AS_READ}
                          size={"semi"}
                          weight={"bold"}
                          className={"notification-text4"}
                        />
                      )}
                      <PopupDoc
                        {...{
                          setOpen,
                          setShowSelection,
                          setClearNotification,
                          handleDeleteAllSelected,
                          showSelection,
                        }}
                      />
                    </div>
                  </div>
                )}

              {(responseType === "Read"
                ? readNotifications?.length
                : responseType == "Child"
                ? childNotifications?.length
                : AllNot?.length) > 0 && isUserLoggedIn ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: height ? "48vh" : mobileDevices ? "78vh" : "73vh",
                      overflow: "auto",
                    }}
                    onScroll={() => {
                      if (responseType) {
                        onScroll();
                      }
                    }}
                    ref={
                      responseType === "Read"
                        ? readRef
                        : responseType == "Child"
                        ? childRef
                        : actionRef
                    }
                  >
                    {(responseType === "Read"
                      ? readNotifications
                      : responseType === "Child"
                      ? childNotifications
                      : AllNot
                    )?.map((each, index) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            if (showSelection) {
                              handleSelectEachNotification(each);
                            } else if (responseType !== "Child") {
                              handleClick(each);
                            }
                          }}
                          className="notification-div6"
                          style={{ position: "relative", width: "100%" }}
                          ref={(el) =>
                            responseType === "Read"
                              ? (readRefs.current[index] = el)
                              : responseType == "Child"
                              ? (childRefs.current[index] = el)
                              : (actionRefs.current[index] = el)
                          }
                        >
                          <div
                            style={{
                              cursor: showCursor(each) ? "pointer" : null,
                            }}
                            id={"course-notification"}
                            className={"notCard"}
                            key={each?._id}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {showSelection && (
                                <Checkbox
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleSelectEachNotification(each);
                                  }}
                                  sx={{ padding: 0, mr: 1 }}
                                  checked={isNotificationSelected(each)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              )}
                            </div>
                            <img
                              alt={"No"}
                              src={
                                each?.courseImage ||
                                each?.gigImage ||
                                each?.image ||
                                each?.profileImage ||
                                LogoIcon
                              }
                              className="notification-img1"
                            />
                            <div className="notification-div7">
                              <div className="notification-div8">
                                <AautiText
                                  size={"semi"}
                                  title={capitalizeWordFirstLetter(
                                    each?.notificationType
                                  )}
                                  weight={"bold"}
                                  className={"notification-text5"}
                                  textStyle={{
                                    width: moment(each?.createdAt)
                                      ?.fromNow()
                                      .includes("few")
                                      ? "90%"
                                      : "90%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 3,
                                    color: globalColors?.HEADING_COLOR,
                                  }}
                                />
                                {each?.status == "0" && !childId && (
                                  <GoDotFill color="#DC3545" />
                                )}
                              </div>
                              <AautiText
                                size={"small"}
                                textAlign={"left"}
                                title={
                                  <NotificationMessage {...{ item: each }} />
                                }
                                className={"notification-text7"}
                              />
                              <div
                                className="notification-div9"
                                style={{
                                  justifyContent: "space-between",
                                }}
                              >
                                <AautiText
                                  size={"small"}
                                  title={moment(each?.createdAt).fromNow()}
                                  className={"notification-text6"}
                                />
                                <button
                                  className="cancelIcon2"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelected(each);
                                    setOpen(true);
                                    setClearNotification("delete");
                                    setSelectedNotifications([]);
                                    setShowSelection(false);
                                  }}
                                >
                                  <IoTrashOutline size={18} />
                                </button>
                              </div>
                              <div className="return-button">
                                {
                                  <NotificationButtons
                                    {...{
                                      item: each,
                                      responseType,
                                      childId,
                                      handleCloseNot,
                                      showMorebtns,
                                      AllNot,
                                      setAlNot,
                                      readNotifications,
                                      setReadNotifications,
                                      setLoadingNotifi,
                                      setOpen,
                                    }}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {showReadScrollButton &&
                      responseType === "Read" &&
                      renderScrollToTopWrapper()}
                    {showActionScrollButton &&
                      responseType === "Action" &&
                      renderScrollToTopWrapper()}
                  </div>
                  {showMorebtns && (
                    <div className="more-btns-notifications">
                      {!childId && (
                        <AautiText
                          title={constants?.MARK_AS_READ}
                          textAlign={"center"}
                          onClick={() => handleMarkAsAllRead()}
                          textStyle={{
                            color: globalColors?.AAUTI_THEME_COLOR,
                            width: "48%",
                            display: unReadMessages == 0 && "none",
                            cursor: "pointer",
                            display: unReadMessages == 0 && "none",
                          }}
                          weight={"bold"}
                        />
                      )}
                      <AautiText
                        title={"See All"}
                        textStyle={{
                          color: globalColors?.HEADING_COLOR,
                          width: unReadMessages == 0 && "96%",
                        }}
                        onClick={() => {
                          if (showMorebtns) {
                            handleCloseNot();
                          }
                          navigate("/notifications");
                        }}
                        weight={"bold"}
                        className={"see-all-btn-notifications"}
                      />
                    </div>
                  )}
                </>
              ) : (
                !loadingNotifi && (
                  <div
                    className="notification-div10"
                    style={{
                      height: showMorebtns ? "40vh" : "73vh",
                      justifyContent: "center",
                    }}
                  >
                    <EmptyData
                      showExplore={false}
                      height={"30vh"}
                      title={"No Notifications"}
                    />
                  </div>
                )
              )}
              {loadingNotifi && (
                <div className="notification-div5">
                  <CircularProgress />
                </div>
              )}
            </Box>
          </div>
        </>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle> {"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {clearNotification === "delete"
              ? "Are you sure you want to delete?"
              : clearNotification === "Selected"
              ? `Are you sure you want to delete selected ${
                  selectedNotifications?.length
                } ${
                  selectedNotifications?.length === 1
                    ? "notification"
                    : "notifications"
                }?`
              : "Are you sure you want to delete all the notifications?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              if (clearNotification === "delete") {
                onClickCrosscon(selected?._id);
              } else if (clearNotification === "Selected") {
                deleteSelectedNotifications();
                setOpen(false);
              } else {
                handleClearAll();
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Notifications;
