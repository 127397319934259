import { useSelector } from "react-redux";
import { deleteTrusteeByUserId, saveTrusteeInvitees } from "../TrusteeServices";
import { showToast } from "../../globalComponents/Toast";

export const useTrusteeFunctionHooks = (props) => {
  const {
    setSearch,
    setOpenAddTrustee,
    invitesList,
    setInvitesList,
    refresh,
    setRefresh,
    setOpenBackdrop,
  } = props;
  const { loggedinUserId } = useSelector((state) => state.app);
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleClickOption = (option, baseObj) => {
    if (option.toLowerCase() === "delete") {
      deleteTrusteeByUserId(
        { userId: loggedinUserId, trusteeId: baseObj?.userId },
        (response) => {
          console.log(response, "delete");
          setRefresh(!refresh);
        },
        (error) => {
          console.error(error, "error-deleting trustee");
        }
      );
    }
  };
  const handleOpenTrustee = () => {
    setOpenAddTrustee(true);
  };

  const handleInvitees = () => {
    const usersList = invitesList?.map((each) => each?._id);
    const payload = {
      userId: loggedinUserId,
      trusteeList: usersList,
      status: "pending",
    };
    setOpenBackdrop(true);
    saveTrusteeInvitees(
      payload,
      (response) => {
        setOpenBackdrop(false);
        if (response?.status === "Success") {
          showToast("success", response.message);
          setInvitesList([]);
          setOpenAddTrustee(false);
          setRefresh(!refresh);
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        setOpenBackdrop(false);
        console.error(error, "error inviting trustee");
      }
    );
  };

  return {
    onChangeSearch,
    handleClickOption,
    handleOpenTrustee,
    handleInvitees,
  };
};
