import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

export const getBatchChangeRequestsBySpId = (
  serviceProviderId,
  transferStatus,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: "coursetransfer/getSpIdByTransferStatus",
    method: ApiMethods.GET,
    params: { serviceProviderId, transferStatus },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const approveOrRejectRequest = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: "/coursetransfer/approveOrReject",
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
