import { Avatar, Button } from "@mui/material";
import { isEmpty } from "lodash";
import { getFirstTwoChars, returnColorbasedName } from "../CommonFunctions";
import AautiText from "../globalComponents/AautiText";
import { AautiStrings } from "../globalComponents/AautiStrings";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { useState } from "react";
import { showToast } from "../globalComponents/Toast";
import { sendSms, VerifySms } from "./service";
import AautiDialog from "../globalComponents/AautiDialog";

const OtpDialog = ({
  selectedUser,
  otp,
  setOtp,
  resendEnabled,
  setResendEnabled,
  timer,
  setTimer,
  loadingVerification,
  setLoadingVerification,
  onSuccess,
  dialogType,
  makeTypeEmpty,
}) => {
  return (
    <AautiDialog
      open={dialogType === "enterOtp"}  
      onClose={() => {
        makeTypeEmpty();
      }}
      dialogTitle={AautiStrings?.OTPVerificationString}
      titlePosition="left"
      dialogWidth="500px"
      sxStyles={{ padding: "0px 30px 20px 30px !important" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          src={
            !isEmpty(selectedUser?.profileImage)
              ? selectedUser?.profileImage
              : undefined
          }
          alt="profile"
          style={{
            width: "70px",
            height: "70px",
            backgroundColor: isEmpty(selectedUser?.profileImage)
              ? returnColorbasedName(
                  selectedUser?.displayName || selectedUser?.userName
                )
              : "transparent",
          }}
        >
          {isEmpty(selectedUser?.profileImage) &&
            getFirstTwoChars(
              selectedUser?.displayName || selectedUser?.userName
            )}
        </Avatar>

        <AautiText
          title={selectedUser?.displayName}
          weight={"bold"}
          style={{ textAlign: "left", marginTop: "10px" }}
        />
        <AautiText
          title={AautiStrings?.pleaseEnterTheOTPSentToYourMobileNumberString}
          style={{ marginTop: "10px" }}
        />
      </div>
      <AautiTextInput
        important
        text={AautiStrings?.OTPString}
        value={otp}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value) && value.length <= 6) {
            setOtp(value);
          }
        }}
        type="tel"
        maxLength={6}
        style={{ width: "100%", marginTop: "10px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            VerifySms(
              selectedUser.mobile,
              otp,
              (response) => {
                if (response.status === "Success") {
                  onSuccess();
                } else {
                  showToast("error", "Invalid OTP. Please try again.");
                }
              },
              (err) => {
                console.log(err);
                showToast("error", "An error occurred. Please try again.");
              }
            );
          }}
          disabled={otp.length !== 6}
          style={{ textTransform: "unset" }}
        >
          Verify
        </Button>
      </div>
      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <AautiText
          title={`Resend OTP ${resendEnabled ? "" : `in ${timer} seconds`}`}
          weight={"bold"}
          textStyle={{
            color: resendEnabled ? "#3166BA" : "grey",
            cursor: resendEnabled ? "pointer" : "default",
          }}
          onClick={() => {
            if (resendEnabled) {
              setOtp("");
              sendSms(
                selectedUser?.mobile,
                (response) => {
                  if (response.status === "Success") {
                    setResendEnabled(false);
                    setTimer(120);
                  }
                },
                (err) => {
                  console.log(err, "errr");
                }
              );
            }
          }}
        />
      </div>
    </AautiDialog>
  );
};

export default OtpDialog;
