import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiServices from "../../components/paymentsGateway/services/ApiServices";

export const apiStatus = {
  idle: "idle",
  loading: "loading",
  success: "success",
  failed: "failed",
};

const initialState = {
  paymentMethods: {
    status: apiStatus.idle,
    paymentMethods: [],
  },
  orderDetails: {
    status: apiStatus.idle,
    orderData: {},
  },
  paymentStatus: {
    status: apiStatus.idle,
    paymentResponse: {},
  },
  updateOrder: {
    status: apiStatus.idle,
  },
};

export const fetchPaymentMethods = createAsyncThunk(
  "paymentMethods",
  async (data) => {
    const res = await ApiServices.getAdyenPaymentMethods(data);
    return res.data;
  }
);

export const getCompleteOrderDetails = createAsyncThunk(
  "completeOrder",
  async (orderId) => {
    const res = await ApiServices.getCompleteOrderDetails(orderId);
    return res.data;
  }
);

export const cardPayment = createAsyncThunk(
  "cardPaymentMethod",
  async (data) => {
    const res = await ApiServices.cardPayment(data);
    return res.data;
  }
);

export const updateOrderDetails = createAsyncThunk(
  "updateOrder",
  async (data) => {
    const res = await ApiServices.updateOrder(data);
    return res.data;
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updateIntialState: (state, action) => {
      state.paymentStatus.status = apiStatus.idle;
      state.paymentMethods.status = apiStatus.idle;
      state.updateOrder.status = apiStatus.idle;
      state.orderDetails.status = apiStatus.idle;
      state.paymentStatus.paymentResponse = {};
      state.orderDetails.orderData = {};
      state.paymentMethods.paymentMethods = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.paymentMethods.status = apiStatus.loading;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods.status = apiStatus.success;
        state.paymentMethods.paymentMethods = action.payload.paymentMethods;
      })

      .addCase(fetchPaymentMethods.rejected, (state) => {
        state.paymentMethods.status = apiStatus.failed;
      })
      .addCase(getCompleteOrderDetails.pending, (state) => {
        state.orderDetails.status = apiStatus.loading;
      })
      .addCase(getCompleteOrderDetails.fulfilled, (state, action) => {
        state.orderDetails.status = apiStatus.success;
        state.orderDetails.orderData = action.payload;
      })
      .addCase(getCompleteOrderDetails.rejected, (state) => {
        state.orderDetails.status = apiStatus.failed;
      })
      .addCase(cardPayment.pending, (state) => {
        state.paymentStatus.status = apiStatus.loading;
      })
      .addCase(cardPayment.fulfilled, (state, action) => {
        state.paymentStatus.status = apiStatus.success;
        state.paymentStatus.paymentResponse = action.payload;
      })
      .addCase(cardPayment.rejected, (state) => {
        state.paymentStatus.status = apiStatus.failed;
      })
      .addCase(updateOrderDetails.pending, (state) => {
        state.updateOrder.status = apiStatus.loading;
      })
      .addCase(updateOrderDetails.fulfilled, (state, action) => {
        state.updateOrder.status = apiStatus.success;
      })
      .addCase(updateOrderDetails.rejected, (state) => {
        state.updateOrder.status = apiStatus.failed;
      }),
});

export const { updateIntialState } = paymentSlice.actions;

export default paymentSlice.reducer;
