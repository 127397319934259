import React, { useCallback, useEffect, useRef, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import { IoIosCheckmark } from "react-icons/io";
import { globalColors } from "../../../themes/GlobalColors";
import moment from "moment";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { options } from "./feed/poll/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { CalenderStrings } from "../CalenderStrings";
import JoinButton from "../../globalComponents/JoinButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { AuthService } from "./feed/task/service";
import { showToast } from "../../globalComponents/Toast";
import { FaChevronRight } from "react-icons/fa6";
import { GoDotFill, GoTrash } from "react-icons/go";
import AautiDialog from "../../globalComponents/AautiDialog";
import SearchIcon from "@mui/icons-material/Search";
import { isEmpty } from "lodash";
import { Dropdown } from "primereact/dropdown";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import {
  IoIosCloseCircle,
  IoIosCloseCircleOutline,
  IoMdClose,
  IoMdCloseCircle,
} from "react-icons/io";
import { FiPlus, FiPlusCircle } from "react-icons/fi";
import styled from "styled-components";
import {
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "../../../service/api-constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IoCamera, IoDocumentTextOutline } from "react-icons/io5";
import { ChatGptFunc } from "../../course/createCourse/Services";
import ConfirmationDialogRaw from "../../globalComponents/ConfirmationDialogRaw";
import { Editor } from "../../globalComponents/Editor";
import { FeedsToolTip } from "./feedMenu";
import { BsFileEarmarkPdf } from "react-icons/bs";
import AautiPdfViewer from "../../reUsableComponents/AautiPdfViewer";
import { FeedAllowedTypes, FileUploadMaxCount } from "../constants";
import CourseImages from "../../globalComponents/CourseImages";

const AssignmentCard = (props) => {
  const {
    setSwitchChange,
    setSubmitClicked,
    scheduleDetails,
    cardDetails,
    UserRole,
    screen,
    handleOnClickEditMenu,
    switchChange,
    selectTaskSession,
    classDetails,
    AllSessions,
    sessionDetails,
    setCountPopUp,
    countPopUp,
    handleConfirmCountDialogClose,
    setMenuActiveIndex,
    MenuActiveIndex,
    updateSession,
    setScroll,
    sessionSubmitName,
    setSessionSubmitName,
    setImagesLiist,
    imagesList,
    setFileType,
    fileType,
    file,
    setFile,
  } = props;
  const toolTipRef = useRef();
  const [submitDialog, setSubmitDialog] = useState(false);
  const [endUsersFilesLoad, setEndUserFilesLoad] = useState(false);
  const [remarksDialog, setRemarksDialog] = useState(false);
  const [skip, setSkip] = useState(false);
  const [overallRemarks, setOverallRemarks] = useState("");
  const [data, setData] = useState(cardDetails);
  const QuestionsType = ["Open Ended", "MCQ"];
  const [imagePrev, setImagePrev] = useState(false);
  const [doneClicked, setDoneClicked] = useState(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [aiLoad, setAiLoad] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [studentsList, setStudentsList] = useState([]);
  const [correctionAns, setCorrectionAns] = useState();
  const [anchorQuestion, setAnchorQuestion] = useState(null);

  const [answers, setAnswers] = useState([]);
  const [camIndex, setCamIndex] = useState();
  const regex = /^[0-9]*$/;
  const studentSelection = ["EveryOne", "Selected"];
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionsType, setQuestionsTypeState] = useState("");
  const [aiCount, setAiCount] = useState(1);
  const [submitCorrectionObj, setSubmitCorrectionObj] = useState({
    _id: "",
    answers: correctionAns,
    endUserId: "",
    assignmentRemarks: "",
  });

  const [askopenName, setAskOpenName] = useState(
    "Task Correction(Session 1) - 25 May 2024"
  );
  const [uploadLoad, setUploadLoad] = useState(false);
  const [studentsSearchValue, setStudentsSearchValue] = useState("");
  const [askopen, setAskOpen] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [clickedUser, setClickedUser] = useState({});
  const [eachLoad, setEachLoad] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    classDetails?.subscribers || []
  );
  const [eachAssign, setEachAssign] = useState({});
  const [saveAssignment, setSaveAssignment] = useState({});
  const [dataLoad, setDataLoad] = useState(false);
  const [studentSelectionState, setStudentSelectionState] =
    useState("EveryOne");
  const [assignDataLoad, setAssignDataLoad] = useState(false);
  const [submissionDialog, setSubmissionDialog] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [resultId, setResultId] = useState("");
  const [questionsArray, setQuestionsArrray] = useState([]);
  const [dropObj, setDropObj] = useState(selectTaskSession);
  const [activeIndex, setActiveIndex] = useState(0);
  const [detailsDialog, setDetailsDialog] = useState(false);
  const { miniLapys, mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [submitAssign, setSubmitAssign] = useState({
    _id: eachAssign?._id,
    endUserId: loggedinUserId,
    answers: [], // Initial empty array, later populated
    type: "task",
    displayName: userDetails?.displayName,
  });
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    let Arr = [];
    eachAssign?.answers?.map((each) => {
      if (each.type === "MCQ") {
        const MCQCheck =
          eachAssign?.answers?.filter((each) => each.type === "MCQ")?.[0]
            ?.description ===
          eachAssign?.questions?.filter((each) => each.type === "MCQ")?.[0]
            ?.correctAnswer;
        const MCQScore = MCQCheck
          ? eachAssign?.questions?.filter((each) => each.type === "MCQ")?.[0]
              ?.points
          : 0;

        Arr.push({
          correctedPoints: MCQScore,
          questionId: each?.questionId,
          totalPoints: eachAssign?.questions?.filter(
            (each) => each.type === "MCQ"
          )?.[0]?.points,
        });
      } else {
        Arr.push({
          correctedPoints: 0,
          questionId: each?.questionId,
          totalPoints: eachAssign?.questions?.filter(
            (each) => each.type === "Open"
          )?.[0]?.points,
        });
      }
    });

    setCorrectionAns(Arr);
  }, [eachAssign]);

  function formatDate(dateString) {
    const startDate = moment(dateString?.startDate);
    const endDate = moment(dateString?.endDate);
    const startFormatted = startDate.format("ddd, DD MMM YYYY");
    const endFormatted = endDate.format("ddd, DD MMM YYYY");
    const range = `${startFormatted} - ${endFormatted}`;
    return range;
  }

  const handleOptionTextChange = (index, optIndex, newText) => {
    const updatedQuestionsArray = questionsArray.map((q, i) =>
      i === index
        ? {
            ...q,
            options: q.options.map((opt, j) =>
              j === optIndex ? { ...opt, text: newText } : opt
            ),
          }
        : q
    );

    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment((prev) => ({
      ...prev,
      questions: updatedQuestionsArray,
    }));
  };

  const handleDeleteOption = (optIndex, index) => {
    const updatedQuestionsArray = questionsArray.map((q, i) =>
      i === index
        ? {
            ...q,
            options: q.options.filter((_, j) => j !== optIndex),
          }
        : q
    );

    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment((prev) => ({
      ...prev,
      questions: updatedQuestionsArray,
    }));
  };

  const handleViewDraft = (each) => {
    setAskOpenName("Task Details");
    setAskOpen(true);
    setPopupName("View");
    setEachLoad(true);

    AuthService.getDraftAssignmentCreationById(
      each?._id,
      onSuccessEachAssign,
      onFailure
    );
  };

  const onSuccessEachAssign = (response) => {
    if (response && response?.data?.status === "Success") {
      if (switchChange) {
        setEachAssign(response?.data?.result);
      } else {
        setEachAssign(response?.data?.result[0]);
      }

      setEachLoad(false);
    } else {
      setEachLoad(false);
    }
  };
  const onFailure = (error) => {
    setEachLoad(false);
    setDataLoad(false);
    setAssignDataLoad(false);
  };

  const renderViewTime = (each) => {
    const StartDateTime = moment(each?.startDate);
    const today = moment().startOf("day");
    if (StartDateTime?.isAfter(today, "day")) {
      return true;
    } else {
      return false;
    }
  };

  const handleAssignmentEachDetail = (each) => {
    if (UserRole === "serviceprovider") {
      setAskOpenName("Task Details");
      setAskOpen(true);
      setPopupName("View");
      setEachLoad(true);
      if (switchChange) {
        AuthService.getDraftAssignmentCreationById(
          each?._id,
          onSuccessEachAssign,
          onFailure
        );
      } else {
        AuthService.getAssignmentDetailsBySpId(
          each?._id,
          "task",
          loggedinUserId,
          onSuccessEachAssign,
          onFailure
        );
      }
    } else {
      const givenDate = moment(each?.endDate);
      const today = moment().startOf("day");
      const isBefore = givenDate.isBefore(today, "day");
      if (isBefore) {
        showToast("error", CalenderStrings?.subMissionDateExp);
      } else {
        setSubmissionDialog(true);
        if (screen === "feed") {
          if (each?.isCorrected) {
            setPopupName("View");
            setAskOpenName("Task Details");
          } else {
            setPopupName("Submission");
            setAskOpenName("Task Submission");
          }
        } else {
          setPopupName("Submission");
          setAskOpenName("Task Submission");
        }

        AuthService.getAssignmentDetailsByEuId(
          loggedinUserId,
          each?._id,
          "task",
          onSuccessEachAssign,
          onFailure
        );
      }
    }
  };

  const handleOnClickResults = (each) => {
    setAskOpenName("Task Correction");
    setAskOpen(true);
    setPopupName("Results");
    screen !== "feed" && setSessionSubmitName(each?.sessionName);
    AuthService.getAssignmentUserListById(
      each._id,
      "task",
      onSuccessUserList,
      onFailure
    );
  };

  const onSuccessUserList = (response) => {
    if (response?.data?.status === "Success") {
      setUsersList(response?.data?.result);
    } else {
      console.log(response?.data?.message);
    }
  };

  const handleCompleteAssignmentDetails = (each) => {
    setDetailsDialog(true);
    setAskOpenName("Task Details");
    // AuthService.getCompleteAssignmentDetailsByEUId(
    //   each?._id,
    //   loggedinUserId,
    //   "task",
    //   onSuccessEUComplete,
    //   onFailure
    // );
    AuthService.getAssignmentDetailsAnswersForEU(
      each?._id,
      loggedinUserId,
      "task",
      onSuccessEUAnswers,
      onFailure
    );
  };

  const handleStudentsSearch = (e) => {
    const searchValue = e.target.value.trim().toLowerCase();
    setStudentsSearchValue(searchValue);
    const filteredStudentsList = searchValue
      ? classDetails?.subscribers?.filter((each) =>
          each.displayName.toLowerCase().startsWith(searchValue)
        )
      : classDetails?.subscribers;

    setStudentsList(filteredStudentsList);
  };

  const handleUserClick = (item) => {
    const isSelected = studentsList?.some((user) => user?._id === item?._id);

    if (isSelected) {
      setStudentsList(studentsList.filter((user) => user?._id !== item?._id));
    } else {
      setStudentsList([...studentsList, item]);
    }
  };

  const handleSelectSession = (e) => {
    if (e.value) {
      const objectIdVal = e.value;
      setDropObj(e.value);

      setSaveAssignment({
        ...saveAssignment,
        sessionId: objectIdVal?._id,
      });
    }
  };

  const handleRemoveQuestion = (each, index) => {
    setQuestionsArrray(questionsArray.filter((_, ind) => ind !== index));
    setQuestionsTypeState("");
    setActiveQuestionIndex(0);
  };

  const handleClickQuestion = (event) => {
    setAnchorQuestion(event.currentTarget);
  };

  const handleQuestionsArray = (each) => {
    const newQuestion =
      each === "Open Ended"
        ? {
            type: "Open Ended",
            description: "",
            uploadedFiles: [],
          }
        : {
            type: "MCQ",
            description: "",
            uploadedFiles: [],
            options: [
              { option: "1", text: "", image: "" },
              { option: "2", text: "", image: "" },
              { option: "3", text: "", image: "" },
              { option: "4", text: "", image: "" },
            ],
            correctAnswer: "",
          };

    const newUpdatedArray = [...questionsArray, newQuestion];

    setQuestionsArrray([...questionsArray, newQuestion]);
    setSaveAssignment({ ...saveAssignment, questions: newUpdatedArray });
    setActiveQuestionIndex(questionsArray?.length);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedQuestionsArray = questionsArray.map((question, i) =>
      i === index ? { ...question, description: value } : question
    );
    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment({ ...saveAssignment, questions: updatedQuestionsArray });
  };

  const handleChangeFile = async (e, index, optIndex) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      setUploadLoad(true);
      setCamIndex(index);
      formData.append("file", file);

      try {
        let res = await fetch(ApiPaths.BASE_URL + "file/upload", {
          method: "POST",
          body: formData,
        });

        let responseJson = await res.json();

        if (res.ok && responseJson.status === "Success") {
          const imageUrl = responseJson.result;
          setCamIndex();
          const updatedQuestionsArray = questionsArray.map((q, i) => {
            if (i === index) {
              return {
                ...q,
                options: q.options.map((opt, j) => {
                  if (j === optIndex) {
                    return { ...opt, image: imageUrl };
                  }
                  return opt;
                }),
              };
            }
            return q;
          });
          setQuestionsArrray(updatedQuestionsArray);
          setSaveAssignment((prev) => ({
            ...prev,
            questions: updatedQuestionsArray,
          }));
        } else {
          console.error("Failed to upload image:", responseJson.message);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploadLoad(false);
        e.target.value = null;
      }
    }
  };

  const deleteImage = (index, optIndex) => {
    const updatedQuestionsArray = questionsArray.map((q, i) => {
      if (i === index) {
        return {
          ...q,
          options: q.options.map((opt, j) => {
            if (j === optIndex) {
              return { ...opt, image: "" };
            }
            return opt;
          }),
        };
      }
      return q;
    });

    setQuestionsArrray(updatedQuestionsArray);
    setSaveAssignment((prev) => ({
      ...prev,
      questions: updatedQuestionsArray,
    }));
  };

  const handleClick4 = (index) => {
    const updatedQuestionsArray = questionsArray.map((q, i) =>
      i === index
        ? {
            ...q,
            options: [
              ...q.options,
              {
                option: (q.options.length + 1).toString(),
                text: "",
                image: "",
              },
            ],
          }
        : q
    );
    setQuestionsArrray(updatedQuestionsArray);
  };
  const handleChangeAddFiles = async (e, index) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      let res = await fetch(ApiPaths.BASE_URL + "file/upload", {
        method: "POST",
        body: formData,
      });

      let responseJson = await res.json();

      if (res.ok && responseJson.status === "Success") {
        const updatedQuestionsArray = questionsArray.map((q, i) => {
          if (i === index) {
            return {
              ...q,
              uploadedFiles: [...q.uploadedFiles, responseJson.result],
            };
          }
          return q;
        });

        setQuestionsArrray(updatedQuestionsArray);
        setSaveAssignment((prev) => ({
          ...prev,
          questions: updatedQuestionsArray,
        }));
      } else {
        console.error("Failed to upload image:", responseJson.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadLoad(false);
    }
  };
  const handleAnswerChange = useCallback(
    (each, index, value) => {
      const numericValue = parseInt(value);
      const numberOfOptions = each.options.length;

      if (value === "") {
        const updatedQuestionsArray = saveAssignment?.questions.map((q, i) =>
          i === index ? { ...q, correctAnswer: "" } : q
        );
        setQuestionsArrray(updatedQuestionsArray);
        setSaveAssignment((prevQuiz) => ({
          ...prevQuiz,
          questions: updatedQuestionsArray,
        }));
      } else if (!isNaN(numericValue) && numericValue <= numberOfOptions) {
        const updatedQuestionsArray = saveAssignment?.questions.map((q, i) =>
          i === index ? { ...q, correctAnswer: numericValue.toString() } : q
        );

        setQuestionsArrray(updatedQuestionsArray);
        setSaveAssignment((prevQuiz) => ({
          ...prevQuiz,
          questions: updatedQuestionsArray,
        }));
      } else {
        // setError(true);
      }
    },
    [saveAssignment?.questions]
  );

  const handleDeleteImage = (questionIndex, imageIndex) => {
    setQuestionsArrray((prevQuestionsArray) => {
      const updatedQuestions = [...prevQuestionsArray];
      if (updatedQuestions[questionIndex]?.uploadedFiles) {
        const updatedFiles = updatedQuestions[
          questionIndex
        ].uploadedFiles.filter((file, index) => index !== imageIndex);
        updatedQuestions[questionIndex].uploadedFiles = updatedFiles;
      } else {
        console.error("uploadedFiles is undefined for this question");
      }

      return updatedQuestions;
    });
  };

  const disable = !(aiCount >= 1 && aiCount <= 10);
  const disblePublish =
    popupName !== "Edit"
      ? !saveAssignment?.title ||
      saveAssignment?.title.length < 5 ||
        isEmpty(questionsArray) ||
        questionsArray?.some(
          (question) =>
            question.description === "" ||
            (question.type === "MCQ" &&
              (question?.options?.some((option) => option.text === "") ||
                question.correctAnswer === ""))
        ) ||
        isEmpty(classDetails?.subscribers) ||
        (studentSelectionState === "Selected" && isEmpty(selectedUsers))
      : false ||
        isEmpty(classDetails?.subscribers) ||
        (studentSelectionState === "Selected" && isEmpty(selectedUsers));

  const disbleUpdate =
    isEmpty(saveAssignment?.title) ||
    isEmpty(questionsArray) ||
    questionsArray?.some(
      (question) =>
        question.description === "" ||
        (question.type === "MCQ" &&
          (question.options?.some((option) => option.text === "") ||
            question.correctAnswer === ""))
    ) ||
    isEmpty(classDetails?.subscribers) ||
    (studentSelectionState === "Selected" && isEmpty(selectedUsers)) ||
    isEmpty(classDetails?.subscribers) ||
    (studentSelectionState === "Selected" && isEmpty(selectedUsers));

  const onSuccessDeleteAssignment = (response) => {
    if (response?.data?.status === "Success") {
      showToast("success", response?.data?.message);
    } else {
    }
  };

  const disbleDraftPublish =
    isEmpty(saveAssignment?.title) ||
    isEmpty(questionsArray) ||
    questionsArray?.some(
      (question) =>
        question.description === "" ||
        (question.type === "MCQ" &&
          (question?.options?.some((option) => option.text === "") ||
            question.correctAnswer === ""))
    ) ||
    isEmpty(classDetails?.subscribers) ||
    (studentSelectionState === "Selected" && isEmpty(selectedUsers));

  const disabeDraft =
    popupName !== "Edit" ? saveAssignment?.title === "" : false;

  const handleCreateAssignment = () => {
    setAskOpen(false);
    setQuestionsTypeState("");

    if (switchChange) {
      const newSave = {
        ...saveAssignment,
        questions: questionsArray,
      };
      AuthService.CreateAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "publish"),
        onFailure
      );
      AuthService.DeleteDraftAssignment(
        saveAssignment?._id,
        onSuccessDeleteAssignment,
        onFailure
      );
    } else {
      const newSave = {
        ...saveAssignment,
        questions: questionsArray,
      };

      AuthService.CreateAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "publish"),
        onFailure
      );
    }
  };

  const handleDraftAssignment = () => {
    setAskOpen(false);
    setQuestionsTypeState("");

    const newSave = { ...saveAssignment, questions: questionsArray };

    if (newSave.hasOwnProperty("_id")) {
      delete newSave?._id;

      AuthService.SaveDraftAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "draft"),
        onFailure
      );
    } else {
      AuthService.SaveDraftAssignment(
        newSave,
        (response) => onSuccessSaveAssignment(response, "draft"),
        onFailure
      );
    }
  };

  const handleUpdateAssignment = () => {
    setAskOpen(false);
    setQuestionsTypeState("");

    const newSave = { ...saveAssignment, questions: questionsArray };
    AuthService.UpdateAssignment(
      newSave,
      (response) => onSuccessSaveAssignment(response, "publish"),
      onFailure
    );
  };

  const onSuccessSaveAssignment = (response, text) => {
    if (response?.data?.status === "Success") {
      setAskOpen(false);
      setSubmitClicked(false);
      showToast("success", response?.data?.message);
      if (text === "draft") {
        setSwitchChange(true);
      } else {
        setSwitchChange(false);
      }
      setActiveQuestionIndex(0);
      setActiveIndex(0);
    } else {
      setAskOpen(false);
      setSubmitClicked(false);
      setActiveQuestionIndex(0);
      setActiveIndex(0);
    }
  };

  const handleUpdateDraft = () => {
    setAskOpen(false);
    const newSave = { ...saveAssignment, questions: questionsArray };
    AuthService.UpdateDraftAssignment(
      newSave,
      (response) => onSuccessSaveAssignment(response, "draft"),
      onFailure
    );
  };

  const handleDescription = (titleContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = titleContent;
    const textContent = tempElement.textContent;
    return textContent;
  };

  const handleRemarksChange = (e, questionId) => {
    const newRemark = e.target.value;
    setSubmitCorrectionObj((prevState) => {
      const updatedAnswers = prevState.answers?.map((answer) =>
        answer.questionId === questionId
          ? { ...answer, remarks: newRemark }
          : answer
      );

      return {
        ...prevState,
        answers: updatedAnswers,
      };
    });
  };

  const handleTotalRemarksChange = (e) => {
    setOverallRemarks(e.target.value);
  };

  const handleSubmitAssignmentCorrection = () => {
    const newObj = {
      ...submitCorrectionObj,
      _id: clickedUser._id,
      endUserId: clickedUser.endUserId,
      assignmentRemarks: skip ? "" : overallRemarks,
    };

    AuthService.UpdateAssignmentCorrection(
      newObj,
      onSuccessAssignmentCorrection,
      onFailure
    );
  };

  const onSuccessAssignmentCorrection = (response) => {
    if (response?.data?.status === "SUccess") {
      showToast("Success", response?.data?.message);
      setData({ ...data, isCorrected: true });
      setSubmitDialog(false);
      setSkip(false);
      setRemarksDialog(false);
      setOverallRemarks("");
      setSubmissionDialog(false);
      setActiveQuestionIndex(0);
      setActiveIndex(0);
    } else {
      // showToast("error", response?.data?.message);
      setData(data);
      setSubmitDialog(false);
      setSkip(false);
      setRemarksDialog(false);
      setOverallRemarks("");
      setSubmissionDialog(false);
      setActiveQuestionIndex(0);
      setActiveIndex(0);
    }
  };

  const handleChangeYourAnswer = (answerText, streak) => {
    const existingAnswerIndex = submitAssign?.answers?.findIndex(
      (each) => each.questionId === streak._id
    );
    let updatedAnswers;
    if (existingAnswerIndex !== -1) {
      updatedAnswers = submitAssign?.answers?.map((each, index) =>
        index === existingAnswerIndex
          ? {
              ...each,
              description: answerText,
              type: "Open Ended",
              uploadedFiles: each?.uploadedFiles,
              remarks: "",
            }
          : each
      );
    } else {
      const existingAnswer = submitAssign?.answers?.find(
        (each) => each.questionId === streak._id
      );
      updatedAnswers = [
        ...submitAssign.answers,
        {
          questionId: streak._id,
          description: answerText,
          remarks: "",
          type: "Open Ended",
          uploadedFiles: [],
        },
      ];
    }
    setSubmitAssign({
      ...submitAssign,
      answers: updatedAnswers,
    });
  };

  const isSubmitButtonDisabled = () => {
    // Check if there are no questions
    if (isEmpty(eachAssign?.questions)) return true;

    // Check if each question has an answer
    return eachAssign?.questions?.some((question) => {
      const answer = submitAssign?.answers?.find(
        (ans) => ans.questionId === question._id
      );

      if (question.type === "Open Ended") {
        return false; // Check if description is empty
      } else if (question.type === "MCQ") {
        return !answer || answer.description.trim() === ""; // Check if MCQ answer is selected
      }
      return true;
    });
  };

  const handleSubmitAssignment = () => {
    const newQuest = eachAssign?.questions?.map((each) =>
      each?.type === "Open Ended"
        ? {
            questionId: each?._id,
            description: "",
            type: "Open Ended",
            remarks: "",
            uploadedFiles: [],
          }
        : {
            type: "MCQ",
            questionId: each?._id,
            description: "",
            remarks: "",
            uploadedFiles: [],
          }
    );
    const emptyQuestionsSubmitAssign = {
      ...submitAssign,
      answers: newQuest,
      _id: eachAssign?._id,
    };
    const updatedSubmitAssign = {
      ...submitAssign,
      _id: eachAssign?._id, // Ensure eachAssign._id is populated here
    };

    setSubmissionDialog(false);
    screen === "task" && setSubmitClicked(true);
    setScroll("");
    AuthService.endUserAssignmentSubmission(
      isEmpty(submitAssign?.answers)
        ? emptyQuestionsSubmitAssign
        : updatedSubmitAssign,
      onSuccessSubmission,
      onFailure
    );
  };

  const onSuccessSubmission = (response) => {
    if (response?.data?.status === "Success") {
      if (screen === "feed") {
        setData({ ...data, isSubmitted: true });
      } else {
        setSubmitClicked(false);
      }
      setSubmitDialog(false);
      setActiveQuestionIndex(0);
      setActiveIndex(0);
    } else {
      if (screen === "feed") {
        setData(data);
      } else {
        setSubmitClicked(false);
      }
      setSubmitDialog(false);
      setActiveQuestionIndex(0);
      setActiveIndex(0);
    }
  };

  const handleOnClickStudent = (each) => {
    setClickedUser(each);
    if (each.status === "Pending") {
      showToast("error", CalenderStrings?.haveNotSubmittedTask);
    } else {
      setAskOpen(false);
      setSubmissionDialog(true);
      setPopupName("Correction");
      setAskOpenName("Task Correction");
      AuthService.getAssignmentDetailsAnswersForEU(
        each?._id,
        each?.endUserId,
        "task",
        onSuccessEUAnswers,
        onFailure
      );
    }
  };

  const onSuccessEUAnswers = (response) => {
    if (response && response?.data?.status === "Success") {
      setEachAssign(response?.data?.result[0]);

      const newAnswers = response?.data?.result?.[0]?.questions?.map(
        (each) => ({
          questionId: each?._id,
          remarks: "",
          totalPoints: 0,
          correctedPoints: 0,
        })
      );

      setSubmitCorrectionObj({
        ...submitCorrectionObj,
        answers: newAnswers,
      });
    } else {
      console.log(response?.data?.message);
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name ? name[0] : "",
    };
  }

  const handleChangeRadio = (event, streak, option) => {
    const updatedAnswers = submitAssign.answers.map((answer) => {
      if (answer.questionId === streak._id) {
        return {
          ...answer,
          description: event.target.value, // Update the description with selected option
        };
      }
      return answer;
    });

    // Check if the answer for the current question already exists
    const answerExists = submitAssign?.answers?.some(
      (answer) => answer.questionId === streak._id
    );

    // If the answer doesn't exist, add a new one
    if (!answerExists) {
      updatedAnswers.push({
        type: streak.type,
        questionId: streak._id,
        description: event.target.value,
        remarks: "",
        uploadedFiles: [],
      });
    }

    setSubmitAssign({
      ...submitAssign,
      answers: updatedAnswers,
    });
  };

  const handleEndDateChange = (newEndDate) => {
    const dateString = newEndDate;
    const parsedDate = dayjs(dateString)
      .set("hour", 9)
      .set("minute", 30)
      .set("second", 0); // Set the desired time

    const year1 = parsedDate.year();
    const month1 = String(parsedDate.month() + 1).padStart(2, "0");
    const day1 = String(parsedDate.date()).padStart(2, "0");

    const formattedDate1 = `${year1}-${month1}-${day1}`;

    // Use `.format()` with a timezone-aware date instead of `.toISOString()` to avoid timezone shifts
    setSaveAssignment({
      ...saveAssignment,
      endDate: dayjs(formattedDate1).toISOString(), // Adjust as necessary
    });
  };

  const handleStartDateChange = (newDate) => {
    const dateString = newDate;
    const parsedDate = dayjs(dateString);

    const year1 = parsedDate.year();
    const month1 = String(parsedDate.month() + 1).padStart(2, "0");
    const day1 = String(parsedDate.date()).padStart(2, "0");

    const formattedDate1 = `${year1}-${month1}-${day1}`;

    // setDate(formattedDate1);

    setSaveAssignment({
      ...saveAssignment,
      startDate: dayjs(formattedDate1).toISOString(),
      endDate: dayjs(formattedDate1).toISOString(),
    });
  };

  const handleTooltipToggle = useCallback((id) => {
    setMenuActiveIndex((prev) => (prev === id ? null : id));
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
        setMenuActiveIndex(null); // Close the tooltip
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangeEndUserAddFiles = async (e, streak) => {
    const files = e.target.files;
    if (!files.length) return; // No files selected

    const alreadyUploadedFilesCount =
      submitAssign?.answers?.find((each) => each.questionId === streak._id)
        ?.uploadedFiles?.length || 0;

    if (alreadyUploadedFilesCount + files.length > FileUploadMaxCount) {
      showToast("error", "You can only upload a maximum of 10 files");
      return;
    }

    const invalidFiles = Array.from(files).filter((file) => {
      const isInvalidType = !FeedAllowedTypes.includes(file.type);

      const isJfifFile = file.name.toLowerCase().endsWith(".jfif");

      return isInvalidType || isJfifFile;
    });

    if (invalidFiles.length > 0) {
      showToast(
        "error",
        "Only JPEG,JPG, PNG, PDF, DOC, or DOCX files are allowed."
      );
      e.target.value = null; // Clear input value
      return;
    }

    setEndUserFilesLoad(true);
    const uploadedFiles = [];
    const url = `${uploadUrl}${ApiPaths.FILE_UPLOAD}`;

    try {
      // Iterate over each selected file
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("platform", UploadBaseUrl);
        let res = await fetch(url, {
          method: "POST",
          body: formData,
        });

        let responseJson = await res.json();

        if (res.ok && responseJson.status === "success") {
          uploadedFiles.push(responseJson.result); // Assuming API returns fileUrl
        } else {
          console.error(`Failed to upload ${file.name}:`, responseJson.message);
        }
      }

      if (uploadedFiles.length > 0) {
        const existingAnswerIndex = submitAssign?.answers?.findIndex(
          (each) => each.questionId === streak._id
        );

        let updatedAnswers;
        if (existingAnswerIndex !== -1) {
          updatedAnswers = submitAssign?.answers?.map((each, index) =>
            index === existingAnswerIndex
              ? {
                  ...each,
                  uploadedFiles: [
                    ...(each.uploadedFiles || []),
                    ...uploadedFiles,
                  ],
                }
              : each
          );
        } else {
          const existingAnswer = submitAssign?.answers?.find(
            (each) => each.questionId === streak._id
          );

          updatedAnswers = [
            ...submitAssign.answers.filter(
              (each) => each.questionId !== streak._id
            ),
            {
              ...existingAnswer,
              questionId: streak._id,
              uploadedFiles: [
                ...(existingAnswer?.uploadedFiles || []),
                ...uploadedFiles,
              ],
            },
          ];
        }

        setSubmitAssign({
          ...submitAssign,
          answers: updatedAnswers,
        });
      } else {
        console.error("No files were successfully uploaded.");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setUploadLoad(false);
      setEndUserFilesLoad(false);
    }
  };

  const handleDeleteEUImage = (questionIndex, imageIndex) => {
    // Find the specific question in the answers array
    const updatedAnswers = submitAssign?.answers?.map((answer, index) =>
      index === questionIndex
        ? {
            ...answer,
            uploadedFiles: answer?.uploadedFiles?.filter(
              (_, idx) => idx !== imageIndex // Exclude the selected image
            ),
          }
        : answer
    );

    // Update the state with the modified answers array
    setSubmitAssign({
      ...submitAssign,
      answers: updatedAnswers,
    });
  };

  const checkEmpty = (each) => {
    const emptyCheck =
      each?.type === "Open Ended"
        ? each?.description === ""
        : each?.correctAnswer === "" ||
          each?.description === "" ||
          each?.options.every((option) => option.text === "");

    return emptyCheck;
  };

  const isUpdated = moment(data?.date).isSame(moment(data?.updatedAt))
    ? false
    : true;

  const renderSubmitTitle = () => {
    if (UserRole === "endUser") {
      if (submitAssign?.answers?.length === eachAssign?.questions?.length) {
        return `Are you sure you want to submit?`;
      } else {
        return `You have not answered ${
          eachAssign?.questions?.length - submitAssign?.answers?.length
        } questions do you want to submit?`;
      }
    } else {
      return `Are you sure you want to submit?`;
    }
  };

  return (
    <>
      <div
        key={data?._id}
        style={{
          width: "100%",
          display: "flex",
          overflow: "scroll",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: screen === "feed" ? "space-between" : "flex-end",
              marginBottom: "2px",
              alignItems: "center",
              width: "100%",
            }}
          >
            {screen === "feed" && (
              <AautiText
                size={"normal"}
                weight={"medium"}
                textStyle={{
                  color: "#828282",
                }}
                title={"Task"}
              />
            )}
            <AautiText
              textAlign={"left"}
              size={"small"}
              weight={"normal"}
              textStyle={{
                color: "#828282",
              }}
              title={
                isUpdated
                  ? `Updated on ${moment(data?.updatedAt).format("D MMM YYYY")},
                ${moment(data?.updatedAt).format("hh:mm A")}`
                  : `Created on ${moment(data?.createdAt).format("D MMM YYYY")},
                ${moment(data?.createdAt).format("hh:mm A")}`
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              backgroundColor: "#F7F7FA",
              border: "1px solid #E0E0E0",
              borderRadius: 10,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E0E0E0",
                padding: 10,
                width: "100%",
              }}
            >
              <AautiText
                weight={"bold"}
                title={data?.title}
                size={"normal"}
                textStyle={{
                  color: "#1E1E1E",
                  marginLeft: "5px",
                }}
              />
              {UserRole === "serviceprovider" && screen !== "feed" && (
                <div>
                  <FeedsToolTip
                    isOpen={MenuActiveIndex === cardDetails?._id}
                    key={cardDetails?._id}
                    id="feed-tooltip"
                    onToggle={() => handleTooltipToggle(cardDetails?._id)}
                    title={
                      <div style={{ padding: "5px" }} ref={toolTipRef}>
                        {options?.map((option) => (
                          <p
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              padding: "5px",
                              fontSize: "14px",
                            }}
                            key={option}
                            onClick={(e) =>
                              handleOnClickEditMenu(option, data, data?._id)
                            }
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    }
                  >
                    <div>
                      <HiOutlineDotsVertical
                        key={data?._id?.$oid}
                        size={18}
                        // onClick={() => {
                        //   setOpenMenu(true);
                        //   setMenuActiveIndex(data?._id);
                        // }}
                        style={{ alignSelf: "center", cursor: "pointer" }}
                      />
                    </div>
                  </FeedsToolTip>

                  {/* )} */}
                </div>
              )}
            </div>
            {UserRole === "serviceprovider" && screen !== "feed" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: mobileDevices ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 10,
                    width: mobileDevices ? "90%" : "98%",
                    border: "1px solid #E0E0E0",
                    backgroundColor: "white",
                    margin: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <AautiText
                    weight={"medium"}
                    title={CapitalizeFirstLetter(data?.sessionName)}
                    size={"semi"}
                  />
                  <AautiText
                    weight={"medium"}
                    title={formatDate(data)}
                    size={mobileDevices ? "small" : "semi"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    flexWrap: "wrap",
                    padding: "10px 10px",
                  }}
                >
                  {data?.assignedUsers?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        border: "1px solid #E0E0E0",
                        padding: "5px 10px",
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",

                        width: mobileDevices
                          ? "45%"
                          : ipodDevices
                          ? "30%"
                          : "18%",
                      }}
                    >
                      {item?.profileImage && item?.profileImage !== "" ? (
                        <Avatar
                          alt="profileImage"
                          src={item?.profileImage}
                          sx={{ width: 40, height: 40 }}
                        />
                      ) : (
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                          }}
                          {...stringAvatar(
                            CapitalizeFirstLetter(item?.displayName)
                          )}
                        />
                      )}
                      <AautiText
                        title={item?.displayName}
                        size={mobileDevices ? "small" : "semi"}
                        textStyle={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {(UserRole === "endUser" || screen === "feed") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    marginRight: "15px",
                    width: ipodDevices ? "50%" : miniLapys ? "30%" : "20%",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <AautiText
                    title={CalenderStrings?.startDate}
                    size={"semi"}
                    weight={"medium"}
                  />
                  <AautiText
                    weight={"normal"}
                    title={moment(data?.startDate).format("ddd, DD MMM YYYY")}
                    size={"semi"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    width: ipodDevices ? "50%" : miniLapys ? "30%" : "20%",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <AautiText
                    title={CalenderStrings?.endDate}
                    size={"semi"}
                    weight={"medium"}
                  />
                  <AautiText
                    weight={"normal"}
                    title={moment(data?.endDate).format("ddd, DD MMM YYYY")}
                    size={"semi"}
                  />
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "flex-end",
                padding: "10px 10px",
                marginLeft: UserRole === "endUser" && "5px",
              }}
            >
              {UserRole === "serviceprovider" ? (
                <JoinButton
                  onClick={() =>
                    switchChange
                      ? handleViewDraft(data)
                      : renderViewTime(data)
                      ? handleAssignmentEachDetail(data)
                      : handleOnClickResults(data)
                  }
                  buttonStyle={{
                    display: switchChange ? "none" : "block",
                    width: "100px",
                    // height: "40px",
                  }}
                  title={
                    switchChange
                      ? "View"
                      : renderViewTime(data)
                      ? "View"
                      : "Results"
                  }
                />
              ) : (
                <JoinButton
                  onClick={() => {
                    data?.isCorrected
                      ? handleCompleteAssignmentDetails(data)
                      : data?.isSubmitted
                      ? handleCompleteAssignmentDetails(data)
                      : handleAssignmentEachDetail(data);
                  }}
                  buttonStyle={{ width: "110px" }}
                  title={
                    data?.isCorrected
                      ? CalenderStrings?.results
                      : data?.isSubmitted
                      ? "Submitted"
                      : "View"
                  }
                />
              )}
              {screen !== "feed" && (
                <AautiText
                  weight={"normal"}
                  title={`Total Questions - ${data?.questionsCount}`}
                  size={"semi"}
                  textStyle={{ marginRight: "0px" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AautiDialog
        titlePosition="left"
        dialogTitle={askopenName}
        open={askopen}
        showCloseIcons={askopenName === "Select Users" && true}
        onClose={() => {
          setAskOpen(false);
          setAskOpenName("");
          setResultId("");
          setSaveAssignment([]);
          setQuestionsArrray([]);
          setAnswers([]);
          setSelectedOption(null);
          // setSubmitAssign({});
          // setEnablePoints(false);
          setQuestionsTypeState("");
          setDropObj(selectTaskSession);
          setStudentsSearchValue("");
          setStudentsList(classDetails?.subscribers);
          setStudentSelectionState("EveryOne");
          setActiveIndex(0);
          setSelectedUsers(classDetails?.subscribers || []);
          screen !== "feed" && setSessionSubmitName("");
        }}
      >
        {popupName === "Create" || popupName === "Edit" ? (
          dataLoad || aiLoad ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {askopenName === "Select Users" && doneClicked ? (
                <div>
                  {/* <FormControl
                    size="small"
                    sx={{ m: 1, width: "100%", mb: 1.5 }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-password"
                      onChange={(e) => handleStudentsSearch(e)}
                      value={studentsSearchValue}
                      type={"text"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label={CalenderStrings?.search}
                    />
                    <InputLabel htmlFor="outlined-adornment-password">
                      {CalenderStrings?.search}
                    </InputLabel>
                  </FormControl> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                      flexWrap: "wrap",
                      padding: 10,
                    }}
                  >
                    {!isEmpty(studentsList) || studentsSearchValue === "" ? (
                      classDetails?.subscribers?.map((item, index) => (
                        <div
                          onClick={() => handleUserClick(item)}
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #E0E0E0",
                            padding: "10px 15px",
                            // backgroundColor: studentsList?.some(
                            //   (each) => each._id === item._id
                            // )
                            //   ? "#3083EF"
                            //   : "#ffffff",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <Avatar
                            alt="profileImage"
                            src={item?.profileImage}
                            sx={{ width: 40, height: 40 }}
                          />

                          <AautiText
                            title={item?.displayName}
                            size={mobileDevices ? "small" : "normal"}
                            textStyle={{
                              color: studentsList?.some(
                                (each) => each._id === item._id
                              )
                                ? "#ffffff"
                                : null,
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 1,
                              textOverflow: "ellipsis",
                              maxWidth: "100px",
                              marginLeft: "10px",
                            }}
                          />
                          {studentsList?.some(
                            (each) => each._id === item._id
                          ) && (
                            <IoIosCheckmark
                              style={{
                                color: globalColors.AAUTI_THEME_COLOR,
                              }}
                              size={40}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          width: "100%",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={"No users found"}
                          textStyle={{
                            color: "grey",
                            textAlign: "end",
                            cursor: "pointer",
                            marginRight: "3px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      marginTop: 20,
                      gap: 15,
                    }}
                  >
                    <JoinButton
                      outlined
                      onClick={() => {
                        if (popupName === "Edit") {
                          setPopupName("Edit");
                          setAskOpenName("Edit Task");
                        } else {
                          setPopupName("Create");
                          setAskOpenName("Create Task");
                        }
                        setStudentsSearchValue("");
                        setStudentSelectionState(studentSelectionState);
                        setDoneClicked(false);
                        setSelectedUsers(selectedUsers);
                      }}
                      textStyle={{ color: globalColors?.AAUTI_THEME_COLOR }}
                      buttonStyle={{
                        width: 100,
                        height: 40,
                      }}
                      title={CalenderStrings?.cancel}
                    />
                    <JoinButton
                      disabled={studentsList?.length === 0}
                      onClick={() => {
                        if (popupName === "Edit") {
                          setPopupName("Edit");
                          setAskOpenName("Edit Task");
                        } else {
                          setPopupName("Create");
                          setAskOpenName("Create Task");
                        }
                        setStudentsSearchValue("");
                        setDoneClicked(false);
                        if (
                          selectedUsers.length ===
                          classDetails?.subscribers.length
                        ) {
                          setStudentSelectionState("EveryOne");
                        }
                        setSelectedUsers(studentsList);
                      }}
                      buttonStyle={{ width: 100, height: 40 }}
                      title={CalenderStrings?.done}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <Dropdown
                    className="centered-dropdown"
                    disabled={dropObj?.scheduleType === "adhoc"}
                    value={popupName === "Edit" ? updateSession : dropObj}
                    onChange={(e) => handleSelectSession(e)}
                    options={AllSessions}
                    optionLabel="sessionName"
                    placeholder={CalenderStrings?.selectSession}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignSelf: "flex-start",
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    itemTemplate={(option) => (
                      <div
                        style={{
                          color: option.sessionName.includes("Ongoing")
                            ? "#066cb5"
                            : "black",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {option.sessionName}
                      </div>
                    )}
                  />

                  <AautiTextInput
                    style={{
                      width: "100%",
                      margin: "15px 0px",
                    }}
                    text={"Title"}
                    maxLength={30}
                    value={saveAssignment?.title}
                    helperText={`${saveAssignment?.title?.length}/30 (minimum 5 characters)`}
                    onChange={(e) =>
                      setSaveAssignment({
                        ...saveAssignment,
                        title: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {!isEmpty(questionsArray) ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            overflow: "scroll",
                          }}
                        >
                          {questionsArray.map((each, index) => {
                            const emptyCheck = checkEmpty(each);
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      setQuestionsTypeState(each.type);
                                      setActiveQuestionIndex(index);
                                      checkEmpty(each);
                                    }}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginRight: 8,
                                      borderRadius: 5,
                                      padding: 10,
                                      backgroundColor:
                                        activeQuestionIndex === index
                                          ? globalColors?.AAUTI_THEME_COLOR
                                          : "white",
                                      border:
                                        activeQuestionIndex === index
                                          ? "1px solid white"
                                          : emptyCheck
                                          ? "1px solid red"
                                          : `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                                      cursor: "pointer",
                                      minWidth:
                                        each.type === "Open Ended"
                                          ? "155px"
                                          : "80px",
                                    }}
                                  >
                                    <AautiText
                                      title={`${index + 1}.${each?.type}`}
                                      textStyle={{
                                        color:
                                          activeQuestionIndex === index
                                            ? "white"
                                            : globalColors?.AAUTI_THEME_COLOR,
                                        marginRight: 5,
                                      }}
                                    />
                                    {questionsArray.length > 1 && (
                                      <IoIosCloseCircleOutline
                                        id={`assignment_close_${index}`}
                                        color={
                                          activeQuestionIndex === index
                                            ? "white"
                                            : globalColors?.AAUTI_THEME_COLOR
                                        }
                                        size={20}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveQuestion(each, index);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {saveAssignment?.questions?.length < 10 && (
                          <div id="basic-menu" onClick={handleClickQuestion}>
                            <FiPlusCircle
                              style={{ cursor: "pointer" }}
                              color={globalColors?.AAUTI_THEME_COLOR}
                              size={25}
                            />
                          </div>
                        )}

                        <Menu
                          id="basic-menu1"
                          anchorEl={anchorQuestion}
                          open={Boolean(anchorQuestion)}
                          onClose={() => setAnchorQuestion(null)}
                          MenuListProps={{ "aria-labelledby": "basic-button" }}
                        >
                          {QuestionsType.map((each, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                setQuestionsTypeState(each);
                                setAnchorQuestion(null);
                                handleQuestionsArray(each);
                              }}
                            >
                              {each}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          onClick={handleClickQuestion}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            alignSelf: "flex-end",
                            cursor: "pointer",
                          }}
                        >
                          <FiPlusCircle
                            color={globalColors?.AAUTI_THEME_COLOR}
                            size={18}
                          />
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.addQuestions}
                            textStyle={{
                              color: globalColors?.AAUTI_THEME_COLOR,
                              textAlign: "end",
                              cursor: "pointer",
                              marginRight: "3px",
                            }}
                          />
                        </div>

                        <div>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorQuestion}
                            open={Boolean(anchorQuestion)}
                            onClose={() => setAnchorQuestion(null)}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {QuestionsType.map((each, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  setQuestionsTypeState(each);
                                  setAnchorQuestion(null);
                                  handleQuestionsArray(each);
                                }}
                              >
                                {each}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </div>
                    )}
                  </div>
                  {questionsType !== "" && questionsType !== undefined && (
                    <div className="button-ai-generate-cont">
                      <button
                        className="generate-button-ali"
                        onClick={() => {
                          setCountPopUp(true);
                          setQuestionsTypeState(questionsType);
                        }}
                      >
                        <AautiText
                          weight={"bold"}
                          title={CalenderStrings?.generateUsingAi}
                          size={"semi"}
                          textStyle={{
                            color: globalColors?.AAUTI_THEME_COLOR,
                            textAlign: "end",
                            cursor: "pointer",
                            marginRight: "3px",
                          }}
                        />
                      </button>
                    </div>
                  )}

                  <div>
                    {!isEmpty(questionsArray) &&
                      questionsArray.map((each, index) => (
                        <div key={index}>
                          {questionsType !== "" &&
                            activeQuestionIndex === index && (
                              <>
                                {/* <AautiTextInput
                                  value={questionsArray?.[index]?.description}
                                  style={{ width: "100%", margin: "5px 0px" }}
                                  multiline
                                  rows={3}
                                  maxLength={50}
                                  important
                                  text={CalenderStrings?.descrioption}
                                  onChange={(e) =>
                                    handleDescriptionChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                /> */}
                                <Editor
                                  onChange={(newContent) =>
                                    handleDescriptionChange(index, newContent)
                                  }
                                  key={index}
                                  value={questionsArray?.[index]?.description}
                                />
                                {each.type === "MCQ" && (
                                  <>
                                    {each?.options.map((option, optIndex) => (
                                      <div
                                        key={optIndex}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                          justifyContent:
                                            each.options.length >= 2
                                              ? "flex-start"
                                              : "space-between",
                                          margin: "5px 0px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: 50,
                                            height: 50,
                                            justifyContent: "center",
                                            borderRadius: 5,
                                            marginRight: "25px",
                                          }}
                                        >
                                          {option.image &&
                                          option?.image !== "" ? (
                                            <div
                                              style={{
                                                width: "80px",
                                                marginTop: "-12px",
                                                // marginRight: "10px",
                                              }}
                                            >
                                              <IoMdCloseCircle
                                                onClick={() =>
                                                  deleteImage(index, optIndex)
                                                }
                                                color="grey"
                                                style={{
                                                  position: "relative",
                                                  top: 18,
                                                  left: 48,
                                                  cursor: "pointer",
                                                }}
                                              />
                                              <img
                                                src={option.image}
                                                alt={option.text}
                                                style={{
                                                  height: "55px",
                                                  width: "60px",
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <Button
                                              key={optIndex}
                                              component="label"
                                              variant="contained"
                                              sx={{
                                                height: 60,
                                                backgroundColor: "white",
                                                mr: 1,
                                                border: "1px solid #F2F2F6",
                                                borderRadius: "8px",
                                                boxShadow: "none",
                                                "&:hover": {
                                                  backgroundColor:
                                                    "transparent",
                                                },
                                              }}
                                              startIcon={
                                                uploadLoad &&
                                                optIndex === camIndex ? (
                                                  <CircularProgress size={20} />
                                                ) : (
                                                  <PhotoCameraIcon
                                                    size={20}
                                                    sx={{ color: "#B0B0B0" }}
                                                  />
                                                )
                                              }
                                            >
                                              <VisuallyHiddenInput
                                                id={`file-upload-${index}-${optIndex}`}
                                                type="file"
                                                accept=".jpeg,.jpg,.png"
                                                onChange={(e) =>
                                                  handleChangeFile(
                                                    e,
                                                    index,
                                                    optIndex
                                                  )
                                                }
                                              />
                                            </Button>
                                          )}
                                        </div>
                                        <TextField
                                          id="outlined-basic"
                                          label={
                                            <AautiText
                                              size={"semi"}
                                              weight={"normal"}
                                              title={`Option ${optIndex + 1}`}
                                            />
                                          }
                                          variant="outlined"
                                          sx={{
                                            width: "80% !important",
                                            margin: "15px 0px",
                                            marginRight: "25px",
                                          }}
                                          value={option.text}
                                          onChange={(e) =>
                                            handleOptionTextChange(
                                              index,
                                              optIndex,
                                              e.target.value
                                            )
                                          }
                                        />
                                        {each.options.length > 2 && (
                                          <GoTrash
                                            onClick={() =>
                                              handleDeleteOption(
                                                optIndex,
                                                index
                                              )
                                            }
                                            color="black"
                                            size={20}
                                          />
                                        )}
                                      </div>
                                    ))}
                                    {each.options.length < 5 && (
                                      <AautiText
                                        onClick={() => handleClick4(index)}
                                        weight={"bold"}
                                        title={CalenderStrings?.addOptions}
                                        textStyle={{
                                          color:
                                            globalColors?.AAUTI_THEME_COLOR,
                                          textAlign: "end",
                                          cursor: "pointer",
                                          marginBottom: 10,
                                        }}
                                      />
                                    )}

                                    <FormControl
                                      sx={{ m: 1, minWidth: 120 }}
                                      size="small"
                                    >
                                      <InputLabel
                                        id="demo-simple-select-label"
                                        sx={{
                                          backgroundColor: "white",
                                          color: "black",
                                        }}
                                      >
                                        <AautiText
                                          title={CalenderStrings?.answer}
                                          size={"semi"}
                                          textStyle={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            WebkitLineClamp: 1,
                                            textOverflow: "ellipsis",
                                            color: "grey",
                                          }}
                                        />
                                      </InputLabel>
                                      <Select
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "#E0E0E0", // Removes border color on focus
                                            },
                                          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "#E0E0E0", // Keeps border color transparent when focused
                                            },
                                          "&.Mui-focused .MuiSelect-select": {
                                            color: "black", // Set text color to black on focus
                                          },
                                          "& .MuiSelect-select": {
                                            color: "black", // Default text color
                                          },
                                        }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={
                                          each.correctAnswer > 0
                                            ? each.correctAnswer.toString()
                                            : ""
                                        }
                                        label="Age"
                                        onChange={(e) => {
                                          if (e.target.value !== 0) {
                                            handleAnswerChange(
                                              each,
                                              index,
                                              e.target.value
                                            );
                                          }
                                        }}
                                      >
                                        {each.options.map((item, itemIndex) => (
                                          <MenuItem value={itemIndex + 1}>
                                            {itemIndex + 1}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  <Button
                                    component="label"
                                    variant="contained"
                                    disableElevation
                                    disableRipple
                                    sx={{
                                      height: 60,
                                      backgroundColor: "white",
                                      textTransform: "none",
                                      borderRadius: "8px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <AautiText
                                      weight={"bold"}
                                      title={CalenderStrings?.addFiles}
                                      textStyle={{
                                        color: globalColors?.AAUTI_THEME_COLOR,
                                        textAlign: "end",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <VisuallyHiddenInput
                                      id={`file-upload-${index}`}
                                      type="file"
                                      accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                                      onChange={(e) =>
                                        handleChangeAddFiles(e, index)
                                      }
                                    />
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  {!isEmpty(each?.uploadedFiles) && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {each?.uploadedFiles?.map(
                                        (img, index) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              marginRight: "5px",
                                            }}
                                          >
                                            <IoIosCloseCircle
                                              onClick={() =>
                                                handleDeleteImage(index, index)
                                              }
                                              size={14}
                                              color="grey"
                                              style={{
                                                position: "relative",
                                                display: "flex",
                                                alignSelf: "flex-end",
                                                bottom: "-12px",
                                                cursor: "pointer",
                                              }}
                                            />
                                            {img.substring(
                                              img.lastIndexOf(".") + 1
                                            ) === "pdf" ? (
                                              <BsFileEarmarkPdf
                                                className="task-itm-up"
                                                onClick={() => {
                                                  setImagePrev(true);
                                                  setFile(img);
                                                  setFileType(
                                                    img.substring(
                                                      img.lastIndexOf(".") + 1
                                                    )
                                                  );
                                                }}
                                              />
                                            ) : img.substring(
                                                img.lastIndexOf(".") + 1
                                              ) === "doc" ||
                                              img.substring(
                                                img.lastIndexOf(".") + 1
                                              ) === "docx" ? (
                                              <IoDocumentTextOutline
                                                className="task-itm1-up"
                                                onClick={() => {
                                                  setImagePrev(true);
                                                  setFile(img);
                                                  setFileType(
                                                    img.substring(
                                                      img.lastIndexOf(".") + 1
                                                    )
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <img
                                                onClick={() => {
                                                  setImagePrev(true);
                                                  setFile(img);
                                                  setFileType(
                                                    img.substring(
                                                      img.lastIndexOf(".") + 1
                                                    )
                                                  );
                                                  setImagesLiist(
                                                    each?.uploadedFiles?.filter(
                                                      (each) =>
                                                        [
                                                          "jpeg",
                                                          "jpg",
                                                          "png",
                                                        ].includes(
                                                          each.substring(
                                                            each.lastIndexOf(
                                                              "."
                                                            ) + 1
                                                          )
                                                        )
                                                    )
                                                  );
                                                }}
                                                src={img}
                                                alt={`img ${index}`}
                                                className="task-itm1-up"
                                              />
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                        </div>
                      ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      backgroundColor:
                        classDetails?.subscribers.length > 1
                          ? "transparent"
                          : "#F7F7FA",
                      border: "1px solid #E0E0E0",
                      borderRadius: "5px",
                      flexDirection: "column",
                      margin: "15px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "2px solid #E0E0E0",
                        padding: 10,
                        width: "100%",
                      }}
                    >
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.assignedTo}
                        size={"normal"}
                      />

                      <FormControl sx={{ width: "40%" }}>
                        <InputLabel id="demo-simple-select-label">
                          <AautiText
                            size={"semi"}
                            weight={"normal"}
                            title={CalenderStrings?.assigned}
                          />
                        </InputLabel>
                        <Select
                          disabled={classDetails?.subscribers?.length === 1}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={studentSelectionState}
                          label={
                            <AautiText
                              size={"semi"}
                              weight={"normal"}
                              title={"Assigned"}
                            />
                          }
                        >
                          {studentSelection.map((each, index) => (
                            <MenuItem
                              value={each}
                              key={index}
                              onClick={() => {
                                setStudentSelectionState(each);

                                if (each === "Selected") {
                                  setSelectedUsers([]);
                                  setStudentsList([]);
                                } else {
                                  setStudentsList(classDetails?.subscribers);
                                }
                              }}
                            >
                              <AautiText
                                size={"semi"}
                                weight={"normal"}
                                title={each}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",

                        padding: 10,
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width:
                            classDetails?.subscribers?.length ===
                            selectedUsers?.length
                              ? "100%"
                              : "90%",
                          overflow: "scroll",
                        }}
                      >
                        {selectedUsers?.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              border: "1px solid #E0E0E0",
                              padding: "10px 15px",
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                          >
                            {item?.profileImage && item?.profileImage !== "" ? (
                              <Avatar
                                alt="profileImage"
                                src={item?.profileImage}
                                sx={{ width: 40, height: 40 }}
                              />
                            ) : (
                              <Avatar
                                sx={{ width: 40, height: 40 }}
                                {...stringAvatar(
                                  CapitalizeFirstLetter(item?.displayName)
                                )}
                              />
                            )}

                            <AautiText
                              title={item?.displayName}
                              size={mobileDevices ? "small" : "normal"}
                              textStyle={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 1,
                                textOverflow: "ellipsis",
                                maxWidth: "100px",
                                marginLeft: "10px",
                              }}
                            />
                            {studentSelectionState === "Selected" && (
                              <IoMdClose
                                onClick={() => {
                                  setStudentsList((prev) =>
                                    prev.filter((each) => each._id !== item._id)
                                  );
                                  setSelectedUsers((prev) =>
                                    prev.filter((each) => each._id !== item._id)
                                  );
                                }}
                                size={18}
                                style={{
                                  marginLeft: "15px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      {classDetails?.subscribers?.length !==
                        selectedUsers?.length && (
                        <button
                          onClick={() => {
                            setDoneClicked(true);
                            setAskOpenName("Select Users");
                          }}
                          className="add-button-cont-1"
                        >
                          <FiPlus
                            color={globalColors?.AAUTI_THEME_COLOR}
                            size={20}
                          />
                          <AautiText
                            textStyle={{
                              color: globalColors?.AAUTI_THEME_COLOR,
                            }}
                            title={"Add"}
                            weight={"medium"}
                            size={"semi"}
                          />
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="dateDivStyle1">
                    <div style={{ width: "100%", marginRight: "20px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          slotProps={{ field: { readOnly: true } }}
                          sx={{ width: "100%" }}
                          label={
                            <AautiText
                              size={"semi"}
                              weight={"normal"}
                              title={"Start Date"}
                            />
                          }
                          value={dayjs(saveAssignment?.startDate)}
                          minDate={dayjs(formattedDate)}
                          defaultValue={dayjs(formattedDate)}
                          format="DD/MM/YYYY"
                          onChange={handleStartDateChange}
                        />
                      </LocalizationProvider>
                    </div>

                    <div style={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          slotProps={{ field: { readOnly: true } }}
                          label={
                            <AautiText
                              size={"semi"}
                              weight={"normal"}
                              title={"End Date"}
                            />
                          }
                          format="DD/MM/YYYY"
                          minDate={dayjs(saveAssignment?.startDate)}
                          defaultValue={dayjs(saveAssignment?.startDate)}
                          value={
                            selectedOption === "Edit"
                              ? dayjs(saveAssignment?.endDate)
                              : dayjs(saveAssignment?.startDate) // Just create a new dayjs object
                          }
                          onChange={handleEndDateChange}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {popupName === "Edit" ? (
                      switchChange ? (
                        <>
                          <button
                            disabled={disbleDraftPublish}
                            className="create-button"
                            style={{
                              backgroundColor: disbleDraftPublish
                                ? "lightgrey"
                                : globalColors?.AAUTI_THEME_COLOR,
                              marginRight: "10px",
                              width: "120px",
                              height: "45px",
                            }}
                            onClick={() => handleCreateAssignment()}
                          >
                            {CalenderStrings?.publish}
                          </button>
                          <button
                            disabled={
                              popupName !== "Edit"
                                ? saveAssignment?.title === ""
                                : false
                            }
                            className="create-button"
                            style={{
                              backgroundColor: disabeDraft
                                ? "lightgrey"
                                : "transparent",

                              border: disabeDraft
                                ? "1px solid lightgrey"
                                : `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                              color: disabeDraft
                                ? "white"
                                : globalColors?.AAUTI_THEME_COLOR,
                              width: "140px",
                              height: "45px",
                            }}
                            onClick={() => handleUpdateDraft()}
                          >
                            {CalenderStrings?.updateToDraft}
                          </button>
                        </>
                      ) : (
                        <button
                          disabled={disbleUpdate}
                          className="create-button"
                          style={{
                            backgroundColor: disbleUpdate
                              ? "lightgrey"
                              : globalColors?.AAUTI_THEME_COLOR,
                            width: "120px",
                            height: "45px",
                          }}
                          onClick={() => handleUpdateAssignment()}
                        >
                          {CalenderStrings?.update}
                        </button>
                      )
                    ) : (
                      <>
                        <button
                          disabled={disblePublish}
                          className="create-button"
                          style={{
                            backgroundColor: disblePublish
                              ? "lightgrey"
                              : globalColors?.AAUTI_THEME_COLOR,
                            marginRight: "10px",
                            width: "120px",
                            height: "45px",
                          }}
                          onClick={() => handleCreateAssignment()}
                        >
                          {CalenderStrings?.publish}
                        </button>
                        <button
                          disabled={
                            popupName !== "Edit"
                              ? saveAssignment?.title === ""
                              : false
                          }
                          className="create-button"
                          style={{
                            backgroundColor: disabeDraft
                              ? "lightgrey"
                              : "transparent",

                            border: disabeDraft
                              ? "1px solid lightgrey"
                              : `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                            color: disabeDraft
                              ? "white"
                              : globalColors?.AAUTI_THEME_COLOR,
                            width: "130px",
                            height: "45px",
                          }}
                          onClick={() => handleDraftAssignment()}
                        >
                          {CalenderStrings?.saveToDraft}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )
        ) : popupName === "View" ? (
          eachLoad ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "5px",
                }}
              >
                <AautiText
                  weight={"bold"}
                  title={eachAssign?.title}
                  size={"medium"}
                  textStyle={{
                    color: "#1E1E1E",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "15px",
                  overflow: "scroll",
                  width: "90%",
                }}
              >
                {eachAssign?.questions?.map((button, index) => (
                  <div
                    key={index}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <button
                      key={index}
                      onClick={() => setActiveIndex(index)}
                      style={{
                        backgroundColor:
                          activeIndex === index
                            ? globalColors?.AAUTI_THEME_COLOR
                            : "white",

                        border:
                          activeIndex === index
                            ? `0px solid ${globalColors?.AAUTI_THEME_COLOR}`
                            : `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                        padding: "10px 15px",
                        outline: "none",
                        borderRadius: "5px",
                        color:
                          activeIndex === index
                            ? "white"
                            : globalColors?.AAUTI_THEME_COLOR,
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}.{button.type}
                    </button>
                  </div>
                ))}
              </div>
              <div>
                {eachAssign?.questions?.map((streak, index) => (
                  <div>
                    {activeIndex === index && streak.type === "MCQ" && (
                      <>
                        <AautiText
                          weight={"bold"}
                          title={`Question`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: "10px 15px",
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={handleDescription(streak?.description)}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                            }}
                          />
                        </div>
                        {!isEmpty(streak?.uploadedFiles) && (
                          <AautiText
                            weight={"bold"}
                            title={`Files`}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              marginTop: "10px",
                            }}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                            overflowX: "scroll",
                          }}
                        >
                          {streak?.uploadedFiles.map((each, index) =>
                            each.substring(each.lastIndexOf(".") + 1) ===
                            "pdf" ? (
                              <BsFileEarmarkPdf
                                className="task-itm"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : each.substring(each.lastIndexOf(".") + 1) ===
                                "doc" ||
                              each.substring(each.lastIndexOf(".") + 1) ===
                                "docx" ? (
                              <IoDocumentTextOutline
                                className="task-itm1"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                  setImagesLiist(
                                    streak?.uploadedFiles?.filter((each) =>
                                      ["jpeg", "jpg", "png"].includes(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      )
                                    )
                                  );
                                }}
                                src={each}
                                alt={`${index}`}
                                className="task-img-up"
                              />
                            )
                          )}
                        </div>
                        <AautiText
                          weight={"bold"}
                          title={CalenderStrings?.options}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          {streak?.options?.map((option) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <AautiText
                                weight={"normal"}
                                title={`${option?.option}.`}
                                size={"normal"}
                                textStyle={{ minWidth: "15px" }}
                              />

                              {option.image && option?.image !== "" ? (
                                <img
                                  src={option?.image}
                                  alt={option?.text}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "30px",
                                  }}
                                />
                              ) : (
                                <IoCamera
                                  size={14}
                                  style={{ marginLeft: "10px" }}
                                />
                              )}

                              <AautiText
                                weight={"normal"}
                                title={option?.text}
                                size={"normal"}
                                textStyle={{ marginLeft: "8px" }}
                              />
                            </div>
                          ))}
                        </div>

                        <AautiText
                          weight={"medium"}
                          title={`Correct Answer - ${streak?.correctAnswer}`}
                          size={"normal"}
                          textStyle={{ marginTop: "8px", color: "black" }}
                        />
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.assignedTo}
                            size={"normal"}
                            textStyle={{ marginTop: "8px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                              flexWrap: "wrap",
                              marginTop: "5px",
                            }}
                          >
                            {eachAssign?.assignedUsers?.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  border: "1px solid #E0E0E0",
                                  padding: "10px 10px",
                                  backgroundColor: "#ffffff",
                                  borderRadius: "5px",
                                }}
                              >
                                {item?.profileImage &&
                                item?.profileImage !== "" ? (
                                  <Avatar
                                    alt="profileImage"
                                    src={item?.profileImage}
                                    sx={{ width: 40, height: 40 }}
                                  />
                                ) : (
                                  <Avatar
                                    sx={{ width: 40, height: 40 }}
                                    {...stringAvatar(
                                      CapitalizeFirstLetter(item?.displayName)
                                    )}
                                  />
                                )}
                                <AautiText
                                  title={item?.displayName}
                                  size={mobileDevices ? "small" : "normal"}
                                  textStyle={{
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                    maxWidth: "100px",
                                    marginLeft: "10px",
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.startDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(eachAssign?.startDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.endDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(eachAssign?.endDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {activeIndex === index && streak.type === "Open Ended" && (
                      <>
                        <AautiText
                          weight={"bold"}
                          title={`Question`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: "10px 15px",
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={handleDescription(streak?.description)}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                            }}
                          />
                        </div>
                        <AautiText
                          weight={"bold"}
                          title={`Files`}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                            marginTop: "10px",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                          }}
                        >
                          {streak?.uploadedFiles.map((each, index) =>
                            each.substring(each.lastIndexOf(".") + 1) ===
                            "pdf" ? (
                              <BsFileEarmarkPdf
                                className="task-itm"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : each.substring(each.lastIndexOf(".") + 1) ===
                                "doc" ||
                              each.substring(each.lastIndexOf(".") + 1) ===
                                "docx" ? (
                              <IoDocumentTextOutline
                                className="task-itm1"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                  setImagesLiist(
                                    streak?.uploadedFiles?.filter((each) =>
                                      ["jpeg", "jpg", "png"].includes(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      )
                                    )
                                  );
                                }}
                                src={each}
                                alt={`${index}`}
                                className="task-img-up"
                              />
                            )
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f0eded",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.assignedTo}
                            size={"normal"}
                            textStyle={{ marginTop: "8px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                              flexWrap: "wrap",
                              marginTop: "5px",
                            }}
                          >
                            {eachAssign?.assignedUsers?.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  border: "1px solid #E0E0E0",
                                  padding: "10px 15px",
                                  backgroundColor: "#ffffff",
                                  borderRadius: "5px",
                                }}
                              >
                                {item?.profileImage &&
                                item?.profileImage !== "" ? (
                                  <Avatar
                                    alt="profileImage"
                                    src={item?.profileImage}
                                    sx={{ width: 40, height: 40 }}
                                  />
                                ) : (
                                  <Avatar
                                    sx={{ width: 40, height: 40 }}
                                    {...stringAvatar(
                                      CapitalizeFirstLetter(item?.displayName)
                                    )}
                                  />
                                )}
                                <AautiText
                                  title={item?.displayName}
                                  size={mobileDevices ? "small" : "normal"}
                                  textStyle={{
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    WebkitLineClamp: 1,
                                    textOverflow: "ellipsis",
                                    maxWidth: "100px",
                                    marginLeft: "10px",
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.startDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(eachAssign?.startDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid #f0eded",
                              borderRadius: "5px",
                              padding: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <AautiText
                              weight={"bold"}
                              title={CalenderStrings?.endDate}
                              size={"normal"}
                              textStyle={{ marginTop: "8px" }}
                            />
                            <AautiText
                              weight={"normal"}
                              title={`${moment(eachAssign?.endDate).format(
                                "ddd, DD MMM YYYY"
                              )}`}
                              size={"normal"}
                              textStyle={{ marginBottom: "8px" }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {screen !== "feed" && (
              <div
                style={{
                  backgroundColor: "whitesmoke",
                  padding: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <AautiText
                  weight={"bold"}
                  title={CapitalizeFirstLetter(sessionSubmitName)}
                  size={"normal"}
                />
              </div>
            )}

            {!resultId && (
              <AautiText
                weight={"bold"}
                title={CalenderStrings?.students}
                size={"normal"}
                textStyle={{ marginBottom: 10 }}
              />
            )}
            {usersList?.map((each, index) => (
              <>
                <div
                  onClick={() => {
                    handleOnClickStudent(each);
                  }}
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                    borderTop: "1px solid #E0E0E0",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Avatar
                      alt="profileImage"
                      src={each?.profileImage}
                      sx={{ width: 40, height: 40 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <AautiText
                        title={each?.displayName}
                        size={mobileDevices ? "small" : "normal"}
                        textStyle={{
                          marginLeft: "10px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <GoDotFill
                          color={
                            each?.status === "Pending"
                              ? "red"
                              : each?.status === "Submitted"
                              ? "#ffc107"
                              : "green"
                          }
                          size={18}
                          style={{ marginRight: "5px" }}
                        />

                        <AautiText title={each?.status} size={"normal"} />
                      </div>
                    </div>
                  </div>

                  <FaChevronRight size={18} />
                </div>
              </>
            ))}
          </div>
        )}
      </AautiDialog>
      <AautiDialog
        titlePosition="left"
        dialogTitle={askopenName}
        open={submissionDialog}
        onClose={() => {
          setSubmissionDialog(false);
          setAnswers([]);
          // setSubmitAssign({});
          setSelectedOption(null);
          setActiveIndex(0);
        }}
      >
        <div>
          {popupName === "Submission" ? (
            <div>
              <AautiText
                weight={"bold"}
                title={eachAssign?.title}
                size={"normal"}
                textStyle={{
                  color: "#1E1E1E",
                  marginBottom: "10px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  overflow: "scroll",
                  width: "100%",
                }}
              >
                {eachAssign?.questions?.map((button, index) => (
                  <div
                    key={index}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <button
                      key={index}
                      onClick={() => setActiveIndex(index)}
                      style={{
                        backgroundColor:
                          activeIndex === index
                            ? globalColors?.AAUTI_THEME_COLOR
                            : "white",

                        border:
                          activeIndex === index
                            ? `0px solid ${globalColors?.AAUTI_THEME_COLOR}`
                            : `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                        padding: "10px 15px",
                        outline: "none",
                        borderRadius: "5px",
                        color:
                          activeIndex === index
                            ? "white"
                            : globalColors?.AAUTI_THEME_COLOR,
                        fontSize: "14px",
                        cursor: "pointer",
                        minWidth:
                          button.type === "Open Ended" ? "155px" : "80px",
                      }}
                    >
                      {index + 1}.{button.type}
                    </button>
                  </div>
                ))}
              </div>
              {eachAssign?.questions?.map((streak, index) => (
                <div>
                  {activeIndex === index && streak.type === "MCQ" && (
                    <>
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>
                      {!isEmpty(streak?.uploadedFiles) && (
                        <>
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.files}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              marginTop: "10px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              overflow: "scroll",
                              width: "100%",
                            }}
                          >
                            {streak?.uploadedFiles?.map((each, index) => (
                              <div style={{ width: "50px" }}>
                                {each.substring(each.lastIndexOf(".") + 1) ===
                                "pdf" ? (
                                  <BsFileEarmarkPdf
                                    className="task-itm"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                    }}
                                  />
                                ) : each.substring(
                                    each.lastIndexOf(".") + 1
                                  ) === "doc" ||
                                  each.substring(each.lastIndexOf(".") + 1) ===
                                    "docx" ? (
                                  <IoDocumentTextOutline
                                    className="task-itm1"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                      setImagesLiist(
                                        streak?.uploadedFiles?.filter((each) =>
                                          ["jpeg", "jpg", "png"].includes(
                                            each.substring(
                                              each.lastIndexOf(".") + 1
                                            )
                                          )
                                        )
                                      );
                                    }}
                                    src={each}
                                    alt={`${index}img`}
                                    className="task-img-up"
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.options}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid lightgrey",
                        }}
                      >
                        {streak?.options?.map((option, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name={`radio-buttons-group-${streak._id}`}
                              value={
                                submitAssign?.answers?.find(
                                  (answer) => answer.questionId === streak._id
                                )?.description || ""
                              }
                              onChange={(event) =>
                                handleChangeRadio(event, streak, option)
                              }
                            >
                              <FormControlLabel
                                value={option.option}
                                control={<Radio />}
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {option.image && (
                                      <img
                                        src={option.image}
                                        alt={option.text}
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          borderRadius: "30px",
                                          marginLeft: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    )}
                                    <span>{option.text}</span>
                                  </div>
                                }
                              />
                            </RadioGroup>
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignSelf: "flex-end",
                        }}
                      >
                        <Button
                          component="label"
                          variant="contained"
                          disableElevation
                          disableRipple
                          sx={{
                            height: 60,
                            backgroundColor: "white",
                            textTransform: "none",
                            borderRadius: "8px",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.addFiles}
                            textStyle={{
                              color: globalColors.AAUTI_THEME_COLOR,
                              textAlign: "end",
                              cursor: "pointer",
                            }}
                          />
                          <VisuallyHiddenInput
                            id={`file-upload-${index}`}
                            type="file"
                            accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                            onChange={(e) =>
                              handleChangeEndUserAddFiles(e, streak)
                            }
                            multiple
                          />
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        {endUsersFilesLoad ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <CircularProgress size={20} />
                          </div>
                        ) : (
                          !isEmpty(
                            submitAssign?.answers?.find(
                              (answer) => answer.questionId === streak._id
                            )?.uploadedFiles
                          ) && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowX: "scroll",
                                width: "100%",
                              }}
                            >
                              {submitAssign?.answers
                                ?.find(
                                  (answer) => answer.questionId === streak._id
                                )
                                ?.uploadedFiles?.map((img, index) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <IoIosCloseCircle
                                      onClick={() =>
                                        handleDeleteEUImage(index, index)
                                      }
                                      size={14}
                                      color="grey"
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        alignSelf: "flex-end",
                                        bottom: "-12px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    {img.substring(img.lastIndexOf(".") + 1) ===
                                    "pdf" ? (
                                      <BsFileEarmarkPdf
                                        className="task-itm-up"
                                        onClick={() => {
                                          setImagePrev(true);
                                          setFile(img);
                                          setFileType(
                                            img.substring(
                                              img.lastIndexOf(".") + 1
                                            )
                                          );
                                        }}
                                      />
                                    ) : img.substring(
                                        img.lastIndexOf(".") + 1
                                      ) === "doc" ||
                                      img.substring(
                                        img.lastIndexOf(".") + 1
                                      ) === "docx" ? (
                                      <IoDocumentTextOutline
                                        className="task-itm1-up"
                                        onClick={() => {
                                          setImagePrev(true);
                                          setFile(img);
                                          setFileType(
                                            img.substring(
                                              img.lastIndexOf(".") + 1
                                            )
                                          );
                                        }}
                                      />
                                    ) : (
                                      <img
                                        onClick={() => {
                                          setImagePrev(true);
                                          setFile(img);
                                          setFileType(
                                            img.substring(
                                              img.lastIndexOf(".") + 1
                                            )
                                          );
                                          setImagesLiist(
                                            submitAssign?.answers
                                              ?.find(
                                                (answer) =>
                                                  answer.questionId ===
                                                  streak._id
                                              )
                                              ?.uploadedFiles?.filter((each) =>
                                                ["jpeg", "jpg", "png"].includes(
                                                  each.substring(
                                                    each.lastIndexOf(".") + 1
                                                  )
                                                )
                                              )
                                          );
                                        }}
                                        src={img}
                                        alt={`img ${index}`}
                                        className="task-itm1-up"
                                      />
                                    )}
                                  </div>
                                ))}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                  {activeIndex === index && streak.type === "Open Ended" && (
                    <>
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>
                      {!isEmpty(streak?.uploadedFiles) && (
                        <>
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.files}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              marginTop: "10px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              overflow: "scroll",
                            }}
                          >
                            {streak?.uploadedFiles?.map((each, index) => (
                              <div style={{ width: "50px" }}>
                                {each.substring(each.lastIndexOf(".") + 1) ===
                                "pdf" ? (
                                  <BsFileEarmarkPdf
                                    className="task-itm"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                    }}
                                  />
                                ) : each.substring(
                                    each.lastIndexOf(".") + 1
                                  ) === "doc" ||
                                  each.substring(each.lastIndexOf(".") + 1) ===
                                    "docx" ? (
                                  <IoDocumentTextOutline
                                    className="task-itm1"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                      setImagesLiist(
                                        streak?.uploadedFiles?.filter((each) =>
                                          ["jpeg", "jpg", "png"].includes(
                                            each.substring(
                                              each.lastIndexOf(".") + 1
                                            )
                                          )
                                        )
                                      );
                                    }}
                                    src={each}
                                    alt={`${index}img`}
                                    className="task-img-up"
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      {/* <AautiTextInput
                        style={{
                          width: "100%",
                          margin: "8px 0px",
                        }}
                        multiline={true}
                        placeholder={CalenderStrings?.enterHere}
                        rows={5}
                        text={
                          <>
                            <AautiText
                              weight={"medium"}
                              title={CalenderStrings?.yourAnswer}
                              size={"normal"}
                              textStyle={{
                                color: "#1E1E1E",
                              }}
                            />
                          </>
                        }
                        value={
                          submitAssign?.answers?.find(
                            (answer) => answer.questionId === streak._id
                          )?.description || ""
                        }
                        onChange={(e) => handleChangeYourAnswer(e, streak)}
                      /> */}
                      <Editor
                        placeholder={CalenderStrings?.yourAnswer}
                        onChange={(e) => handleChangeYourAnswer(e, streak)}
                        key={index}
                        value={
                          submitAssign?.answers?.find(
                            (answer) => answer.questionId === streak._id
                          )?.description || ""
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        {streak?.answers?.map((each, index) => {
                          return (
                            each.type === "Open Ended" &&
                            !isEmpty(each.uploadedFiles) &&
                            each.uploadedFiles.map((fdh, index) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <IoIosCloseCircle
                                  size={16}
                                  color="grey"
                                  style={{ position: "absolute" }}
                                />
                                {fdh.substring(fdh.lastIndexOf(".") + 1) ===
                                "pdf" ? (
                                  <BsFileEarmarkPdf
                                    className="task-itm-up"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(fdh);
                                      setFileType(
                                        fdh.substring(fdh.lastIndexOf(".") + 1)
                                      );
                                    }}
                                  />
                                ) : fdh.substring(fdh.lastIndexOf(".") + 1) ===
                                    "doc" ||
                                  fdh.substring(fdh.lastIndexOf(".") + 1) ===
                                    "docx" ? (
                                  <IoDocumentTextOutline
                                    className="task-itm1-up"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(fdh);
                                      setFileType(
                                        fdh.substring(fdh.lastIndexOf(".") + 1)
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(fdh);
                                      setFileType(
                                        fdh.substring(fdh.lastIndexOf(".") + 1)
                                      );
                                      setImagesLiist(
                                        each.uploadedFiles?.filter((each) =>
                                          ["jpeg", "jpg", "png"].includes(
                                            each.substring(
                                              each.lastIndexOf(".") + 1
                                            )
                                          )
                                        )
                                      );
                                    }}
                                    src={fdh}
                                    alt={`${index}`}
                                    className="task-itm1-up"
                                  />
                                )}
                              </div>
                            ))
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignSelf: "flex-end",
                        }}
                      >
                        <Button
                          component="label"
                          variant="contained"
                          disableElevation
                          disableRipple
                          sx={{
                            height: 60,
                            backgroundColor: "white",
                            textTransform: "none",
                            borderRadius: "8px",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <AautiText
                            weight={"bold"}
                            title={CalenderStrings?.addFiles}
                            textStyle={{
                              color: globalColors.AAUTI_THEME_COLOR,
                              textAlign: "end",
                              cursor: "pointer",
                            }}
                          />
                          <VisuallyHiddenInput
                            id={`file-upload-${index}`}
                            type="file"
                            accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                            onChange={(e) =>
                              handleChangeEndUserAddFiles(e, streak)
                            }
                            multiple
                          />
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        {endUsersFilesLoad ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <CircularProgress size={20} />
                          </div>
                        ) : (
                          !isEmpty(
                            submitAssign?.answers?.find(
                              (answer) => answer.questionId === streak._id
                            )?.uploadedFiles
                          ) && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                                overflowX: "scroll",
                              }}
                            >
                              {submitAssign?.answers
                                ?.find(
                                  (answer) => answer.questionId === streak._id
                                )
                                ?.uploadedFiles?.map((img, index) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <IoIosCloseCircle
                                      onClick={() =>
                                        handleDeleteImage(index, index)
                                      }
                                      size={14}
                                      color="grey"
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        alignSelf: "flex-end",
                                        bottom: "-12px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    {img.substring(img.lastIndexOf(".") + 1) ===
                                    "pdf" ? (
                                      <BsFileEarmarkPdf
                                        className="task-itm-up"
                                        onClick={() => {
                                          setImagePrev(true);
                                          setFile(img);
                                          setFileType(
                                            img.substring(
                                              img.lastIndexOf(".") + 1
                                            )
                                          );
                                        }}
                                      />
                                    ) : img.substring(
                                        img.lastIndexOf(".") + 1
                                      ) === "doc" ||
                                      img.substring(
                                        img.lastIndexOf(".") + 1
                                      ) === "docx" ? (
                                      <IoDocumentTextOutline
                                        className="task-itm1-up"
                                        onClick={() => {
                                          setImagePrev(true);
                                          setFile(img);
                                          setFileType(
                                            img.substring(
                                              img.lastIndexOf(".") + 1
                                            )
                                          );
                                        }}
                                      />
                                    ) : (
                                      <img
                                        onClick={() => {
                                          setImagePrev(true);
                                          setFile(img);
                                          setFileType(
                                            img.substring(
                                              img.lastIndexOf(".") + 1
                                            )
                                          );
                                          setImagesLiist(
                                            submitAssign?.answers
                                              ?.find(
                                                (answer) =>
                                                  answer.questionId ===
                                                  streak._id
                                              )
                                              ?.uploadedFiles?.filter((each) =>
                                                ["jpeg", "jpg", "png"].includes(
                                                  each.substring(
                                                    each.lastIndexOf(".") + 1
                                                  )
                                                )
                                              )
                                          );
                                        }}
                                        src={img}
                                        alt={`img ${index}`}
                                        className="task-itm1-up"
                                      />
                                    )}
                                  </div>
                                ))}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <JoinButton
                  disabled={isSubmitButtonDisabled()}
                  buttonStyle={{ width: 100, height: 40 }}
                  title={CalenderStrings?.submit}
                  onClick={() => setSubmitDialog(true)}
                />
              </div> */}

              {activeIndex === eachAssign?.questions?.length - 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  {activeIndex !== 0 && (
                    <JoinButton
                      buttonStyle={{
                        width: 100,
                        height: 40,
                        marginRight: "10px",
                      }}
                      title={"Prev"}
                      onClick={() => setActiveIndex(activeIndex - 1)}
                    />
                  )}
                  <JoinButton
                    // disabled={isSubmitButtonDisabled()}
                    buttonStyle={{ width: 100, height: 40 }}
                    title={CalenderStrings?.submit}
                    onClick={() => setSubmitDialog(true)}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  {activeIndex !== 0 && (
                    <JoinButton
                      buttonStyle={{
                        width: 100,
                        height: 40,
                        marginRight: "10px",
                      }}
                      title={"Prev"}
                      onClick={() => setActiveIndex(activeIndex - 1)}
                    />
                  )}

                  <JoinButton
                    buttonStyle={{ width: 100, height: 40 }}
                    title={"Next"}
                    onClick={() => setActiveIndex(activeIndex + 1)}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <AautiText
                weight={"bold"}
                title={eachAssign?.title}
                size={"medium"}
                textStyle={{
                  color: "#1E1E1E",
                  marginBottom: "10px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  overflow: "scroll",
                }}
              >
                {eachAssign?.questions?.map((button, index) => (
                  <div key={index} style={{ marginRight: "10px" }}>
                    <button
                      key={index}
                      onClick={() => setActiveIndex(index)}
                      style={{
                        backgroundColor:
                          activeIndex === index
                            ? globalColors?.AAUTI_THEME_COLOR
                            : "white",

                        border:
                          activeIndex === index
                            ? `0px solid ${globalColors?.AAUTI_THEME_COLOR}`
                            : `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                        padding: "10px 15px",
                        outline: "none",
                        borderRadius: "5px",
                        color:
                          activeIndex === index
                            ? "white"
                            : globalColors?.AAUTI_THEME_COLOR,
                        fontSize: "14px",
                        cursor: "pointer",
                        minWidth:
                          button.type === "Open Ended" ? "155px" : "80px",
                      }}
                    >
                      {index + 1}.{button.type}
                    </button>
                  </div>
                ))}
              </div>
              {eachAssign?.questions?.map((streak, index) => (
                <div>
                  {activeIndex === index && streak.type === "MCQ" && (
                    <>
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.question}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                          marginBottom: "10px",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          overflow: "scroll",
                          marginTop: "10px",
                        }}
                      >
                        {streak?.uploadedFiles?.map((each, index) => (
                          <div style={{ width: "50px" }}>
                            {each.substring(each.lastIndexOf(".") + 1) ===
                            "pdf" ? (
                              <BsFileEarmarkPdf
                                className="task-itm"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : each.substring(each.lastIndexOf(".") + 1) ===
                                "doc" ||
                              each.substring(each.lastIndexOf(".") + 1) ===
                                "docx" ? (
                              <IoDocumentTextOutline
                                className="task-itm1"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                  setImagesLiist(
                                    streak?.uploadedFiles?.filter((each) =>
                                      ["jpeg", "jpg", "png"].includes(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      )
                                    )
                                  );
                                }}
                                src={each}
                                alt={`${index}img`}
                                className="task-img-up"
                              />
                            )}
                          </div>
                        ))}
                      </div>

                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.options}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid lightgrey",
                        }}
                      >
                        {streak?.options?.map((option, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              key={option.option}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Radio
                                disabled
                                value={option.option}
                                checked={
                                  // Check if the answer is selected for the current option
                                  eachAssign.answers.find(
                                    (each) =>
                                      each.type === "MCQ" &&
                                      each.questionId === streak._id
                                  )?.description === option.option ||
                                  // Check if the correct answer matches the option
                                  streak.correctAnswer === option.option
                                }
                                onChange={(event) =>
                                  handleChangeRadio(event, streak, option)
                                }
                                sx={{
                                  color:
                                    // If the selected answer matches the correct answer, show green
                                    eachAssign.answers.find(
                                      (each) =>
                                        each.type === "MCQ" &&
                                        each.questionId === streak._id
                                    )?.description === streak.correctAnswer
                                      ? "green"
                                      : // If the current option is the correct one, show green, otherwise red
                                      streak.correctAnswer === option.option
                                      ? "green"
                                      : "red",
                                  "&.Mui-checked": {
                                    color:
                                      eachAssign.answers.find(
                                        (each) =>
                                          each.type === "MCQ" &&
                                          each.questionId === streak._id
                                      )?.description === streak.correctAnswer
                                        ? "green"
                                        : streak.correctAnswer === option.option
                                        ? "green"
                                        : "red",
                                  },
                                }}
                              />
                              {option.image && (
                                <img
                                  src={option.image}
                                  alt={option.text}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "30px",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                              <span>{option.text}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          overflow: "scroll",
                        }}
                      >
                        {eachAssign?.answers?.find(
                          (answer) => answer?.questionId === streak?._id
                        )?.uploadedFiles && (
                          <div
                            style={{
                              marginTop: "10px",
                              overflowX: "scroll",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {eachAssign?.answers
                              ?.find(
                                (answer) => answer?.questionId === streak?._id
                              )
                              ?.uploadedFiles?.map((each, index) => (
                                <div style={{ width: "50px" }}>
                                  {each.substring(each.lastIndexOf(".") + 1) ===
                                  "pdf" ? (
                                    <BsFileEarmarkPdf
                                      className="task-itm"
                                      onClick={() => {
                                        setImagePrev(true);
                                        setFile(each);
                                        setFileType(
                                          each.substring(
                                            each.lastIndexOf(".") + 1
                                          )
                                        );
                                      }}
                                    />
                                  ) : each.substring(
                                      each.lastIndexOf(".") + 1
                                    ) === "doc" ||
                                    each.substring(
                                      each.lastIndexOf(".") + 1
                                    ) === "docx" ? (
                                    <IoDocumentTextOutline
                                      className="task-itm1"
                                      onClick={() => {
                                        setImagePrev(true);
                                        setFile(each);
                                        setFileType(
                                          each.substring(
                                            each.lastIndexOf(".") + 1
                                          )
                                        );
                                      }}
                                    />
                                  ) : (
                                    <img
                                      key={index} // Add a unique key for each item
                                      onClick={() => {
                                        setImagePrev(true);
                                        setFile(each);
                                        setFileType(
                                          each.substring(
                                            each.lastIndexOf(".") + 1
                                          )
                                        );
                                        setImagesLiist(
                                          eachAssign?.answers
                                            ?.find(
                                              (answer) =>
                                                answer?.questionId ===
                                                streak?._id
                                            )
                                            ?.uploadedFiles?.filter((each) =>
                                              ["jpeg", "jpg", "png"].includes(
                                                each.substring(
                                                  each.lastIndexOf(".") + 1
                                                )
                                              )
                                            )
                                        );
                                      }}
                                      src={each}
                                      alt={`${index}img`}
                                      className="task-img-up"
                                    />
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                      {UserRole === "serviceprovider" &&
                      askopenName === "Task Correction" &&
                      clickedUser.status !== "Reviewed" ? (
                        <AautiTextInput
                          maxLength={100}
                          value={
                            submitCorrectionObj?.answers?.find(
                              (answer) => answer.questionId === streak?._id
                            )?.remarks || ""
                          }
                          helperText={`${
                            submitCorrectionObj?.answers?.find(
                              (answer) => answer.questionId === streak?._id
                            )?.remarks?.length
                          }/100 (minimum 5 characters)`}
                          onChange={(e) => handleRemarksChange(e, streak?._id)}
                          style={{ width: "100%" }}
                          text={"Question Remarks"}
                        />
                      ) : (
                        eachAssign?.answers?.find(
                          (answer) => answer.questionId === streak?._id
                        )?.remarks !== "" && (
                          <AautiTextInput
                            readOnly={true}
                            maxLength={100}
                            value={
                              eachAssign?.answers?.find(
                                (answer) => answer.questionId === streak?._id
                              )?.remarks || ""
                            }
                            onChange={null}
                            style={{ width: "100%" }}
                            text={"Question Remarks"}
                          />
                        )
                      )}
                      {clickedUser.status === "Reviewed" &&
                        eachAssign?.assignmentRemarks &&
                        eachAssign?.assignmentRemarks !== "" && (
                          <AautiTextInput
                            readOnly={true}
                            maxLength={100}
                            value={eachAssign?.assignmentRemarks || ""}
                            onChange={null}
                            style={{ width: "100%", marginTop: "5px" }}
                            text={"Overall Remarks"}
                          />
                        )}
                    </>
                  )}
                  {activeIndex === index && streak.type === "Open Ended" && (
                    <>
                      <AautiText
                        weight={"bold"}
                        title={CalenderStrings?.question}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "10px",
                        }}
                      />
                      <div
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          marginTop: "10px",
                          backgroundColor: "#f0eded",
                          marginBottom: "10px",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(streak?.description)}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          overflow: "scroll",
                        }}
                      >
                        {streak?.uploadedFiles?.map((each, index) => (
                          <div style={{ width: "50px" }}>
                            {each.substring(each.lastIndexOf(".") + 1) ===
                            "pdf" ? (
                              <BsFileEarmarkPdf
                                className="task-itm"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : each.substring(each.lastIndexOf(".") + 1) ===
                                "doc" ||
                              each.substring(each.lastIndexOf(".") + 1) ===
                                "docx" ? (
                              <IoDocumentTextOutline
                                className="task-itm1"
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                }}
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setImagePrev(true);
                                  setFile(each);
                                  setFileType(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  );
                                  setImagesLiist(
                                    streak?.uploadedFiles?.filter((each) =>
                                      ["jpeg", "jpg", "png"].includes(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      )
                                    )
                                  );
                                }}
                                src={each}
                                alt={`${index}img`}
                                className="task-img-up"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      <AautiText
                        weight={"bold"}
                        title={`${
                          UserRole === "serviceprovider"
                            ? `${clickedUser.displayName}'s Answer`
                            : "Your Answer"
                        }`}
                        size={"normal"}
                        textStyle={{
                          color: "#1E1E1E",
                          marginTop: "5px",
                        }}
                      />
                      <Editor
                        disable={true}
                        key={index}
                        value={handleDescription(
                          eachAssign?.answers?.filter(
                            (each) => each?.questionId === streak?._id
                          )?.[0]?.description || "Not Answered"
                        )}
                      />
                      {/* <div
                        style={{
                          border: "1px solid lightgrey",
                          padding: "10px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "10px",
                          flexWrap: "wrap",
                          borderRadius: "5px",
                        }}
                      >
                        <AautiText
                          weight={"medium"}
                          title={handleDescription(
                            eachAssign?.answers?.filter(
                              (each) => each?.questionId === streak?._id
                            )?.[0]?.description || "N/A"
                          )}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                      </div> */}

                      {!isEmpty(
                        eachAssign?.answers?.find(
                          (answer) => answer?.questionId === streak?._id
                        )?.uploadedFiles
                      ) && (
                        <div
                          style={{
                            marginTop: "10px",
                            overflowX: "scroll",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {eachAssign?.answers
                            ?.find(
                              (answer) => answer?.questionId === streak?._id
                            )
                            ?.uploadedFiles?.map((each, index) => (
                              <div style={{ width: "50px" }}>
                                {each.substring(each.lastIndexOf(".") + 1) ===
                                "pdf" ? (
                                  <BsFileEarmarkPdf
                                    className="task-itm"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                    }}
                                  />
                                ) : each.substring(
                                    each.lastIndexOf(".") + 1
                                  ) === "doc" ||
                                  each.substring(each.lastIndexOf(".") + 1) ===
                                    "docx" ? (
                                  <IoDocumentTextOutline
                                    className="task-itm1"
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    key={index} // Add a unique key for each item
                                    onClick={() => {
                                      setImagePrev(true);
                                      setFile(each);
                                      setFileType(
                                        each.substring(
                                          each.lastIndexOf(".") + 1
                                        )
                                      );
                                      setImagesLiist(
                                        eachAssign?.answers
                                          ?.find(
                                            (answer) =>
                                              answer?.questionId === streak?._id
                                          )
                                          ?.uploadedFiles?.filter((each) =>
                                            ["jpeg", "jpg", "png"].includes(
                                              each.substring(
                                                each.lastIndexOf(".") + 1
                                              )
                                            )
                                          )
                                      );
                                    }}
                                    src={each}
                                    alt={`${index}img`}
                                    className="task-img-up"
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      )}

                      {UserRole === "serviceprovider" &&
                      askopenName === "Task Correction" &&
                      clickedUser.status !== "Reviewed" ? (
                        <AautiTextInput
                          maxLength={100}
                          value={
                            submitCorrectionObj?.answers?.find(
                              (answer) => answer?.questionId === streak?._id
                            )?.remarks || ""
                          }
                          onChange={(e) => handleRemarksChange(e, streak?._id)}
                          helperText={`${
                            submitCorrectionObj?.answers?.find(
                              (answer) => answer.questionId === streak?._id
                            )?.remarks?.length
                          }/100 (minimum 5 characters)`}
                          style={{ width: "100%" }}
                          text={"Question Remarks"}
                        />
                      ) : (
                        eachAssign?.answers?.find(
                          (answer) => answer.questionId === streak?._id
                        )?.remarks !== "" && (
                          <AautiTextInput
                            readOnly={true}
                            maxLength={100}
                            value={
                              eachAssign?.answers?.find(
                                (answer) => answer.questionId === streak?._id
                              )?.remarks || ""
                            }
                            onChange={null}
                            style={{ width: "100%", marginTop: "5px" }}
                            text={"Question Remarks"}
                          />
                        )
                      )}
                      {clickedUser.status === "Reviewed" &&
                        eachAssign?.assignmentRemarks &&
                        eachAssign?.assignmentRemarks !== "" && (
                          <AautiTextInput
                            readOnly={true}
                            maxLength={100}
                            value={eachAssign?.assignmentRemarks || ""}
                            onChange={null}
                            style={{ width: "100%", marginTop: "5px" }}
                            text={"Overall Remarks"}
                          />
                        )}
                    </>
                  )}
                </div>
              ))}

              {clickedUser.status !== "Reviewed" &&
                UserRole === "serviceprovider" &&
                (activeIndex === eachAssign?.questions?.length - 1 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    {activeIndex !== 0 && (
                      <JoinButton
                        // Disable button if any remarks are empty
                        // disabled={
                        //   !submitCorrectionObj?.answers?.every(
                        //     (answer) => answer.remarks && answer.remarks.length >= 5
                        //   )
                        // }
                        buttonStyle={{
                          width: 100,
                          height: 40,
                          marginRight: "10px",
                        }}
                        title={"Prev"}
                        onClick={() => setActiveIndex(activeIndex - 1)}
                      />
                    )}

                    <JoinButton
                      // Disable button if any remarks are empty
                      // disabled={
                      //   !submitCorrectionObj?.answers?.every(
                      //     (answer) => answer.remarks && answer.remarks.length >= 5
                      //   )
                      // }
                      buttonStyle={{ width: 100, height: 40 }}
                      title={"Submit"}
                      onClick={() => setRemarksDialog(true)}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    {activeIndex !== 0 && (
                      <JoinButton
                        // Disable button if any remarks are empty
                        // disabled={
                        //   !submitCorrectionObj?.answers?.every(
                        //     (answer) => answer.remarks && answer.remarks.length >= 5
                        //   )
                        // }
                        buttonStyle={{
                          width: 100,
                          height: 40,
                          marginRight: "10px",
                        }}
                        title={"Prev"}
                        onClick={() => setActiveIndex(activeIndex - 1)}
                      />
                    )}

                    <JoinButton
                      // Disable button if any remarks are empty
                      // disabled={
                      //   !submitCorrectionObj?.answers?.every(
                      //     (answer) => answer.remarks && answer.remarks.length >= 5
                      //   )
                      // }
                      buttonStyle={{ width: 100, height: 40 }}
                      title={"Next"}
                      onClick={() => setActiveIndex(activeIndex + 1)}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </AautiDialog>

      <AautiDialog
        titlePosition="left"
        dialogTitle={askopenName}
        open={detailsDialog}
        onClose={() => {
          setDetailsDialog(false);
          setActiveIndex(0);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              overflow: "scroll",
            }}
          >
            {eachAssign?.questions?.map((button, index) => (
              <div key={index} style={{ marginRight: "10px" }}>
                <button
                  key={index}
                  onClick={() => setActiveIndex(index)}
                  style={{
                    backgroundColor:
                      activeIndex === index
                        ? globalColors?.AAUTI_THEME_COLOR
                        : "white",

                    border:
                      activeIndex === index
                        ? `0px solid ${globalColors?.AAUTI_THEME_COLOR}`
                        : `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                    padding: "10px 15px",
                    outline: "none",
                    borderRadius: "5px",
                    color:
                      activeIndex === index
                        ? "white"
                        : globalColors?.AAUTI_THEME_COLOR,
                    fontSize: "14px",
                    cursor: "pointer",
                    minWidth: button.type === "Open Ended" ? "155px" : "80px",
                  }}
                >
                  {index + 1}.{button.type}
                </button>
              </div>
            ))}
          </div>
          {eachAssign?.questions?.map((streak, index) => (
            <div>
              {activeIndex === index && streak.type === "MCQ" && (
                <>
                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.question}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "10px",
                    }}
                  />

                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: "10px 15px",
                      marginTop: "10px",
                      backgroundColor: "#f0eded",
                      marginBottom: "10px",
                    }}
                  >
                    <AautiText
                      weight={"medium"}
                      title={handleDescription(streak?.description)}
                      size={"normal"}
                      textStyle={{
                        color: "#1E1E1E",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      overflow: "scroll",
                      width: "100%",
                    }}
                  >
                    {streak?.uploadedFiles?.map((each, index) => (
                      <div style={{ width: "50px" }}>
                        {each.substring(each.lastIndexOf(".") + 1) === "pdf" ? (
                          <BsFileEarmarkPdf
                            className="task-itm"
                            onClick={() => {
                              setImagePrev(true);
                              setFile(each);
                              setFileType(
                                each.substring(each.lastIndexOf(".") + 1)
                              );
                            }}
                          />
                        ) : each.substring(each.lastIndexOf(".") + 1) ===
                            "doc" ||
                          each.substring(each.lastIndexOf(".") + 1) ===
                            "docx" ? (
                          <IoDocumentTextOutline
                            className="task-itm1"
                            onClick={() => {
                              setImagePrev(true);
                              setFile(each);
                              setFileType(
                                each.substring(each.lastIndexOf(".") + 1)
                              );
                            }}
                          />
                        ) : (
                          <img
                            onClick={() => {
                              setImagePrev(true);
                              setFile(each);
                              setFileType(
                                each.substring(each.lastIndexOf(".") + 1)
                              );
                              setImagesLiist(
                                streak?.uploadedFiles?.filter((each) =>
                                  ["jpeg", "jpg", "png"].includes(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  )
                                )
                              );
                            }}
                            src={each}
                            alt={`${index}img`}
                            className="task-img-up"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.options}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "10px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#f0eded",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    {streak?.options?.map((option, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          key={option.option}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <Radio
                            disabled
                            value={option.option}
                            checked={
                              // Check if the answer is selected for the current option
                              eachAssign.answers.find(
                                (each) =>
                                  each.type === "MCQ" &&
                                  each.questionId === streak._id
                              )?.description === option.option ||
                              // Check if the correct answer matches the option
                              streak.correctAnswer === option.option
                            }
                            onChange={(event) =>
                              handleChangeRadio(event, streak, option)
                            }
                            sx={{
                              color:
                                // If the selected answer matches the correct answer, show green
                                eachAssign.answers.find(
                                  (each) =>
                                    each.type === "MCQ" &&
                                    each.questionId === streak._id
                                )?.description === streak.correctAnswer
                                  ? "green"
                                  : // If the current option is the correct one, show green, otherwise red
                                  streak.correctAnswer === option.option
                                  ? "green"
                                  : "red",
                              "&.Mui-checked": {
                                color:
                                  eachAssign.answers.find(
                                    (each) =>
                                      each.type === "MCQ" &&
                                      each.questionId === streak._id
                                  )?.description === streak.correctAnswer
                                    ? "green"
                                    : streak.correctAnswer === option.option
                                    ? "green"
                                    : "red",
                              },
                            }}
                          />

                          {option.image ? (
                            <>
                              <img
                                src={option.image}
                                alt={option.text}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "30px",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              />
                              <AautiText
                                weight={"normal"}
                                title={option?.text}
                                size={"semi"}
                                textStyle={{
                                  color: "#1E1E1E",
                                }}
                              />
                            </>
                          ) : (
                            <AautiText
                              weight={"normal"}
                              title={option?.text}
                              size={"semi"}
                              textStyle={{
                                color: "#1E1E1E",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {UserRole === "endUser" &&
                    eachAssign?.answers?.find(
                      (answer) => answer?.questionId === streak?._id
                    )?.uploadedFiles && (
                      <div
                        style={{
                          marginTop: "10px",
                          overflowX: "scroll",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {eachAssign?.answers
                          ?.find((answer) => answer?.questionId === streak?._id)
                          ?.uploadedFiles?.map((each, index) => (
                            <div style={{ width: "50px" }}>
                              {each.substring(each.lastIndexOf(".") + 1) ===
                              "pdf" ? (
                                <BsFileEarmarkPdf
                                  className="task-itm"
                                  onClick={() => {
                                    setImagePrev(true);
                                    setFile(each);
                                    setFileType(
                                      each.substring(each.lastIndexOf(".") + 1)
                                    );
                                  }}
                                />
                              ) : each.substring(each.lastIndexOf(".") + 1) ===
                                  "doc" ||
                                each.substring(each.lastIndexOf(".") + 1) ===
                                  "docx" ? (
                                <IoDocumentTextOutline
                                  className="task-itm1"
                                  onClick={() => {
                                    setImagePrev(true);
                                    setFile(each);
                                    setFileType(
                                      each.substring(each.lastIndexOf(".") + 1)
                                    );
                                  }}
                                />
                              ) : (
                                <img
                                  key={index} // Add a unique key for each item
                                  onClick={() => {
                                    setImagePrev(true);
                                    setFile(each);
                                    setFileType(
                                      each.substring(each.lastIndexOf(".") + 1)
                                    );
                                    setImagesLiist(
                                      eachAssign?.answers
                                        ?.find(
                                          (answer) =>
                                            answer?.questionId === streak?._id
                                        )
                                        ?.uploadedFiles?.filter((each) =>
                                          ["jpeg", "jpg", "png"].includes(
                                            each.substring(
                                              each.lastIndexOf(".") + 1
                                            )
                                          )
                                        )
                                    );
                                  }}
                                  src={each}
                                  alt={`${index}img`}
                                  className="task-img-up"
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    )}
                  {UserRole === "endUser" &&
                    eachAssign?.answers?.find(
                      (answer) => answer.questionId === streak?._id
                    )?.remarks &&
                    eachAssign?.answers?.find(
                      (answer) => answer.questionId === streak?._id
                    )?.remarks !== "" && (
                      <div>
                        <AautiText
                          weight={"medium"}
                          title={"Remarks"}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            padding: "10px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginTop: "10px",
                            flexWrap: "wrap",
                            borderRadius: "5px",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={
                              eachAssign?.answers?.find(
                                (answer) => answer.questionId === streak?._id
                              )?.remarks
                            }
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {UserRole === "endUser" &&
                    eachAssign?.assignmentRemarks &&
                    eachAssign?.assignmentRemarks !== "" && (
                      <div style={{ marginTop: "5px" }}>
                        <AautiText
                          weight={"medium"}
                          title={"Overall Remarks"}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            padding: "10px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginTop: "10px",
                            flexWrap: "wrap",
                            borderRadius: "5px",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={eachAssign?.assignmentRemarks || "N/A"}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                </>
              )}
              {activeIndex === index && streak.type === "Open Ended" && (
                <>
                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.question}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "10px",
                    }}
                  />

                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: "10px 15px",
                      marginTop: "10px",
                      backgroundColor: "#f0eded",
                      marginBottom: "10px",
                    }}
                  >
                    <AautiText
                      weight={"medium"}
                      title={handleDescription(streak?.description)}
                      size={"normal"}
                      textStyle={{
                        color: "#1E1E1E",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      overflowX: "scroll",
                    }}
                  >
                    {streak?.uploadedFiles?.map((each, index) => (
                      <div style={{ width: "50px" }}>
                        {each.substring(each.lastIndexOf(".") + 1) === "pdf" ? (
                          <BsFileEarmarkPdf
                            className="task-itm"
                            onClick={() => {
                              setImagePrev(true);
                              setFile(each);
                              setFileType(
                                each.substring(each.lastIndexOf(".") + 1)
                              );
                            }}
                          />
                        ) : each.substring(each.lastIndexOf(".") + 1) ===
                            "doc" ||
                          each.substring(each.lastIndexOf(".") + 1) ===
                            "docx" ? (
                          <IoDocumentTextOutline
                            className="task-itm1"
                            onClick={() => {
                              setImagePrev(true);
                              setFile(each);
                              setFileType(
                                each.substring(each.lastIndexOf(".") + 1)
                              );
                            }}
                          />
                        ) : (
                          <img
                            onClick={() => {
                              setImagePrev(true);
                              setFile(each);
                              setFileType(
                                each.substring(each.lastIndexOf(".") + 1)
                              );
                              setImagesLiist(
                                streak?.uploadedFiles?.filter((each) =>
                                  ["jpeg", "jpg", "png"].includes(
                                    each.substring(each.lastIndexOf(".") + 1)
                                  )
                                )
                              );
                            }}
                            src={each}
                            alt={`${index}img`}
                            className="task-img-up"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  <AautiText
                    weight={"bold"}
                    title={CalenderStrings?.yourAnswer}
                    size={"normal"}
                    textStyle={{
                      color: "#1E1E1E",
                      marginTop: "5px",
                    }}
                  />
                  <Editor
                    disable={true}
                    key={index}
                    value={handleDescription(
                      eachAssign?.answers?.filter(
                        (each) => each?.questionId === streak._id
                      )?.[0]?.description || "Not Answered"
                    )}
                  />
                  {/* <div
                    style={{
                      border: "1px solid lightgrey",
                      padding: "10px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginTop: "10px",
                      flexWrap: "wrap",
                      borderRadius: "5px",
                    }}
                  >
                    <AautiText
                      weight={"medium"}
                      title={handleDescription(
                        eachAssign?.answers?.filter(
                          (each) => each?.questionId === streak._id
                        )?.[0]?.description || "N/A"
                      )}
                      size={"normal"}
                      textStyle={{
                        color: "#1E1E1E",
                      }}
                    />
                  </div> */}

                  {UserRole === "endUser" &&
                    eachAssign?.answers?.find(
                      (answer) => answer?.questionId === streak?._id
                    )?.uploadedFiles && (
                      <div
                        style={{
                          marginTop: "10px",
                          overflowX: "scroll",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {eachAssign?.answers
                          ?.find((answer) => answer?.questionId === streak?._id)
                          ?.uploadedFiles?.map((each, index) => (
                            <div style={{ width: "50px" }}>
                              {each.substring(each.lastIndexOf(".") + 1) ===
                              "pdf" ? (
                                <BsFileEarmarkPdf
                                  className="task-itm"
                                  onClick={() => {
                                    setImagePrev(true);
                                    setFile(each);
                                    setFileType(
                                      each.substring(each.lastIndexOf(".") + 1)
                                    );
                                  }}
                                />
                              ) : each.substring(each.lastIndexOf(".") + 1) ===
                                  "doc" ||
                                each.substring(each.lastIndexOf(".") + 1) ===
                                  "docx" ? (
                                <IoDocumentTextOutline
                                  className="task-itm1"
                                  onClick={() => {
                                    setImagePrev(true);
                                    setFile(each);
                                    setFileType(
                                      each.substring(each.lastIndexOf(".") + 1)
                                    );
                                  }}
                                />
                              ) : (
                                <img
                                  key={index} // Add a unique key for each item
                                  onClick={() => {
                                    setImagePrev(true);
                                    setFile(each);
                                    setFileType(
                                      each.substring(each.lastIndexOf(".") + 1)
                                    );
                                    setImagesLiist(
                                      eachAssign?.answers
                                        ?.find(
                                          (answer) =>
                                            answer?.questionId === streak?._id
                                        )
                                        ?.uploadedFiles?.filter((each) =>
                                          ["jpeg", "jpg", "png"].includes(
                                            each.substring(
                                              each.lastIndexOf(".") + 1
                                            )
                                          )
                                        )
                                    );
                                  }}
                                  src={each}
                                  alt={`${index}img`}
                                  className="task-img-up"
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    )}

                  {UserRole === "endUser" &&
                    eachAssign?.answers?.find(
                      (answer) => answer.questionId === streak?._id
                    )?.remarks &&
                    eachAssign?.answers?.find(
                      (answer) => answer.questionId === streak?._id
                    )?.remarks !== "" && (
                      <div style={{ marginTop: "10px" }}>
                        <AautiText
                          weight={"medium"}
                          title={"Remarks"}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            padding: "10px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginTop: "10px",
                            flexWrap: "wrap",
                            borderRadius: "5px",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={
                              eachAssign?.answers?.find(
                                (answer) => answer.questionId === streak?._id
                              )?.remarks || "N/A"
                            }
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {UserRole === "endUser" &&
                    eachAssign?.assignmentRemarks &&
                    eachAssign?.assignmentRemarks !== "" && (
                      <div style={{ marginTop: "5px" }}>
                        <AautiText
                          weight={"medium"}
                          title={"Overall Remarks"}
                          size={"normal"}
                          textStyle={{
                            color: "#1E1E1E",
                          }}
                        />
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            padding: "10px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginTop: "10px",
                            flexWrap: "wrap",
                            borderRadius: "5px",
                          }}
                        >
                          <AautiText
                            weight={"medium"}
                            title={eachAssign?.assignmentRemarks || "N/A"}
                            size={"normal"}
                            textStyle={{
                              color: "#1E1E1E",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          ))}
        </div>
      </AautiDialog>
      <AautiDialog
        onClose={() => {
          setRemarksDialog(false);
          setOverallRemarks("");
        }}
        open={remarksDialog}
      >
        <div style={{ width: "100%" }}>
          <AautiText
            weight="bold"
            title={`Do you want to add remarks on overall task?`}
            size="normal"
            textStyle={{ marginTop: "8px" }}
          />
          <AautiTextInput
            maxLength={100}
            value={overallRemarks || ""}
            onChange={(e) => handleTotalRemarksChange(e)}
            helperText={`${
              overallRemarks?.length || 0
            }/100 (minimum 5 characters)`}
            style={{ width: "100%" }}
            text={"Your Remarks"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <JoinButton
              // disabled={isSubmitButtonDisabled()}
              buttonStyle={{ width: 150, height: 40, marginRight: "10px" }}
              title={"Skip & Submit"}
              onClick={() => {
                setSubmitDialog(true);
                setSkip(true);
              }}
            />
            <JoinButton
              disabled={overallRemarks === "" || overallRemarks?.length < 5}
              buttonStyle={{ width: 150, height: 40 }}
              title={CalenderStrings?.submit}
              onClick={() => {
                setSubmitDialog(true);
                setSkip(false);
              }}
            />
          </div>
        </div>
      </AautiDialog>
      <AautiDialog onClose={() => setImagePrev(false)} open={imagePrev}>
        <div>
          <AautiText
            size="medium"
            title={file?.substring(file?.lastIndexOf("/") + 1)}
            weight="bold"
            textAlign="center"
            textStyle={{ marginBottom: "5px" }}
          />
          {fileType === "png" || fileType === "jpeg" || fileType === "jpg" ? (
            <CourseImages {...{ imagesList, showIcon: false }} />
          ) : fileType === "pdf" ||
            fileType === "doc" ||
            fileType === "docx" ? (
            <div>
              <AautiPdfViewer pdfUrl={file} />
            </div>
          ) : (
            <video
              src={file}
              alt={fileType}
              autoPlay
              controls
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          )}
        </div>
      </AautiDialog>
      <ConfirmationDialogRaw
        keepMounted
        open={submitDialog}
        submitTitle={"Submit"}
        dialogTitle={renderSubmitTitle()}
        onClick={(e) => {
          e.stopPropagation();
          // deleteFunction(deleteAssId);
          UserRole === "endUser"
            ? handleSubmitAssignment()
            : handleSubmitAssignmentCorrection();
          setSubmitDialog(false);
        }}
        onClose={() => {
          setSubmitDialog(false);
        }}
      />
    </>
  );
};

export default AssignmentCard;
