import React from "react";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import { emailCount } from "../../globalComponents/helpers.variables";
import JoinButton from "../../globalComponents/JoinButton";
import { showToast } from "../../globalComponents/Toast";
import AautiText from "../../globalComponents/AautiText";
import InviteeCard from "./InviteeCard";
import { isEmpty } from "lodash";
import { checkTrusteeMemberEligibility } from "../TrusteeServices";
import { removePlusAtStart } from "../../CommonFunctions";
import { Backdrop, CircularProgress } from "@mui/material";

const InviteTrustee = ({
  isNumber,
  country,
  setCountry,
  trusteeBase,
  setTrusteeBase,
  handleInvitees,
  setInvitesList,
  invitesList,
  addBtnValidation,
  loggedinUserId,
  openBackdrop,
  setOpenBackdrop,
}) => {
  const handleRemove = (obj) => {
    const prevList = [...invitesList]?.filter((each) => each?._id !== obj?._id);
    setInvitesList(prevList);
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <AautiTextInput
          text="Email or Mobile"
          important
          leftContent={isNumber}
          defaultCountry={country}
          setDefaultCountry={setCountry}
          capitalize={false}
          style={{ width: "95%", mb: 1 }}
          value={trusteeBase}
          maxLength={emailCount}
          onChange={(e) => {
            setTrusteeBase(e.target.value.toLocaleLowerCase());
          }}
        />
        <JoinButton
          title={"Add"}
          height="40px"
          debounce
          onClick={async () => {
            let userData = {
              userId: loggedinUserId,
            };

            if (isNumber) {
              const string = removePlusAtStart(country?.phoneCode);
              userData.mobile = `${string}${trusteeBase}`;
            } else {
              userData.email = trusteeBase;
            }
            setOpenBackdrop(true);
            checkTrusteeMemberEligibility(
              userData,
              (response) => {
                setOpenBackdrop(false);
                const userInfo = response?.result;
                if (userInfo) {
                  const isPresent = invitesList?.some(
                    (item) => item?._id === userInfo?._id
                  );
                  if (isPresent) {
                    showToast(
                      "info",
                      "User already exists in the invitee list"
                    );
                    return;
                  }
                  let userData = {
                    _id: userInfo?._id,
                    profileImage: userInfo?.profileImage,
                  };
                  if (isNumber) {
                    userData.mobile = `+${userInfo?.mobile}`;
                  } else {
                    userData.email = userInfo?.email;
                  }
                  setInvitesList([...invitesList, userData]);
                  setTrusteeBase("");
                } else {
                  showToast("info", response?.message);
                }
              },
              (error) => {
                setOpenBackdrop(false);
                console.error(error, "error-checking");
              }
            );

            // const { isUserRegistered, userInfo } = await checkIsUserRegistered(
            //   trusteeBase,
            //   country
            // );
            // if (isUserRegistered) {

            // } else {
            //   showToast("info", "User Not Registered");
            // }
          }}
          disabled={!addBtnValidation()}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <AautiText title={"Invitee List"} textStyle={{ marginBottom: 5 }} />
        <div
          style={{
            height: isEmpty(invitesList) ? "auto" : "250px",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          {invitesList?.map((user) => {
            return (
              <InviteeCard key={user?._id} user={user} {...{ handleRemove }} />
            );
          })}
        </div>

        {isEmpty(invitesList) && (
          <div
            style={{
              height: "100px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AautiText title={"No Invitees"} />
          </div>
        )}
      </div>
      <JoinButton
        disabled={isEmpty(invitesList)}
        onClick={() => {
          handleInvitees(invitesList);
        }}
        width="100%"
        title={"Invite"}
        buttonStyle={{
          marginTop: 20,
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default InviteTrustee;
