import React, { useCallback, useState } from "react";
import { HeaderChatInActive } from "../homeScreen/header/pageHeader/pageHeaderUtils";
import {
  HamBurgerMenuIcon,
  MenuBarDashboardIcon,
  MenuBarSearchIcon,
  TodoListIcon,
} from "./assets";
import AautiText from "../globalComponents/AautiText";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { globalColors } from "../../themes/GlobalColors";
import TodoListDialog from "../home/dialogs/TodoListDialog";
import {
  setHasEvents,
  setTodayEvents,
  setToDoList,
  setTodosLoading,
} from "../../redux/reducer/appReducer";
import moment from "moment";
import { AuthService } from "../home/Service";

const menuIconsList = [
  {
    id: 1,
    title: "Search",
    icon: MenuBarSearchIcon,
    path: "/search-result",
  },
  {
    id: 2,
    title: "Todo List",
    icon: TodoListIcon,
    path: "",
  },

  {
    id: 3,
    title: "Dashboard",
    icon: MenuBarDashboardIcon,
    path: "/calendar",
  },
  {
    id: 4,
    title: "Chat",
    icon: HeaderChatInActive,
    path: "/chat",
  },
  {
    id: 5,
    title: "Menu",
    icon: HamBurgerMenuIcon,
    path: "/my-account",
  },
];

const MenuBar = () => {
  const navigate = useNavigate();
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { loggedinUserId, isUserLoggedIn, UserRole, todayEvents, toDoList } =
    useSelector((state) => state.app);

  const endDate = moment().endOf("day").utc().format();
  const startDate = moment().startOf("day").utc().format();
  const [openTodoDialog, setOpenTodoDialog] = useState(false);

  const handleMenuNavigations = (item) => {
    switch (item.title) {
      case "Search":
        navigate("/search-result");
        break;
      case "Dashboard":
        navigate("/calendar");
        break;
      case "Chat":
        navigate("/chat");
        break;
      case "Menu":
        navigate("/my-account");
        break;
      case "Todo List":
        toggleOpenTodoDialog();
        break;

      default:
        break;
    }
  };
  const navigateToTodoOptions = (route) => {
    navigate(route);
  };
  const toggleOpenTodoDialog = useCallback(() => {
    setOpenTodoDialog((value) => {
      const newValue = !value;
      if (newValue) {
        dispatch(setTodosLoading(true));
        AuthService.getSessionApi(
          UserRole,
          loggedinUserId,
          endDate,
          startDate,
          (res) => {
            dispatch(setTodosLoading(false));
            if (
              res?.status == "Success" &&
              res?.result &&
              res?.message == "Session(s) found"
            ) {
              let hasEvents =
                res?.result?.filter(
                  (eachItem) =>
                    new Date() < moment(eachItem?.endTime).utc() &&
                    eachItem?.scheduleType != "offTime" &&
                    eachItem?.usersList?.length > 0
                ) || [];
              dispatch(setHasEvents(hasEvents.length > 0));
              dispatch(setTodayEvents(hasEvents));
              dispatch(setTodosLoading(false));
            } else {
              dispatch(setTodayEvents(null));
              dispatch(setTodosLoading(false));
            }
          },
          (err) => {
            dispatch(setTodosLoading(false));
            console.log(err);
          }
        );
        AuthService.getToDoList(
          loggedinUserId,
          (res) => {
            if (res?.status == "Success") {
              dispatch(setToDoList(res?.result));
              dispatch(setTodosLoading(false));
            } else {
              dispatch(setTodosLoading(false));
            }
          },
          (err) => {
            dispatch(setTodosLoading(false));
            console.log(err);
          }
        );
      }
      return newValue;
    });
  }, [loggedinUserId, dispatch, UserRole]);

  return (
    <section id="menu-bar-mobile" style={{ width: "100%" }}>
      <div
        style={{
          height: 60,
          background: "#fff",
          width: "100%",
          position: "fixed",
          bottom: 0,
          zIndex: 220,
          display: !mobileDevices ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow:
            "rgba(17, 17, 26, 0.05) 0px -1px 0px, rgba(17, 17, 26, 0.1) 0px -8px 8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "center",
          }}
        >
          {menuIconsList?.map((item) => {
            const IconComponent = item.icon;
            const isActive = item?.path === pathname;
            return (
              <button
                onClick={() => {
                  handleMenuNavigations(item);
                }}
                key={item?.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  border: "none",
                  background: "none",
                }}
              >
                <IconButton sx={{ height: 28 }}>
                  <IconComponent
                    fill={isActive ? globalColors.AAUTI_THEME_COLOR : "#5C5B57"}
                  />
                </IconButton>
                <AautiText
                  title={item.title}
                  textAlign={"center"}
                  size={"semi"}
                  textStyle={{
                    color: isActive ? globalColors.AAUTI_THEME_COLOR : "#000",
                  }}
                />
              </button>
            );
          })}
        </div>
      </div>

      <TodoListDialog
        toggleOpenTodoDialog={toggleOpenTodoDialog}
        openTodoDialog={openTodoDialog}
        navigateToTodoOptions={navigateToTodoOptions}
        todayEvents={todayEvents}
        toDoList={toDoList}
      />
    </section>
  );
};

export default MenuBar;
