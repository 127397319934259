import { useState } from "react";

const NotificationsContainer = () => {
  const [activeTab, setActiveTab] = useState("Read");

  const onSwitchRoleTab = (title) => {
    setActiveTab(title);
  };
  return { onSwitchRoleTab, activeTab };
};
export default NotificationsContainer;