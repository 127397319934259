import React from "react";
import AppContainer from "../../layouts/AppContainer";
import { Container } from "./Container";
import LegalInformation from "./legalInfoModule/LegalInformation";
import VerificationMethods from "./verificationMethodModules/VerificationMethods";
import IdVerification from "./IdVerificationModule/IdVerification";
import LoadingBackdrop from "../../components/globalComponents/LoadingBackdrop";
import AccountTabs from "../../components/globalComponents/AccountTabs";
import NavButton from "../../components/globalComponents/NavButton";
import { showToast } from "../../components/globalComponents/Toast";
const VerifyUser = () => {
  const {
    legalInformation,
    handleChangeInputs,
    verificationMethod,
    setVerificationMethod,
    verificationDetails,
    handleChangeVerification,
    handleUploadeImages,
    loader,
    mainContainerRef,
  } = Container();

  return (
    <AppContainer
      style={{
        padding: "1% 3%",
        height: "calc(100vh - 50px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AccountTabs
        {...{
          tabObj: {
            label: "Verify Yourself",
            navigation: "/user-verification",
            active: true,
          },
        }}
      />
      <div className="verify_main_section" ref={mainContainerRef}>
        <LegalInformation {...{ handleChangeInputs, legalInformation }} />
        <VerificationMethods
          {...{ verificationMethod, setVerificationMethod }}
        />
        <IdVerification
          {...{
            handleChangeVerification,
            verificationDetails,
            handleUploadeImages,
            loader,
          }}
        />
        <LoadingBackdrop loading={loader} />
      </div>
      <NavButton
        text={"Verify"}
        handleClick={() => {
          showToast("info", "Comming soon");
        }}
      />
    </AppContainer>
  );
};

export default VerifyUser;
