import React from "react";
import AautiText from "./AautiText";
import "@lottiefiles/lottie-player";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const EmptyData = ({
  showExplore = false,
  text = "No Data",
  height,
  title,
}) => {
  const navigate = useNavigate();
  const { mobileDevices } = useSelector((state) => state.responsive);

  return (
    <div
      style={{
        height: height ? height : "70vh",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <lottie-player
        src="https://assets9.lottiefiles.com/packages/lf20_rc6CDU.json"
        background="transparent"
        speed="1"
        style={{ width: "100%", height: mobileDevices ? "150px" : "190px" }}
        loop
        autoplay
      />
      <AautiText
        title={title ?? text}
        textStyle={{ color: "grey", marginBottom: 10 }}
      />
      {showExplore && (
        <button
          style={{
            border: "none",
            backgroundColor: "#1e98d7",
            width: "auto",
            padding: "10px",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AautiText
              title={"Keep Exploring"}
              size={"normal"}
              weight={"extraBold"}
              textStyle={{
                color: "#fff",
                marginRight: "10px",
                marginTop: "2px",
              }}
            />
            {/* <IoIosArrowForward
            fill="#fff"
            style={{
              borderRadius: "50%",
              fontSize: "23px",
              border: "1px solid rgba(255, 255, 255, 0.27)",
              backgroundColor: "rgba(255, 255, 255, 0.27)",
              fill: "#fff",
            }}
          /> */}
          </div>
        </button>
      )}
    </div>
  );
};
export default EmptyData;
