import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";

const urlConstants = {
  getRelatedResults: "myCourse/matchMySchedule",
  getTSP: "user/getTopServiceProvidersByAreaOfIntrest?",
  saveSearch: "userSearch/save",
  getUserSearch: "userSearch/getUserSearchByUserId?",
  deleteSearchById: "userSearch/delete?",
  deleteAllSearches: "userSearch/deleteByUserId?",
  getGigRelatedResults: "gig/matchMySchedule",
  getSPRelatedResults: "user/matchMySchedule",
  getAdhocRelatedResults: "mySchedule/matchMySchedule",
};

function gigSMatchMySchedule(data, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.getGigRelatedResults,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function adhocSessionsMatchMySchedule(data, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.getAdhocRelatedResults,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getSpMatchMySchedule(data, onSuccess, onFailure) {
  ApiRequest({
    url: urlConstants.getSPRelatedResults,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export const FetchServices = {
  gigSMatchMySchedule,
  adhocSessionsMatchMySchedule,
  getSpMatchMySchedule,
};
