import React, { useEffect } from "react";
import ReviewCards from "./ReviewCards";
import { useDispatch, useSelector } from "react-redux";
import LoadingCards from "../../globalComponents/LoadingCards";
import { isEmpty } from "lodash";
import NoData from "../../globalComponents/NoDataFound";
import { getAllTestimonials } from "../reducer";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import AppContainer from "../../../layouts/AppContainer";

const MoreListing = () => {
  const { testimonialsData, status } = useSelector(
    (state) => state.homePageReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllTestimonials());
  }, [dispatch]);
  return (
    <AppContainer>
      {status == "loading" ? (
        <LoadingCards />
      ) : isEmpty(testimonialsData) ? (
        <NoData NoDataFound={AautiStrings?.noResults} />
      ) : (
        <ReviewCards {...{ testimonialsData }} />
      )}
    </AppContainer>
  );
};

export default MoreListing;
