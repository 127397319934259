import mixed from './mixed.json'
import emptyMessagesList from './empty_messages_list.json'

 const emptyChatsList = mixed.empty_chats
const emptyImage = mixed.empty_image

export {
    emptyChatsList,
    emptyMessagesList,
    emptyImage,
}