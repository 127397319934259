import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDeactivateReasons, getMembers } from "./service";
import { updateMembersList } from "../../redux/reducer/appReducer";
import moment from "moment";

export const useDeleteOrDeactivateContainer = (props) => {
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [yourMembers, setYourMembers] = useState(null);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [openPwdVerification, setOpenPwdVerification] = useState(false);
  const [openOtpVerification, setOpenOtpVerification] = useState(false);
  const [openDeactivationReasons, setOpenDeactivationReasons] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState("deActivate");
  const [password, setPassword] = useState("");
  const [deactivationReasons, setDeactivationReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [customReason, setCustomReason] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const daysOptions = [
    { label: "Item 1", value: "10 Days" },
    { label: "Item 2", value: "1 Month" },
    { label: "Item 3", value: "2 Months" },
    { label: "Item 4", value: "Custom" },
    { label: "Item 5", value: "Not Sure" },
  ];
  const [deactivationDays, setDeactivationDays] = useState(
    daysOptions[0].value
  );

  const [customDate, setCustomDate] = useState(dayjs().add(1, "day"));
  const [reactivateDate, setReactivateDate] = useState(dayjs);
  const [selectedUser, setSelectedUser] = useState(userDetails);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(120);
  const [dialogType, setDialogType] = useState("");
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const getDate = (val, customDate) => {
    switch (val) {
      case daysOptions[0]?.value:
        return moment().add(10, "days");
      case daysOptions[1]?.value:
        return moment().add(1, "months");
      case daysOptions[2]?.value:
        return moment().add(2, "months");
      case daysOptions[3]?.value:
        return moment(customDate);
      default:
        return moment().add(12, "months");
    }
  };

  useEffect(() => {
    setLoading(true);
    getMembers(
      loggedinUserId,
      (resp) => {
        const filteredMembers = resp?.result?.filter(
          (member) => member?.createdByParent
        );
        setYourMembers(filteredMembers);
        dispatch(updateMembersList(resp?.result || []));
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
    getDeactivateReasons(
      (resp) => {
        if (resp.status === "Success") {
          setDeactivationReasons(resp.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [loggedinUserId, dispatch, deleteClicked]);

  console.log(timer, dialogType, "typeee");
  useEffect(() => {
    if (dialogType == "enterOtp") {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(countdown);
            setResendEnabled(true);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [dialogType, resendEnabled]);

  useEffect(() => {
    if (selectedValue === daysOptions[3].value) {
      setReactivateDate(getDate(deactivationDays, customDate));
    } else {
      setReactivateDate(getDate(deactivationDays));
    }
  }, [deactivationDays, customDate]);

  useEffect(() => {
    setSelectedUser(userDetails);
  }, [userDetails]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleClosePwdVerification = () => {
    makeTypeEmpty();
  };

  const onSucessVerification = () => {
    handleClosePwdVerification();
    setDialogType("deactivateReasons");
  };

  const handleOpenOtpVerification = () => {
    setDialogType("enterOtp");
    setOtp("");
    setTimer(120);
  };

  const handleCloseOtpVerification = () => {
    setOpenOtpVerification(false);
  };

  const onSucessOtpVerification = () => {
    handleCloseOtpVerification();
    setDialogType("deactivateReasons");
  };

  const handleCloseConfirmationDialog = () => {
    setDeactivationDays(daysOptions[0].value);
    makeTypeEmpty("");
    setSelectedReasons([]);
    setCustomReason("");
  };

  const handleCloseConfirmDialog = () => {
    makeTypeEmpty();
  };

  const handleInfoClick = () => {
    setShowInfo((prevShowInfo) => !prevShowInfo);
  };

  const resetDeactivationData = () => {
    makeTypeEmpty();
    setDeactivationDays(daysOptions[0].value);
    setCustomDate(dayjs().add(1, "day"));
    setSelectedReasons([]);
    setCustomReason("");
  };

  const handleCustomReasonChange = (e) => {
    if (e.target.value.length <= 300) {
      setCustomReason(e.target.value);
    }
  };

  const handleDeactivationDaysChange = (e) => {
    setDeactivationDays(e.target.value);
    if (e.target.value !== daysOptions[3].value) {
      setReactivateDate(getDate(e.target.value));
    }
  };

  const toggleReason = (reason) => {
    console.log(reason, "reason");
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((r) => r !== reason)
        : [...prev, reason]
    );
  };

  const onPressContinue = () => {
    setDialogType("confirmation");
  };

  const makeTypeEmpty = () => {
    setDialogType("");
  };

  return {
    customDate,
    setCustomDate,
    reactivateDate,
    setReactivateDate,
    selectedUser,
    setSelectedUser,
    deleteClicked,
    setDeleteClicked,
    resendEnabled,
    setResendEnabled,
    timer,
    setTimer,
    deactivationDays,
    loadingVerification,
    setLoadingVerification,
    showInfo,
    loading,
    setLoading,
    otp,
    setOtp,
    yourMembers,
    setYourMembers,
    customReason,
    setCustomReason,
    selectedReasons,
    setSelectedReasons,
    deactivationReasons,
    setDeactivationReasons,
    openConfirmDialog,
    setOpenConfirmDialog,
    selectedValue,
    dialogType,
    setDialogType,
    makeTypeEmpty,
    daysOptions,
    mobileDevices,
    navigation,
    getDate,
    toggleReason,
    onPressContinue,
    handleInfoClick,
    resetDeactivationData,
    handleCloseConfirmDialog,
    handleCustomReasonChange,
    onSucessOtpVerification,
    onSucessVerification,
    handleOpenOtpVerification,
    handleCloseConfirmationDialog,
    handleDeactivationDaysChange,
    handleChange,
  };
};
