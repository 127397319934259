import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import Trash from "../../../assets/images/trash-icon.png";

const TrashIcon = ({
  height = "20px",
  width = "20px",
  onClick,
  buttonStyle,
}) => {
  return (
    <Tooltip title="Remove">
      <IconButton
        onClick={(e) => onClick(e)}
        sx={{
          height: height,
          width: width,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          ...buttonStyle,
          //   backgroundColor: "#fff",
          p: 0,
          //   "&:hover": { background: "#fff" },
        }}
      >
        <img
          src={Trash}
          alt="trash"
          style={{
            height: "100%",
            width: "100%",
            zIndex: 100,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default TrashIcon;
