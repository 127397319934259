import React from "react";
import Lottie from "react-lottie-player";
import LottieOffline from "../../assets/lotties/Animation - 1704374262478.json";

const Offline = () => {
  return (
    <div
      style={{
        padding: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 150,
      }}
    >
      <div>
        <Lottie
          loop
          play
          animationData={LottieOffline}
          // className={"lottie_styles"}
          style={{ height: "30vh" }}
        />
        <h2>No internet connection</h2>
        <p>Try: </p>
        <div>
          <ul>
            <li>Checking the network cables, modem and router</li>
            <li>Reconnection to Wi-Fi</li>
            <li>Running Windows Network Diagnostics</li>
          </ul>
        </div>
        <h5>Please check your internet connection</h5>
        <button
          style={{
            padding: "5px 15px",
            border: "1px solid lightgrey",
            borderRadius: 4,
            cursor: "pointer",
            alignSelf: "center",
          }}
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

export default Offline;
