import { useEffect, useState } from "react";
import { checkNull } from "../../../utils/Helpers";
import { AuthService } from "../service";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { useSelector } from "react-redux";

const RefundsScreenHooks = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("credits");
  const { loggedinUserId } = useSelector((state) => state.app);
  const [refundsList, setRefundsList] = useState([]);
  const [refundAmount, setRefundAmount] = useState(0);
  const [offSet, setOffset] = useState(0);
  const [hasData, setHasData] = useState(true);

  let count = AautiStrings?.count;
  const getAllRefunds = (offsetKey = offSet) => {
    if (offsetKey === 0) {
      setLoading(true);
    }
    const params = {
      endUserId: loggedinUserId,
      refundMethod: activeTab === "credits" ? "credits" : "refunds",
      count: count,
      offset: offsetKey,
    };
    AuthService.getAllRefundRequests(
      params,
      (response) => {
        if (offsetKey === 0) {
          if (checkNull(response?.result)) {
            setRefundsList([]);
            setHasData(false);
            setLoading(false);
          } else if (response?.result[0]?.refundList?.length < count) {
            setRefundsList(response?.result[0]?.refundList);
            setRefundAmount(response?.result[0]?.refundAmount);
            setHasData(false);
          } else {
            setRefundsList(response?.result[0]?.refundList);
            setRefundAmount(response?.result[0]?.refundAmount);
            setHasData(true);
            setOffset(offsetKey + 1);
          }
        } else {
          if (checkNull(response?.result)) {
            setHasData(false);
            setLoading(false);
          } else if (response?.result[0]?.refundList?.length < count) {
            setRefundsList([
              ...refundsList,
              ...response?.result[0]?.refundList,
            ]);
            setHasData(false);
          } else {
            setRefundsList([
              ...refundsList,
              ...response?.result[0]?.refundList,
            ]);
            setHasData(true);
            setOffset(offsetKey + 1);
          }
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error, "error");
      }
    );
  };
  useEffect(() => {
    setLoading(true);
    getAllRefunds(0);
  }, [activeTab]);

  return {
    loading,
    activeTab,
    refundsList,
    hasData,
    refundAmount,
    setActiveTab,
    getAllRefunds,
  };
};
export default RefundsScreenHooks;
