import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

/** login authentication
 * @param username, password
 */

const GET_SERVICEPROVIDER_RATING_REVIEWS =
  "ratingsReviews/getServiceProviderIdRatingsReviews";

function getMyGigsForServiceProvider(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_MYGIGBYUSERID_SERVICEPROVIDER,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getServiceProviderRatingsReviewsBy(
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: GET_SERVICEPROVIDER_RATING_REVIEWS,
    method: ApiMethods.GET,
    params: {
      serviceProviderId: serviceProviderId,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMyCoursesByUserid(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesByUserId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMyOfferedCoursesByUserid(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getOfferedCoursesByUserId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMyExpCoursesByUserid(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getExpiredCourseBySpId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getServiceProviderDetails(id, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getServiceProviderDetailsById",
    method: ApiMethods.GET,
    params: { id, endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getInstitutionDetails(instituteId, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "institution/getInstituteDetailsById?",
    method: ApiMethods.GET,
    params: { instituteId, endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getServiceProviderDetailsBeforeLogin(id, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getServiceProviderDetailsById",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getRatingsReviewsByServiceProviderId(
  serviceProviderId,
  sortBy,
  offset,
  count,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "ratingsReviews/getRatingsReviewsByServiceProviderId",
    method: ApiMethods.GET,
    params: {
      serviceProviderId: serviceProviderId,
      sortBy: sortBy,
      offset,
      count,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function GetuserDetailsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETUSERSBYID,
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getServiceProviderDashboardDetails(
  loggedInUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SERVICEPROVIDER_DASHBOARD_DETAILS + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getReports(type, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_REPORTS + `?type=${type}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getStudentsListByServiceProviderID(
  loggedInUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SERVICEPROVIDERS_STUDENTS_LIST + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getRecentViewedServiceProviders(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/recentViewedServiceProviders",
    params: data,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getRecentViewedByEndUsers(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMyProfileViewedUsers",
    params: data,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveRatingsReviews(ratingsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_RATINGS_REVIEWS,
    method: ApiMethods.POST,
    data: ratingsData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function complaintSave(complaintData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_COMPLAINT_SAVE,
    method: ApiMethods.POST,
    data: complaintData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteRatingsReviews(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_RATING_REVIEWS_BY_REVIEWSID,
    params: { id },
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function updateRatingsReviews(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_RATING_REVIEWS,
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAttendance(serviceProviderId, noOfDays, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ATTENDANCE +
      "?serviceProviderId=" +
      serviceProviderId +
      "&noOfDays=" +
      noOfDays,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getCourseAttendanceEU(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getEndUserAttendanceForCourse",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getCourseAttendanceSp(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getServiceProviderAttendanceForCourse",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getAdhocAttendanceSp(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getServiceProviderAttendanceForAdhoc",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getAdhocAttendanceEU(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getEndUserAttendanceForAdhoc",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getEndUserAttendance(endUserId, noOfDays, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ENDUSER_ATTENDANCE +
      "?endUserId=" +
      endUserId +
      "&noOfDays=" +
      noOfDays,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getEligibilty(endUserId, serviceProviderId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ELIGIBILTY +
      "endUserId=" +
      endUserId +
      "&serviceProviderId=" +
      serviceProviderId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getSessionsMapByStartEndDatesByServiceProvider(
  data,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SESSION_MAP_BY_START_END_DATES_BY_SERVICEPROVIDER,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response === undefined ? [] : response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMaxAndRecommendedSessions(data, onSuccess, onFailure) {
  ApiRequest({
    url: "session/getMaxAndRecommendedSessions",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response === undefined ? [] : response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function wishlistServiceProvider(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_WISHLIST_WISHLIST_SERVICEPROVIDER,
    method: ApiMethods.POST,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function wishlistInstitute(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_SAVE_WISHLIST,
    method: ApiMethods.POST,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteWishlistedServiceProvider(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_WISHLIST_DELETE_WISHLIST_SERVICEPROVIDER,
    method: ApiMethods.DELETE,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteWishlistedInstitute(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_WISHLIST,
    method: ApiMethods.DELETE,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getTopSeviceProviders(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TOPSERVICEPROVIDERS + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getTopServiceProvidersBeforeLogin(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TOPSERVICEPROVIDERS_BEFORE_LOGIN,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function getMembers(parentId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMembersList",
    method: ApiMethods.GET,
    params: { parentId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function reportInChatRoom(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.REPORT_CHAT_ROOM,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
export const getAllSpAdhocEvents = (
  count,
  offset,
  userId,
  searchKey,
  onSuccess,
  onFailure
) => {
  const params = count
    ? { userId, type: "adhoc", offset, count, searchKey: searchKey }
    : { userId, type: "adhoc", offset, searchKey: searchKey };
  ApiRequest({
    url: "mySchedule/getMySchedulesByUserId",
    method: ApiMethods.GET,
    params: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
};

function getSessionDetailsById(sessionId, onSuccess, onFailure) {
  ApiRequest({
    url: "session/getSessionDetailsById?sessionId=" + sessionId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getPackagesListBySP(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_PACKAGES_BY_SERVICEPROVIDER_ID,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure();
    }
  );
}
function getPackagesListByPackageId(data, onSuccess, onFailure) {
  ApiRequest({
    url: "package/getAdhocPackageById",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure();
    }
  );
}
function getAdhocReviewsById(data, onSuccess, OnFailure) {
  ApiRequest({
    url: "ratingsReviews/getRatingsReviewsByCategoryAndSubCategory",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      OnFailure && OnFailure(error);
    }
  );
}
export const AuthService = {
  getAdhocReviewsById,
  getPackagesListBySP,
  getPackagesListByPackageId,
  getSessionDetailsById,
  getRecentViewedServiceProviders,
  getRecentViewedByEndUsers,
  getStudentsListByServiceProviderID,
  getSessionsMapByStartEndDatesByServiceProvider,
  getMaxAndRecommendedSessions,
  getMyGigsForServiceProvider,
  getMyCoursesByUserid,
  getMyOfferedCoursesByUserid,
  getMyExpCoursesByUserid,
  getServiceProviderDetails,
  getInstitutionDetails,
  getRatingsReviewsByServiceProviderId,
  GetuserDetailsById,
  getServiceProviderDashboardDetails,
  getReports,
  saveRatingsReviews,
  complaintSave,
  deleteRatingsReviews,
  updateRatingsReviews,
  getAttendance,
  getCourseAttendanceEU,
  getAdhocAttendanceEU,
  getCourseAttendanceSp,
  getAdhocAttendanceSp,
  getEndUserAttendance,
  getEligibilty,
  getServiceProviderDetailsBeforeLogin,
  wishlistServiceProvider,
  wishlistInstitute,
  deleteWishlistedServiceProvider,
  deleteWishlistedInstitute,
  getTopSeviceProviders,
  getTopServiceProvidersBeforeLogin,
  getServiceProviderRatingsReviewsBy,
  getMembers,
  reportInChatRoom,
  getAllSpAdhocEvents,
};
