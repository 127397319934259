import React from "react";
import { AccordionSummary, Avatar, styled } from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { globalColors } from "../../themes/GlobalColors";
import { isEmpty } from "lodash";
import AautiText from "../globalComponents/AautiText";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&& .css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root": {
    padding: "0px !important",
    margin: "none !important",
    minHeight: "auto !important",
  },
  "&& .css-1betqn-MuiAccordionSummary-content": {
    margin: "0px !important",
    minHeight: "auto !important",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SwitchAccountTrustee = ({
  isEndUser,
  isInstituteAdmin,
  trusteeMembersWithBase,
  primaryObject,
  handleClickTrusteeMem,
}) => {
  const { loggedinUserId } = useSelector((state) => state.app);
  return (
    <div
      style={{
        display:
          (isEndUser || isInstituteAdmin || isEmpty(trusteeMembersWithBase)) &&
          "none",
        marginTop: 5,
      }}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"aria-controls"}
        >
          <AautiText
            title={"Switch Accounts"}
            size={"semi"}
            weight={"medium"}
            // textStyle={{ color: globalColors.AAUTI_THEME_COLOR }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              marginTop: 10,
            }}
          >
            {trusteeMembersWithBase?.map((each) => {
              return (
                <div
                  onClick={() => {
                    handleClickTrusteeMem(each);
                  }}
                  key={each}
                  style={{
                    display: loggedinUserId === each?.userId ? "none" : "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "6px",
                    borderRadius: 6,
                    border: "1px solid #ccc",
                    width: "100%",
                    gap: 6,
                    cursor: "pointer",
                  }}
                >
                  <Avatar src={each?.profileImage} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      // justifyContent: "space-between",
                      // alignItems: "center",
                    }}
                  >
                    <AautiText
                      title={each?.displayName}
                      textStyle={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        maxWidth: "80%",
                      }}
                    />
                    {each?.userId === primaryObject?.userId && (
                      <AautiText
                        title={"Primary"}
                        size={"semi"}
                        textStyle={{
                          color: globalColors?.AAUTI_THEME_COLOR,
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SwitchAccountTrustee;
