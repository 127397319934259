import React from "react";
import AautiText from "../../../components/globalComponents/AautiText";
import { Divider } from "@mui/material";

const InfoHead = ({ title }) => {
  return (
    <div>
      <AautiText title={title} weight={"bold"} />
      <Divider sx={{ color: "#E0E0E0", width: "100%", marginTop: 1 }} />
    </div>
  );
};

export default InfoHead;
