import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { MoreVert } from '@mui/icons-material';

export default function ChatMenu({id='chat_menu',options,onPressMenuItem}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event,
    index,
  ) => {
    onPressMenuItem(options[index]);
    setAnchorEl(null);
  };

  return (
    <div id={id}>
      <MoreVert aria-label="more"
        id={`${id}_button`}
        aria-controls={open ? `${id}_menu` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup={true}
        onClick={handleClickListItem} />
      <Menu
        id={`${id}_menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={()=>setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': `${id}_button`,
          role: 'listbox',
        }}  
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(event) => handleMenuItemClick(event, index)}
            style={{fontFamily:'Poppins',fontSize:12,fontWeight:'500',width:'100%'}}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
