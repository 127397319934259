import {throttle} from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { useChannelActionContext, useTranslationContext } from '../../../context';
import { showToast } from '../../../../globalComponents/Toast';

const getDurationForInfinityDurationAudioFile = (
  src,
  callback
) => {
  const audioElement = new Audio();
  audioElement.src = src;

  audioElement.addEventListener('loadedmetadata', () => {
    const { duration } = audioElement;
    if (duration === Infinity) {
      audioElement.currentTime = 1e101;
      return;
    }

    return callback(duration);
  });

  audioElement.addEventListener('durationchange', () => {
    if (audioElement.duration === Infinity) {
      return;
    }
    callback(audioElement.duration);
    audioElement.remove();
  });
};

export const elementIsPlaying = (audioElement) =>
  audioElement && !(audioElement.paused || audioElement.ended);

const DEFAULT_PLAYBACK_RATES = [1.0, 1.5, 2.0];


export const useAudioController = ({
  durationSeconds,
  mimeType,
  playbackRates = DEFAULT_PLAYBACK_RATES,
  src
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackError, setPlaybackError] = useState();
  const [canPlayRecord, setCanPlayRecord] = useState(true);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [playbackRateIndex, setPlaybackRateIndex] = useState(0);
  const [duration, setDuration] = useState(0);
  const playTimeout = useRef();
  const audioRef = useRef(null);

  const registerError = useCallback((e) => {
    setPlaybackError(e);
    showToast('error', e.message);
  }, []);

  const togglePlay = useCallback(async () => {
    if (!audioRef.current) return;

    if (audioRef.current.paused) {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (e) {
        registerError(e);
        setIsPlaying(false);
      }
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, [registerError]);

  const increasePlaybackRate = () => {
    setPlaybackRateIndex((prev) => {
      if (!audioRef.current) return prev;
      const nextIndex = prev === playbackRates.length - 1 ? 0 : prev + 1;
      audioRef.current.playbackRate = playbackRates[nextIndex];
      return nextIndex;
    });
  };

  const seek = (newTime) => {
    if (!audioRef.current) return;
    // Ensure newTime is within bounds (0 to duration)
    const clampedTime = Math.max(0, Math.min(newTime, audioRef.current.duration));
    audioRef.current.currentTime = clampedTime;
    setSecondsElapsed(clampedTime);
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement) return;

    const handleEnded = () => {
      setSecondsElapsed(audioElement?.duration ?? durationSeconds ?? 0);
      setIsPlaying(false);
    };

    const handleError = () => {
      showToast('error', 'Error reproducing the recording');
      setIsPlaying(false);
    };

    const handleTimeupdate = () => {
      setSecondsElapsed(audioElement?.currentTime);
    };

    const onLoadMetaData = (e) => {
      const { duration } = e.target;
      if (duration !== Infinity) {
        setDuration(duration);
      } else {
        getDurationForInfinityDurationAudioFile(src, setDuration);
      }
    };

    audioElement.addEventListener('ended', handleEnded);
    audioElement.addEventListener('error', handleError);
    audioElement.addEventListener('timeupdate', handleTimeupdate);
    audioElement.addEventListener('loadedmetadata', onLoadMetaData);

    return () => {
      if (!audioElement) return;
      audioElement.removeEventListener('ended', handleEnded);
      audioElement.removeEventListener('error', handleError);
      audioElement.removeEventListener('timeupdate', handleTimeupdate);
      audioElement.removeEventListener('loadedmetadata', onLoadMetaData);
    };
  }, [durationSeconds, src]);

  return {
    audioRef,
    canPlayRecord,
    increasePlaybackRate,
    isPlaying,
    playbackError,
    playbackRate: playbackRates[playbackRateIndex],
    progress:
      audioRef.current && secondsElapsed && audioRef.current.duration > 0
        ? (secondsElapsed / audioRef.current.duration) * 100
        : 0,
    secondsElapsed,
    togglePlay,
    seek, // Expose the seek function for use in the UI
    duration: audioRef.current?.duration,
  };
};

