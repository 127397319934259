import { createContext, useState } from "react";
export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  const [lightMode, setLightMode] = useState(
    localStorage.getItem("lightMode") == null
      ? "light"
      : localStorage.getItem("lightMode") === "light"
      ? "light"
      : "dark"
  );

  const toggleLightMode = () => {
    localStorage.setItem("lightMode", lightMode === "light" ? "dark" : "light");
    setLightMode((value) => (value === "light" ? "dark" : "light"));
  };

  return (
    <AppContext.Provider
      value={{
        lightMode,
        toggleLightMode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
