import React, { memo } from "react";
import AnimateButton from "../../../utils/AnimateButton";
import moment from "moment";
import { isEmpty } from "lodash";
import { validateTitle } from "../../CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "../../../service";
import { showToast } from "../Toast";
import {
  setJwtToken,
  setRoomName,
  setScreenName,
} from "../../../redux/reducer/appReducer";
import { useNavigate } from "react-router-dom";
import {
  openClassWindow,
  restoreWindowsFromLocalStorage,
} from "../../../utils/JoinClassHooks";

const ClassJoinButton = ({
  event,
  toggleOpenTodoDialog = () => {},
  isDashboard = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedinUserId, UserRole } = useSelector((state) => state.app);
  const checkButtonStatus = () => {
    const startDateTime = moment(event?.startTime);
    const endDateTime = moment(event?.endTime);
    if (!startDateTime.isValid() || !endDateTime.isValid()) {
      return false;
    }
    const startWindow = startDateTime.clone().subtract(15, "minutes");
    if (
      moment().isBetween(startWindow, endDateTime) &&
      !isEmpty(event?.usersList)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const btnValidation = !checkButtonStatus();
  const handleAttendanceService = () => {
    const isEndUser = UserRole?.includes("endUser");
    const data = {
      serviceProviderId: event?.serviceProviderId,
      sessionId: event?._id,
      courseId: event?.courseId,
    };
    if (event?.instituteId) {
      data.instituteId = event?.instituteId;
    }
    if (isEndUser) {
      data.endUserId = loggedinUserId;
    }
    AuthService.saveAttendance(data, onSuccessAttendance, OnFailure);
  };

  let isModerator =
    UserRole?.includes("serviceprovider") &&
    loggedinUserId === event?.serviceProviderId;

  const onSuccessAttendance = (response) => {
    // if (response.status === "Success" && response.result !== null) {
    //   showToast(
    //     "success",
    //     response?.message || "Attendance saved successfully"
    //   );
    // } else {
    //   showToast("info", response?.message);
    // }
  };

  const OnFailure = (error) => {
    showToast("error", error?.message);
  };

  const handleJoin = () => {
    handleAttendanceService();
    const room = `${validateTitle(event?.courseName ?? event?.scheduleType)}-${
      event?._id
    }`;

    let data = {
      room: room,
      userId: loggedinUserId,
      isSp: isModerator,
    };

    if (event?.scheduleType === "adhoc") {
      data.sessionId = event?.sessionId;
    } else {
      data.batchId =
        event?.scheduleType === "course adhoc"
          ? event?.schedulerId
          : event?.batchId;
    }

    AuthService.generateJWT(
      data,
      (response) => {
        if (response?.result) {
          dispatch(setJwtToken(response?.result));
          localStorage.setItem("jwtToken", response?.result);
          localStorage.setItem("room", room);
          localStorage.setItem(
            "event",
            JSON.stringify({ ...event, isModerator })
          );
          // dispatch(setJitsiFullScreenMode(true));
          dispatch(setRoomName(room));
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
    restoreWindowsFromLocalStorage(room);
    openClassWindow({ path: window.location.origin, room });
  };

  if (event?.inPerson) {
    return (
      <AnimateButton>
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (isDashboard) {
              const newArray = {
                ...event,
                screenName: "Calender",
              };
              navigate("/join_class", { state: newArray });
              dispatch(setScreenName("Calendar"));
            } else {
              event?.scheduleType != "offTime" &&
                navigate("/join_class", { state: event });
            }

            toggleOpenTodoDialog();
          }}
          // disabled={btnValidation}
          className="course_button"
          style={{
            cursor: "pointer",
            display: event?.scheduleType === "offTime" && "none",
          }}
        >
          View
        </button>
      </AnimateButton>
    );
  } else {
    return (
      <AnimateButton>
        <button
          onClick={(e) => {
            e.stopPropagation();
            // dispatch(setShowTodaysEvents(false));
            handleJoin();
            toggleOpenTodoDialog();
          }}
          disabled={btnValidation}
          className="course_button"
          style={{
            backgroundColor: btnValidation && "lightGrey",
            cursor: !btnValidation && "pointer",
          }}
        >
          Join
        </button>
      </AnimateButton>
    );
  }
};

export default memo(ClassJoinButton);
