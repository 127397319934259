import React from 'react'
import AbstractReply from '../abstract_reply'
import MessageText from '../text';

const styles = {
    left: {
      container: {
        backgroundColor: '#ffffff',
        borderRadius:5,
        display:'flex',
        flexDirection:'column',
        rowGap:4,
      },
    },
    right: {
      container: {
        backgroundColor: '#cce1fc',
        borderRadius: 5,
        display:'flex',
        flexDirection:'column',
        rowGap:4,
      },
    },
  }
  
  export function MessageReply({
    currentMessage = {},
    position = 'left',
    searchText,
  }) {
    const onClickReply = (e)=>{
      const parentMsg = document.getElementById(currentMessage?.parentId);
      parentMsg?.scrollIntoView({ behavior: 'smooth' });
    }
    return (
      <div style={styles[position].container}
        onClick={onClickReply}
      >
        <AbstractReply
          {...{
            message: currentMessage.reply,
            searchText,
          }}
        />
        <MessageText
          {...{
            currentMessage,
            position,
            searchText,
          }}
        />
      </div>
    )
  }
  