import React, { useCallback, useRef, useState } from "react";
import { checkNull } from "../../../utils/Helpers";
import { AuthService } from "./Service";
import { useDispatch, useSelector } from "react-redux";
import { PackageStrings } from "../Constants";
import { showToast } from "../../globalComponents/Toast";
import { setBuyNowDataAfterRefresh } from "../../CommonFunctions";
import {
  ChangeUserRole,
  setBuyNowdata,
  setRefHeadNotifications,
} from "../../../redux/reducer/appReducer";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../../storage/constants";
import { HeaderStrings } from "../../header/constants";

export const Container = ({
  responseType,
  childId,
  handleCloseNot,
  showMorebtns,
  unreadCount,
}) => {
  const { loggedinUserId, UserRole, userDetails, refHeadNotification } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [unReadMessages, setUnReadMessages] = useState([]);
  const [AllNot, setAlNot] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadingNotifi, setLoadingNotifi] = useState(false);
  const [selected, setSelected] = useState(null);
  const [accepteState, setAccepteState] = useState(false);
  const [clearNotification, setClearNotification] = useState("");
  const [readOffset, setReadOffset] = useState(0);
  const [childNotifications, setChildNotifications] = useState(null);
  const [childOffset, setChildOffset] = useState(0);
  const [actionOffSet, setActionOffset] = useState(0);
  const [readLastItem, setReadLastItem] = useState(false);
  const [actionLastItem, setActionLastItem] = useState(false);
  const [childEndReach, setChildEndReach] = useState(false);
  const [actionIndex, setActionIndex] = useState(0);
  const [readIndex, setReadIndex] = useState(0);
  const [childIndex, setChildIndex] = useState(0);
  const [showReadScrollButton, setShowReadScrollButton] = useState(false);
  const [showActionScrollButton, setShowActionScrollButton] = useState(false);
  const [childScrollbtn, setChildScrollBtn] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [inCart, setInCart] = useState(false);
  const readRefs = useRef([]);
  const actionRefs = useRef([]);
  const actionRef = useRef(null);
  const readRef = useRef(null);
  const childRef = useRef(null);
  const childRefs = useRef([]);
  const [showSelection, setShowSelection] = useState(false);

  const showCursor = (item) => {
    const validTypes = [
      "invite Course",
      "Adhoc starts in 15 Mins",
      "Course starts in 15 Mins",
      "Course Adhoc",
      "Session reschedule",
      "Course",
      "course",
      "Batch Transfer",
      "course purchase",
      "Adhoc purchase",
      "batchTransfer",
      "Course Expiring",
      "Task",
      "Quiz",
      "Ask",
      "Agenda",
      "Poll",
      "Merge",
    ];

    return (
      validTypes.includes(item?.notificationType.trim()) || item?.status === "0"
    );
  };

  function formatTo12HourTime(isoString) {
    const date = new Date(isoString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  }

  const onSuccessNot = (response) => {
    if (response?.status === "Success") {
      setUnReadMessages(response?.result?.unreadCount || 0);
      setLoadingNotifi(false);
      if (response?.result?.notifications?.length > 0) {
        if (responseType === "Child") {
          setChildNotifications([
            ...(childNotifications ?? []),
            ...response?.result?.notifications,
          ]);
          if (response?.result?.notifications?.length < 10) {
            setChildEndReach(true);
            return;
          }
        } else if (responseType === "Read") {
          setReadNotifications([
            ...(readNotifications ?? []),
            ...response?.result?.notifications,
          ]);
          if (response?.result?.notifications?.length < 10) {
            setReadLastItem(true);
            return;
          }
        } else {
          setAlNot([...(AllNot ?? []), ...response?.result?.notifications]);
          if (response?.result?.notifications?.length < 10) {
            setActionLastItem(true);
            return;
          }
        }
      } else {
        return null;
      }
    } else {
      setLoadingNotifi(false);
    }
  };

  const onFailure = (error) => {
    setLoadingNotifi(false);
    setOpen(false);
    showToast(PackageStrings?.error, error?.message);
  };

  let countValue = 10;

  const params = {
    userId: responseType == "Child" ? childId : loggedinUserId,
    count: countValue,
  };
  switch (responseType) {
    case "Read":
      params.responseType = "read";
      params.offset = readOffset;
      params.offset = readOffset;
      break;
    case "Action":
      params.responseType = "action";
      params.offset = actionOffSet;
      break;
    case "Child":
      params.offset = childOffset;
      break;
    default:
      break;
  }

  let paramload =
    responseType == "Read"
      ? readOffset
      : responseType == "Child"
      ? childOffset
      : actionOffSet;

  const getNotificationData = () => {
    if (paramload == 0) {
      setLoadingNotifi(true);
    }
    AuthService.getAllNotificationsByUserId(params, onSuccessNot, onFailure);
  };

  const refreshNotifications = async () => {
    const paramstoPass = {
      userId: responseType === "Child" ? childId : loggedinUserId,
      count: 10,
    };

    switch (responseType) {
      case "Read":
        paramstoPass.responseType = "read";
        paramstoPass.offSet = 0;
        break;
      case "Action":
        paramstoPass.responseType = "action";
        paramstoPass.offSet = 0;
        break;
      case "Child":
        paramstoPass.offSet = 0;
        break;
      default:
        break;
    }

    await AuthService.getAllNotificationsByUserId(
      paramstoPass,
      (response) => {
        if (responseType === "Child") {
          setChildNotifications(response?.result?.notifications);
          if (response?.result?.notifications?.length < 10) {
            setChildEndReach(true);
          }
        } else if (responseType === "Read") {
          setReadNotifications(response?.result?.notifications);
          if (response?.result?.notifications?.length < 10) {
            setReadLastItem(true);
          }
        } else {
          setAlNot(response?.result?.notifications);
          if (response?.result?.notifications?.length < 10) {
            setActionLastItem(true);
          }
        }
      },
      (err) => {
        console.error("Error refreshing notifications:", err);
      }
    );
  };

  const deleteSelectedNotifications = async () => {
    AuthService.deleteMultipleNotifications(
      selectedNotifications,
      (res) => {
        if (res?.status == "Success") {
          setShowSelection(false);
          dispatch(setRefHeadNotifications(!refHeadNotification));
          setActionIndex(0);
          setReadIndex(0);
          setChildIndex(0);
          refreshNotifications();
          setSelectedNotifications([]);
        }
      },
      (err) => {
        console.log(err, "----------error multiple delete");
      }
    );
  };

  const cancelSelection = () => {
    setShowSelection(false);
    setSelectedNotifications([]);
  };

  const handleSelectEachNotification = useCallback((notification) => {
    setSelectedNotifications((prevSelected) => {
      const selectedSet = new Set(prevSelected.map((item) => item._id));
      return selectedSet.has(notification._id)
        ? prevSelected.filter((each) => each._id !== notification._id)
        : [...prevSelected, notification];
    });
  }, []);

  const isAllSelected = () => {
    let check =
      (responseType === "Action" ? AllNot : readNotifications).length ==
      selectedNotifications?.length;

    return check;
  };

  const isNotificationSelected = (each) => {
    return selectedNotifications.some((item) => item._id === each._id);
  };

  const courseBuyApi = (data) => {
    const onSuccessApi = (response) => {
      let buyNowData = [];
      if (response?.status?.toLowerCase() === "success") {
        const courseData = response?.result[0];
        const { course = {} } = courseData;
        const { individualDaily, individualWeekly, groupDaily, groupWeekly } =
          course?.batchDeatils;
        const selectedBatch = [
          ...individualDaily,
          ...individualWeekly,
          ...groupDaily,
          ...groupWeekly,
        ].find((each) => each._id === data?.batchId);
        const courseObject = {
          type: "course",
          endUserId: loggedinUserId,
          serviceProviderId: courseData?.serviceProviderId,
          courseId: course?._id || course?.id,
          serviceProviderName: courseData?.serviceProviderName,
          selectedBatch: selectedBatch,
          selectedBatchId: selectedBatch?._id,
          transactionCurrencyCode:
            courseData?.transactionCurrencyCode ?? courseData?.currencyCode,
          course: {
            _id: course?._id || course?.id,
            courseName: course?.courseName,
            category: course?.category,
            subCategory: course?.subCategory,
            courseDescription: course?.courseDescription,
            courseImage: course?.courseImage,
            courseLevel: course?.courseLevel,
            averageRating: course?.averageRating,
            ratingCount: course?.ratingCount,
            batchDeatils: {
              individualDaily,
              individualWeekly,
              groupDaily,
              groupWeekly,
            },
            blockbusterDeal: course?.blockbusterDeal,
            adminDiscount: course?.adminDiscount,
            blockbusterEndDate: course?.blockbusterEndDate,
            endUserList: course?.endUserList,
          },
          subscribersCount: courseData?.subscribersCount ?? 0,
          averageRating: courseData?.averageRating,
          ratingCount: courseData?.ratingCount,
          yearsOfExperience: courseData?.yearsOfExperience,
          childDetails: [
            {
              endUserId: loggedinUserId,
              batchId: selectedBatch?._id,
            },
          ],
        };
        buyNowData.push(courseObject);
        dispatch(setBuyNowdata(buyNowData));
        setInCart(!inCart);
        setBuyNowDataAfterRefresh(buyNowData, dispatch, "gig");

        navigate(`/buyNow`, {
          state: "buyNow",
        });
        if (showMorebtns) {
          handleCloseNot();
        }
      } else {
        console.log("buyNow failed");
      }
    };
    if (!inCart) {
      addToCart(data);
    }
    AuthService.getBuyNowData(data, onSuccessApi, onFailure);
  };

  const addToCart = (completeDetails) => {
    let ApiObj = {
      type: "course",
      endUserId: loggedinUserId,
      serviceProviderId: completeDetails?.serviceProviderId,
      courseId: completeDetails?.courseId,
      batchId: completeDetails?.batchId ?? "",
      parentId: userDetails?.parentId ? userDetails?.parentId : loggedinUserId,
      childDetails: [],
    };
    AuthService.saveCart(
      ApiObj,
      (response) => {
        if (response.status === "Success") {
          showToast("info", response?.message);
          dispatch(setRefHeadNotifications(!refHeadNotification));
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error, "error save cart");
      }
    );
  };

  const CoursePurchaseNavigation = (item) => {
    const params = {
      courseId: item?.courseId,
    };
    if (UserRole === userRoles.endUser) {
      params.userId = loggedinUserId;
    }
    if (item?.type === "Course Adhoc") {
      params.schedulerId = item?.schedulerId;
    } else {
      params.batchId = item?.batchId;
    }
    AuthService.getRecentSessionData(
      params,
      (res) => {
        if (checkNull(res?.result?._id || res?.result?.id)) {
          showToast("Info", "No Sessions Available");
        } else {
          const result = res?.result;
          if (result?.serviceProviderId === loggedinUserId) {
            localStorage.setItem(
              HeaderStrings?.role,
              HeaderStrings?.serviceProvide2
            );
            dispatch(ChangeUserRole(HeaderStrings?.serviceProvide2));
          } else if (result?.serviceProviderId !== loggedinUserId) {
            localStorage.setItem(HeaderStrings?.role, HeaderStrings?.endUser2);
            dispatch(ChangeUserRole(HeaderStrings?.endUser2));
          }
          navigate("/join_class", {
            state: res?.result,
          });
          if (showMorebtns) {
            handleCloseNot();
          }
        }
      },
      onFailure
    );
  };

  const handleClick = (data) => {
    const idx = data?.courseId || data?.gigId;
    const type = data?.notificationType ?? "";
    const clearSuccess = async (response) => {
      if (response?.data?.status == "Success") {
        const updatedNotifications = (
          responseType === "Read" ? readNotifications : AllNot
        )?.map((each) => {
          if (each?._id === data?._id) {
            return {
              ...each,
              status: "1",
            };
          }
          return each;
        });
        if (responseType === "Read") {
          unReadMessages != 0 && setReadNotifications(updatedNotifications);
        } else {
          unReadMessages != 0 && setAlNot(updatedNotifications);
        }
        unReadMessages != 0 && setUnReadMessages(unreadCount - 1);
        switch (type) {
          case "Batch Transfer":
          case "batchTransfer":
            CoursePurchaseNavigation(data);
            break;
          case "Course starts in 15 Mins":
          case "Adhoc starts in 15 Mins":
          case "Merge":
          case "session reschedule":
          case "Adhoc reschedule":
          case "session reschedule":
          case "Adhoc purchase":
          case "invite moderator":
          case "Course Assigned":
          case "Quiz":
          case "Task":
          case "Ask":
          case "Poll":
          case "Agenda":
          case "Assignment":
            AuthService.getSessionDetailsById(
              data?.sessionId,
              (res) => {
                if (checkNull(res?.result?.[0])) {
                  showToast("info", "No Sessions Available");
                } else {
                  const result = res?.result?.[0] ?? {};

                  if (result?.serviceProviderId === loggedinUserId) {
                    localStorage.setItem(
                      HeaderStrings?.role,
                      HeaderStrings?.serviceProvide2
                    );
                    dispatch(ChangeUserRole(HeaderStrings?.serviceProvide2));
                  } else if (result?.serviceProviderId !== loggedinUserId) {
                    localStorage.setItem(
                      HeaderStrings?.role,
                      HeaderStrings?.endUser2
                    );
                    dispatch(ChangeUserRole(HeaderStrings?.endUser2));
                  }
                  navigate("/join_class", {
                    state: res?.result[0] ?? {},
                  });
                  if (showMorebtns) {
                    if (showMorebtns) {
                      handleCloseNot();
                    }
                  }
                }
              },
              (err) => console.log("err", err)
            );
            break;
          case "course":
          case "Course Approval Status":
          case "Course Expiring":
            navigate(`/course-details/${data?.courseId}`);
            if (showMorebtns) {
              if (showMorebtns) {
                handleCloseNot();
              }
            }
            break;
          case "gig":
          case "gig purchase":
          case "Gig Approval Status":
            navigate(`/gig-preview/${data?.gigId}`);
            if (showMorebtns) {
              if (showMorebtns) {
                handleCloseNot();
              }
            }
            break;

          case "course purchase":
          case "Course Adhoc":
          case "batchTransfer":
            CoursePurchaseNavigation(data);
            return;
          case "courseTransfer":
            return console.log("courseTransfer");
          case "Batch Reschedule":
            showMorebtns && handleCloseNot();
            return navigate(`/reschedule-details`, { state: data });
          case "gig purchase":
            navigate(`/gig-preview/${data?.gigId}`);
            if (showMorebtns) {
              handleCloseNot();
            }
          case "ServiceProvider Approval Status":
          case "serviceProvider Approval":
            navigate(`/calendar`);
            if (showMorebtns) {
              handleCloseNot();
            }

          case "ServiceProvider Approval Status":
            navigate("/calendar");
            navigate("/");
            if (showMorebtns) {
              handleCloseNot();
            }
          case "onBoard Instructor":
          case "onBoard Participant":
            navigate("/");
            if (showMorebtns) {
              handleCloseNot();
            }
          default:
            break;
        }
      } else {
        showToast("error", "error");
      }
    };

    AuthService.clearNotification(data, clearSuccess, onFailure);
  };

  return {
    isAllSelected,
    inCart,
    readRef,
    actionRef,
    actionRefs,
    readRefs,
    childRef,
    childRefs,
    showCursor,
    formatTo12HourTime,
    unReadMessages,
    setUnReadMessages,
    AllNot,
    setAlNot,
    readNotifications,
    setReadNotifications,
    open,
    setOpen,
    loadingNotifi,
    setLoadingNotifi,
    selected,
    setSelected,
    accepteState,
    setAccepteState,
    clearNotification,
    setClearNotification,
    readOffset,
    setReadOffset,
    actionOffSet,
    setActionOffset,
    readLastItem,
    setReadLastItem,
    actionIndex,
    setActionIndex,
    childIndex,
    setChildIndex,
    actionLastItem,
    setActionLastItem,
    readIndex,
    setReadIndex,
    showReadScrollButton,
    setShowReadScrollButton,
    showActionScrollButton,
    setShowActionScrollButton,
    selectedNotifications,
    setSelectedNotifications,
    handleSelectEachNotification,
    isNotificationSelected,
    showSelection,
    setShowSelection,
    deleteSelectedNotifications,
    getNotificationData,
    cancelSelection,
    childEndReach,
    setChildEndReach,
    childNotifications,
    setChildNotifications,
    childOffset,
    setChildOffset,
    childScrollbtn,
    setChildScrollBtn,
    courseBuyApi,
    setInCart,
    handleClick,
  };
};
