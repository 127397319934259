import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import InPersonIcon from "../../../assets/images/drop-inperson.png";
const InPerson = ({ customStyle = {} }) => {
  const styles = {
    mb: -0.5,
    p: 0.8,
    ...customStyle
  }
  return (
    <Tooltip title={"In Person"}>
      <IconButton
        sx={styles}
      >
        <img
          src={InPersonIcon}
          alt="inPerson"
          style={{
            height: "18px",
            width: "18px",
            zIndex: 100,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default InPerson;
