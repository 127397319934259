import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { IoTrashOutline } from "react-icons/io5";
import "primeicons/primeicons.css";

export default function PopupDoc({
  setOpen,
  setShowSelection,
  handleDeleteAllSelected,
  setClearNotification,
  showSelection,
}) {
  const menuLeft = useRef(null);
  const items = [
    // {
    //   items: [
    {
      label: "Delete All",
      command: () => {
        setOpen(true);
        setClearNotification("delete All");
        setShowSelection(false);
      },
    },
    {
      label: "Select Multiple",
      command: () => {
        setShowSelection(true);
      },
    },
    //   ],
    // },
  ];

  return (
    <div className="flex justify-content-center w-120" style={{ zIndex: 566 }}>
      {/* <Toast ref={toast}></Toast> */}
      <Menu
        model={items}
        popup
        ref={menuLeft}
        baseZIndex={2000000}
        id="popup_menu_left"
        // onClick={() => {
        //   alert("Hello");
        // }}
      />
      <i
        style={{ fontSize: "1rem" }}
        onClick={(event) => {
          if (showSelection) {
            handleDeleteAllSelected();
          } else {
            menuLeft.current.toggle(event);
          }
        }}
      >
        <IoTrashOutline
          onClick={(event) => {
            if (showSelection) {
              handleDeleteAllSelected();
            } else {
              menuLeft.current.toggle(event);
            }
          }}
          aria-controls="popup_menu_left"
          aria-haspopup
          size={18}
          style={{
            marginLeft: 5,
            cursor: "pointer",
          }}
        />
      </i>
    </div>
  );
}
