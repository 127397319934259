export const RescheduleConstants = {
  rescheduleBatch: "Reschedule Batch",
  myAautiPath: "My Aauti> ",
  coursesPath: "Courses> ",
  testing: "Testing",
  crashCourse: "Crash Course",
  initiateRefund: "Initiate Refund",
  addToExistingBatch: "Add to Existing Batch",
  theBatchHasBeenRescheduledToBelowTimings:
    "The batch has been rescheduled to below timings",
  refundInitiated: "Refund Initiated",
  selectedDays: "Selected Days",
  selectedDates: "Selected Dates",
  selectDates: "Select Dates",
};
