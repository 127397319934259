// import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiRequest } from "../../service/api-request";

/** login authentication
 * @param username, password
 */

function getRecentgigWithRecords(records, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/getAllRecentGigs?noOfRecords=100",
    method: ApiMethods.GET,
    params: records,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getAllGigsBySp(data, onSuccess, onFailure) {
  ApiRequest({
    url: "/gig/getGigListByServiceProviderId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getGigById(data, onSuccess, onFailure) {
  ApiRequest({
    url: "/gig/getCompleteGigDetails",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getSimilarGigsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "/gig/relatedGigs",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getCoursesCountByCategoryAndSubcategory(
  category,
  subcategory,
  onSuccess,
  onFailure
) {
  const data = {
    categoryName: [category],
    subCategoryName: [subcategory],
  };
  ApiRequest({
    url: `myCourse/getCoursesCountByCategoryAndSubcategory`,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getGigsCountByCategoryAndSubcategory(
  category,
  subcategory,
  onSuccess,
  onFailure
) {
  const data = {
    categoryName: [category],
    subCategoryName: [subcategory],
  };
  ApiRequest({
    url: `gig/getGigsCountByCategoryAndSubcategory?`,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getGigListBySubCtegory(subCategory, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/getGigsListBySubCategory",
    method: ApiMethods.GET,
    params: subCategory,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function saveGigView(data, onSuccess, onFailure) {
  ApiRequest({
    url: "gigViews/saveGigView",
    method: ApiMethods?.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getGigsListByCategory(params, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/getGigsListByCategory",
    method: ApiMethods.POST,
    data: params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}



export const AuthServices = {
  getRecentgigWithRecords,
  getGigById,
  getCoursesCountByCategoryAndSubcategory,
  getSimilarGigsById,
  getGigsCountByCategoryAndSubcategory,
  getGigListBySubCtegory,
  getGigsListByCategory,
  saveGigView,
  getAllGigsBySp,
};
