import  { useEffect } from "react";
import { connect } from "react-redux";
import { SocketEvents } from "./constants";

const SocketProvider = (props) => {
  const { isSocketOpen, connectSocket, children } = props;
  const userId = localStorage.getItem("USER_ID");

  function onVisibilityChange(){
      if (document.visibilityState === "visible" && !isSocketOpen && !!userId) {
        connectSocket(userId);
      }
  }
  
  const onNetworkStatusChange=()=> connectSocket(userId)
  useEffect(() => {
    if(!userId) return;
    
    if (!isSocketOpen) {
      connectSocket(userId);
    }
    document.addEventListener("visibilitychange",onVisibilityChange);
    window.addEventListener('online', onNetworkStatusChange);
    return ()=>{
      if(!isSocketOpen) return;
      document.removeEventListener("visibilitychange",onVisibilityChange);
      window.removeEventListener('online',onNetworkStatusChange);
    }
  }, [isSocketOpen,userId]);

  return children;
};

const mapStateToProps = (state) => ({
  isSocketOpen: state.chat.isSocketOpen,
});

const mapDispatchToProps = (dispatch) => ({
  connectSocket: (userId) =>
    dispatch({
      type: SocketEvents.connect,
      payload:userId
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SocketProvider);
