import { ApiRequest } from "../../../../../service";
import { ApiMethods } from "../../../../../service/api-constants";

const apiUrls = {
  saveQuiz: "quizCreation/save",
  updateQuiz: "quizCreation/update",
  deleteQuiz: "quizCreation/delete?",
  spQuizList: "quizCreation/getQuizDetailsByBatchIdOrSessionId",
  euQuizList: "quizCreation/getQuizsListByEndUserId?",
  spQuizDetails: "quizCreation/getUserQuizBySpId?",
  euQuizDetails: "quizCreation/getQuizDetailsByendUserId?",
  submitQuiz: "quizCreation/submitQuiz",
  getAssignedQuizUsersList: "quizCreation/getQuizUsersListById?",
  submitQuizCorrection: "quizCreation/updateQuizCorrectionPoints",
  quizCorrectionDetails: "quizCreation/getQuizDetailsByendUserIdAnswers?",
  //quiz drafts
  saveQuizDraft: "draftQuizCreation/save",
  updateQuizDraft: "draftQuizCreation/update",
  quizDraftsList: "draftQuizCreation/getDraftQuizDetailsByBatchIdOrSessionId?",
  getDraftQuizDetailsById: "draftQuizCreation/getDraftQuizDetails?",
  deleteDraftQuiz: "draftQuizCreation/delete?",
};

function createQuiz(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.saveQuiz,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function SubmitQuiz(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.submitQuiz,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}
function submitQuizCorrectionPoints(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.submitQuizCorrection,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function updateQuiz(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.updateQuiz,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}
function deleteQuiz(id, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.deleteQuiz,
    method: ApiMethods.DELETE,
    params: { id },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function createQuizDraft(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.saveQuizDraft,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function getSpQuizList(data, onSuccess, onFailure) {
  ApiRequest({
    url: "quizCreation/getQuizDetailsByBatchIdOrSessionId?",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getSQuizzDetailsByEndUserIdAnswers(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.quizCorrectionDetails,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getEUQuizList(data, onSuccess, onFailure) {
  ApiRequest({
    url: "quizCreation/getQuizsListByEndUserId?",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getQuizUsersListById(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.getAssignedQuizUsersList,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getEndUserQuizDetailsById(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.euQuizDetails,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getSPQuizDetailsById(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.spQuizDetails,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getQuizDrafts(params, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.quizDraftsList,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function spQuizDraftDetails(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.getDraftQuizDetailsById,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function updateQuizDraft(data, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.updateQuizDraft,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

function deleteDraftQuiz(id, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.deleteDraftQuiz,
    method: ApiMethods.DELETE,
    params: { id },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

export const AuthService = {
  createQuiz,
  updateQuiz,
  deleteQuiz,
  createQuizDraft,
  getQuizDrafts,
  spQuizDraftDetails,
  updateQuizDraft,
  deleteDraftQuiz,
  getSpQuizList,
  getEUQuizList,
  getQuizUsersListById,
  getEndUserQuizDetailsById,
  getSPQuizDetailsById,
  SubmitQuiz,
  submitQuizCorrectionPoints,
  getSQuizzDetailsByEndUserIdAnswers,
};
