import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { BiFontFamily } from 'react-icons/bi';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}


function stringAvatar(name,sxExtra) {
    let avatarName
     name = name.toUpperCase().split(/\s+/).filter(str=>str.length>1)
    if (name.length === 1) {
      avatarName = `${[...name[0]][0] ?? name[0]}`
    } else if (name.length > 1) {
      avatarName = `${[...name[0]][0]}${[...name[1]][0]}`
    } else {
      avatarName = ''
    }
  return {
    sx: {
      bgcolor: stringToColor(avatarName),
      fontFamily:'Poppins',
      ...sxExtra,
    },
    children: `${avatarName}`,
  };
}

export default function AautiAvatar({
    alt,
    src,
    height=20,
    width=20,
    textStyle={
      fontSize:14,
    }
}) {
  if(!alt && !src) return;
  const fontSize = height
  let sx = {height,width,...textStyle}
  if(!src){
    return <Avatar {...stringAvatar(alt,sx)} alt={alt} />
  }
  return <Avatar {...{sx,src,alt}} />
}
