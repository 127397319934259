import moment from "moment";
import { CapitalizeFirstLetter } from "../../CommonFunctions";

// Function to generate the notification message
export function generateNotificationMessage(item) {
  if (
    item?.notificationType === "Merge" ||
    item?.notificationType === "session reschedule" ||
    item?.notificationType === "canceled session" ||
    item?.notificationType === "Adhoc reschedule"
  ) {
    const fromBatchDate = moment(item?.fromBatchDate).utc().format();
    const fromDate = moment(fromBatchDate).format("DD/MM/YYYY");
    const fromTime = moment(fromBatchDate).format("hh:mm A");
    const toBatchDate = moment(item?.toBatchDate).utc().format();
    const toDate = moment(toBatchDate).format("DD/MM/YYYY");
    const toTime = moment(toBatchDate).format("hh:mm A");

    switch (item?.notificationType) {
      case "Merge":
        return `Your session from ${item?.fromBatchName}, which was supposed to begin at ${fromTime} on ${fromDate}, has been merged with batch ${item?.toBatchName}, which will begin at ${toTime} on ${toDate}`;
      case "session reschedule":
        return `Your session from ${item?.fromBatchName}, which was supposed to begin at ${fromTime} on ${fromDate}, has been rescheduled to ${item?.toBatchName}, which will begin at ${toTime} on ${toDate}`;
      case "canceled session":
        return `The scheduled session for ${item?.fromBatchName} at ${toTime} on ${toDate} has been canceled`;
      case "Adhoc reschedule":
        return `Your ${item?.adhocName}, which was supposed to begin at ${fromTime} on ${fromDate}, has been rescheduled with adhoc ${item?.adhocName}, which will begin at ${toTime} on ${toDate}`;
      case "Session Reschedule Deleted":
        return `The session reschedule for ${item?.batchName} for ${toTime} has been deleted`;
      case "Session Merge Deleted":
        return `The session merge for ${item?.batchName} for ${toTime} has been deleted`;
      default:
        return CapitalizeFirstLetter(item?.message);
    }
  } else {
    return CapitalizeFirstLetter(item?.message);
  }
}

// React Component
const NotificationMessage = ({ item }) => {
  return generateNotificationMessage(item);
};

export default NotificationMessage;
