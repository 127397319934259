import React from "react";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import { Typography } from "@mui/material";
import { globalColors } from "../../themes/GlobalColors";

const AautiUpload = ({
  accept,
  id = "",
  onChange,
  iconName = "upload",
  iconSize = 20,
  iconColor = globalColors?.AAUTI_THEME_COLOR,
  style = {},
  important,
  customLabel,
  disabled,
  multiple,
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        style={{
          cursor: "pointer",
          marginTop: 10,
          alignItems: "center",
          border: `2px dashed ${globalColors?.AAUTI_THEME_COLOR}`,
          padding: 15,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: 5,
          ...style,
        }}
      >
        {customLabel ? (
          customLabel
        ) : (
          <>
            <CastForEducationIcon
              style={{ color: iconColor }}
              fontSize="medium"
            />
            <h4 style={{ display: "flex" }}>
              {iconName}
              {important && (
                <Typography
                  sx={{ color: "red", marginTop: "-2px", marginLeft: "1px" }}
                >
                  *
                </Typography>
              )}
            </h4>
          </>
        )}
      </label>

      <input
        multiple={multiple}
        type="file"
        name={id}
        id={id}
        accept={accept && accept}
        style={{ display: "none" }}
        onChange={onChange && onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default AautiUpload;
