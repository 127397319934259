import React, { useEffect, useState } from "react";
import { PartTypeView } from "aauti_lessonplan";
import "./style.scss";
import { useParams } from "react-router-dom";
import { getLessonPlanById } from "../createLessonPlan/Services";
import { getCourseDetails } from "../course/courseDetails/Services";
import CircularProgress from "@mui/material/CircularProgress";

const PartTypeViewComponent = (props) => {
  const params = useParams();
  const { id, isCourseId = false, userId } = params;
  const [nativeData, setNativeData] = useState();
  const [loader, setLoader] = useState(false);
  const [isEU, setIsEU] = useState(true);

  const onSuccess = (res) => {
    if (isCourseId === "true") {
      let data = res?.result?.[0]?.lessonPlan;
      setNativeData(data);
      let role = data?.serviceProviderId === userId;
      setIsEU(!role);
    } else {
      setNativeData(res?.result);
      let role = res?.result?.serviceProviderId === userId;
      setIsEU(!role);
    }
    setLoader(false);
  };
  const onFailure = (err) => {
    console.log(err);
    setLoader(false);
  };
  const ApiCall = () => {
    if (isCourseId === "true") {
      getCourseDetails(false, id, "", onSuccess, onFailure);
    } else {
      getLessonPlanById(id, onSuccess, onFailure);
    }
  };
  useEffect(() => {
    if (id != null) {
      //loader should be here
      ApiCall();
      setLoader(true);
    }
  }, [params?.id]);

  return (
    <div className="main-div">
      {loader ? (
        <div className="loader-div">
          <CircularProgress />
        </div>
      ) : nativeData !== null && nativeData !== undefined ? (
        <PartTypeView PTData={nativeData} role={isEU ? "EU" : "SP"} />
      ) : (
        <div className="no-content-div">
          <h5>NO CONTENT</h5>
        </div>
      )}
    </div>
  );
};

export default PartTypeViewComponent;
