import * as React from 'react';
import {MenuItem,Menu,ListItemIcon, Divider, Box} from '@mui/material';
import { MoreVert } from '@mui/icons-material';

export default function MessageOptionsMenu({id='message-options-menu',options,onPressMenuItem}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event,
    index,
  ) => {
    onPressMenuItem(options[index].label);
    setAnchorEl(null);
  };

  return (
    <div id={id} style={{alignSelf:'flex-start'}}>
      <MoreVert 
        fontSize='inherit'
        aria-label={`messages-options`}
        id={`${id}-button`}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup={true}
        onClick={handleClickListItem}
        sx={{cursor:'pointer'}}
        />
      <Menu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={()=>setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
          role: 'listbox',
        }}
        
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.label}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
              <ListItemIcon>
                  {option.icon}
              </ListItemIcon>
              <span className={"message-option-label"} style={option?.style??{}}>
                {option.label}
              </span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
