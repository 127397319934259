import React, { useEffect } from "react";
import AautiText from "../globalComponents/AautiText";
import { updateMembersList } from "../../redux/reducer/appReducer";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { getFirstTwoChars, returnColorbasedName } from "../CommonFunctions";
import { useNavigate } from "react-router-dom";
import { getMembers, sendSms, getDeactivateReasons } from "./service";
import { isEmpty } from "lodash";
import {
  Box,
  FormControl,
  Skeleton,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import AccountTabs from "../globalComponents/AccountTabs";
import { AautiStrings } from "../globalComponents/AautiStrings";
import Spinner from "../globalComponents/Spinner";
import dayjs from "dayjs";
import { useDeleteOrDeactivateContainer } from "./container";
import PasswordDialog from "./PasswordDialog";
import OtpDialog from "./otpDialog";
import DeactivationReasonsScreen from "./DeactivationReason";
import ConfirmationDialog from "./conformation";
import ConfirmDialog from "./conformationdialog";
import { courseConstant } from "../course/courseCreation/constants";
import AppContainer from "../../layouts/AppContainer";

const DeleteOrDeactivate = () => {
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const {
    customDate,
    setCustomDate,
    reactivateDate,
    setReactivateDate,
    selectedUser,
    setSelectedUser,
    deleteClicked,
    setDeleteClicked,
    resendEnabled,
    setResendEnabled,
    timer,
    setTimer,
    deactivationDays,
    loadingVerification,
    setLoadingVerification,
    showInfo,
    loading,
    setLoading,
    otp,
    setOtp,
    yourMembers,
    setYourMembers,
    customReason,
    selectedReasons,
    deactivationReasons,
    setDeactivationReasons,
    selectedValue,
    dialogType,
    setDialogType,
    makeTypeEmpty,
    daysOptions,
    mobileDevices,
    navigation,
    getDate,
    toggleReason,
    onPressContinue,
    handleInfoClick,
    resetDeactivationData,
    handleCloseConfirmDialog,
    handleCustomReasonChange,
    onSucessOtpVerification,
    onSucessVerification,
    handleOpenOtpVerification,
    handleCloseConfirmationDialog,
    handleDeactivationDaysChange,
    handleChange,
  } = useDeleteOrDeactivateContainer();

  useEffect(() => {
    setLoading(true);
    getMembers(
      loggedinUserId,
      (resp) => {
        const filteredMembers = resp?.result?.filter(
          (member) => member?.createdByParent
        );
        setYourMembers(filteredMembers);
        dispatch(updateMembersList(resp?.result || []));
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
    getDeactivateReasons(
      (resp) => {
        if (resp.status === "Success") {
          setDeactivationReasons(resp.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [loggedinUserId, deleteClicked]);

  const returnHeaderTabs = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignSelf: "center",
        marginBottom: 10,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <AautiText
          weight={"bold"}
          title={courseConstant?.deactivationOrDeletion}
          textStyle={{ color: "#000000" }}
        />
      </div>
    </div>
  );

  const Card = ({ user, isSelected, onClick }) => (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        backgroundColor: isSelected ? "#E9F2FD" : "transparent",
        padding: "10px 0px 0px 10px",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <Radio
        checked={isSelected}
        onChange={onClick}
        value={user._id}
        name="user-selection"
        color="primary"
      />
      <Avatar
        src={!isEmpty(user?.profileImage) ? user?.profileImage : undefined}
        alt="profile"
        style={{
          width: "40px",
          height: "40px",
          margin: "0px 10px 10px 0px",
          backgroundColor: isEmpty(user?.profileImage)
            ? returnColorbasedName(user?.displayName || user?.userName)
            : "transparent",
        }}
      >
        {isEmpty(user?.profileImage) &&
          getFirstTwoChars(user?.displayName || user?.userName)}
      </Avatar>
      <div
        style={{
          flex: 1,
          borderBottom: "2px solid #E9E9E9",
          paddingBottom: "10px",
        }}
      >
        <AautiText
          className={"textStyle1"}
          title={user?.displayName}
          weight={"bold"}
        />
        <AautiText
          className={"textStyle2"}
          title={user?.relation || "Myself"}
          weight={"normal"}
          size={"semi"}
          textStyle={{
            color: "#5C5B57",
          }}
        />
        <div style={{ marginTop: "10px" }} />
      </div>
    </div>
  );

  const UserCard = () => (
    <Card
      user={userDetails}
      isSelected={selectedUser === userDetails}
      onClick={() => setSelectedUser(userDetails)}
    />
  );

  const MemberCard = (each) => (
    <Card
      key={each._id}
      user={each}
      isSelected={selectedUser === each}
      onClick={() => setSelectedUser(each)}
    />
  );

  const RadioWithSubtitle = ({ title, subtitle, value }) => (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            marginTop: "6px",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: "4px", maxWidth: "600px" }}
          >
            {subtitle}
          </Typography>
        </Box>
      }
      sx={{ alignItems: "flex-start", margin: "0" }}
    />
  );

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: "Deactivate or Delete",
            navigation: "/delete-deactivate",
            active: true,
          },
        }}
      />
      {loadingVerification ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div className="head">
          {returnHeaderTabs()}
          {loading ? (
            <Box
              className="TRloadingdiv1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box className="TRloadingdiv2" sx={{ width: "25%" }}>
                <Skeleton variant="rounded" width={"100%"} height={40} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={mobileDevices ? 200 : 400}
                />
              </Box>
              <Box className="TRloadingdiv3" sx={{ width: "74%" }}>
                <Skeleton variant="rounded" width={"100%"} height={30} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton variant="rounded" width={"100%"} height={60} />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                borderRadius: 1.5,
                boxShadow:
                  "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
              }}
            >
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    width: mobileDevices ? "100%" : "30%",
                    borderRight: "1px #dedede solid",
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: "80vh", md: "65vh" },
                      overflow: "scroll",
                      padding: 2,
                      width: "100%",
                    }}
                  >
                    <div>{UserCard()}</div>
                    {selectedUser?.displayName === userDetails?.displayName && (
                      <FormControl
                        component="fieldset"
                        sx={{ display: !mobileDevices && "none" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            width: "100%",
                          }}
                        >
                          <Button
                            variant={selectedValue === "deActivate"}
                            onClick={() => {
                              handleChange({ target: { value: "deActivate" } });
                              onPressContinue();
                            }}
                            fullWidth
                            sx={{ flex: 1, color: "#3083EF" }}
                          >
                            Deactivate
                          </Button>
                          <Button
                            variant={selectedValue === "delete"}
                            onClick={() => {
                              handleChange({ target: { value: "delete" } });
                              onPressContinue();
                            }}
                            fullWidth
                            sx={{ flex: 1, color: "#3083EF" }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </FormControl>
                    )}
                    {yourMembers && yourMembers.length > 0 && (
                      <>
                        <AautiText
                          weight={"bold"}
                          title={AautiStrings?.membersString}
                        />
                        <div>
                          {yourMembers?.map((each, index) => (
                            <>
                              {MemberCard(each, index)}

                              {selectedUser?._id === each?._id && (
                                <FormControl
                                  component="fieldset"
                                  sx={{ display: !mobileDevices && "none" }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <Button
                                      variant={selectedValue === "deActivate"}
                                      onClick={() => {
                                        handleChange({
                                          target: { value: "deActivate" },
                                        });
                                        onPressContinue();
                                      }}
                                      fullWidth
                                      sx={{ color: "#3083EF" }}
                                    >
                                      Deactivate
                                    </Button>
                                    <Button
                                      variant={selectedValue === "delete"}
                                      onClick={() => {
                                        handleChange({
                                          target: { value: "delete" },
                                        });
                                        onPressContinue();
                                      }}
                                      fullWidth
                                      sx={{ color: "#3083EF" }}
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </FormControl>
                              )}
                            </>
                          ))}
                        </div>
                      </>
                    )}
                  </Box>
                </Box>
                <div
                  style={{
                    display: mobileDevices ? "none" : "flex",
                    width: "100%",
                    margin: "30px",
                  }}
                >
                  <div>
                    <AautiText
                      weight={"bold"}
                      title={
                        AautiStrings?.deactivationOrDeletionYourAautiAccountString
                      }
                    />
                    <AautiText
                      weight={"normal"}
                      size={"semi"}
                      title={
                        AautiStrings?.ifYouWantToTemporarilyCloseYourAccountYouCanDeactivateItIfYouWantToPermanentlyRemoveYourDataFromAautiYouCanDeleteYourAccountString
                      }
                      textStyle={{
                        marginTop: "8px",
                        maxWidth: "900px",
                        fontFamily: "Segoe UI",
                        fontSize: "16px",
                      }}
                    />
                    <FormControl
                      component="fieldset"
                      sx={{
                        "& .css-8q464b-MuiTypography-root": {
                          fontFamily: "Segoe UI",
                          fontSize: "16px",
                        },
                      }}
                    >
                      <RadioGroup value={selectedValue} onChange={handleChange}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            borderRadius: 1.5,
                            padding: "10px",
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                            marginTop: "10px",
                            minHeight: "85px",
                            border:
                              selectedValue === "deActivate"
                                ? "1px solid #3083EF"
                                : "2px solid transparent",
                          }}
                        >
                          <RadioWithSubtitle
                            title="Deactivate Account"
                            subtitle="Your Profile won't be shown on Aauti (There should not be any Active/Upcoming Sessions)"
                            value="deActivate"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            borderRadius: 1.5,
                            padding: "10px",
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                            marginTop: "18px",
                            minHeight: "90px",
                            border:
                              selectedValue === "delete"
                                ? "1px solid #3083EF"
                                : "2px solid transparent",
                          }}
                        >
                          <RadioWithSubtitle
                            title={AautiStrings?.deleteAccountString}
                            subtitle={
                              AautiStrings?.deletingYourAccountIsPermanentAndIrreversibleOnceDeletedYouWontBeAbleToRetrieveYourAccountString
                            }
                            value="delete"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </>
            </Box>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          margin: "20px 0px",
        }}
      >
        <Button
          style={{
            display: mobileDevices || loading ? "none" : "block",
            textTransform: "unset",
            color: "white",
            backgroundColor: "#3083EF",
          }}
          onClick={onPressContinue}
          variant="contained"
        >
          Continue
        </Button>
      </div>
      {dialogType === "enterPassword" && (
        <PasswordDialog
          selectedUser={selectedUser}
          dialogType={dialogType}
          makeTypeEmpty={makeTypeEmpty}
          loadingVerification={loadingVerification}
          setLoadingVerification={setLoadingVerification}
          onSuccess={onSucessVerification}
        />
      )}
      {dialogType === "enterOtp" && (
        <OtpDialog
          selectedUser={selectedUser}
          timer={timer}
          setTimer={setTimer}
          otp={otp}
          resendEnabled={resendEnabled}
          setResendEnabled={setResendEnabled}
          setOtp={setOtp}
          makeTypeEmpty={makeTypeEmpty}
          dialogType={dialogType}
          loadingVerification={loadingVerification}
          setLoadingVerification={setLoadingVerification}
          onSuccess={onSucessOtpVerification}
        />
      )}
      {dialogType === "deactivateReasons" && (
        <DeactivationReasonsScreen
          {...{
            dayjs,
            customDate,
            setCustomDate,
            selectedReasons,
            customReason,
            deactivationDays,
            daysOptions,
            resetDeactivationData,
            setReactivateDate,
            selectedValue,
            deactivationReasons,
            toggleReason,
            handleCustomReasonChange,
            handleInfoClick,
            showInfo,
            handleDeactivationDaysChange,
            reactivateDate,
            dialogType,
            setDialogType,
            getDate,
          }}
        />
      )}
      {dialogType === "finalConfirmation" && (
        <ConfirmationDialog
          {...{
            dialogType,
            handleCloseConfirmationDialog,
            mobileDevices,
            selectedValue,
            setLoadingVerification,
            loggedinUserId,
            resetDeactivationData,
            selectedUser,
            customReason,
            selectedReasons,
            daysOptions,
            customDate,
            reactivateDate,
            setDeleteClicked,
            navigation,
            deactivationDays,
            setSelectedUser,
            userDetails,
            yourMembers,
            dispatch,
          }}
        />
      )}
      {dialogType === "confirmation" && (
        <ConfirmDialog
          {...{
            mobileDevices,
            dialogType,
            handleCloseConfirmDialog,
            setDialogType,
            selectedUser,
            handleOpenOtpVerification,
          }}
        />
      )}
    </AppContainer>
  );
};

export default DeleteOrDeactivate;
