import { ApiRequest } from "../../../service";
import { ApiMethods, ApiPaths } from "../../../service/api-constants";

function getAdyenPaymentMethods(data) {
  return ApiRequest({
    url: ApiPaths.paymentMethods,
    method: ApiMethods.GET,
    params: data,
  });
}
function getCompleteOrderDetails(orderId) {
  return ApiRequest({
    url: ApiPaths.orderDetails,
    method: ApiMethods.GET,
    params: { orderId },
  });
}
function cardPayment(data) {
  return ApiRequest({
    url: ApiPaths.cardPayment,
    method: ApiMethods.POST,
    data: data,
  });
}
function updateOrder(data) {
  return ApiRequest({
    url: ApiPaths.updateOrder,
    method: ApiMethods.PUT,
    data: data,
  });
}
export default {
  getAdyenPaymentMethods,
  getCompleteOrderDetails,
  cardPayment,
  updateOrder,
};
