import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAautiLoader } from "../../../redux/reducer/appReducer";
import { showToast } from "../../globalComponents/Toast";
import { InstituteAuthService } from "../instituteServices/institute-api-services";
import CreateOrEditInstituteInformation from "./CreateOrEditInstituteInformation";
import AautiText from "../../globalComponents/AautiText";
import "../Style.css";
import { CiSquarePlus } from "react-icons/ci";
import { isEmpty } from "lodash";
import NoVideo from "../../../assets/images/no-video.jpg";
import { BiPencil, BiTrash } from "react-icons/bi";

const InstituteInfo = () => {
  const { instituteId } = useSelector((state) => state.app);
  const [Data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const RWD = useSelector((state) => state.responsive);
  const [editInstituteData, setEditInstituteData] = useState(null);
  const [isloding, setisloding] = useState(true);
  const emptyData = [
    {
      header: "",
      description: "",
      uploadImage: [],
      instituteId: instituteId,
      deleted: false,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    getInstituteInformation();
  }, []);

  const getInstituteInformation = () => {
    dispatch(setAautiLoader(true));
    const params = { instituteId };
    InstituteAuthService.GETInstituteInformation_ByInstituteId(
      params,
      onSuccessGetInstituteInformation,
      onFailureGetInstituteInformations
    );
  };

  const onSuccessGetInstituteInformation = (response) => {
    if (response?.result) {
      setData(response.result);
      setisloding(false);
    }
  };

  const onFailureGetInstituteInformations = (error) => {
    dispatch(setAautiLoader(false));
    showToast("error", error);
  };

  const createBranch = () => {
    setEditInstituteData(emptyData);
  };

  const addNewSectioninCreate = () => {
    setEditInstituteData([...editInstituteData, emptyData]);
  };

  const removeSection = (index) => {
    let data = editInstituteData.filter((each, i) => i !== index);
    setEditInstituteData(data);
  };

  const deleteInstitute = (id) => {
    dispatch(setAautiLoader(true));
    InstituteAuthService.DeleteInstituteInformation(
      { _id: id },
      onSuccessDeleteInstitute,
      onFailureDeleteInstitute
    );
  };

  const onSuccessDeleteInstitute = (response) => {
    showToast("success", response?.message);
    getInstituteInformation();
  };

  const onFailureDeleteInstitute = (error) => {
    dispatch(setAautiLoader(false));
    showToast("error", error);
  };

  return (
    <div className="Bio-Super-Main">
      <div className="Bio-Main">
        <div className="skill1-Main-Sub" style={{ marginTop: "10px" }}>
          <div className="skill-sub">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <AautiText
                title={"About Institute"}
                weight={"bold"}
                size={"medium"}
                className="fontSize_18px"
                textStyle={{
                  color: "#1E1E1E",
                }}
              />
              {!isloding && (
                <button
                  className="pencil-button"
                  onClick={createBranch}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    cursor: "pointer",
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                >
                  <CiSquarePlus
                    color="#3083EF"
                    size={18}
                    style={{ marginRight: "5px" }}
                  />
                  <AautiText
                    title={RWD.mobileDevices ? "" : "Institute Info"}
                    weight={"medium"}
                    size={"normal"}
                    textStyle={{
                      color: "#3083EF",
                    }}
                  />
                </button>
              )}
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "15px",
                border: "1px solid #E0E0E0",
              }}
            />
            <div className="skill-sub">
              {!isEmpty(Data) ? (
                Data?.map((each, index) => (
                  <div className="skill-each-main" key={index}>
                    <div className="SSItem-cont">
                      <div className="ssitem-sub-cont">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: "100%",
                          }}
                        >
                          <div className="ssitem-sub-content">
                            <AautiText
                              title={each?.header}
                              weight={"Semibold"}
                              size={"normal"}
                              className={"fontSize_16px"}
                              textStyle={{
                                color: "#1E1E1E",
                                textOverflow: "ellipsis",
                                width: "100%",
                                wordWrap: "break-word",
                                textWrap: "wrap",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                              }}
                            />
                            <AautiText
                              title={each?.description}
                              weight={"normal"}
                              size={"normal"}
                              className={"fontSize_16px"}
                              textStyle={{
                                color: "#5C5B57",
                                marginBottom: "5px",
                                width: "90%",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                overflow: "hidden",
                              }}
                            />
                          </div>

                          <div
                            className="ss-buttons-cont"
                            style={{ marginTop: "4px" }}
                          >
                            <button
                              onClick={() => {
                                setEditInstituteData(each);
                              }}
                              className="pencil-button"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginRight: "10px",
                                flexDirection: "row",
                                cursor: "pointer",
                                alignSelf: "center",
                              }}
                            >
                              <BiPencil size={20} />
                            </button>
                            <button
                              onClick={() => deleteInstitute(each?._id)}
                              className="pencil-button"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: "row",
                                cursor: "pointer",
                                alignSelf: "center",
                              }}
                            >
                              <BiTrash size={20} style={{ marginLeft: 4 }} />
                            </button>
                          </div>
                        </div>
                      </div>
                      {!isEmpty(each?.uploadImage) &&
                      Array.isArray(each?.uploadImage) ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {each?.uploadImage.map((image, imgIndex) => (
                            <img
                              key={imgIndex}
                              src={image || NoVideo}
                              style={{
                                height: "48px",
                                width: "60px",
                                borderRadius: "5px",
                                border: "1px solid #E0E0E0",
                              }}
                              alt={`Uploaded image ${imgIndex + 1}`}
                            />
                          ))}
                        </div>
                      ) : (
                        <img
                          src={each?.uploadImage || NoVideo}
                          style={{
                            height: "48px",
                            width: "60px",
                            borderRadius: "5px",
                            border: "1px solid #E0E0E0",
                          }}
                          alt={each?.achievement}
                        />
                      )}
                    </div>
                    {index !== Data?.length - 1 && (
                      <hr
                        style={{
                          width: "100%",
                          border: "1px solid #E0E0E0",
                        }}
                      />
                    )}
                  </div>
                ))
              ) : (
                <AautiText
                  title={"Add Institute Details"}
                  weight={"medium"}
                  size={"normal"}
                  textAlign={"center"}
                  className={"fontSize_16px"}
                  textStyle={{
                    color: "#5C5B57",
                    width: "100%",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <CreateOrEditInstituteInformation
        data={editInstituteData}
        onUpdateList={getInstituteInformation}
        setEditInstituteData={setEditInstituteData}
        addNewSectioninCreate={addNewSectioninCreate}
        removeSection={removeSection}
      />
    </div>
  );
};

export default InstituteInfo;
