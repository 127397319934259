import React, { useCallback, useEffect, useRef, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import { setAskClicked, setAskObject } from "../../../redux/reducer/appReducer";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa6";
import { Tooltip } from "@mui/material";
import { options } from "./feed/poll/constants";
import { AuthService } from "../join_class/feed/ask/service";
import { showToast } from "../../globalComponents/Toast";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ConfirmationDialogRaw from "../../globalComponents/ConfirmationDialogRaw";
import { FeedsToolTip } from "./feedMenu";

const AskCard = (props) => {
  const {
    cardDetails,
    UserRole,
    screen,
    setSubmitted,
    submitted,
    setSaveAskObj,
    setAskOpen,
    setSelectedOption,
    confirmDialog,
    deleteAssId,
    setDeleteId,
    setConfirmDialog,
    setAskOpenName,
    onPressAsk,
    MenuActiveIndex,
    setMenuActiveIndex,
    updateSession,
    setUpdateSession,
    AllSessions,
  } = props;
  const toolTipRef = useRef();
  const { loggedinUserId } = useSelector((state) => state.app);
  const { miniLapys, ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );

  const renderValidations = (each) => {
    if (UserRole.includes("serviceprovider")) {
      if (each.serviceProviderId === loggedinUserId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const deleteFunction = (each) => {
    // if (switchChange) {
    //   AuthService.deleteDraftAsk(each?._id, onSuccessDelete, onFailure);
    // } else {
    //   AuthService.deleteAsk(each?._id, onSuccessDelete, onFailure);
    // }
    AuthService.deleteAsk(each?._id, onSuccessDelete, onFailure);
  };

  const handleClickOption = (e, each, index) => {
    setSelectedOption(e.target.innerText);
    setMenuActiveIndex(null);
    if (e.target.innerText === "Edit") {
      setAskOpen(true);
      const newSaveObj = {
        _id: each?._id,
        batchId: each?.batchId,
        courseId: each?.courseId,
        description: each?.description,
        scheduleDate: each?.scheduleDate,
        serviceProviderId: each?.serviceProviderId,
        sessionId: each?.sessionId,
        title: each?.title,
        sessionDate: each?.sessionDate,
        sessionName: each?.sessionName,
      };
      setSaveAskObj(newSaveObj);
      setAskOpenName("Edit Ask");
      if (each?.sessionName !== "Adhoc Session") {
        const newSessionDate = AllSessions?.filter((item) => {
          return (
            item?.sessionName.toLowerCase().match(/(session \d+)/)?.[1] ===
            each?.sessionName
          );
        });

        setUpdateSession(newSessionDate?.[0]);
      } else {
        setUpdateSession(AllSessions?.[0]);
      }
    } else {
      setConfirmDialog(true);
      setDeleteId(each);
    }
  };

  const onFailure = (error) => {
    setAskOpen(false);
    setSubmitted(false);
  };
  const onSuccessDelete = (response) => {
    if (response?.data?.status === "Success") {
      setSubmitted(true);
      showToast("success", response?.data?.message);
    } else {
      setSubmitted(true);
    }
  };

  const onClickAsk = () => {
    onPressAsk(cardDetails);
  };

  const handleTooltipToggle = useCallback((id) => {
    setMenuActiveIndex((prev) => (prev === id ? null : id));
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
        setMenuActiveIndex(null); // Close the tooltip
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ marginTop: "10px" }} key={cardDetails?._id}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: screen === "feed" ? "space-between" : "flex-end",
            marginBottom: "2px",
            alignItems: "center",
            width: "100%",
          }}
        >
          {screen === "feed" && (
            <AautiText
              size={"normal"}
              weight={"medium"}
              textStyle={{
                color: "#828282",
              }}
              title={"Ask"}
            />
          )}
          <AautiText
            textAlign={"left"}
            size={"small"}
            weight={"normal"}
            textStyle={{
              color: "#828282",
            }}
            title={
              cardDetails?.updatedAt > cardDetails?.createdAt
                ? `Updated on ${moment(cardDetails?.updatedAt).format(
                    "D MMM YYYY"
                  )},
              ${moment(cardDetails?.updatedAt).format("hh:mm A")}`
                : `Created on ${moment(cardDetails?.createdAt).format(
                    "D MMM YYYY"
                  )},
              ${moment(cardDetails?.createdAt).format("hh:mm A")}`
            }
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: 12,
            borderRadius: 4,
            border: "1px #E0E0E0 solid",
            backgroundColor: "#F7F7FA",
            justifyContent: "space-between",
            alignSelf: "center",
          }}
        >
          <div
            onClick={onClickAsk}
            style={{
              width: UserRole === "endUser" ? "100%" : "97%",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              padding: 10,
              justifyContent: "space-between",
              borderRadius: 4,
              border: "1px #E0E0E0 solid",
              backgroundColor: "#ffffff",
              cursor: "pointer",
            }}
          >
            <AautiText
              title={cardDetails?.title}
              size={"normal"}
              textStyle={{ color: "#1e1e1e" }}
            />

            <FaChevronRight size={18} />
          </div>
          {renderValidations(cardDetails) && screen !== "feed" && (
            <>
              <FeedsToolTip
                isOpen={MenuActiveIndex === cardDetails?._id}
                key={cardDetails?._id}
                id="feed-tooltip"
                onToggle={() => handleTooltipToggle(cardDetails?._id)}
                title={
                  <>
                    <div style={{ padding: "5px" }} ref={toolTipRef}>
                      {options?.map((option) => (
                        <p
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            padding: "5px",
                            marginLeft: "5px",
                            fontSize: "14px",
                          }}
                          key={option}
                          onClick={(e) =>
                            handleClickOption(e, cardDetails, cardDetails?._id)
                          }
                        >
                          {option}
                        </p>
                      ))}
                    </div>
                  </>
                }
              >
                <div>
                  <HiOutlineDotsVertical
                    key={cardDetails?._id.$oid}
                    size={25}
                    // onClick={() => handleClick(cardDetails, cardDetails?._id)}
                    style={{
                      alignSelf: "center",
                      cursor: "pointer",
                      marginLeft: mobileDevices ? "0px" : "10px",
                      marginTop: "8px",
                    }}
                  />
                </div>
              </FeedsToolTip>
            </>
          )}
        </div>
      </div>
      <ConfirmationDialogRaw
        keepMounted
        open={confirmDialog}
        onClick={(e) => {
          e.stopPropagation();
          deleteFunction(deleteAssId);
          setConfirmDialog(false);
        }}
        onClose={() => {
          setConfirmDialog(false);
        }}
      />
    </>
  );
};

export default AskCard;
