import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkNull } from "../../utils/Helpers";
import { updateNotificationSettings } from "./Services";
import { useNavigate } from "react-router-dom";
import { setUserdetails } from "../../redux/reducer/appReducer";
import { showToast } from "../globalComponents/Toast";

const NotificationSettingsContainer = () => {
  const { userDetails } = useSelector((state) => state.app);
  const [notificationSettings, setNotificationSettings] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setNotificationSettings([
      {
        id: 1,
        name: "Email Notifications",
        enabled: userDetails?.emailNotifications,
        show: !checkNull(userDetails?.email),
      },
      {
        id: 2,
        name: "Mobile Notifications",
        enabled: userDetails?.mobileNotifications,
        show: !checkNull(userDetails?.mobile),
      },
      {
        id: 3,
        name: "WhatsApp Notifications",
        enabled: userDetails?.whatsAppNotifications,
        show: !checkNull(userDetails?.mobile),
      },
      {
        id: 4,
        name: "In-app Notifications",
        enabled:
          userDetails?.appNotifications !== undefined
            ? userDetails?.appNotifications
            : true,
        show: true,
      },
    ]);
  }, [userDetails]);
  const onChangeNotificationSettings = (item, index) => {
    let notificationsData = [...notificationSettings];
    notificationsData[index].enabled = !item?.enabled;
    setNotificationSettings(notificationsData);
  };
  const onPressSubmit = () => {
    const updatedData = {
      _id: userDetails?._id,
      emailNotifications: notificationSettings?.[0]?.enabled,
      mobileNotifications: notificationSettings?.[1]?.enabled,
      whatsAppNotifications: notificationSettings?.[2]?.enabled,
      appNotifications: notificationSettings?.[3]?.enabled,
    };
    updateNotificationSettings(updatedData, onSuccess, onFail);
  };
  const onSuccess = (response) => {
    const { result = {} } = response;
    if (response?.status?.toLowerCase() === "success") {
      dispatch(setUserdetails(result));
      navigate(-1);
    }
  };
  const onFail = (err) => {
    showToast("error", err?.message);
  };
  return {
    notificationSettings,
    onChangeNotificationSettings,
    onPressSubmit,
  };
};
export default NotificationSettingsContainer;
