import React from "react";
import defaultImage from "../../assets/images/default_img.png";

const CourseImage = ({
  imgUrl,
  height = "200px",
  className = "",
  style,
  showBackdrop = false,
}) => {
  const encodedUrl = imgUrl;
  const blurStyles = showBackdrop
    ? {
        backgroundImage: `url(${encodedUrl})`,
        position: "relative", // borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ...style,
      }
    : { ...style };

  const dropStyles = showBackdrop
    ? {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        objectFit: "contain",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }
    : {};
  return (
    <div
      style={{
        height: height,
        width: "100%",
        backgroundSize: "cover",
        borderRadius: 10,
        ...blurStyles,
      }}
    >
      {showBackdrop && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(10px)",
            borderRadius: 6,
          }}
        ></div>
      )}
      <img
        className={className}
        alt="related-imgs"
        width="100%"
        src={imgUrl || defaultImage}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundSize: "contain",
          ...dropStyles,
        }}
      />
    </div>
  );
};

export default CourseImage;
