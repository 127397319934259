import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CardLoader from "./CardLoader";

const LoadingCards = () => {
  const { mobileDevices, ipodDevices, miniLapys, lapy } = useSelector(
    (state) => state.responsive
  );
  return (
    <Grid container spacing={1.5} p={mobileDevices ? 2 : 1}>
      {[1, 2, 3, 4, 5, 6, 7, 8]
        .slice(
          0,
          mobileDevices ? 1 : ipodDevices ? 2 : miniLapys ? 3 : lapy ? 3 : 4
        )
        .map((each, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
              <CardLoader />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default LoadingCards;
