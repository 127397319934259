import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import AautiText from "../globalComponents/AautiText";
import { CircularProgress } from "@mui/material";

const AautiInfiniteScroll = ({
  children,
  cardsList = [],
  hasData = false,
  handleLoadMore = () => {},
  testId = "",
}) => {
  return (
    <InfiniteScroll
      style={{ width: "100%" }}
      scrollableTarget={testId}
      // endMessage={
      //   <AautiText
      //     textStyle={{ background: "aliceblue" }}
      //     title={"End of results!"}
      //     size={"normal"}
      //     textAlign={"center"}
      //   />
      // }
      // height={"76vh"}
      dataLength={cardsList?.length}
      next={handleLoadMore}
      hasMore={hasData}
      loader={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
            alignItems: "flex-start",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      {React.Children?.map(children, (child, index) => (
        <div key={index}>{child}</div>
      ))}
    </InfiniteScroll>
  );
};

export default AautiInfiniteScroll;
