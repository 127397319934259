import React, { useState } from "react";
import {
  ImageList,
  ImageListItem,
} from "@mui/material";
import AautiDialog from "../../../../globalComponents/AautiDialog";
import MessageText from "../text";
import { chatConstants } from "../../../constants";
import "./styles.scss";

function MessageImage({ currentMessage, position, reply = false, searchText }) {
  const [showImageDialog, setShowImageDialog] = useState(undefined);
  const { files, ...rest } = currentMessage;

  const toggleImageDialog = () => setShowImageDialog((prev) => !prev);

  const renderImage = (file, index) => {
    const styles = {
      height: 90,
      width: "auto",
      borderRadius:4,
    };
    return (
      <ImageListItem key={file.uri}>
        <img
          srcSet={`${file.uri}?w=80&h=80&fit=crop&auto=format&dpr=2 2x`}
          src={`${file.uri}?w=80&h=80&fit=crop&auto=format`}
          alt={file.name}
          loading="lazy"
          onClick={toggleImageDialog}
          style={styles}
        />
        {index == 3 && files?.length > 4 && (
          <div className="message-image-overlay" onClick={toggleImageDialog}>
            <span cla>+{files.length - 4}</span>
          </div>
        )}
      </ImageListItem>
    );
  };

  const renderImages = () => {
    if (files.length === 1) return renderImage(files[0], 0);
    return <ImageList>{files.slice(0, 4).map(renderImage)}</ImageList>;
  };

  const renderDialogImage = (file, index) => (
    <ImageListItem key={file.uri}>
      <img
        srcSet={`${file.uri}?w=80&h=80&fit=crop&auto=format&dpr=2 2x`}
        src={`${file.uri}?w=80&h=80&fit=crop&auto=format`}
        alt={file.name}
        loading="lazy"
        style={{
          height: "auto",
          width: "100%",
        }}
      />
    </ImageListItem>
  );

  return (
    <div>
      {renderImages()}
      {currentMessage.text && (
        <MessageText
          {...{
            currentMessage: rest,
            position,
            reply,
            searchText,
          }}
        />
      )}
      <AautiDialog
        open={showImageDialog}
        onClose={toggleImageDialog}
        dialogTitle={chatConstants?.previewImage}
        fullScreen={true}
      >
        <div style={{ paddingTop: 30 }}>
          <ImageList
            cols={1}
            sx={{
              width: "auto",
              overflowY: "scroll",
            }}
            rowHeight={"auto"}
          >
            {files.map(renderDialogImage)}
          </ImageList>
        </div>
      </AautiDialog>
    </div>
  );
}

export default MessageImage;
