import React from 'react'
import SkeletonMessageListItem from './MessageListItem'

function SkeletonMessagesList({size=20}) {
    const list = new Array(size).fill(0)
  return (
    <div style={{width:'100%',display:'flex',height:'inherit',flexDirection:'column'}}>
       {list.map(SkeletonMessageListItem)}
    </div>
  )
}

export default SkeletonMessagesList