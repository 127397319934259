import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import {
  approveOrRejectRequest,
  getBatchChangeRequestsBySpId,
} from "./Services";
import { useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MdOutlineCalendarMonth } from "react-icons/md";
import "./Style.scss";
import NoImage from "../../assets/images/no-image.svg";
import { Box, Grid, Skeleton } from "@mui/material";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import moment from "moment";
import AautiDialog from "../globalComponents/AautiDialog";
import { LuClock4 } from "react-icons/lu";
import JoinButton from "../globalComponents/JoinButton";
import EmptyData from "../globalComponents/EmptyData";
import AccountTabs from "../globalComponents/AccountTabs";
import EmptyTransfer from "../../assets/images/EmptyTransfer.png";
import EmptyTopic from "../globalComponents/EmptyTopic";
import { isEmpty } from "lodash";
import { batchExpired } from "../CommonFunctions";
import { showToast } from "../globalComponents/Toast";
import { retrunSlotStatus } from "../cart/Batches";
import AppContainer from "../../layouts/AppContainer";

const RequestItem = ({ each, index, setSelectedItem, selectedItem }) => {
  return (
    <div
      onClick={() => {
        setSelectedItem(each);
      }}
      className={
        each?.courseId === selectedItem?.courseId
          ? "requestItem-div1"
          : "requestItem-div2"
      }
      key={index}
    >
      <img
        className="requestItem-img1"
        src={
          each?.courseImage ||
          "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/No_Image_Available.jpg"
        }
        alt="order-img"
      />
      <div className="requestItem-div3" style={{ marginLeft: "5px" }}>
        <AautiText
          size={"semi"}
          className={"requestItem-text1"}
          title={each?.courseName || "NA"}
          weight={"bold"}
        />

        <div className="requestItem-div4"></div>
      </div>
    </div>
  );
};

const TransferRequests = () => {
  const { loggedinUserId } = useSelector((state) => state.app);
  const [activeTab, setActiveTab] = useState("pending");
  const [requests, setRequests] = useState([]);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [selectedItem, setSelectedItem] = useState(requests[0]);
  const [selectedId, setSelectedId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [totalRequests, setTotalRequests] = useState([]);

  useEffect(() => {
    const filterData = totalRequests?.filter((each) =>
      each?.courseName?.toLowerCase().includes(searchInput?.toLowerCase())
    );
    setRequests(filterData);
    setSelectedItem(filterData?.[0]);
  }, [searchInput]);

  const TransferType = [
    {
      id: 215,
      statetype: "Pending",
      type: "pending",
      attendancePerc: requests,
      attendanceCount: 3,
    },
    {
      id: 454,
      statetype: "Approved",
      type: "approved",
      attendancePerc: requests,
      attendanceCount: 3,
    },
    {
      id: 344,
      statetype: "Rejected",
      type: "rejected",
      attendancePerc: requests,
      attendanceCount: 3,
    },
  ];

  useEffect(() => {
    setLoading(true);
    getBatchChangeRequestsBySpId(
      loggedinUserId,
      activeTab,
      (response) => {
        if (response?.result) {
          setTotalRequests(response?.result);
          setRequests(response?.result);
          setSelectedItem(response?.result[0]);
        }
        setLoading(false);
      },
      (error) => {
        console.log(error, "error-transfer list");
        setLoading(false);
      }
    );
  }, [loggedinUserId, refresh, activeTab]);

  const handleRequestAction = (item, action) => {
    const requestId = item?._id;
    if (requestId) {
      const obj = {
        _id: requestId,
        transferStatus: action,
      };
      approveOrRejectRequest(
        obj,
        (resp) => {
          setRefresh(!refresh);
          setOpen(false);
        },
        (error) => {
          console.log(error, "error-transfer req");
          setOpen(false);
        }
      );
    }
  };

  const returnDate = (date, type) => {
    let formattedDate;
    if (type === "time") {
      formattedDate = moment(date).format("hh:mm A");
    } else {
      formattedDate = moment(date).format("DD MMM YYYY");
    }
    return formattedDate || "NA";
  };

  const returnWeek = (weeks) => {
    const nonEmptyElements = [];
    for (const key in weeks) {
      if (weeks[key].length > 0) {
        nonEmptyElements.push(
          <div key={key} className="transferRequest-div1">
            <LuClock4 size={20} />
            <div className="transferRequest-div2">
              <AautiText
                title={`${returnDate(
                  weeks[key][0]?.startTime,
                  "time"
                )}-${returnDate(weeks[key][0]?.endTime, "time")}`}
              />
              <div className="transferRequest-div3">
                <AautiText
                  title={key.slice(0, 3)}
                  textStyle={{ color: "#ffffff" }}
                />
              </div>
            </div>
          </div>
        );
      }
    }
    return nonEmptyElements;
  };

  return (
    <AppContainer style={{ width: "100%", height: "90vh" }}>
      <AccountTabs
        {...{
          tabObj: {
            label: "Transfer Requests",
            navigation: "/transfer-requests",
            active: true,
          },
        }}
      />
      <div className="transferRequest-div4">
        <Grid className="transferRequest-div5">
          {loading ? (
            <Box className="TRloadingdiv1">
              <Box className="TRloadingdiv2" sx={{ width: "25%" }}>
                <Skeleton variant="rounded" width={"100%"} height={40} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={mobileDevices ? 200 : 400}
                />
              </Box>
              <Box className="TRloadingdiv3" sx={{ width: "74%" }}>
                <Skeleton variant="rounded" width={"100%"} height={30} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton variant="rounded" width={"100%"} height={60} />
              </Box>
            </Box>
          ) : (
            <Box className="TRboxShadowDiv">
              <Box className="TRleftmaindiv01">
                <div className="TRSPleftmaindiv2">
                  {TransferType.map((each, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveTab(each?.type);
                      }}
                      className={
                        each?.type === activeTab
                          ? "transferRequest-div6"
                          : "transferRequest-div7"
                      }
                    >
                      <AautiText
                        size={"normal"}
                        weight={"semi"}
                        title={`${each.statetype} `}
                        textAlign={"start"}
                        textStyle={{
                          color:
                            each?.type === activeTab ? "#078dda" : "#1e1e1e",
                        }}
                      />
                    </div>
                  ))}
                </div>
                {(requests?.length > 0 || searchInput?.length > 0) && (
                  <div className="transferRequest-div8">
                    <FormControl
                      className="transferRequest-div9"
                      size="small"
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={"text"}
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                        style={{ width: "100%" }}
                        label={"Search"}
                      />
                      <InputLabel htmlFor="outlined-adornment-password">
                        <AautiText title={"Search"} />
                      </InputLabel>
                    </FormControl>
                  </div>
                )}
                <div className="TRscrollDiv">
                  {requests?.length === 0 ? (
                    searchInput === "" ? (
                      <EmptyTopic
                        Image={EmptyTransfer}
                        topicName={"No Data Found"}
                      />
                    ) : (
                      <EmptyData
                        showExplore={false}
                        text={
                          searchInput?.length > 0
                            ? "No Search Results"
                            : "No Results"
                        }
                      />
                    )
                  ) : (
                    requests?.map((each, index) => (
                      <RequestItem
                        {...{ each, index, setSelectedItem, selectedItem }}
                      />
                    ))
                  )}
                </div>
              </Box>
              {requests?.length === 0 ? (
                <div className="transferRequest-div10"></div>
              ) : (
                <div className="transferRequest-div11">
                  <section className="transferRequest-div13">
                    <div>
                      <div className="transferRequest-div12">
                        <img
                          src={selectedItem?.courseImage || NoImage}
                          alt={selectedItem?.courseName}
                          className="transferRequest-div14"
                        />
                        <div className="course-name-sub-cont">
                          <div className="transferRequest-div15">
                            <AautiText
                              textAlign={"left"}
                              size={"medium"}
                              weight={"bold"}
                              textStyle={{
                                color: "#4B4D4D",
                              }}
                              title={`${selectedItem?.courseName}`}
                            />
                          </div>
                          <div className="transferRequest-div16"></div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <hr className="transferRequest-div17" />
                  <section className="transferRequest-div18">
                    {selectedItem?.requestedEndUsers?.map((item) => (
                      <button
                        className="transferRequest-div19"
                        onClick={(e) => {
                          activeTab === "pending" && setSelectedId(item);
                          activeTab === "pending" && setOpen(true);
                        }}
                      >
                        <img
                          src={
                            item?.profileImage ||
                            "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/No_Image_Available.jpg"
                          }
                          alt="No"
                          className="transferRequest-div20"
                        />
                        <div className="TRcartCard1">
                          <AautiText
                            size={mobileDevices ? "small" : "normal"}
                            weight={"bold"}
                            title={item?.displayName}
                            className={"TRText-1"}
                          />
                        </div>
                      </button>
                    ))}
                  </section>
                </div>
              )}
            </Box>
          )}
        </Grid>
        <AautiDialog
          open={open}
          onClose={() => setOpen(false)}
          showCloseIcons={false}
        >
          <div className="TRDialog-div1">
            <div className="TRDialog-div2">
              <img
                src={selectedItem?.courseImage || NoImage}
                alt={selectedItem?.courseName}
                className="TRDialog-img1"
              />
              <div className="course-name-sub-cont">
                <div className="transferRequest-div15">
                  <AautiText
                    textAlign={"left"}
                    size={"medium"}
                    weight={"bold"}
                    className={"TRDialog-text1"}
                    title={selectedItem?.courseName}
                  />
                </div>
                <AautiText
                  textAlign={"left"}
                  size={"normal"}
                  weight={"normal"}
                  className={"TRDialog-text1"}
                  title={`${selectedId?.displayName}`}
                />
                <div className="transferRequest-div16"></div>
              </div>
            </div>
          </div>
          <div className="TRDialog-div3">
            <div className="TRDialog-div4">
              <AautiText
                size={"semi"}
                weight={"bold"}
                className={"TRDialog-text2"}
                title={`From :    ${selectedId?.fromBatch?.batchName}`}
              />
              <AautiText
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  color: retrunSlotStatus(selectedId?.fromBatch, true)?.color,
                }}
                title={retrunSlotStatus(selectedId?.fromBatch, true)?.text}
              />
            </div>
            <div className="TRDialog-div5">
              <div className="TRDialog-div6">
                <CalendarMonthIcon className="TRDialog-icon1" size={20} />
                <AautiText
                  title={
                    selectedId?.fromBatch?.recurringDays ? "Weekly" : "Daily"
                  }
                  size={"semi"}
                />
              </div>
              <div className="TRDialog-div6">
                <MdOutlineCalendarMonth size={20} />
                <AautiText
                  title={`${returnDate(
                    selectedId?.fromBatch?.startDate
                  )}-${returnDate(selectedId?.fromBatch?.endDate)}`}
                  size={"semi"}
                />
              </div>
              {selectedId?.fromBatch?.recurringDays ? (
                returnWeek(selectedId?.fromBatch?.recurringDays)
              ) : (
                <div className="TRDialog-div6">
                  <LuClock4 size={20} />
                  <AautiText
                    size={"semi"}
                    title={`${returnDate(
                      selectedId?.fromBatch?.startTime,
                      "time"
                    )}-${returnDate(selectedId?.fromBatch?.endTime, "time")}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={
              isEmpty(selectedId?.toBatch) ? "TRDialog-div7" : "TRDialog-div8"
            }
          >
            <div className="TRDialog-div9">
              <AautiText
                size={"semi"}
                weight={"bold"}
                className={"TRDialog-text2"}
                title={`To :    ${selectedId?.toBatch?.batchName}`}
              />
              <AautiText
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  color: retrunSlotStatus(selectedId?.toBatch, true)?.color,
                }}
                title={retrunSlotStatus(selectedId?.toBatch, true)?.text}
              />
            </div>
            <div style={{ padding: 8 }}>
              <div className="TRDialog-div6">
                <CalendarMonthIcon className="TRDialog-icon1 " size={20} />
                <AautiText
                  title={
                    selectedId?.toBatch?.recurringDays ? "Weekly" : "Daily"
                  }
                  size={"semi"}
                />
              </div>
              <div className="TRDialog-div6">
                <MdOutlineCalendarMonth size={20} />
                <AautiText
                  title={`${returnDate(
                    selectedId?.toBatch?.startDate
                  )}-${returnDate(selectedId?.toBatch?.endDate)}`}
                  size={"semi"}
                />
              </div>
              {selectedId?.toBatch?.recurringDays ? (
                returnWeek(selectedId?.toBatch?.recurringDays)
              ) : (
                <div className="TRDialog-div6">
                  <LuClock4 size={20} />
                  <AautiText
                    size={"semi"}
                    title={`${returnDate(
                      selectedId?.toBatch?.startTime,
                      "time"
                    )}-${returnDate(selectedId?.toBatch?.endTime, "time")}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="TRDialog-div10">
            <JoinButton
              outlined
              title={"Reject"}
              className="rightCont-div7"
              onClick={() => {
                if (!batchExpired(selectedId?.toBatch?.endDateTime)) {
                  handleRequestAction(selectedId, "rejected");
                } else {
                  showToast("error", "Batch Expired you cannot do any action");
                }
              }}
            />
            <JoinButton
              title={"Approve"}
              className="rightCont-div7"
              onClick={() => {
                if (!batchExpired(selectedId?.toBatch?.endDateTime)) {
                  handleRequestAction(selectedId, "approved");
                } else {
                  showToast("error", "Batch Expired you cannot do any action");
                }
              }}
            />
          </div>
        </AautiDialog>
      </div>
    </AppContainer>
  );
};

export default TransferRequests;
