import { APP_VERSION } from "../config";
import { baseUrl } from "../service/api-constants";

let timeoutId;

const DEPLOYED_VERSION = APP_VERSION.substring(1);

const VERSION_DATA_API_URL = baseUrl + "aautiDomain/getAll";

async function fetchVersionData() {
  try {
    const response = await fetch(VERSION_DATA_API_URL);
    if (!response.ok) {
      throw new Error("Failed to fetch version data from API");
    }

    const data = await response.json();
    if (data.status === "Success" && Array.isArray(data.result)) {
      return data.result;
    } else {
      console.warn("Unexpected API response format:", data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching version data:", error);
    return null;
  }
}

function getCurrentVersionInfo(versionData) {
  const origin = window.location.origin;
  return versionData.find((item) => item.domainUrl === origin) || null;
}

async function checkForUpdate() {
  try {
    const versionData = await fetchVersionData();
    if (versionData) {
      const currentVersionInfo = getCurrentVersionInfo(versionData);

      if (currentVersionInfo) {
        const latestVersion = currentVersionInfo.version.substring(1);
        if (latestVersion !== DEPLOYED_VERSION) {
          window.location.reload();
          return;
        }
      }
    }
  } catch (error) {
    console.error("Error during version check:", error);
  }

  timeoutId = setTimeout(checkForUpdate, 300000);
}

export function startVersionChecker() {
  timeoutId = setTimeout(checkForUpdate, 300000);
}

export function stopVersionChecker() {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
}
