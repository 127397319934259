import { useEffect, useMemo, useState } from "react";
import "./index.scss";
import moment from "moment";
import AautiText from "../globalComponents/AautiText";
import ClockIcon from "../../assets/images/Clock-Icon.png";
import { Icon } from "../appCards/AdhocEventsCard";
import { formatStartandEndTime } from "../../utils/Helpers";

const WeekView = ({ data }) => {
  const weekReccurences = {
    Sun: data?.sunTimeSlots,
    Mon: data?.monTimeSlots,
    Tue: data?.tueTimeSlots,
    Wed: data?.wedTimeSlots,
    Thu: data?.thuTimeSlots,
    Fri: data?.friTimeSlots,
    Sat: data?.satTimeSlots,
  };

  const [selected, setSelected] = useState("");
  const days = useMemo(() => {
    const slots = Object.keys(weekReccurences).filter(
      (each) => weekReccurences[each].length > 0
    );
    setSelected(slots[0]);
    return slots;
  }, []);

  const calculateMotion = (width = 1) => {
    return parseInt(width / 100) * 1300;
  };

  useEffect(() => {
    const box = document.getElementById(data?._id + "textBoxWeek");
    const keyFrames = [
      { transform: "translateX(0%) " }, //0%
      { transform: "translateX(-100%)" }, // 100%
    ];
    const firstChild = box?.children[0];
    if (box) {
      if (firstChild) {
        const KeyFrameConstructure = new KeyframeEffect(firstChild, keyFrames, {
          duration: calculateMotion(firstChild?.scrollWidth),
          iterations: Infinity,
        });
        const a = new Animation(KeyFrameConstructure);
        a.id = data?._id + "textBoxWeek";
        firstChild.addEventListener("mouseover", (e) => {
          a.effect.updateTiming({
            duration: calculateMotion(e?.target?.scrollWidth),
          });
          if (firstChild.scrollWidth > box?.getClientRects()[0].width) {
            a.play();
          }
        });

        firstChild.addEventListener("mouseleave", () => {
          const animations = document.getAnimations(data?._id + "textBoxWeek");
          if (animations.length > 0) {
            animations[0].cancel();
          }
        });
      }
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="week-cont">
        {days.map((each) => {
          return (
            <button
              className={`week-day-btn ${
                each == selected ? "selected-day" : ""
              }`}
              onClick={(e) => {
                e?.stopPropagation();
                setSelected(each);
              }}
            >
              {<AautiText title={each} />}
            </button>
          );
        })}
      </div>
      {selected && (
        <div
          style={{
            gap: 12,
            marginBottom: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon icon={ClockIcon} />
          <div className="textBoxWeek" id={data?._id + "textBoxWeek"}>
            <AautiText
              className={"span"}
              title={`${weekReccurences[selected].map((each) => {
                return (
                  "  " +
                  formatStartandEndTime({
                    start: each?.startTime?.replace("Z", ""),
                    end: each?.endTime?.replace("Z", ""),
                  })
                );
              })}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WeekView;
