import React from "react";
import { StyledMsgContainer } from "../../styled_components";
import MessageText from "../text";

function MessageVideo({ currentMessage,position, reply = false, searchText }) {
  const { files, ...rest } = currentMessage;
  return (
    <StyledMsgContainer>
      <video
        style={{
          height: 160,
          width: 'auto',
        }}
        src={files[0].uri}
        controls
        controlsList="nodownload"
      />
      {currentMessage.text && <MessageText {...{ currentMessage:rest,position, reply, searchText }} />}
    </StyledMsgContainer>
  );
}

export default MessageVideo;
