import { ApiMethods, ApiPaths } from "../../../../../service/api-constants";
import { ApiRequest } from "../../../../../service/api-request";

function saveAsk(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_ASK,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function saveAskDraft(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_ASK_DRAFT,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getSpIdPollDetails(serviceProviderId, pollId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_SP_POLL_DETAILS +
      "?serviceProviderId=" +
      serviceProviderId +
      "&pollId=" +
      pollId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllAsksDraftsBySessionIdBatchIdSp(
  sessionId,
  batchId,
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      ApiPaths.GET_ALL_DRAFTS_ASK_BY_SESSION_BATCH_SP +
      "?sessionId=" +
      sessionId +
      "&batchId=" +
      batchId +
      "&serviceProviderId=" +
      serviceProviderId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getEndUserPollDetails(endUserId, pollId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ENDUSER_POLL_DETAILS +
      "?endUserId=" +
      endUserId +
      "&pollId=" +
      pollId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function updateAsk(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_ASK,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function submitPoll(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SUBMIT_POLL,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function deleteAsk(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_ASK + "?id=" + id,
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteDraftAsk(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_ASK_DRAFT + "?id=" + id,
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAskDetailsBySessionIdBatchId(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ASK_DETAILS_BATCHID_SESSIONID,

    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getDraftsSpIdPollDetails(
  sessionId,
  batchId,
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      ApiPaths.GET_DRAFT_SP_POLL_DETAILS +
      "?sessionId=" +
      sessionId +
      "&batchId=" +
      batchId +
      "&serviceProviderId=" +
      serviceProviderId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function updateDraftAsk(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_DRAFT_ASK,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

export const AuthService = {
  saveAsk,
  getSpIdPollDetails,
  updateAsk,
  deleteAsk,
  getAskDetailsBySessionIdBatchId,
  getEndUserPollDetails,
  submitPoll,
  saveAskDraft,
  getAllAsksDraftsBySessionIdBatchIdSp,
  getDraftsSpIdPollDetails,
  deleteDraftAsk,
  updateDraftAsk,
};
