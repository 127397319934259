import React from "react";
import AccountTabs from "../globalComponents/AccountTabs";
import { useTrusteStates } from "./trusteeCustomHooks/useTrusteStates";
import { useTrusteeFunctionHooks } from "./trusteeCustomHooks/useTrusteeFunctionHooks";
import TrusteesCardsListing from "./addTrusteModules/TrusteesCardsListing";
import AautiDialog from "../globalComponents/AautiDialog";
import InviteTrustee from "./addTrusteModules/InviteTrustee";
import AppContainer from "../../layouts/AppContainer";
import ContainerLayout from "../globalComponents/ContainerLayout";

const TrusteeComponent = () => {
  const {
    search,
    setSearch,
    scheduleRef,
    trusteesList,
    apiLoading,
    openAddTrustee,
    setOpenAddTrustee,
    trusteeBase,
    setTrusteeBase,
    country,
    setCountry,
    isNumber,
    addBtnValidation,
    invitesList,
    setInvitesList,
    refresh,
    setRefresh,
    loggedinUserId,
    openBackdrop,
    setOpenBackdrop,
  } = useTrusteStates();

  const {
    onChangeSearch,
    handleOpenTrustee,
    handleInvitees,
    handleClickOption,
  } = useTrusteeFunctionHooks({
    setSearch,
    setOpenAddTrustee,
    invitesList,
    setInvitesList,
    refresh,
    setRefresh,
    openBackdrop,
    setOpenBackdrop,
  });
  return (
    <AppContainer
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 60px)",
      }}
    >
      <AccountTabs
        {...{
          tabObj: {
            label: "My Trusted Accounts",
            navigation: "/trustee-management",
            active: true,
          },
        }}
      />
      <ContainerLayout
        {...{ search, onChangeSearch, setSearch, scheduleRef }}
        buttonAction={() => {
          handleOpenTrustee();
        }}
      >
        <TrusteesCardsListing
          {...{ apiLoading, trusteesList, search, handleClickOption }}
        />
      </ContainerLayout>
      <AautiDialog
        dialogTitle={"Add Trustee"}
        open={openAddTrustee}
        onClose={() => {
          setOpenAddTrustee(false);
          setInvitesList([]);
        }}
      >
        <InviteTrustee
          {...{
            isNumber,
            country,
            setCountry,
            trusteeBase,
            setTrusteeBase,
            handleInvitees,
            setInvitesList,
            invitesList,
            addBtnValidation,
            loggedinUserId,
            openBackdrop,
            setOpenBackdrop,
          }}
        />
      </AautiDialog>
    </AppContainer>
  );
};

export default TrusteeComponent;
