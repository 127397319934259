export const cardTitles = {
  allCards: "ALL_CARDS_TITLE",
  startingInNext6Hours: "Starting in Next 8 Hours",
  topServiceProviders: "Top Service Providers",
  gigs: "Gigs",
  newSessions: "Upcoming",
  newServiceProviders: "New Service Providers",
  freeDemoClassess: "Free Demo",
  blockBusterDeals: "Blockbuster deals",
  Packages: "Packages",
  testimonials: "Testimonials",
  trending: "Trending",
  upcomingSession: "Upcoming Session",
  Institutes: "Institutes",
  upcomingSessions: "Upcoming Sessions",
};

export const nextScreens = {
  newSessionCardView: "/NewSessionCardView",
  moreTopServiceProviders: "/top-serviceproviders",
  allGigs: "/gigs",
  recentProviderMore: "/MoreTopServiceProviders",
  moreLiveClasses: "/MoreLiveClasses",
  morePackages: "/more-packages",
  moreTestimonies: "/testimonies",
  moreBlockDeals: "/block-deals",
  allTrending: "/trending-courses",
  institutes: "/more-institutes",
};

export const testimonialStrings = {
  testimonials: "Testimonials",
};

export const PackageStrings = {
  memberTransfer: "member Transfer",
  gigpurchase: "gig purchase",
  coursepurchase: "course purchase",
  inviteCourse: "invite Course",
  inviteModerator: "invite moderator",
  inviteMember: "invite Member",
  AdhocPurchase: "Adhoc purchase",
  BatchReschedule: "Batch Reschedule",
  RescheduleRefund: "Reschedule/Refund",
  batchTransfer: "batchTransfer",
  Gigs: "Gigs",
  Courses: "Courses",
  uploadPackageMedia: "upload-package-media",
  fileUpload: "file/upload",
  physicalBook: "physicalBook",
  success: "Success",
  post: "POST",
  postSmall: "post",
  file: "file",
  ratioAlert: "Selected image must have a 4:3 aspect ratio.",
  ratio4_3: "4/3",
  apiAlert: "Something went wrong! Please try again later.",
  gig: "gig",
  course: "course",
  course2: "course",
  courseCap: "Course",
  gigCap: "Gig",
  individual: "Individual",
  group: "Group",
  percentage: "Percentage",
  error: "error",
  search: "Search",
  important: "*",
  packageName: "Package Name",
  packageName2: "PackageName",
  minLength_PackageName: "/70 (minimum 50 characters)",
  packageDescription1: "Package Description",
  packageDescription2: "PackageDescription",
  minLength_PackageDesc: "/200 (minimum 50 characters)",
  generateUsingAi: "Generate Using AI",
  discountPercentage: "Discount Percentage",
  select: "Select",
  uploadImage: "Upload Image",
  coverImage: "Cover Image",
  extarText1: "Only images with 4:3  ratio  are allowed",
  previewImage: "Preview Image",
  allCourses: "All Courses",
  allGigs: "All Gigs",
  searchCoupons: "Search",
  selected: "Selected",
  altImage:
    "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/No_Image_Available.jpg",
  altText: "NO",
  cancel: "Cancel",
  Add: "Add",
  Preview: "Preview",
  submit: "Submit",
  update: "Update",
  save: "Save",
  individualDaily: "individualDaily",
  groupDaily: "groupDaily",
  individualWeekly: "individualWeekly",
  groupWeekly: "groupWeekly",
  available: "Available",
  waitingList: "Waiting List",
  Expired: "EXPIRED",
  Full: "FULL",
  booked: "BOOKED",
  purchaseFor: "Purchase For",
  id: "_id",
  BoughtFor: "Bought For",
  amount: "Amount",
  daily: "Daily",
  weekly: "Weekly",
  notAvailable: "N/A",
  yearsExp: "Years Exp",
  expNotListed: "| Exp not listed",
  noneRegistered: "None registered yet!",
  adhoc: "adhoc",
  selectDates: "Select Dates",
  new: "New",
  students: "Students",
  noDataFound: "No Data Found",
  keepExploring: "Keep Exploring!",
  packages: "Packages",
  myPackagesEmptytext: "You haven’t added any packages yet",
  searchEmptytext: "No Data Found on Your Search",
  addPackage: "Add Package",
  nameNotMention: "Name not Mentioned",
  upto: "Upto",
  off: "% Off",
  edit: "Edit",
  delete: "Delete",
  ueserId: "userId",
  right: "right",
  gigsList: "gigsList",
  coursesList: "coursesList",
  buyNow: "buyNow",
  packagePrice: "Package Price :",
  discountAmount: "Discount Amount : ",
  discountPrice: "Discount Price : ",
  buynowCap: "Buy Now",
  courseAvailable: "Courses Available",
  gigsAvailable: "Gigs Available",
  relatedPackages: "Related Packages",
  similarPackages: "Similar Packages",
  noPackages: "No Packages",
  overView: "Overview",
  viewMore: "View More",
  moreBlockDeals: "More BlockBuster Deals",
  selectProfession: "Select Your Profession",
  skip: "Skip",
  next: "Next",
  professions: "professions",
  professionupdated: "Professions updated",
  userFound: "User found",
  successSmall: "success",
  noSelectedCategories: "There are no Selected Categories to update",
  addedToWishList: "Added to Wishlist",
  removedFromWishList: "Removed from wishlist",
  nameUnAvailable: "Name Unavailable",
  from: "From",
  notifications: "Notifications",
  notification: "Notification",
  adhocStartIn5Minutes: "Adhoc starts in 15 Mins",
  courseStartin15Minutes: "Course starts in 15 Mins",
  viewCourse: "View Course",
  viewGig: "View Gig",
  viewAdhoc: "View Adhoc",
  joinClass: "Join Class",
  clearAll: "Clear All",
  markAsRead: "Mark All As Read",
  noNotifications: "No Notifications",
  wishList: "Wishlist",
  wishListSmall: "wishlist",
  serviceProviders: "Service Providers",
  favouriteServiceProviders: "My favourite service providers",
  favouriteListEmpty: "No favourite service providers yet.",
  favouriteInstituteEmpty: "No Favourite Institutes yet.",
  keepExploringTextForSp: "Keep exploring to find Service providers!",
  favourite: "favorite",
  favouriteInstitutes: "My Favourite Institutes",
  TopServiceproviders: "Top Service Providers",
  unFollowed: "Unfollowed",
  following: "Following",
  nodesc: "No_description",
  aautiPool: "AautiPool",
  altImage2:
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png",
  loginText: "Please login to continue....",
  info: "info",
  ratings: "Rating",
  knowMore: "Know More",
  Rating5: "5.0 Ratings",
  risingStar: "Rising star",
  hihgDemand: "High-demand",
  follow: "Follow",
  allTrendingCourses: "All Trending Courses",
  filters: "Filters",
  appliedFilters: "Applied Filters",
  clear: "Clear",
  searchCourses: "Search",
  upcomingCourses: "Upcoming Courses",
  upcomingCourses2: "UpcomingCourses",
  upComing: "Upcoming ",
  onGoingCourses: "Ongoing Courses",
  onGoingCourses2: "OngoingCourses",
  completedCourses: "Completed Courses",
  CompletedCourses2: "CompletedCourses",
  addToCart: "Add To Cart",
  moveToCart: "Move To Cart",
  EmptyCourseWishList: "Your Course Wishlist is Empty",
  EmptyGigWishList: "Your Gigs Wishlist is Empty",
  descriptionUnAvailable: "Description unavailable",
  noBlockBusterDealaAvailable: "No Blockbuster Courses Available",
  noTrendingCoursesAvailable: "No Trending Courses Available",
  noCoursesAvailable: "No Courses Available",
  noGigsAvailble: "No Gigs Available",
  noSPAvailable: "No Service Providers Available",
  noPackagesAvailable: "No Packages Available",
  noTestimoniesAvailable: "No Testimoinies Available",
  noUpcomingCoursesAvailable: "No Upcoming Courses Availble",
  scroll: "scroll",
  testimonials: "Testimonials",
  blockBusterDeals: "Blockbuster deals",
  viewAll: "(View All)",
  plsSelectSubCat: "Please select Sub Category",
  noteText:
    "Note: Aauti is not responsible for the content the Service Provider uploads.",
  topRated: "Top Rated",
  moreResults: "More Results",
  noInstitutesFound: "No Institutes Found",
  showingResultsFor: "Showing Results For",
  noresults: "No Results",
  noSearchResults: "No Search Results",
  similarPackagebyOtherProviders: "Similar Packages by other service providers",
};

export const adminCardTitles = {
  upcommingCourse: "Upcoming",
  onGoing: "Ongoing",
  completed: "Completed",
};

export const adminNextScreens = {
  upcommingCourse: "/upcoming-courses",
  onGoing: "/on-going-courses",
  completed: "/completed-courses",
};
