import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import Virtualicon from "../../../assets/images/monitor.png";

const VirtualIcon = ({ customStyle = {} }) => {
  const styles = {
    //   background: "#fff",
    mb: -0.5,

    p: 1,
    "&:hover": { background: "#fff" },
    ...customStyle,
  };
  return (
    <Tooltip title="Virtual">
      <IconButton sx={styles}>
        <img
          src={Virtualicon}
          alt="virtual"
          style={{
            height: "18px",
            width: "18px",
            zIndex: 100,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default VirtualIcon;
