import React, { useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import dayjs from "dayjs";
import moment from "moment";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import AautiTimePicker from "../../globalComponents/timepickers/AautiTimePicker";
import {
  addOneHourOrEndOfDay,
  returnisPresentDay,
} from "../../CommonFunctions";
import { getNextTimeSlot, weekName } from "../courseCreation/constants";
import NavButton from "../../globalComponents/NavButton";

const RescheduleWeek = ({ batchObject, updateSlots }) => {
  const [activeWeek, setActiveWeek] = useState("");
  const [recurringObject, setRecurringObject] = useState({});

  useEffect(() => {
    if (batchObject?.recurringDays) {
      setRecurringObject(batchObject?.recurringDays);
    } else {
      const createWeekObject = (days) => {
        const week = {};
        days.forEach((day) => {
          week[day] = [];
        });
        return week;
      };

      const weekObject = createWeekObject(weekName);
      setRecurringObject(weekObject);
    }
  }, [batchObject]);

  const handleChangeTiming = (time, field) => {
    const presentTime = moment(time?.$d);
    const start = moment(time?.$d || time).format("YYYY-MM-DDTHH:mm:00");

    const end = addOneHourOrEndOfDay(start);
    let array = [];
    if (field == "startTime") {
      array.push({
        startTime: start,
        endTime: end,
      });
    } else {
      let object = recurringObject[activeWeek]?.[0] || {};
      object.endTime = start;
      array.push(object);
    }
    setRecurringObject((prev) => ({
      ...prev,
      [activeWeek]: array,
    }));
  };

  const returnMinTime = () => {
    const { startDate, endDate } = batchObject;
    const today = moment().startOf("day");

    // Convert startDate and endDate to moment objects and set to start of the day
    const start = moment(startDate).startOf("day");
    const end = moment(endDate).startOf("day");

    // Check if today's date is within the range
    if (today.isBetween(start, end, null, "[]")) {
      // '[]' includes start and end dates
      //compare week and return min time
      const todayDayOfWeek = today.format("dddd");
      if (todayDayOfWeek === activeWeek) {
        return getNextTimeSlot();
      } else {
        return today;
      }
    } else {
      return today;
    }
  };

  const endTime =
    recurringObject && recurringObject?.[activeWeek]
      ? moment(recurringObject?.[activeWeek]?.[0]?.startTime)
          .add(1, "hour")
          ?.format("YYYY-MM-DDTHH:mm:ss")
      : moment(getNextTimeSlot())
          ?.add(1, "hour")
          ?.format("YYYY-MM-DDTHH:mm:ss");

  return (
    <>
      <div className="weeknames-map">
        {Object.keys(recurringObject)?.map((each, index) => {
          const hasData = recurringObject[each]?.length;
          return (
            <div
              key={index}
              onClick={() => {
                setActiveWeek(each);
              }}
              className="weekNae"
              style={{
                backgroundColor:
                  activeWeek === each ? "rgba(49, 102, 186, 1)" : "transparent",
                borderColor:
                  hasData || activeWeek === each ? "#2e5da6d6" : "#d2d2d2",
                color: activeWeek === each && "white",
              }}
            >
              <AautiText
                title={each.slice(0, 3)}
                size={"semi"}
                weight={"bold"}
              />
            </div>
          );
        })}
      </div>
      {activeWeek !== "" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <AautiText title={activeWeek} textStyle={{ marginTop: 12 }} />
          <div
            className="start-end-time"
            style={{ width: "100%", border: "none" }}
          >
            <div
              style={{
                width: "45%",
              }}
              className="eachDateStart"
            >
              <AautiTimePicker
                timeSteps={{ minutes: 30 }}
                ampm={false}
                minTime={returnMinTime()}
                timerValue={
                  recurringObject?.[activeWeek][0]?.startTime
                    ? dayjs(recurringObject?.[activeWeek][0]?.startTime)
                    : null
                }
                onChangeTimePicker={(e) => handleChangeTiming(e, "startTime")}
                slotProps={{ field: { readOnly: true } }}
                minutesStep={30}
              />
            </div>
            <div
              className="eachDateStart"
              style={{
                width: "49%",
              }}
            >
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <DemoItem label={AautiStrings?.EndTimeString}>
                    <TimePicker
                      ampm={false}
                      timeSteps={{ minutes: 30 }}
                      value={
                        recurringObject?.[activeWeek][0]?.endTime
                          ? dayjs(recurringObject?.[activeWeek][0]?.endTime)
                          : null
                      }
                      onChange={(e) => {
                        handleChangeTiming(e, "endTime");
                      }}
                      slotProps={{ field: { readOnly: true } }}
                      minutesStep={30}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider> */}

              <AautiTimePicker
                minTime={endTime}
                timerValue={
                  recurringObject?.[activeWeek][0]?.endTime
                    ? dayjs(recurringObject?.[activeWeek][0]?.endTime)
                    : null
                }
                onChangeTimePicker={(e) => {
                  handleChangeTiming(e, "endTime");
                }}
              />
            </div>
          </div>
        </div>
      )}

      <NavButton
        text={AautiStrings?.SaveString}
        handleClick={() => {
          updateSlots(recurringObject);
        }}
      />
    </>
  );
};

export default RescheduleWeek;
