import React, { useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import JoinButton from "../../globalComponents/JoinButton";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "./Service";
import { PackageStrings } from "../Constants";
import { showToast } from "../../globalComponents/Toast";
import { useSelector } from "react-redux";
import AautiDialog from "../../globalComponents/AautiDialog";
import RequestRefunds from "../../ordersScreen/refunds/RequestRefunds";
import { PriceConversions } from "../../CommonFunctions";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import IndividualIcon from "../../../assets/images/individual.svg";
import GroupIcon from "../../../assets/images/group.svg";
import "./Styles.scss";
import { isEmpty } from "lodash";
import { sassTrue } from "sass";
import { Skeleton } from "@mui/material";
import moment from "moment";
import { formatStartandEndTime, formatTime } from "../../../utils/Helpers";
import { LuClock4 } from "react-icons/lu";

const ReschedulePage = (props) => {
  const { courseId, batchId, notificationId, setRejectBs, extraData } = props;
  const [selectedData, setSelectedData] = useState("");
  const [selectedOptionRefund, setselectedOptionRefund] =
    useState("otherBatches");
  const [cartData, setCartData] = useState(null);
  const [scheduleSkeleton, setScheduleSkeleton] = useState(false);
  const [typeState, setTypeState] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("credits");
  const [refundData, setRefundData] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [details, setDetails] = useState("");
  const { convertPriceWithCurrency } = PriceConversions();
  const navigate = useNavigate();
  const { loggedinUserId } = useSelector((state) => state.app);
  const selectedBatch =
    selectedData?.type === "individualDaily" ||
      selectedData?.type === "individualWeekly"
      ? "individual"
      : "group";
  const selectedRejectedBatch = selectedData?.batchId || selectedData?._id;
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChange = (event, type) => {
    if (type === "details") {
      setDetails(event);
    } else {
      setRefundReason(event.target.value);
    }
  };
  const handleOptionChangeBatch = (event) => {
    setselectedOptionRefund(event.target.value);
  };
  useEffect(() => {
    batchDetailsApiCall();
  }, []);
  const batchDetailsApiCall = () => {
    setScheduleSkeleton(true);
    const data = { courseId: courseId, batchId: batchId };
    AuthService.getBatchDetailsByBatchId(data, onSuccessReschedule, onFailure);
  };
  const onSuccessCourseData = (response) => {
    if (response.status === PackageStrings?.success) {
      setScheduleSkeleton(false);
      setCartData(response?.result);
      setSelectedData(response?.result?.[typeState]?.[0]);
    }
  };
  const onSuccessReschedule = (response) => {
    if (response.status === PackageStrings?.success) {
      setTypeState(response?.result[0]?.type);
      const data = {
        courseId: courseId,
        batchId: batchId,
        type: response?.result[0]?.type,
        price:
          response?.result[0]?.type === "groupDaily" ||
            response?.result[0]?.type === "groupWeekly"
            ? response?.result[0]?.localGroupPrice
            : response?.result[0]?.localIndividualPrice,
      };
      AuthService.getBatchesByCourseId(data, onSuccessCourseData, onFailure);
    } else {
      showToast(PackageStrings?.error, response.message);
    }
  };
  const onFailure = (error) => {
    showToast(PackageStrings?.error, error.message);
    setScheduleSkeleton(false);
  };
  const formatDate = (isoDate) => {
    const dateObject = new Date(isoDate);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    console.log("formated date", formattedDate, dateObject, isoDate);
    return formattedDate;
  };

  const onPressReject = () => {
    if (selectedOptionRefund === "otherBatches") {
      const status = "batchChange";
      const data = {
        status,
        selectedRejectedBatch,
      };
      onPressRejectSubmit(data);
    } else {
      getRescheduleRefundAmountAPI();
    }
  };
  const onPressRejectSubmit = (data) => {
    const apiData = {
      courseId: courseId,
      toId: selectedRejectedBatch,
      fromId: batchId,
      endUserId: loggedinUserId,
      batchType: selectedBatch,
    };
    const rejectData = {
      courseId,
      batchId,
      endUserId: loggedinUserId,
      notificationId,
      status: data?.status ? data?.status : "rejected",
    };
    AuthService.batchReschedule(
      rejectData,
      () => onSuccessBatch(apiData),
      onFailure
    );
  };
  const onSuccessBatch = (res) => {
    AuthService.changeBatchInReject(res, onSuccessStatus, onFailure);
  };
  const onSuccessStatus = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(response.message);
      navigate(`/`);
    } else {
      showToast(response.message);
    }
  };
  const getRescheduleRefundAmountAPI = () => {
    const data = {
      courseId: courseId,
      batchId: batchId,
      endUserId: loggedinUserId,
    };
    // AuthService.getRescheduleRefundAmount(data, onSuccessData, onFailure);
    const params = {
      orderId: extraData?.orderId,
    };
    if (extraData?.family) {
      params.courseId = courseId;
      params.batchId = batchId;
    } else {
      params.courseId = courseId;
      params.batchId = batchId;
      params.endUserId = loggedinUserId;
    }
    params.fromReschedule = true;
    AuthService.getRefundRequestDetails(
      params,
      (response) => {
        if (response?.result) {
          if (response?.result?.refundAmount > 0) {
            setOpen(true);
            setRefundData(response?.result);
            setSelectedOption(
              response?.result?.aautiRefundAmount ? "credits" : "bankPayment"
            );
          } else {
            showToast("info", "The refund policy does not meet the criteria.");
          }
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error, "error-refund");
      }
    );
  };
  const onSuccessData = (response) => {
    setRefundData(response?.result);
  };
  const onSubmitRefund = (data) => {
    const apiData = {
      courseId: courseId,
      batchId: batchId,
      endUserId: loggedinUserId,
      notificationId,
      status: "rejected",
    };
    AuthService.batchReschedule(apiData, onSuccessStatus, onFailure);
  };
  const refundRequestApi = () => {
    const data = {
      orderId: refundData?._id,
      reason: refundReason,
      orderDate: refundData?.orderDate,
      endUserId: loggedinUserId,
      description: details,
      currencyCode: refundData?.currencyCode,
      currencySymbol: refundData?.currencySymbol,
      serviceProviderId: refundData?.serviceProviderId,
      purchasedPrice: refundData?.purchasedPrice,
      localPurchasedPrice: refundData?.localPurchasedPrice,
      refundMethod:
        selectedOption !== "credits" ? refundData?.paymentMethod : "credits",
      parentId: refundData?.parentId || "",
      refundAmount:
        selectedOption !== "credit"
          ? refundData?.refundAmount
          : convertPriceWithCurrency(refundData?.refundAmount),
      courseId: refundData?.courseId,
      batchId: refundData?.batchId,
      refundList: refundData?.refundList,
    };
    AuthService.refundRequest(data, onSuccessRefund, onFailure);
  };
  const onSuccessRefund = (response) => {
    if (response?.data.status === "Success") {
      showToast("success", response?.data?.message);
      onSubmitRefund();
    } else {
      showToast("error", response?.data?.message);
    }
  };
  const formattedDiscount = (each) => {
    return each?.discountType === "Percentage"
      ? `${each?.localGroupDiscountValue ||
      each?.localIndividualDiscountValue ||
      0
      }%`
      : `${convertPriceWithCurrency(each?.localGroupDiscountValue) ||
      convertPriceWithCurrency(each?.localIndividualDiscountValue) ||
      0
      }`;
  };
  function convertToLocalTime(utcDateString) {
    const date = new Date(utcDateString);
    // Convert to local time in 12-hour format with AM/PM
    const localTime = date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return localTime;
  }

  const renderDays = (a) => {
    let timeSlots =
      Object.keys(a)?.length > 0
        ? [a].map((each, index) => {
          let weekData = [];
          const keys = Object.keys(each.recurringDays);
          keys.forEach((dayItem) => {
            let obj = {
              day: dayItem,
              slots: each.recurringDays[dayItem],
            };
            weekData.push(obj);
          });
          const batch = {
            ...each,
            timeSlots: weekData,
            name: each.batchName,
          };
          return batch;
        })
        : [];
    const batchMap = new Map();
    const jsxArray = [];
    timeSlots[0]?.timeSlots?.forEach((element) => {
      if (element?.slots?.length > 0) {
        const { startTime, endTime } = element?.slots[0];
        const time = `${formatTime({ time: startTime })}-${formatTime({
          time: endTime,
        })}`;
        if (!batchMap.has(time)) {
          batchMap.set(time, {
            days: [element.day],
            startTime,
            endTime,
          });
        } else {
          const time2 = batchMap.get(time);
          time2.days.push(element.day);
          batchMap.set(time, time2);
        }
      }
    });
    batchMap.forEach((value, key) => {
      const jsxElement = (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            className="field-style"
          >
            <LuClock4 size={20} className="icon" />

            <AautiText
              title={formatStartandEndTime({
                start: value.startTime,
                end: value.endTime,
              })}
              size={"semi"}
              textStyle={{ textWrap: "nowrap" }}
            />
          </div>
          {/* <div
            className="day-container-main"
            style={{
              overflow: "wrap",
              justifyContent: "flex-start",
            }}
          > */}
          {value.days.map((each, index) => (
            <div className="day-cont" style={{ height: 20, width: 35 }}>
              <AautiText
                title={each.slice(0, 3)}
                textStyle={{ color: "#fff" }}
                size={"small"}
              />
            </div>
          ))}
          {/* </div> */}
        </div>
      );
      jsxArray.push(jsxElement);
    });
    return jsxArray;
  };

  return (
    <div className="rsmainDiv">
      <div className="rsmaindiv2">
        <AautiText title={PackageStrings?.RescheduleRefund} weight={"bold"} />
        <div className="rsradio1">
          {scheduleSkeleton ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 0px",
              }}
            >
              <Skeleton variant="text" sx={{ width: 300, height: 40 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "10px 0px",
                  gap: 10,
                }}
              >
                {Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    sx={{ width: 200, height: 80 }}
                  />
                ))}
              </div>
              <Skeleton variant="text" sx={{ width: 300, height: 40 }} />
            </div>
          ) : (
            <>
              {cartData?.[typeState]?.length > 0 && (
                <>
                  <div className="rsmarginB">
                    <input
                      type="radio"
                      id="otherBatches"
                      name="option"
                      value="otherBatches"
                      checked={selectedOptionRefund === "otherBatches"}
                      className="rsradiocursor"
                      onChange={handleOptionChangeBatch}
                    />
                    <label
                      className="rscursor"
                      onChange={handleOptionChangeBatch}
                      htmlFor="otherBatches"
                    >
                      Other Available Batches
                    </label>
                  </div>
                  {selectedOptionRefund === "otherBatches" && (
                    <div className="rsmapdiv">
                      {cartData?.[typeState]?.map((each, index) => {
                        const isDaily =
                          each?.type === "individualDaily" ||
                          each?.type === "groupDaily" ||
                          typeState === "groupDaily" ||
                          typeState === "individualDaily";
                        return (
                          <div
                            onClick={() => setSelectedData(each)}
                            key={index}
                            className="rschilddiv"
                            style={{
                              border:
                                selectedData?._id === each?._id
                                  ? "2px solid #078dda"
                                  : "1px solid #5C5B5733",
                            }}
                          >
                            <div className="rschilddiv2">
                              <AautiText
                                title={each?.batchName}
                                size={"semi"}
                                weight={"bold"}
                                className={"rstext90"}
                              />
                            </div>
                            <div className="rsicondiv">
                              <div className="rsicondiv2">
                                <CalendarMonthIcon
                                  sx={{ fontSize: 15, mr: 1.2 }}
                                />
                                <AautiText
                                  title={isDaily ? "Daily" : "Weekly"}
                                  size={"semi"}
                                  className={"rscolor"}
                                />
                              </div>
                              <div className="rsicondiv2">
                                <EditCalendarOutlinedIcon
                                  sx={{ fontSize: 15, mr: 1.2 }}
                                />
                                <AautiText
                                  title={`${moment(each.startDate)
                                    .format("DD MMM YYYY")
                                    .toString()} - ${moment(each.endDate)
                                      .format("DD MMM YYYY")
                                      .toString()}`}
                                  size={"semi"}
                                />
                              </div>
                              <div className="rsicondiv2">
                                {isDaily && (
                                  <ScheduleOutlinedIcon
                                    sx={{ fontSize: 15, mr: 1.2 }}
                                  />
                                )}
                                {console.log(each, "ppppppppp")}
                                {isDaily ? (
                                  <AautiText
                                    title={formatStartandEndTime({
                                      start: each?.startDateTime,
                                      end: each?.endDateTime,
                                    })}
                                    size={"semi"}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 10,
                                    }}
                                  >
                                    {renderDays(each).map((each) => each)}
                                  </div>
                                )}
                              </div>
                              {(each?.individualDiscountPrice ||
                                each?.individualPrice) && (
                                  <div className="rsgroupDiv">
                                    <img
                                      src={IndividualIcon}
                                      alt="individual"
                                      className="rsimagestyle"
                                    />
                                    <AautiText
                                      size={"semi"}
                                      weight={"medium"}
                                      className={"rstextline"}
                                      textStyle={{
                                        textDecoration:
                                          !isEmpty(
                                            each?.individualDiscountPrice
                                          ) && "line-through",
                                      }}
                                      title={`${convertPriceWithCurrency(
                                        each?.individualPrice
                                      )}`}
                                    />
                                    <AautiText
                                      title={"(" + formattedDiscount(each) + ")"}
                                      size={"semi"}
                                      textStyle={{
                                        display:
                                          isEmpty(
                                            each?.individualDiscountPrice
                                          ) && "none",
                                      }}
                                      className={"rstextmargin"}
                                    />
                                    <AautiText
                                      size={"semi"}
                                      weight={"bold"}
                                      className={"rscolor"}
                                      textStyle={{
                                        display:
                                          isEmpty(
                                            each?.individualDiscountPrice
                                          ) && "none",
                                      }}
                                      title={`${convertPriceWithCurrency(
                                        each?.individualDiscountPrice
                                      )}`}
                                    />
                                  </div>
                                )}
                              {(each?.groupDiscountPrice ||
                                each?.groupPrice) && (
                                  <div className="rsgroupDiv">
                                    <img
                                      src={GroupIcon}
                                      alt="individual"
                                      className="rsimagestyle"
                                    />
                                    <AautiText
                                      size={"semi"}
                                      weight={"medium"}
                                      className={"rstextline"}
                                      textStyle={{
                                        textDecoration: isEmpty(
                                          each?.groupDiscountPrice
                                        )
                                          ? ""
                                          : "line-through",
                                      }}
                                      title={`${convertPriceWithCurrency(
                                        each?.groupPrice
                                      )}`}
                                    />
                                    <AautiText
                                      title={"(" + formattedDiscount(each) + ")"}
                                      size={"semi"}
                                      textStyle={{
                                        display:
                                          isEmpty(each?.groupDiscountPrice) &&
                                          "none",
                                      }}
                                      className={"rstextmargin"}
                                    />
                                    <AautiText
                                      size={"semi"}
                                      weight={"bold"}
                                      className={"rscolor"}
                                      title={`${convertPriceWithCurrency(
                                        each?.groupDiscountPrice
                                      )}`}
                                      textStyle={{
                                        display:
                                          isEmpty(each?.groupDiscountPrice) &&
                                          "none",
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              <div
                className={cartData?.[typeState]?.length > 0 && "rsmarginB2"}
              >
                <input
                  type="radio"
                  id="requestRefund"
                  name="option"
                  value="requestRefund"
                  checked={selectedOptionRefund === "requestRefund"}
                  className="rsradiocursor"
                  onChange={handleOptionChangeBatch}
                />
                <label
                  className="rscursor"
                  onChange={handleOptionChangeBatch}
                  htmlFor="requestRefund"
                >
                  Request for a refund
                </label>
              </div>
            </>
          )}
        </div>
        {selectedOptionRefund && (
          <div className="rsJoinDiv">
            <JoinButton
              title={"Cancel"}
              onClick={() => {
                setSelectedData("");
                setRejectBs(false);
                // navigate("/");
              }}
              outlined
              buttonStyle={{
                width: "100px",
                marginRight: 15,
                border: "1px solid #078dda",
                color: "#078dda",
                Padding: "2px 0px",
                height: "auto",
              }}
            />
            <JoinButton
              disabled={
                selectedOptionRefund === "otherBatches" && !selectedData
              }
              onClick={() => {
                onPressReject();
              }}
              title={"Submit"}
              buttonStyle={{
                width: "100px",
                Padding: "2px 0px",
                height: "auto",
              }}
            />
          </div>
        )}
      </div>
      <AautiDialog open={open} onClose={() => setOpen(false)}>
        <RequestRefunds
          {...{
            handleOptionChange,
            selectedOption,
            refundData,
            setRefundReason,
            refundRequestApi,
            refundReason,
            handleChange,
            details,
          }}
        />
      </AautiDialog>
    </div>
  );
};
export default ReschedulePage;
