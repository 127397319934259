import React, { useCallback, useEffect, useRef, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { GoDotFill } from "react-icons/go";
import { options } from "./feed/poll/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdFilePresent } from "react-icons/md";
import AautiDialog from "../../globalComponents/AautiDialog";
import { Document, Page } from "react-pdf";
import { FeedsToolTip } from "./feedMenu";
import AautiPdfViewer from "../../reUsableComponents/AautiPdfViewer";
import CourseImages from "../../globalComponents/CourseImages";

const AgendaCard = (props) => {
  const {
    cardDetails,
    UserRole,
    screen,
    handleClickOption,
    MenuActiveIndex,
    setMenuActiveIndex,
  } = props;
  const toolTipRef = useRef();
  const { loggedinUserId } = useSelector((state) => state.app);
  const [topicDialog, SetTopicDialog] = useState(false);
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [imagesList, setImagesLiist] = useState([]);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
        setMenuActiveIndex(null); // Close the tooltip by setting MenuActiveIndex to null
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderValidations = (each) => {
    if (UserRole.includes("serviceprovider")) {
      if (each?.serviceProviderId === loggedinUserId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleTooltipToggle = useCallback((id) => {
    setMenuActiveIndex((prev) => (prev === id ? null : id));
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
        setMenuActiveIndex(null); // Close the tooltip
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isUpdated = moment(cardDetails?.date).isSame(
    moment(cardDetails?.updatedAt)
  )
    ? false
    : true;

  return (
    <>
      <div className="agenda-card" key={cardDetails?._id}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: screen === "feed" ? "space-between" : "flex-end",
            marginBottom: "2px",
            alignItems: "center",
            width: "100%",
          }}
        >
          {screen === "feed" && (
            <AautiText
              size={"normal"}
              weight={"medium"}
              textStyle={{
                color: "#828282",
              }}
              title={"Agenda"}
            />
          )}
          <AautiText
            textAlign={"left"}
            size={"small"}
            weight={"normal"}
            textStyle={{
              color: "#828282",
            }}
            title={
              isUpdated
                ? `Updated on ${moment(cardDetails?.updatedAt).format(
                    "D MMM YYYY"
                  )},
              ${moment(cardDetails?.updatedAt).format("hh:mm A")}`
                : `Created on ${moment(cardDetails?.createdAt).format(
                    "D MMM YYYY"
                  )},
              ${moment(cardDetails?.createdAt).format("hh:mm A")}`
            }
          />
        </div>

        <div
          style={{
            width: "100%",
            border: "1px solid #E0E0E0",
            borderRadius: "10px",
          }}
        >
          <div className="agenda-card-sub">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "5px",
              }}
            >
              <AautiText
                textAlign={"left"}
                size={"normal"}
                weight={"bold"}
                textStyle={{
                  color: "#1E1E1E",
                  width: "100%",
                }}
                title={`${cardDetails?.title || cardDetails?.question}`}
              />
              {renderValidations(cardDetails) && screen !== "feed" && (
                <>
                  <FeedsToolTip
                    isOpen={MenuActiveIndex === cardDetails?._id}
                    key={cardDetails?._id}
                    id="feed-tooltip"
                    onToggle={() => handleTooltipToggle(cardDetails?._id)}
                    title={
                      <div style={{ padding: "5px" }} ref={toolTipRef}>
                        {options?.map((option) => (
                          <p
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              padding: "5px",
                              marginLeft: "5px",
                              fontSize: "14px",
                            }}
                            key={option}
                            onClick={(e) => handleClickOption(e, cardDetails)}
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    }
                  >
                    <div>
                      <HiOutlineDotsVertical
                        key={cardDetails._id.$oid}
                        size={18}
                        // onClick={() => {
                        //   setOpenMenu(!openMenu);
                        //   setMenuActiveIndex(cardDetails?._id);
                        // }}
                        style={{
                          alignSelf: "center",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </FeedsToolTip>
                </>
              )}
            </div>
          </div>
          <div className="agenda-sub-sub">
            <div
              style={{
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  {!isEmpty(cardDetails?.topics) ? (
                    cardDetails?.topics?.map((each, index) => (
                      <div
                        style={{
                          borderBottom:
                            cardDetails?.topics?.length > 1 &&
                            "1px solid #E0E0E0",
                          borderRadius: "5px",
                          padding: "10px",
                          paddingLeft: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <GoDotFill color="black" />
                          <AautiText
                            // maxLength={100}
                            textAlign={"left"}
                            size={"semi"}
                            weight={"normal"}
                            textStyle={{
                              color: "black",

                              borderRadius: "5px",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            title={each?.topicDescription || "N/A"}
                          />
                        </div>

                        <div style={{ marginTop: "5px" }}>
                          {each?.uploadedFiles?.map((item) => (
                            <button
                              className="attachment-class-name"
                              onClick={() => {
                                SetTopicDialog(true);
                                setFile(item);
                                setFileType(
                                  item.substring(item.lastIndexOf(".") + 1)
                                );
                                setImagesLiist(
                                  each?.uploadedFiles.filter((each) =>
                                    ["jpeg", "jpg", "png"].includes(
                                      each.substring(each.lastIndexOf(".") + 1)
                                    )
                                  )
                                );
                              }}
                            >
                              <MdFilePresent size={15} />

                              <AautiText
                                textAlign={"left"}
                                size={"semi"}
                                weight={"normal"}
                                textStyle={{
                                  color: "#1E1E1E",
                                  marginLeft: "2px",
                                }}
                                title={`${item?.substring(
                                  item?.lastIndexOf("/") + 1
                                )}`}
                              />
                            </button>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <AautiText
                        textAlign={"left"}
                        size={"medium"}
                        weight={"bold"}
                        textStyle={{
                          color: "#1E1E1E",
                          width: "100%",
                        }}
                        title={`No Topics`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AautiDialog
        onClose={(e, reason) =>
          reason === "backdropClick" || reason === "escapeKeyDown"
            ? null
            : SetTopicDialog(false)
        }
        open={topicDialog}
        dialogTitle={"Files"}
      >
        <div>
          {fileType === "png" ||
          fileType === "jpeg" ||
          fileType === "jpg" ||
          fileType === "jfif" ? (
            <CourseImages {...{ imagesList, showIcon: false }} />
          ) : fileType === "pdf" ||
            fileType === "doc" ||
            fileType === "docx" ? (
            <div>
              <AautiPdfViewer pdfUrl={file} />
            </div>
          ) : (
            <video
              src={file}
              alt={fileType}
              autoPlay
              controls
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          )}
        </div>
      </AautiDialog>
    </>
  );
};

export default AgendaCard;
