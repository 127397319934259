import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";
import { checkNull } from "../../../utils/Helpers";
import { getCourseAdhocEvents } from "../courseDetails/Services";
import { AautiStrings } from "../../globalComponents/AautiStrings";

export function deleteCourseAdhoc(id, onSuccess, onFailure) {
  ApiRequest({
    method: ApiMethods.DELETE,
    url: "mySchedule/deleteMySchedule?",
    params: { id },
  }).then(
    (response) => {
      onSuccess && onSuccess(response.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

export const useCourseAdhocCont = () => {
  const [courseList, setCourseAdhoc] = useState(null);
  const [sheetData, setSheetData] = useState({ open: false, batches: [] });
  const { loggedinUserId } = useSelector((state) => state.app);
  const [offSet, setOffset] = useState(0);
  const [hasData, setHasData] = useState(true);
  const count = AautiStrings?.count;
  const [loadingData, setLoadingData] = useState(true);

  const getAdhocs = (offsetKey = offSet) => {
    let params = {
      userId: loggedinUserId,
      type: "course adhoc",
      offset: offsetKey,
      count: count,
    };
    getCourseAdhocEvents(
      params,
      (response) => {
        if (response?.status == "Success") {
          if (offsetKey == 0) {
            if (checkNull(response?.result)) {
              setCourseAdhoc([]);
              setHasData(false);
            } else if (response?.result?.length < count) {
              setCourseAdhoc(response?.result);
              setHasData(false);
            } else {
              setCourseAdhoc(response?.result);
              setHasData(true);
              setOffset(offsetKey + 1);
            }
          } else {
            if (checkNull(response?.result)) {
              setHasData(false);
            } else if (response?.result?.length < count) {
              setCourseAdhoc([...courseList, ...response?.result]);
              setHasData(false);
            } else {
              setCourseAdhoc([...courseList, ...response?.result]);
              setHasData(true);
              setOffset(offsetKey + 1);
            }
          }
        }
        setLoadingData(false);
      },
      (error) => {
        setCourseAdhoc([]);
        setLoadingData(false);
      }
    );
  };

  useEffect(() => {
    getAdhocs();
  }, []);

  const onPressDelete = (id) => {
    deleteCourseAdhoc(id, onDeleteSucess, (e) => {});
  };

  const onDeleteSucess = () => {
    // setLoadingData(true)
    setOffset(0);
    getAdhocs(0);
  };

  return {
    courseList,
    onPressDelete,
    hasData,
    getAdhocs,
    setSheetData,
    loadingData,
  };
};
