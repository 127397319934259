import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import gigImageIcon from "../../../assets/images/mygigs.svg";
const GigImage = () => {
  return (
    <Tooltip title={"Gig"}>
      <IconButton
        sx={{
          mb: -0.5,
          p: 0.8,
        }}
      >
        <img
          src={gigImageIcon}
          color="#a55206"
          alt="gigIcon"
          style={{
            height: "18px",
            width: "18px",
            zIndex: 100,
            color: "#a55206",
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default GigImage;
