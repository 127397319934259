import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeletionAlert from "../../assets/images/DeletionAlert.png";
import { deleteUser } from "./service";
import { showToast } from "../globalComponents/Toast";
import { logoutFunction } from "../CommonFunctions";
import { updateMembersList } from "../../redux/reducer/appReducer";

const ConfirmationDialog = ({
  dialogType,
  handleCloseConfirmationDialog,
  mobileDevices,
  selectedValue,
  setLoadingVerification,
  resetDeactivationData,
  loggedinUserId,
  selectedUser,
  customReason,
  selectedReasons,
  daysOptions,
  customDate,
  reactivateDate,
  setDeleteClicked,
  navigation,
  deactivationDays,
  setSelectedUser,
  userDetails,
  yourMembers,
  dispatch,
}) => (
  <Dialog
    open={dialogType == "finalConfirmation"}
    onClose={handleCloseConfirmationDialog}
    aria-labelledby="confirm-dialog-title"
    maxWidth="xs"
    sx={{
      "& .MuiDialog-paper": {
        width: mobileDevices ? "90%" : "50%",
        maxWidth: mobileDevices ? "90%" : "320px",
        maxHeight: mobileDevices ? "90%" : 435,
        padding: mobileDevices ? "20px" : "0px",
        borderRadius: mobileDevices ? "10px" : "8px",
      },
    }}
  >
    <DialogContent
      sx={{
        padding: mobileDevices ? "10px 15px" : "5px 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={DeletionAlert}
        alt="Confirm"
        style={{
          height: mobileDevices ? "120px" : "200px",
          width: mobileDevices ? "120px" : "200px",
          alignSelf: "center",
        }}
      />
    </DialogContent>
    <DialogTitle
      id="confirm-dialog-title"
      sx={{
        fontSize: mobileDevices ? "16px" : "18px",
        textAlign: "center",
        padding: mobileDevices ? "10px 15px" : "5px 10px",
      }}
    >
      {selectedValue === "deActivate"
        ? "Are you sure you want to deactivate account?"
        : "Are you sure you want to delete account?"}
    </DialogTitle>
    <DialogActions
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: mobileDevices ? "center" : "flex-end",
        alignItems: "center",
        gap: mobileDevices ? "10px" : "5px",
        padding: mobileDevices ? "10px" : "10px 48px 16px 0px;",
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={handleCloseConfirmationDialog}
        sx={{
          width: mobileDevices ? "100%" : "auto",
          marginRight: !mobileDevices && "10px",
          textTransform: "unset",
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          handleCloseConfirmationDialog();
          setLoadingVerification(true);
          let obj = {
            reasons: selectedReasons,
            reasonText: customReason,
            userId: selectedUser?._id ?? loggedinUserId,
            parentId: loggedinUserId,
            status: true,
            type: selectedValue === "deActivate" ? "deActivate" : "delete",
          };
          let finalObj =
            selectedValue === "deActivate"
              ? {
                  ...obj,
                  reactivateDate:
                    deactivationDays === daysOptions[3].value
                      ? customDate.toISOString()
                      : reactivateDate.toISOString(),
                }
              : obj;
          setDeleteClicked(true);
          deleteUser(
            finalObj,
            (response) => {
              setLoadingVerification(false);
              showToast("success", response.message);
              if (selectedUser._id === finalObj.userId) {
                setSelectedUser(userDetails);
              }

              if (selectedUser?._id === loggedinUserId) {
                navigation("/");
                logoutFunction();
              }
              dispatch(
                updateMembersList(
                  yourMembers.filter(
                    (member) => member._id !== selectedUser._id
                  )
                )
              );
            },
            (error) => {
              showToast("error", "An error occurred. Please try again.");
              setDeleteClicked(false);
              setLoadingVerification(false);
            }
          );
        }}
        sx={{
          width: mobileDevices ? "100%" : "auto",
          textTransform: "unset",
          color: "white",
          backgroundColor: "#3083EF",
        }}
      >
        {selectedValue === "deActivate" ? "Deactivate" : "Delete"}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
