import React, { useEffect, useState } from "react";
import AautiTextInput from "./AautiTextInput";
import { useSelector } from "react-redux";
import JoinButton from "./JoinButton";
import AautiText from "./AautiText";
import { MarginOutlined } from "@mui/icons-material";
import { AuthService } from "../../service";
import { CommonFunctions } from "../CommonFunctions";

const TrasactionCountry = (props) => {
  const { countriesArray, loggedinUserId, countryObject } = useSelector(
    (state) => state.app
  );

  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(countryObject);
  useEffect(() => {
    
    setSelectedCountry(countryObject);
    setSearch(countryObject?.name);
  }, [countryObject]);

  const relatedToSearch = countriesArray?.filter((each, index) => {
    return each?.name.toLocaleLowerCase().includes(search?.toLocaleLowerCase());
  });

  const { fetchUserDetails } = CommonFunctions();

  const saveCountry = () => {
    AuthService.saveuserCountry(
      {
        _id: loggedinUserId,
        transactionCountry: selectedCountry?.name,
        transactionCurrencyCode: selectedCountry?.countryCurrency,
        transactionCountrySymbol: selectedCountry?.countryCurrencySymbol,
      },
      (res) => {
        if (res?.status == "Success") {
          fetchUserDetails(loggedinUserId);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ width: "100%", justifyContent: "center" }}>
        <AautiTextInput
          type="search"
          style={{ width: "100%" }}
          text="Select Transaction Country"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onClearSearch={() => {
            setSearch("");
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 310,
            overflow: "scroll",

            marginTop: 5,
            padding: "0px 5px 0px 5px",
          }}
        >
          {relatedToSearch?.map((each, index) => {
            return (
              <div
                onClick={() => {
                  setSearch(each?.name);
                  setSelectedCountry(each);
                }}
                style={{
                  padding: "5px 2px 5px 2px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "98%",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              >
                <AautiText
                  textStyle={{
                    color:
                      selectedCountry?.name == each?.name ? "#0b8dda" : "#000",
                  }}
                  title={each?.name}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AautiText
                    textStyle={{
                      color:
                        selectedCountry?.name == each?.name
                          ? "#0b8dda"
                          : "#000",
                    }}
                    title={each?.countryCurrency}
                  />
                  <AautiText
                    title={" - "}
                    textStyle={{
                      marginRight: 5,
                      marginLeft: 5,

                      color:
                        selectedCountry?.name == each?.name
                          ? "#0b8dda"
                          : "#000",
                    }}
                  />
                  <AautiText
                    title={each?.countryCurrencySymbol}
                    textStyle={{
                      fontFamily: "sans-serif",
                      // marginTop: "5px",
                      color:
                        selectedCountry?.name == each?.name
                          ? "#0b8dda"
                          : "#000",
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {selectedCountry && (
          <JoinButton
            width="100%"
            title={"Submit"}
            textStyle={{ justifyContent: "center" }}
            onClick={() => saveCountry()}
          />
        )}
      </div>
    </div>
  );
};

export default TrasactionCountry;
