export const termsnconditions = [
  {
    id: 1,
    title: "Accounts",
    para: "You need an account for most activities on our platform, including to purchase and access content or to submit content for publication. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address.",
  },
  {
    id: 2,
    title: "Content Enrollment and Lifetime Access",
    para: "Under our Instructor Terms, when instructors publish content on Aauti, they grant Aauti a license to offer a license to the content to students. This means that we have the right to sublicense the content to enrolled students.",
  },
  {
    id: 3,
    title: "Payments, Credits and Refunds",
    para: "You need an account for most activities on our platform, including to purchase and access content or to submit content for publication. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address.",
    subPoints: [
      {
        id: 3.1,
        title: "Pricing",
        para: "The prices of content on Aauti are determined based on the terms of the Instructor Terms and our Promotions Policy. In some instances, the price of the content offered on the Aauti website may not be exactly the same as the price offered on our mobile or TV applications, due to mobile platform providers’ pricing systems and their policies around implementing sales and promotions.",
      },
      {
        id: 3.2,
        title: "Payments",
        para: "You agree to pay the fees for content that you purchase, and you authorize us to charge your debit or credit card or process other means of payment (such as Boleto, SEPA, direct debit, or mobile wallet) for those fees.",
      },
      {
        id: 3.3,
        title: "Refunds and Refund Credits",
        para: "If the content you purchased is not what you were expecting, you can request, within 30 days of your purchase of the content, that Aauti apply a refund to your account",
      },
      {
        id: 3.4,
        title: "Gift and Promotional Codes",
        para: "Aauti or our partners may offer gift and promotional codes to students. Certain codes may be redeemed for gift or promotional credits applied to your Aauti account, which then may be used to purchase eligible content on our platform, subject to the terms included with your codes.",
      },
    ],
  },
  {
    id: 4,
    title: "Content and Behaviour Rules",
    para: "You may not access or use the Services or create an account for unlawful purposes. Your use of the Services and behavior on our platform must comply with applicable local or national laws or regulations of your country. You are solely responsible for the knowledge of and compliance with such laws and regulations that are applicable to you.",
  },
  {
    id: 5,
    title: "Accounts",
    para: "The content you post as a student or instructor (including courses) remains yours. By posting courses and other content, you allow Aauti to reuse and share it but you do not lose any ownership rights you may have over your content. If you are an instructor, be sure to understand the content licensing terms that are detailed in the Instructor Terms.",
  },
];

export const CartStrings = {
  Booked: "BOOKED",
  ClOSED: "CLOSED",
  IndividualDaily: "Individual Daily",
  GroupDaily: "Group Daily",
  PurchaseFor: "Purchase For:",
  BoughtFor: "Bought For:",
  AlreadyPurchased: "Already purchased",
  SomethingWentWrong: "Something went wrong",
  EnterValidCouponCode: "Enter valid Coupon Code",
  RemovedFromCart: "Removed from cart",
  AddMoreCourses: "Add more courses",
  YourCartIsEmpty: `Your Cart is Empty`,
  KeepExploring: "Keep Exploring",
  TermsAndConditions: "Terms and conditions",
  EnterCode: "ENTER CODE",
  Apply: "Apply",
  BestOffersForYou: "BEST OFFERS FOR YOU",
  NoActiveCouponsFound: "No Coupons Available",
  BillingAddress: "Billing Address",
  ChangeAddress: "Change Address",
  AddAddress: "Add address",
  OrderSummary: "Order Summary",
  ServiceCharge: "Service Charge : ",
  EstimatedTaxToBeCollected: "Estimated Tax to be collected",
  Total: "Total :",
  ByCompletingYourPurchaseAndYouAgreeToThese:
    "By completing your purchase, you agree to these ",
  TermsOfService: "Terms of Service",
  ApplyCouponCode: "Apply Coupon Code",
  Remove: "Remove",
  KeepExploringToFindACourse: "Keep exploring to find a course!",
  SignInToYourAccount: "Sign in to your account",
  SignUpNow: "Sign up now",
  SelectBatch: "Select Batch",
  30: "(30)",
  NoneRegisteredYet: `| None registered yet!`,
  CourseBatches: "Course Batches",
  SimilarCourses: "Similar Courses",
  AddCoupon: "Add Coupon",
  Select: "Select",
  Title: "Title",
  GenerateUsingAI: "Generate Using AI",
  DiscountPercentage: "Discount Percentage",
  UserRedemptionLimit: "User Redemption Limit",
  MaxRedemptions: "Max Redemptions",
  MaximumDiscountValue: "Maximum Discount Value",
  MinimumOrderValue: "Minimum order value",
  SearchCoupons: "Search Coupons",
  Cancel: "Cancel",
  Submit: "Submit",
  EditCoupon: "Edit Coupon",
  Active: "Active",
  Inactive: "Inactive",
  AllCategories: "All Categories",
  Categories: "Categories",
  CouponsAndDiscounts: "Coupons and Discounts",
  Expired: "EXPIRED",
  Delete: "Delete",
  WelcomeToAauti: "Welcome to Aauti",
  NoCouponsAvailable: "No Coupons Available",
  StartCreatingCoupons: "Start Creating Coupons",
  ConfirmationAlert: "Confirmation Alert",
  YourChangesWillNotBeUpdated: "Your Changes will not be Updated",
  Disagree: "Disagree",
  Agree: "Agree",
  AddMoreCourses: "Add More Items",
  feeApply: "(Other fee may apply)",
  atLeastToastMsg: "At least one member should be exist",
};
