import React from "react";
import Layout from "../Layout";
import { idProofsList, VerifyUserStrings } from "../VerifyUserStrings";
import "../index.scss";
import AautiDropDown from "../../../components/globalComponents/AautiDropDown";
import { useSelector } from "react-redux";
import AautiTextInput from "../../../components/globalComponents/AautiTextInput";
import HelperText from "../../../components/globalComponents/HelperText";
import AautiUpload from "../../../components/globalComponents/AautiUpload";
import AautiText from "../../../components/globalComponents/AautiText";
import UploadIcon from "../assets/Upload Icon.png";
import CloseButton from "../../../components/globalComponents/CloseButton";
import CourseImage from "../../../components/globalComponents/CourseImage";

const IdVerification = ({
  handleChangeVerification,
  verificationDetails,
  handleUploadeImages,
}) => {
  const { countriesArray } = useSelector((state) => state.app);
  const componentsData = [
    {
      component: AautiDropDown,
      props: {
        important: true,
        value: verificationDetails?.country,
        data: countriesArray?.map((each) => each.name),
        style: { width: "100%" },
        title: "Country",
        onChange: (e) => handleChangeVerification(e.target.value, "country"),
      },
    },
    {
      component: AautiDropDown,
      props: {
        important: true,
        value: verificationDetails?.idType,
        data: idProofsList,
        style: { width: "100%" },
        title: "ID Type",
        onChange: (e) => handleChangeVerification(e.target.value, "idType"),
      },
    },
    {
      component: AautiTextInput,
      props: {
        mt: "0px",
        value: verificationDetails?.idNumber,
        style: { width: "100%" },
        fullWidth: true,
        text: `${verificationDetails?.idType} Number`,
        placeholder: `${verificationDetails?.idType} Number`,
        maxLength: 20,
        important: true,
        onChange: (e) => handleChangeVerification(e.target.value, "idNumber"),
      },
    },
  ];
  return (
    <Layout title={VerifyUserStrings?.IdVerify}>
      <div className="verify-flex-items margin_top_10">
        {componentsData?.map(({ component: Component, props }, index) => (
          <div className="legal-inputs" key={index}>
            <Component {...props} />
          </div>
        ))}
      </div>
      <HelperText
        showDot={false}
        title={VerifyUserStrings.IdVerifyHelper}
        textStyle={{ textAlign: "center" }}
        containerStyles={{ alignItems: "center", justifyContent: "center" }}
      />

      <div className="uploads_container">
        <div className="upload_sub_container">
          <AautiUpload
            id="upload_front_side"
            important={true}
            accept="image/jpeg, image/png"
            onChange={(e) => {
              handleUploadeImages(e, "idFrontSide");
            }}
            style={{ border: `1px dashed #5C5B57` }}
            customLabel={
              <div className="custom_upload_verify">
                <img src={UploadIcon} alt="upload" />
                <AautiText
                  size={"small"}
                  textStyle={{ color: "#5C5B57", marginTop: 8 }}
                  title={VerifyUserStrings.idFrontSide}
                />
              </div>
            }
          />
          <HelperText
            showDot={false}
            title={VerifyUserStrings.IDVerifyHelperUpload}
          />
          {verificationDetails?.idFrontSide && (
            <div className="preview_upload_img">
              <CloseButton
                title="remove"
                className="remove_uploaded_img"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChangeVerification("", "idFrontSide");
                }}
              />
              <CourseImage
                style={{ zIndex: 109, borderRadius: "10px !important" }}
                height="108px"
                imgUrl={verificationDetails?.idFrontSide}
                showBackdrop
              />
            </div>
          )}
        </div>
        <div className="upload_sub_container">
          <AautiUpload
            id="upload_back_side"
            important={true}
            accept="image/jpeg, image/png"
            onChange={(e) => {
              handleUploadeImages(e, "idBackSide");
            }}
            style={{ border: `1px dashed #5C5B57` }}
            customLabel={
              <div className="custom_upload_verify">
                <img src={UploadIcon} alt="upload" />
                <AautiText
                  size={"small"}
                  textStyle={{ color: "#5C5B57", marginTop: 8 }}
                  title={VerifyUserStrings.idBackSide}
                />
              </div>
            }
          />
          <HelperText
            showDot={false}
            title={VerifyUserStrings.IDVerifyHelperUpload}
          />
          {verificationDetails?.idBackSide && (
            <div className="preview_upload_img">
              <CloseButton
                title="remove"
                className="remove_uploaded_img"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChangeVerification("", "idBackSide");
                }}
              />
              <CourseImage
                style={{ zIndex: 109, borderRadius: "10px !important" }}
                height="108px"
                imgUrl={verificationDetails?.idBackSide}
                showBackdrop
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default IdVerification;
