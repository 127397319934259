import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getTrusteesListByUserId } from "../TrusteeServices";
import { isEmpty } from "lodash";
import { emailRegex } from "../../CommonFunctions";
import { isValidPhoneNumber } from "libphonenumber-js/max";

export const useTrusteStates = () => {
  const [search, setSearch] = useState("");
  const [trusteesList, setTrusteesList] = useState([]);
  const scheduleRef = useRef(null);
  const { loggedinUserId, countryObject } = useSelector((state) => state.app);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [apiLoading, setApiLoading] = useState(false);
  const [openAddTrustee, setOpenAddTrustee] = useState(false);
  const [country, setCountry] = useState(countryObject);
  const [trusteeBase, setTrusteeBase] = useState("");
  const [invitesList, setInvitesList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const isNumber = !isNaN(trusteeBase) && trusteeBase;

  const getTrustyList = () => {
    const userId = loggedinUserId;
    setApiLoading(true);
    getTrusteesListByUserId(
      userId,
      (response) => {
        setApiLoading(false);
        if (response?.result) {
          setTrusteesList(response.result);
        } else {
          setTrusteesList([]);
        }
      },
      (error) => {
        setApiLoading(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getTrustyList();
  }, [loggedinUserId, refresh]);

  const addBtnValidation = () => {
    if (isNumber) {
      return isValidPhoneNumber(`${country?.phoneCode + trusteeBase}`);
    } else {
      return emailRegex?.test(trusteeBase);
    }
  };

  const filteredTrusteesList = useCallback(() => {
    if (isEmpty(search)) return trusteesList;
    return trusteesList.filter((trustee) =>
      trustee?.displayName?.toLowerCase().includes(search?.toLowerCase())
    );
  }, [search, trusteesList])();

  return {
    search,
    setSearch,
    scheduleRef,
    mobileDevices,
    trusteesList: filteredTrusteesList,
    apiLoading,
    openAddTrustee,
    trusteeBase,
    setTrusteeBase,
    setOpenAddTrustee,
    country,
    setCountry,
    isNumber,
    addBtnValidation,
    invitesList,
    setInvitesList,
    setRefresh,
    loggedinUserId,
    openBackdrop,
    setOpenBackdrop,
  };
};
