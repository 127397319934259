import { Box, Button } from "@mui/material";
import { GetItOnAppleStoreButton, GetItOnPlayStoreButton } from "../container";
import "./styles.scss";

export default function ReadyToStart() {
  return (
    <section id="READT_TO_START">
      <Box className="readt_to_start_container">
        <p className="readt_to_start_label">
          Are you ready to start your{" "}
          <span className="readt_to_start_span">Online Course?</span>
        </p>
      </Box>
      <Box className="readt_to_start_store_container">
        <Button
          className="readt_to_start_store_button"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.aauti.android&pcampaignid=web_share",
              "_blank"
            )
          }
        >
          <GetItOnPlayStoreButton />
        </Button>
        <Button
          className="readt_to_start_store_button"
          onClick={() =>
            window.open(
              "https://apps.apple.com/in/app/aauti/id1628755066",
              "_blank"
            )
          }
        >
          <GetItOnAppleStoreButton />
        </Button>
      </Box>
    </section>
  );
}
