import { Box, Button } from "@mui/material"

const ButtonsComponent = (props) => {

    const { onClickButton1, onClickButton2, btnOneText, btnTwoText, btnTwodisabled, width, btnOnedisabled } = props

    return (
        <Box sx={{width:'100%',marginTop: 1, display: "flex", flexDirection:'row',justifyContent: btnTwoText ? "space-between" : "center" }}>
            <Button
                variant={btnOneText === 'Cancel' ? "outlined" : "contained"}
                size="small"
                sx={{ m: 1,flex:btnTwoText&&1, width: width?width:'20vw'}}
                onClick={() => onClickButton1()}
                disabled={btnOnedisabled}
            >
                {btnOneText}
            </Button>

            {btnTwoText && <Button disabled={btnTwodisabled} size="small" variant="contained" sx={{ m: 1, flex: 1 }} onClick={() => onClickButton2()}>
                {btnTwoText}
            </Button>}

        </Box>
    )
}

export default ButtonsComponent;