import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { apiServices } from "../../service/apiService";
import { showToast } from "../../components/globalComponents/Toast";
import { TwoFactorAuthType } from "../../storage/constants";
// import { appStorageServices } from "../../storage";
// import { defaultCountry } from "../../components/CommonFunctions";

const prevData = localStorage.getItem("CartDataBeforeLogin");

export const defaultCountry = {
  _id: "624c1d2a28bc5658e358b969",
  name: "United States",
  timeZone: "UTC-10:00",
  countryCode: "US",
  countryCurrency: "USD",
  countryCurrencySymbol: "$",
  flag: "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/2807790_18165.jpg",
  phoneCode: "+1",
  gmtDifference: -180,
  description: "USA",
  deleted: false,
  region: "North America",
};

const initialTwoFactorAuthState = {
  qrCode: "",
  secretKey: "",
  type: "",
};

const initialState = {
  userLogin: {
    status: "idle",
    data: {},
  },
  properties: {},
  aautiLoader: false,
  loginUserId: "",
  loginUser: null,
  userDetails: null,
  FirstName: localStorage.getItem("FirstName"),
  signInPop: false,
  signupType: "sign-in",
  UserRole: localStorage.getItem("ROLE")?.includes("instituteAdmin")
    ? "instituteAdmin"
    : localStorage.getItem("ROLE")?.includes("serviceprovider")
    ? "serviceprovider"
    : "endUser",
  registeredRole: "",
  categories: [],
  AllCourses: [],
  isUserLoggedIn: localStorage.getItem("Logged In") === "true",
  refHeadNotification: false,
  loggedinUserId: localStorage.getItem("USER_ID"),
  instituteId: localStorage.getItem("instituteId"),
  profileData: [],
  jitsiPipMode: false,
  jitsiFullScreenMode: false,
  paymentLoading: false,
  cartCount: 0,
  roomName: "",
  buyNowData: null,
  isAdhocScreen:false,
  userLocation: {},
  editLessonPlan: null,
  editCourse: null,
  editGig: null,
  draftCourse: null,
  cloneCourse: null,
  screenName: "",
  packageDetails: null,
  countries: [],
  professions: [],
  subCategories: [],
  cartsDataBeforeLogin: JSON.parse(prevData),
  signUpScreenIndex: 0,
  sessionDetails: {},
  eventSearchKey: "",
  jwtToken: "",
  countryObject: null,
  pricingRates: {},
  membersListWithParent: [],
  isParent: false,
  membersList: [],
  selectedCategory: null,
  editPackage: null,
  countriesArray: [],
  orderData: {},
  adhocEditObject: {},
  todayEvents: null,
  toDoList: {},
  hasEvents: false,
  createAdhocObject: null,
  askClicked: false,
  askObject: {},
  chatClick: false,
  relaodCart: false,
  activeGigObject: {},
  paymentStatus: "",
  allLanguages: [],
  navigateHome: false,
  showReceipt: false,
  chatScreen: false,
  spFollowId: null,
  spWishlistItem: null,
  editOffTimeObject: null,
  profilePercentage: 0,
  prevActFilters: {},
  todoLoading: false,
  minimumAdhocSessions: 1,
  institutionDetails: {},
  twoFactorAuth: initialTwoFactorAuthState,
  trusteeMembers: [],
};

export const verifyTwoFactorAuthOtp = createAsyncThunk(
  "user/verifyTwoFactorAuthOtp",
  async (data) => {
    const res = await apiServices.verifyTFAOtp(data);
    return res.data;
  }
);

export const toggleTwoFactorAuth = createAsyncThunk(
  "user/toggleTwoFactorAuth",
  async (data) => {
    const res = await apiServices.toggleTwoFactorAuth(data);
    return res.data;
  }
);

export const selectLoginUserDetails = (state) => state.app.userDetails;
const selectTwoFactorAuthObj = (state) => state.app.twoFactorAuth;

export const twoFactorAuthBsSelector = createSelector(
  [selectLoginUserDetails, selectTwoFactorAuthObj],
  (user, twoFactorAuth) => ({ user, ...twoFactorAuth })
);

const appReducerSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCartCount: (state, action) => {
      state.cartCount = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    editPackageObject: (state, action) => {
      state.editPackage = action.payload;
    },
    setEventsSearchKey: (state, action) => {
      state.eventSearchKey = action.payload;
    },

    updateMembersList(state, action) {
      state.membersList = action.payload;
      if (action.payload.length > 0) {
        state.membersListWithParent = [
          // {
          //   _id: state?.loggedinUserId,
          //   userName: "",
          //   displayName: "My Self",
          //   relation: "",
          //   email: "",
          //   isIndependent: true,
          //   profileImage: state?.userDetails?.profileImage,
          // },
          ...action.payload,
        ];
      } else {
        state.membersListWithParent = [];
      }
      state.isParent = action.payload?.length > 0;
    },
    setJwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    setSessionDetails: (state, action) => {
      state.sessionDetails = action.payload;
    },
    setBuyNowdata: (state, action) => {
      state.buyNowData = action.payload;
    },
    setAdhocScreen: (state, action) => {
      state.isAdhocScreen = action.payload;
    },
    editLessonPlanObject: (state, action) => {
      state.editLessonPlan = action.payload;
    },
    setDraftCourseObject: (state, action) => {
      state.draftCourse = action?.payload;
    },
    setAdhocEditObject: (state, action) => {
      state.adhocEditObject = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    setAautiLoader: (state, action) => {
      state.aautiLoader = action.payload;
    },
    chnageInstituteId: (state, action) => {
      state.instituteId = action.payload;
    },
    setTrusteeMembers: (state, action) => {
      state.trusteeMembers = action.payload;
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setPrevActiveFilters: (state, action) => {
      state.prevActFilters = action.payload;
    },
    setLoggedInUserId: (state, action) => {
      state.loggedinUserId = action.payload;
    },
    setUserdetails: (state, action) => {
      const userDetails = action.payload;
      state.userDetails = userDetails;
      if (userDetails?.is2FactorAuthExpired) {
        state.twoFactorAuth = {
          ...state.twoFactorAuth,
          type: TwoFactorAuthType.verify,
        };
        delete userDetails.is2FactorAuthExpired;
      }
    },
    toggleSignInPop: (state, action) => {
      state.signInPop = action.payload;
    },
    setRegisteredType: (state, action) => {
      state.registeredRole = action.payload;
    },
    setSignUpType: (state, action) => {
      state.signupType = action.payload;
    },
    AllCategories: (state, action) => {
      state.categories = action.payload;
    },
    AllCourses: (state, action) => {
      state.AllCourses = action.payload;
    },
    loginUser: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },
    setTodosLoading: (state, action) => {
      state.todoLoading = action.payload;
    },
    setAskClicked: (state, action) => {
      state.askClicked = action.payload;
    },
    setAskObject: (state, action) => {
      state.askObject = action.payload;
    },
    setChatClick: (state, action) => {
      state.chatClick = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    ChangeUserRole: (state, action) => {
      state.UserRole = action.payload;
    },
    setJitsiPipMode: (state, action) => {
      state.jitsiPipMode = action.payload;
    },
    setMinimumAdhocSessions: (state, action) => {
      state.minimumAdhocSessions = action.payload;
    },
    setJitsiFullScreenMode: (state, action) => {
      state.jitsiFullScreenMode = action.payload;
    },
    setNavigateHome: (state, action) => {
      state.navigateHome = action.payload;
    },
    setShowReceipt: (state, action) => {
      state.showReceipt = action.payload;
    },
    setPaymentLoading: (state, action) => {
      state.paymentLoading = action.payload;
    },
    setRoomName: (state, action) => {
      state.roomName = action.payload;
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    editCourseObject: (state, action) => {
      state.editCourse = action.payload;
    },
    setSpFollowId: (state, action) => {
      state.spFollowId = action.payload;
    },
    setSpWishlistItem: (state, action) => {
      state.spWishlistItem = action.payload;
    },
    setProfilePercentage: (state, action) => {
      state.profilePercentage = action.payload;
    },

    editGigObject: (state, action) => {
      state.editGig = action.payload;
    },
    CloneCourseObj: (state, action) => {
      state.cloneCourse = action.payload;
    },
    setScreenName: (state, action) => {
      state.screenName = action.payload;
    },
    setProfessions: (state, action) => {
      state.professions = action.payload;
    },
    setSubCategories: (state, action) => {
      state.subCategories = action.payload;
    },
    setCartsDataBeforeLogin: (state, action) => {
      state.cartsDataBeforeLogin = action.payload;
    },
    setSignupScreenIndex: (state, action) => {
      state.signUpScreenIndex = action.payload;
    },
    setRefHeadNotifications: (state, action) => {
      state.refHeadNotification = action.payload;
    },
    setReloadCart: (state, action) => {
      state.relaodCart = action.payload;
    },
    setCountryObject: (state, action) => {
      // if (action?.payload) {
      state.countryObject = action.payload;
      // } else {
      //   state.countryObject = defaultCountry;
      // }
    },
    setPricingRates: (state, action) => {
      state.pricingRates = action.payload;
    },
    setCountriesArray: (state, action) => {
      state.countriesArray = action.payload;
    },
    setTodayEvents: (state, action) => {
      state.todayEvents = action.payload;
    },
    setToDoList: (state, action) => {
      state.toDoList = action.payload;
    },
    setHasEvents: (state, action) => {
      state.hasEvents = action.payload;
    },
    // setShowUpcomingClass: (state, action) => {
    //   state.showUpcomingClass = action.payload;
    // },
    setCreateAdhocObject: (state, action) => {
      state.createAdhocObject = action.payload;
    },
    setActiveGigObject: (state, action) => {
      state.activeGigObject = action.payload;
    },
    setAllLanguages: (state, action) => {
      state.allLanguages = action.payload;
    },
    setChatScreen: (state, action) => {
      state.chatScreen = action.payload;
    },
    setEditOffTimeObject: (state, action) => {
      state.editOffTimeObject = action?.payload;
    },
    setAautiProperties: (state, action) => {
      state.properties = action.payload;
    },
    openTwoFactorAuthBs(state, action) {
      state.twoFactorAuth.type = action.payload;
    },
    setInstitutionDetails: (state, action) => {
      state.institutionDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleTwoFactorAuth.fulfilled, (state, action) => {
        const result = action.payload.result;
        state.userDetails.twoFactorAuthEnabled =
          result?.twoFactorAuthEnabled ??
          state.userDetails?.twoFactorAuthEnabled ??
          false;
        if (
          action.payload.status.toLowerCase() === "success" &&
          result?.qrCode
        ) {
          state.twoFactorAuth = {
            ...result,
            type: TwoFactorAuthType.verify,
          };
        } else showToast({ text: action.payload.message });
      })
      .addCase(toggleTwoFactorAuth.rejected, (state, action) => {
        state.userDetails.twoFactorAuthEnabled =
          state.userDetails?.twoFactorAuthEnabled ?? false;
        showToast("info", action.payload.message);
      })
      .addCase(verifyTwoFactorAuthOtp.fulfilled, (state, action) => {
        const result = action.payload.result;
        state.userDetails.twoFactorAuthEnabled =
          result?.twoFactorAuthEnabled ??
          state.userDetails?.twoFactorAuthEnabled ??
          false;
        if (action.payload.status.toLowerCase() === "success") {
          state.twoFactorAuth = {
            ...state.twoFactorAuth,
            ...initialTwoFactorAuthState,
          };
        }
        showToast("info", action.payload.message);
        if (state.userDetails?.is2FactorAuthExpired) {
          state.userDetails.is2FactorAuthExpired = false;
        }
      });
  },
});

export const {
  toggleStatus,
  setEventsSearchKey,
  updateMembersList,
  setJwtToken,
  setSessionDetails,
  setPricingRates,
  setCountryObject,
  setCartsDataBeforeLogin,
  setProfessions,
  setSubCategories,
  editLessonPlanObject,
  setAautiLoader,
  setLoggedInUserId,
  setUserdetails,
  loginUser,
  toggleSignInPop,
  setRegisteredType,
  setSignUpType,
  AllCategories,
  AllCourses,
  setPaymentStatus,
  setProfileData,
  ChangeUserRole,
  setJitsiPipMode,
  setJitsiFullScreenMode,
  setPaymentLoading,
  setCartCount,
  setRoomName,
  setCreateAdhocObject,
  setBuyNowdata,
  setAdhocScreen,
  setUserLocation,
  editCourseObject,
  editGigObject,
  setScreenName,
  storePackageDetails,
  setSelectedCategory,
  setSignupScreenIndex,
  setPrevActiveFilters,
  editPackageObject,
  chnageInstituteId,
  setCountriesArray,
  setAdhocEditObject,
  setOrderData,
  setDraftCourseObject,
  CloneCourseObj,
  setTodayEvents,
  setMinimumAdhocSessions,
  setHasEvents,
  // setShowUpcomingClass,
  setChatClick,
  setAskObject,
  setShowBackSignUp,
  setAskClicked,
  setReloadCart,
  setActiveGigObject,
  setNavigateHome,
  setShowReceipt,
  setAllLanguages,
  setChatScreen,
  setSpFollowId,
  setSpWishlistItem,
  setEditOffTimeObject,
  updateFilterMetaData,
  setProfilePercentage,
  setTodosLoading,
  setToDoList,
  setAautiProperties,
  openTwoFactorAuthBs,
  setInstitutionDetails,
  setRefHeadNotifications,
  setTrusteeMembers,
} = appReducerSlice.actions;
export default appReducerSlice.reducer;
