import {
  ApiMethods,
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "./api-constants";
import { ApiRequest } from "./api-request";
import { baseUrl } from "./baseUrl";
const getApi = async (url, param) => {
  const api = `${baseUrl + url}`;
  try {
    const response = await fetch(api, {
      method: "GET",
      // Authorization: ''
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("error---", err);
  }
};
const postApi = async (url, data) => {
  const api = `${baseUrl + url}`;
  try {
    const response = await fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
        // Authorization: ''
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Response is not in JSON format");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
const updateApi = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
        // Authorization: ''
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (err) {
    console.log("error---", err);
  }
};
const deleteApi = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
        // Authorization: ''
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (err) {
    console.log("error---", err);
  }
};

const uploadImage = async (data) => {
  const formData = new FormData();
  formData.append("file", data);
  formData.append("platform", UploadBaseUrl);
  const url = uploadUrl + ApiPaths.FILE_UPLOAD;
  try {
    let res = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let image = await res.json();
    return image.result;
  } catch (error) {
    throw new Error("Unable to upload image. Please try again");
  }
};

const uploadMultipleFiles = async (formData) => {
  const url = uploadUrl + ApiPaths.FILE_UPLOAD_MULTI;
  try {
    let res = await fetch(url, {
      method: "POST",
      body: formData,
    });
    if (res.status === 200) {
      res = await res.json();
      return res;
    }
    throw new Error("Something went wrong. Please try again");
  } catch (error) {
    throw new Error("Unable to upload image. Please try again");
  }
};

function verifyTFAOtp(data) {
  return ApiRequest({
    url: ApiPaths.verifyTFAOtp,
    method: ApiMethods.POST,
    data,
  });
}

function toggleTwoFactorAuth(data) {
  return ApiRequest({
    url: ApiPaths.toggleTwoFactorAuth,
    method: ApiMethods.POST,
    data,
  });
}

export const apiServices = {
  getApi,
  postApi,
  updateApi,
  deleteApi,
  uploadImage,
  uploadMultipleFiles,
  verifyTFAOtp,
  toggleTwoFactorAuth,
};
