import React from "react";
import InfoHead from "./reused/InfoHead";
import "./index.scss";

const Layout = ({ title, children }) => {
  return (
    <div className="verify_user_layout">
      <InfoHead title={title} />
      {children}
    </div>
  );
};

export default Layout;
