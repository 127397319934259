import React, { useRef } from "react";
import AccountTabs from "../globalComponents/AccountTabs";
import "./Styles.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AautiText from "../globalComponents/AautiText";
import AautiTextInput from "../globalComponents/AautiTextInput";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SpContainer from "./Container";
import {
  checkNull,
  formatPercentage,
  pluralOrSingular,
} from "../../utils/Helpers";
import { PriceConversions } from "../CommonFunctions";
import EmptyData from "../globalComponents/EmptyData";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import AautiDialog from "../globalComponents/AautiDialog";
import EmptyAttendence from "../../assets/images/EmptyAttendence.png";
import EmptyReview from "../../assets/images/EmptyReviews.png";
import EmptyRevenue from "../../assets/images/EmptyRevenue.png";
import EmptyStudent from "../../assets/images/Emptystudents.svg";
import { DashBoardInfoConstants } from "./Constants";
import StudentProfile from "../../components/students/StudentProfile";
import { globalColors } from "../../themes/GlobalColors";
import AautiInfiniteScroll from "../features/InfiniteScroll";
import AautiSearch from "../globalComponents/AautiSearch";
import EmptyTopic from "../globalComponents/EmptyTopic";
import { HeaderStrings } from "../header/constants";
import AppContainer from "../../layouts/AppContainer";

const DashBoardInfo = ({ type, id }) => {
  const {
    onHandleTitleClick,
    activeTab,
    headerCount,
    renderData,
    expanded,
    handleChange,
    searchText,
    setSearchText,
    selectedData,
    setSelectedData,
    currentScreen,
    loading,
    displayPopUp,
    setDisplayPopUp,
    dashBoardTabTitle,
    studentId,
    setStudentId,
    open,
    setOpen,
    setExpanded,
    onClickEachItem,
    firstItem,
    selectedRole,
    onSwitchRoleTab,
    hasData,
    setOffset,
    apiCall,
    instituteAttendanceApi,
    instituteAttendanceData,
    setInstituteAttendance,
    instituteType,
    setInstituteType,
    instituteUserDetails,
    setInstituteUserDetails,
  } = SpContainer({ type, id });

  const { mobileDevices } = useSelector((state) => state.responsive);
  const { UserRole, userDetails } = useSelector((state) => state.app);
  const ref = useRef(null);
  const { dashBoardRevenueconvertToMillionBillions } = PriceConversions();
  const currencySymbol = userDetails?.transactionCountrySymbol;

  const SPTitles = ({ titles, onHandleTitleClick }) => {
    return (
      <div className="sp-titles-div">
        {titles?.map((title, index) => (
          <>
            <AautiText
              weight={"bold"}
              title={title}
              className="sp-titles-text"
              textStyle={{
                color:
                  activeTab === title
                    ? `${globalColors.AAUTI_THEME_COLOR}`
                    : "#000000",
                border: `1px solid ${
                  activeTab === title
                    ? `${globalColors.AAUTI_THEME_COLOR}`
                    : "#dedede"
                }`,
                display:
                  mobileDevices &&
                  currentScreen === "reviews-ratings" &&
                  "inline-block",
                whiteSpace:
                  mobileDevices &&
                  currentScreen === "reviews-ratings" &&
                  "nowrap",
                flex: mobileDevices && 1,
                width: !mobileDevices && "150px",
              }}
              key={index}
              onClick={() => activeTab !== title && onHandleTitleClick(title)}
              size={"normal"}
            />
          </>
        ))}
      </div>
    );
  };
  const ActiveRoleTab = ({ titles, onHandleTitleClick }) => {
    return (
      <div
        className="sp-attendance-titles"
        style={{
          width: mobileDevices ? "100%" : "30%",
        }}
      >
        {titles?.map((title, index) => (
          <AautiText
            weight={"bold"}
            title={title}
            className="sp-attendance-text"
            textStyle={{
              color: selectedRole === title ? "#ffffff" : "#000000",
              border: `1px solid ${
                selectedRole === title
                  ? `${globalColors.AAUTI_THEME_COLOR}`
                  : "#dedede"
              }`,
              backgroundColor:
                selectedRole === title
                  ? `${globalColors.AAUTI_THEME_COLOR}`
                  : "#ffffff",
              padding: mobileDevices ? "8px 10px" : "10px 15px",
            }}
            key={index}
            onClick={() => selectedRole !== title && onHandleTitleClick(title)}
            size={"normal"}
          />
        ))}
      </div>
    );
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (!name) return {};

    const nameParts = name?.split(" ");
    const initials = nameParts
      ?.slice(0, 2)
      .map((part) => part[0]?.toUpperCase())
      .join("");

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  }

  const cardHeaderImage = (each) => {
    switch (activeTab) {
      case "Courses":
        return each?.courseImage;
      case "Gigs":
        return each?.gigImage;
      case "Adhocs":
        return each?.adhocImage;
      case "My Reviews":
        return each?.adhocImage;
      default:
        break;
    }
  };

  const cardHeaderName = (each) => {
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else {
          return each?.adhocName;
        }
      case "my-revenue":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else {
          return each?.adhocName;
        }
      case "attendance-details":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else {
          return each?.adhocName;
        }
      case "reviews-ratings":
        if (activeTab === "Courses") {
          return each?.courseName;
        } else if (activeTab === "Gigs") {
          return each?.gigName;
        } else if (activeTab === "Adhocs") {
          return each?.adhocName || each?.displayName;
        } else if (activeTab === "My Reviews") {
          return each?.displayName;
        }
      default:
        break;
    }
  };
  const batchesCountData = (each) => {
    const formatPercentage = (value) => {
      return value == null ? "N/A" : value % 1 === 0 ? value : value.toFixed(2);
    };
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return `${each?.batchesCount} ${pluralOrSingular(
            each?.batchesCount,
            "Batch",
            true
          )} | ${each?.totalSubscribedCount} ${pluralOrSingular(
            each?.totalSubscribedCount,
            "Student"
          )}`;
        } else if (activeTab === "Gigs") {
          return `${
            each?.subscribedCount || each?.totalSubscribedCount
          } ${pluralOrSingular(
            each?.subscribedCount || each?.totalSubscribedCount,
            "Student"
          )}`;
        } else {
          return `${each?.totalSubscribersCount} ${pluralOrSingular(
            each?.totalSubscribersCount,
            "Student"
          )}`;
        }
      case "my-revenue":
        return (
          <span>
            Revenue :{" "}
            <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
            {dashBoardRevenueconvertToMillionBillions(each?.revenue, 2)}
          </span>
        );
      case "attendance-details":
        switch (UserRole) {
          case "serviceprovider":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each.sessionsCount
              }/${each?.totalCount}  | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }/${each?.totalCount} | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          case "endUser":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }/${each?.totalCount} | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }/${each?.totalCount} | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          default:
            break;
        }
        break;
      case "reviews-ratings":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {each?.averageRating || each?.rating ? (
              <>
                <Rating
                  sx={{ color: "#078dda" }}
                  color="#078dda"
                  name="text-feedback"
                  value={each?.averageRating || each?.rating}
                  readOnly
                  precision={0.5}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <Box sx={{ ml: 1 }}>
                  {(each?.averageRating || each?.rating)?.toFixed(1)}
                </Box>
              </>
            ) : (
              <AautiText
                title={DashBoardInfoConstants.ratingsEmpty}
                size={"small"}
              />
            )}
          </div>
        );
      default:
        break;
    }
  };

  const onClickEachCard = (each) => {
    if (mobileDevices) {
      onClickEachItem(each);
    } else if (activeTab === "My Reviews") {
      setOpen(true);
      onClickEachItem(each);
    } else {
      // Check if the selected data differs from the current item
      const isDifferent =
        (selectedData?._id ||
          selectedData?.courseId ||
          selectedData?.gigId ||
          selectedData?.adhocId ||
          selectedData?.schedulerId) !==
        (each._id ||
          each?.courseId ||
          each?.gigId ||
          each?.adhocId ||
          each?.schedulerId);
      if (isDifferent) {
        onClickEachItem(each);
      }
    }
  };

  const SPCard = () => {
    return renderData?.map((each, index) => (
      <div
        className="sp-card-div"
        style={{
          backgroundColor:
            (selectedData?._id ||
              selectedData?.courseId ||
              selectedData?.gigId ||
              selectedData?.adhocId ||
              selectedData?.schedulerId) ===
            (each._id ||
              each?.courseId ||
              each?.gigId ||
              each?.adhocId ||
              each?.schedulerId)
              ? "#e9f2fd"
              : "#ffffff",
        }}
        key={index}
        onClick={() => onClickEachCard(each)}
      >
        {!checkNull(cardHeaderImage(each)) ? (
          <Avatar alt={cardHeaderName(each)} src={cardHeaderImage(each)} />
        ) : (
          <Avatar {...stringAvatar(cardHeaderName(each))} />
        )}
        <div className="count-styles" key={index}>
          <AautiText
            size={"normal"}
            title={cardHeaderName(each)}
            weight={"bold"}
            textStyle={{ overflowWrap: "anywhere" }}
            className="sp-card-title"
          />
          <div className="bactches-students-div">
            <AautiText title={batchesCountData(each)} size={"semi"} />
          </div>
        </div>
      </div>
    ));
  };

  const selectedBatchData =
    selectedData?.batches ||
    selectedData?.sessions ||
    selectedData?.users ||
    selectedData?.reviews;

  const batchUsersList = (person, index) => {
    return (
      <div
        style={{
          width: mobileDevices
            ? "100%"
            : currentScreen === "attendance-details"
            ? "100%"
            : null,
        }}
      >
        {currentScreen !== "attendance-details" &&
          currentScreen !== "reviews-ratings" && (
            <div
              className="students-div"
              key={index}
              onClick={() => {
                setStudentId(person?._id);
                currentScreen !== "attendanceDetails" && setOpen(true);
              }}
            >
              {(currentScreen === "my-revenue" ||
                currentScreen === "students") &&
                (person?.profileImage ? (
                  <Avatar
                    alt={person?.displayName}
                    src={person?.profileImage}
                  />
                ) : (
                  <Avatar {...stringAvatar(person?.displayName)} />
                ))}
              <div className="batch-details-div">
                <AautiText
                  weight={"bold"}
                  size={"normal"}
                  title={person?.displayName}
                />
                {currentScreen !== "students" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AautiText
                      title={
                        <>
                          Revenue :{" "}
                          <span style={{ fontFamily: "sans-serif", color: globalColors.AAUTI_THEME_COLOR }}>
                            {currencySymbol}
                          </span>
                          <span style={{ color:globalColors.AAUTI_THEME_COLOR }}>
                          {dashBoardRevenueconvertToMillionBillions(
                            person?.purchasedPrice,
                            2
                          )}
                          </span>
                        </>
                      }
                      size={"semi"}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    );
  };

  const batchMembers = (each, index) => {
    const getBatchMembers =
      each?.batches || each?.sessions || each?.user || each?.users;

    return (
      <>
        {activeTab === "Courses" && currentScreen !== "reviews-ratings" && (
          <div className="courses-batches-students-div" key={index}>
            <Accordion
              expanded={
                expanded ===
                (each?._id || each?.batchId || each?.courseId || index)
              }
              onChange={handleChange(
                each?._id || each?.batchId || each?.courseId || index
              )}
              className="accordian-styles"
              sx={{
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                border: "1px #dedede solid",
              }}
            >
              <AccordionSummary
                expandIcon={
                  !expanded ? <KeyboardArrowDownIcon /> : <ExpandMoreIcon />
                }
                sx={{
                  "& .MuiAccordionSummary-content": {
                    width: "100%",
                  },
                }}
              >
                <AautiText
                  weight={"bold"}
                  size={"normal"}
                  textStyle={{
                    width: "100%",
                  }}
                  title={batchName(each)}
                  className="sp-card-title"
                />
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: currentScreen === "attendance-details" && "0px",
                }}
              >
                <div
                  style={{
                    width: currentScreen === "attendance-details" && "100%",
                  }}
                >
                  {!isInstituteAdmin &&
                    currentScreen === "attendance-details" && (
                      <AttendanceTable getBatchMembers={getBatchMembers} />
                    )}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  {/* it is only for institute instructor */}
                  {isInstituteAdmin === true &&
                    currentScreen === "attendance-details" &&
                    each?.SpList &&
                    each?.SpList?.length > 0 && (
                      <>
                        {isInstituteAdmin &&
                          currentScreen === "attendance-details" && (
                            <AautiText
                              title="Instructors : "
                              textStyle={{
                                margin: "0px 10px 10px 10px",
                              }}
                            />
                          )}
                        <div
                          className="accordian-details"
                          style={{
                            gap:
                              ((isInstituteAdmin === false &&
                                currentScreen !== "attendance-details") ||
                                isInstituteAdmin) &&
                              "10px",
                            padding:
                              ((isInstituteAdmin === false &&
                                currentScreen !== "attendance-details") ||
                                isInstituteAdmin) &&
                              "0px 10px 10px 10px",
                          }}
                        >
                          {each?.SpList?.map((person, index) => {
                            return instructorUserList(
                              person,
                              each,
                              instituteType,
                              index
                            );
                          })}
                        </div>
                      </>
                    )}
                  {/* it is only for institute paticipants  */}
                  {isInstituteAdmin === true &&
                    currentScreen === "attendance-details" &&
                    each?.users &&
                    each?.users?.length > 0 && (
                      <>
                        {isInstituteAdmin &&
                          currentScreen === "attendance-details" && (
                            <AautiText
                              title="Participants : "
                              textStyle={{
                                margin: "0px 10px 10px 10px",
                              }}
                            />
                          )}
                        <div
                          className="accordian-details"
                          style={{
                            gap:
                              ((isInstituteAdmin === false &&
                                currentScreen !== "attendance-details") ||
                                isInstituteAdmin) &&
                              "10px",
                            padding:
                              ((isInstituteAdmin === false &&
                                currentScreen !== "attendance-details") ||
                                isInstituteAdmin) &&
                              "0px 10px 10px 10px",
                          }}
                        >
                          {each?.users?.map((person, index) => {
                            return participantUserList(
                              person,
                              each,
                              instituteType,
                              index
                            );
                          })}
                        </div>
                      </>
                    )}

                  {/* it is common for all market place */}
                  {getBatchMembers && getBatchMembers.length > 0 && (
                    <div
                      className="accordian-details"
                      style={{
                        gap: currentScreen !== "attendance-details" && "10px",
                        padding:
                          currentScreen !== "attendance-details" &&
                          "0px 10px 10px 10px",
                      }}
                    >
                      {getBatchMembers?.map((person, index) => {
                        return batchUsersList(person, index);
                      })}
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        <div
          className="accordian-details-main-div"
          style={{ width: mobileDevices && "100%" }}
        >
          {activeTab === "Gigs" && (
            <div
              className="accordian-details"
              style={{ gap: currentScreen !== "attendance-details" && "10px" }}
            >
              {batchUsersList(each, index)}
            </div>
          )}
          {activeTab === "Adhocs" && currentScreen === "my-revenue" && (
            <div
              className="accordian-details"
              style={{ gap: currentScreen !== "attendance-details" && "10px" }}
            >
              {batchUsersList(each, index)}
            </div>
          )}
          {activeTab === "Adhocs" && currentScreen === "students" && (
            <div
              className="accordian-details"
              style={{ gap: currentScreen !== "attendance-details" && "10px" }}
            >
              {each?.users?.map((person, index) => {
                return batchUsersList(person, index);
              })}
            </div>
          )}
          {currentScreen === "reviews-ratings" && (
            <div
              className="students-div"
              key={index}
              onClick={() => {
                setStudentId(each?.endUserId);
                setOpen(true);
              }}
              style={{ width: mobileDevices && "100%" }}
            >
              {each?.profileImage ? (
                <Avatar alt={each?.displayName} src={each?.profileImage} />
              ) : (
                <Avatar {...stringAvatar(each?.displayName)} />
              )}
              <div className="batch-details-div">
                <AautiText
                  weight={"bold"}
                  size={"semi"}
                  title={each?.displayName}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  {each?.averageRating || each?.rating ? (
                    <>
                      <Rating
                        sx={{ color: "#078dda" }}
                        color="#078dda"
                        name="text-feedback"
                        value={each?.averageRating || each?.rating}
                        readOnly
                        precision={0.5}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <Box
                        sx={{ ml: 1, fontFamily: "Poppins", fontWeight: 500 }}
                      >
                        {(each?.averageRating || each?.rating)?.toFixed(1)}
                      </Box>
                    </>
                  ) : (
                    <AautiText
                      title={DashBoardInfoConstants.ratingsEmpty}
                      size="small"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const InstituteStudentProfile = ({ instituteUserDetails }) => {
    return (
      <div
        style={{
          width: mobileDevices
            ? "100%"
            : currentScreen === "attendance-details"
            ? "100%"
            : null,
        }}
      >
        <div className="students-div">
          {instituteUserDetails?.profileImage ? (
            <Avatar
              alt={instituteUserDetails?.displayName}
              src={instituteUserDetails?.profileImage}
            />
          ) : (
            <Avatar {...stringAvatar(instituteUserDetails?.displayName)} />
          )}
          <div className="batch-details-div">
            <AautiText
              weight={"bold"}
              size={"normal"}
              title={instituteUserDetails?.displayName}
            />
          </div>
        </div>
      </div>
    );
  };

  const instructorUserList = (person, each, type, index) => {
    return (
      <div
        style={{
          width: mobileDevices
            ? "100%"
            : !isInstituteAdmin && currentScreen === "attendance-details"
            ? "100%"
            : null,
        }}
      >
        <div
          className="students-div"
          key={index}
          onClick={() => {
            instituteAttendanceApi(person, each, type);
            setInstituteType("Instructor");
          }}
        >
          {person?.profileImage ? (
            <Avatar alt={person?.displayName} src={person?.profileImage} />
          ) : (
            <Avatar {...stringAvatar(person?.displayName)} />
          )}
          <div className="batch-details-div">
            <AautiText
              weight={"bold"}
              size={"normal"}
              title={person?.displayName}
            />

            {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <AautiText
                        title={
                          <>
                            Attendance :{" "} 0%
                          </>
                        }
                        size={"semi"}
                      />
                    </div> */}
          </div>
        </div>
      </div>
    );
  };
  const participantUserList = (person, each, type, index) => {
    return (
      <div
        style={{
          width: mobileDevices
            ? "100%"
            : !isInstituteAdmin && currentScreen === "attendance-details"
            ? "100%"
            : null,
        }}
      >
        <div
          className="students-div"
          key={index}
          onClick={() => {
            instituteAttendanceApi(person, each, type);
            setInstituteType("Paricipant");
          }}
        >
          {person?.profileImage ? (
            <Avatar alt={person?.displayName} src={person?.profileImage} />
          ) : (
            <Avatar {...stringAvatar(person?.displayName)} />
          )}
          <div className="batch-details-div">
            <AautiText
              weight={"bold"}
              size={"normal"}
              title={person?.displayName}
            />

            {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <AautiText
                        title={
                          <>
                            Attendance :{" "} 0%
                          </>
                        }
                        size={"semi"}
                      />
                    </div> */}
          </div>
        </div>
      </div>
    );
  };
  const InstituteAttendance = () => {
    return (
      <>
        <InstituteStudentProfile instituteUserDetails={instituteUserDetails} />
        <EndUserAttendanceTable selectedBatchData={instituteAttendanceData} />
      </>
    );
  };
  const AttendanceTable = ({ getBatchMembers }) => {
    return (
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          boxShadow: "none",
        }}
      >
        <Table>
          <TableHead
            sx={{
              "& th": { fontSize: "16px", fontWeight: "bold" },
            }}
          >
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Sessions
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Attendance
              </TableCell>
              {selectedRole === "End User" && (
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Percentage
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getBatchMembers?.map((person, index) => (
              <TableRow key={index}>
                <TableCell align="center">{`Session ${index + 1}`}</TableCell>
                {selectedRole === "Service Provider" ? (
                  <TableCell
                    align="center"
                    sx={{
                      color: person?.upcoming
                        ? "#ffba49"
                        : person?.attended
                        ? "#3acc6c"
                        : "#f77777",
                    }}
                  >
                    {person?.upcoming
                      ? "Upcoming"
                      : person?.attended
                      ? "Attended"
                      : "Not Attended"}
                  </TableCell>
                ) : (
                  <TableCell
                    align="center"
                    sx={{
                      color: person?.upcoming ? "#ffba49" : null,
                    }}
                  >
                    {person?.upcoming
                      ? "Upcoming"
                      : `${person?.attendedCount} / ${person?.totalCount}`}
                  </TableCell>
                )}
                {selectedRole === "End User" && (
                  <TableCell align="center">
                    {person?.upcoming
                      ? `--`
                      : `${formatPercentage(person?.sessionPercentage)}%`}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const EndUserAttendanceTable = ({ selectedBatchData }) => {
    return (
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          padding: "0px",
          width: "100%",
        }}
      >
        <Table>
          <TableHead
            sx={{
              "& th": { fontSize: "16px", fontWeight: "bold" },
            }}
          >
            <TableRow>
              <TableCell align="center">Sessions</TableCell>
              <TableCell align="center">Attendance</TableCell>
              {(selectedRole === "End User" || isInstituteAdmin === true) && (
                <TableCell align="center">Percentage</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& th": { fontSize: "14px", fontWeight: "bold" },
            }}
          >
            {selectedBatchData?.map((each, index) => (
              <TableRow key={index}>
                <TableCell align="center">{`Session ${index + 1}`}</TableCell>
                {selectedRole === "Service Provider" ||
                isInstituteAdmin === true ? (
                  <TableCell
                    align="center"
                    sx={{
                      color: each?.upcoming
                        ? "#ffba49"
                        : each?.attended
                        ? "#3acc6c"
                        : "#f77777",
                    }}
                  >
                    {each?.upcoming
                      ? "Upcoming"
                      : each?.attended
                      ? "Attended"
                      : "Not Attended"}
                  </TableCell>
                ) : (
                  <TableCell
                    align="center"
                    sx={{
                      color: each?.upcoming ? "#ffba49" : null,
                    }}
                  >
                    {each?.upcoming
                      ? "Upcoming"
                      : `${each?.attendedCount} / ${each?.totalCount}`}
                  </TableCell>
                )}
                {(selectedRole === "End User" || isInstituteAdmin === true) && (
                  <TableCell align="center">
                    {each?.upcoming
                      ? `--`
                      : `${formatPercentage(each?.sessionPercentage)}%`}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const eachbatchesCountData = (each) => {
    const formatPercentage = (value) => {
      return value == null
        ? "N/A"
        : value % 1 === 0
        ? value
        : value?.toFixed(2);
    };
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return `${each?.batchesCount} ${pluralOrSingular(
            each?.batchesCount,
            "Batch",
            true
          )} | ${each?.totalSubscribedCount} ${pluralOrSingular(
            each?.totalSubscribedCount,
            "Student"
          )}`;
        } else if (activeTab === "Gigs") {
          return `${
            each?.subscribedCount || each?.totalSubscribedCount
          } ${pluralOrSingular(
            each?.subscribedCount || each?.totalSubscribedCount,
            "Student"
          )}`;
        } else {
          return `${each?.totalSubscribedCount} ${pluralOrSingular(
            each?.totalSubscribedCount,
            "Student"
          )}`;
        }
      case "my-revenue":
        if (activeTab === "Courses") {
          return (
            <span>
              Revenue :{" "}
              <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
              {dashBoardRevenueconvertToMillionBillions(each?.courseRevenue, 2)}
            </span>
          );
        } else if (activeTab === "Gigs") {
          return (
            <span>
              Revenue :{" "}
              <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
              {dashBoardRevenueconvertToMillionBillions(each?.gigRevenue, 2)}
            </span>
          );
        } else if (activeTab === "Adhocs") {
          return (
            <span>
              Revenue :{" "}
              <span style={{ fontFamily: "sans-serif" }}>{currencySymbol}</span>
              {dashBoardRevenueconvertToMillionBillions(each?.adhocRevenue, 2)}
            </span>
          );
        }
        break;
      case "attendance-details":
        switch (UserRole) {
          case "serviceprovider":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }/${each?.totalCount}  | 
              ${formatPercentage(each?.totalPercentage)}% Attendance
             `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }/${each?.totalCount} | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          case "endUser":
            if (activeTab === "Courses") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }/${each?.totalCount} | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            } else if (activeTab === "Adhocs") {
              return `${pluralOrSingular(each?.sessionsCount, "Session")} ${
                each?.sessionsCount
              }/${each?.totalCount} | 
            ${formatPercentage(each?.totalPercentage)}% Attendance
           `;
            }
            break;
          default:
            break;
        }
        break;
      case "reviews-ratings":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {each?.averageRating || each?.rating ? (
              <>
                <Rating
                  sx={{ color: "#078dda" }}
                  color="#078dda"
                  name="text-feedback"
                  value={each?.averageRating || each?.rating}
                  readOnly
                  precision={0.5}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <Box sx={{ ml: 1 }}>
                  {(each?.averageRating || each?.rating)?.toFixed(1)}
                </Box>
              </>
            ) : (
              <AautiText
                title={DashBoardInfoConstants.ratingsEmpty}
                size="small"
              />
            )}
          </div>
        );
      default:
        break;
    }
  };

  const Loading = () => {
    return (
      <Box className="TRloadingdiv2">
        <Skeleton variant="rounded" width={"100%"} height={40} />
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={40}
          sx={{ m: "8px 0px" }}
        />
        {Array(3)
          .fill(null)
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={"100%"}
              height={80}
              sx={{ m: "8px 0px" }}
            />
          ))}
      </Box>
    );
  };

  const SPCardItem = () => {
    return (
      renderData?.length > 0 &&
      (firstItem ? (
        <div className="sp-card-loading">{Loading()}</div>
      ) : (
        <>
          {filteredBySearch?.length > 0 && (
            <div className="sp-card-item-div">
              <div className="sp-header-item-div">
                {!checkNull(cardHeaderImage(selectedData)) ? (
                  <Avatar
                    alt={cardHeaderName(selectedData)}
                    src={cardHeaderImage(selectedData)}
                  />
                ) : (
                  <Avatar {...stringAvatar(cardHeaderName(selectedData))} />
                )}
                <div className="count-styles">
                  <AautiText
                    size={"normal"}
                    title={cardHeaderName(selectedData)}
                    weight={"bold"}
                    className="sp-card-title"
                  />
                  <div className="bactches-students-div">
                    <AautiText
                      title={eachbatchesCountData(selectedData)}
                      size={"semi"}
                    />
                  </div>
                </div>
              </div>
              <div
                className="batches-students-div"
                style={{
                  padding:
                    currentScreen === "attendance-details" &&
                    activeTab === "Adhocs"
                      ? "0px"
                      : "10px",
                  gap:
                    currentScreen === "attendance-details"
                      ? activeTab === "Adhocs"
                        ? "0px"
                        : "5px"
                      : activeTab === "Courses"
                      ? currentScreen === "reviews-ratings"
                        ? "10px"
                        : "5px"
                      : "10px",
                }}
              >
                {currentScreen === "attendance-details" &&
                  activeTab === "Adhocs" && (
                    <EndUserAttendanceTable
                      selectedBatchData={selectedBatchData}
                    />
                  )}

                {selectedBatchData?.map((each, index) => {
                  return batchMembers(each, index);
                })}
                <AautiDialog
                  open={open}
                  onClose={() => {
                    setInstituteUserDetails({});
                    setOpen(false);
                  }}
                  dialogTitle={
                    isInstituteAdmin === true
                      ? currentScreen === "my-revenue"
                        ? "Participant Details"
                        : instituteType === "Instructor"
                        ? "Instructor Details"
                        : "Participant Details"
                      : "Student Details"
                  }
                >
                  {(isInstituteAdmin === false ||
                    (isInstituteAdmin === true &&
                      currentScreen === "my-revenue")) && (
                    <StudentProfile
                      studentId={studentId}
                      screenName={"spStudents"}
                    />
                  )}
                  {isInstituteAdmin === true &&
                    currentScreen === "attendance-details" && (
                      <InstituteAttendance />
                    )}
                </AautiDialog>
              </div>
            </div>
          )}
        </>
      ))
    );
  };

  const returnTypeTabs = () => {
    const ratingData = () => {
      switch (activeTab) {
        case "Courses":
          return headerCount?.coursesValue;
        case "Gigs":
          return headerCount?.gigsValue;
        case "Adhocs":
          return headerCount?.adhocValue;
        case "My Reviews":
          return headerCount?.averageRating;
        default:
          return undefined;
      }
    };
    const getCourseAdhocGigTitle = () => {
      switch (activeTab) {
        case "Courses":
          return "Course";
        case "Gigs":
          return "Gig";
        case "Adhocs":
          return "Adhoc";
        case "My Reviews":
          return "User";
        default:
          return null;
      }
    };
    const getCourseAdhocGigCount = () => {
      switch (activeTab) {
        case "Courses":
          return headerCount?.coursesCount;
        case "Gigs":
          return headerCount?.gigsCount;
        case "Adhocs":
          return headerCount?.adhocsCount;
        case "My Reviews":
          return headerCount?.ratingCount;
        default:
          return null;
      }
    };
    const averageRating = () => {
      const ratingValue = ratingData();
      if (ratingValue === undefined || ratingValue === null) {
        return null;
      }
      return (
        <div className="average-ratings-div">
          {`${getCourseAdhocGigCount()} ${pluralOrSingular(
            getCourseAdhocGigCount(),
            getCourseAdhocGigTitle()
          )} | `}
          <Rating
            sx={{ color: "#078dda" }}
            name="text-feedback"
            value={ratingValue}
            readOnly
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          <Box sx={{ ml: 1 }}>{ratingValue?.toFixed(1)}</Box>
        </div>
      );
    };

    const formatPercentage = (value) => {
      return value % 1 === 0 ? value : value?.toFixed(2);
    };

    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          return (
            <AautiText
              title={`${headerCount?.coursesCount} ${pluralOrSingular(
                headerCount?.coursesCount,
                "Course"
              )} | ${headerCount?.coursesValue} ${pluralOrSingular(
                headerCount?.coursesValue,
                "Student"
              )}`}
              size={"normal"}
              weight={"bold"}
            />
          );
        } else if (activeTab === "Gigs") {
          return (
            <AautiText
              title={`${headerCount?.gigsCount} ${pluralOrSingular(
                headerCount?.gigsCount,
                "Gig"
              )} | ${headerCount?.gigsValue} ${pluralOrSingular(
                headerCount?.gigsValue,
                "Student"
              )}`}
              size={"normal"}
              weight={"bold"}
            />
          );
        } else {
          return (
            <AautiText
              title={`${headerCount?.adhocsCount} ${pluralOrSingular(
                headerCount?.adhocsCount,
                "Adhoc"
              )} | ${headerCount?.adhocValue} ${pluralOrSingular(
                headerCount?.adhocValue,
                "Student"
              )}`}
              size={"normal"}
              weight={"bold"}
            />
          );
        }
      case "my-revenue":
        if (activeTab === "Courses") {
          return (
            <AautiText
              title={
                <>
                  {headerCount?.coursesCount}{" "}
                  {pluralOrSingular(headerCount?.coursesCount, "Course")} |
                  Revenue :{" "}
                  <span style={{ fontFamily: "sans-serif",color: globalColors.Aauti_Orange }}>
                    {currencySymbol}
                  </span>
                  <span style={{ color: globalColors.Aauti_Orange}}>
                  {dashBoardRevenueconvertToMillionBillions(
                    headerCount?.coursesValue,
                    2
                  )}
                  </span>
                </>
              }
              size={"normal"}
              weight={"bold"}
            />
          );
        } else if (activeTab === "Gigs") {
          return (
            <AautiText
              title={
                <>
                  {headerCount?.gigsCount}{" "}
                  {pluralOrSingular(headerCount?.gigsCount, "Gig")} | Revenue :{" "}
                  <span style={{ fontFamily: "sans-serif", color : globalColors.Aauti_Orange }}>
                    {currencySymbol}
                  </span>
                  <span style={{color: globalColors.Aauti_Orange}}>
                  {dashBoardRevenueconvertToMillionBillions(
                    headerCount?.gigsValue,
                    2
                  )}
                  </span>
                </>
              }
              size={"normal"}
              weight={"bold"}
            />
          );
        } else {
          return (
            <AautiText
              title={
                <>
                  {headerCount?.adhocsCount}{" "}
                  {pluralOrSingular(headerCount?.adhocsCount, "Adhoc")} |
                  Revenue :{" "}
                  <span style={{ fontFamily: "sans-serif",color: globalColors.Aauti_Orange }}>
                    {currencySymbol}
                  </span>
                  <span style={{color: globalColors.Aauti_Orange}}>
                  {dashBoardRevenueconvertToMillionBillions(
                    headerCount?.adhocValue,
                    2
                  )}
                  </span>
                </>
              }
              size={"normal"}
              weight={"bold"}
            />
          );
        }
      case "attendance-details":
        if (activeTab === "Courses") {
          return (
            <AautiText
              title={`${headerCount?.coursesCount} ${pluralOrSingular(
                headerCount?.coursesCount,
                "Course"
              )} | ${formatPercentage(headerCount?.coursesValue)}% Attendance`}
              size={"normal"}
              weight={"bold"}
            />
          );
        } else if (activeTab === "Adhocs") {
          return (
            <AautiText
              title={`${headerCount?.adhocsCount} ${pluralOrSingular(
                headerCount?.adhocsCount,
                "Adhoc"
              )} | ${formatPercentage(headerCount?.adhocValue)}% Attendance`}
              size={"normal"}
              weight={"bold"}
            />
          );
        }
        break;
      case "reviews-ratings":
        return (
          renderData?.length > 0 && (
            <AautiText
              title={averageRating()}
              size={"normal"}
              weight={"bold"}
            />
          )
        );
      default:
        return null;
    }
  };
  const batchName = (each) => {
    const isCourseScreen = activeTab === "Courses";
    if (isCourseScreen) {
      return each?.batchName;
    }
    return null;
  };

  const filteredBySearch = renderData?.filter((each) => {
    const name =
      activeTab === "Courses"
        ? each.courseName
        : activeTab === "Gigs"
        ? each.gigName
        : activeTab === "Adhocs"
        ? each.adhocName
        : each.displayName;
    return name?.toLowerCase().includes(searchText.toLowerCase());
  });

  let role = [];
  switch (UserRole) {
    case "serviceprovider":
      if (currentScreen === "attendance-details")
        role = ["Service Provider", "End User"];
      break;
    default:
      break;
  }

  let titles = [];
  switch (currentScreen) {
    case "attendance-details":
      titles = ["Courses", "Adhocs"];
      break;
    case "reviews-ratings":
      titles = ["Courses", "Gigs", "Adhocs", "My Reviews"];
      break;
    default:
      titles = ["Courses", "Gigs", "Adhocs"];
      break;
  }

  const isInstituteAdmin = UserRole === HeaderStrings.instituteAdmin;
  if (isInstituteAdmin) {
    titles = titles.filter((title) => title !== "Gigs" && title !== "Adhocs");
  }

  let navigationTitles;
  switch (currentScreen) {
    case "students":
      navigationTitles = "Students";
      break;
    case "my-revenue":
      navigationTitles = "My Revenue";
      break;
    case "attendance-details":
      navigationTitles = "Attendance";
      break;
    case "reviews-ratings":
      navigationTitles = "Reviews & Ratings";
      break;
    default:
      break;
  }

  let navigationPaths;
  switch (currentScreen) {
    case "students":
      navigationPaths = "/students";
      break;
    case "my-revenue":
      navigationPaths = "/my-revenue";
      break;
    case "attendance-details":
      navigationPaths = "/attendance-details";
      break;
    case "reviews-ratings":
      navigationPaths = "/reviews-ratings";
      break;
    default:
      break;
  }

  const emptyData = () => {
    switch (currentScreen) {
      case "students":
        return DashBoardInfoConstants.studentsEmpty;
      case "my-revenue":
        return DashBoardInfoConstants.revenueEmpty;
      case "attendance-details":
        return DashBoardInfoConstants.attendanceEmpty;
      case "reviews-ratings":
        return DashBoardInfoConstants.ratingsEmpty;
      default:
        break;
    }
  };

  return (
    <AppContainer
      style={{
        padding: mobileDevices ? "2% 4%" : type == undefined && "1% 2%",
      }}
    >
      {type == undefined && (
        <AccountTabs
          tabObj={{
            label: navigationTitles,
            navigation: navigationPaths,
            active: true,
            navigationLabel: dashBoardTabTitle,
          }}
        />
      )}
      {currentScreen === "Attendance-details" ||
        (UserRole === "serviceprovider" && (
          <ActiveRoleTab titles={role} onHandleTitleClick={onSwitchRoleTab} />
        ))}
      <div className="head">
        <div
          className="box-styles"
          style={{
            flexDirection: mobileDevices ? "column" : "row",
            height:
              currentScreen === "attendance-details" &&
              UserRole === "serviceprovider"
                ? "75vh"
                : type == undefined
                ? "80vh"
                : "65vh",
          }}
        >
          <div
            className="return-type-tabs"
            style={{
              width: mobileDevices ? "100%" : "40vw",
              borderRight: !mobileDevices && "1px #dedede solid",
            }}
          >
            <SPTitles titles={titles} onHandleTitleClick={onHandleTitleClick} />
            {loading ? (
              Loading()
            ) : (
              <>
                {!isInstituteAdmin &&
                  renderData?.length > 0 &&
                  returnTypeTabs()}
                {!isInstituteAdmin && (
                  <AautiSearch
                    inputRef={ref}
                    searchValue={searchText}
                    addStyle={{
                      minWidth: "100%",
                      display:
                        checkNull(renderData) &&
                        checkNull(searchText) &&
                        "none",
                      marginBottom: "10px",
                      marginTop: "3px",
                    }}
                    onClose={(e) => {
                      e?.stopPropagation();
                      setSearchText("");
                      setOffset(0);
                      apiCall(0, "");
                    }}
                    handleSearchChange={(e) => {
                      setSearchText(e.target.value);
                      apiCall(0, e?.target?.value);
                      setOffset(0);
                    }}
                  />
                )}
                {/* <AautiSearch
                 
                  text="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    apiCall(0, e?.target?.value);
                  }}
                  className="search-styles"
                  size="small"
                  style={{
                    width: "100%",
                    display:
                      checkNull(renderData) && checkNull(searchText) && "none",
                  }}
                /> */}
                {renderData?.length > 0 ? (
                  <>
                    {/* {renderData?.length > 0 ? ( */}
                    <Box
                      style={{
                        height: !mobileDevices
                          ? "65vh"
                          : currentScreen === "attendance-details" &&
                            UserRole === "serviceprovider"
                          ? "58vh"
                          : "63vh",
                        overflow: "scroll",
                      }}
                      id="dashboardScroll"
                      className="sp-card-main-div"
                    >
                      <AautiInfiniteScroll
                        testId="dashboardScroll"
                        cardsList={renderData}
                        {...{ hasData, handleLoadMore: apiCall }}
                      >
                        <SPCard />
                      </AautiInfiniteScroll>
                    </Box>
                    {/* ) : } */}
                  </>
                ) : searchText != "" ? (
                  <EmptyData title="No search results" />
                ) : (
                  <EmptyTopic
                    Image={
                      currentScreen == "attendance-details"
                        ? EmptyAttendence
                        : currentScreen == "my-revenue"
                        ? EmptyRevenue
                        : currentScreen == "students"
                        ? EmptyStudent
                        : EmptyReview
                    }
                    topicName={"No Data"}
                    showButton={false}
                  />
                )}
              </>
            )}
          </div>

          {!mobileDevices && (
            <div
              className="sp-header-div"
              style={{
                width: mobileDevices ? "100%" : "60vw",
              }}
            >
              <SPCardItem />
            </div>
          )}
        </div>
      </div>
      {selectedBatchData?.length > 0 && (
        <AautiDialog
          open={displayPopUp}
          onClose={() => {
            setDisplayPopUp(false);
            setInstituteUserDetails({});
            setInstituteAttendance([]);
            setOpen(false);
          }}
        >
          <SPCardItem />
        </AautiDialog>
      )}
      {!isInstituteAdmin ||
        (isInstituteAdmin === true &&
          currentScreen !== "attendance-details" && (
            <AautiDialog
              open={open}
              onClose={() => {
                setInstituteUserDetails({});
                setInstituteAttendance([]);
                setOpen(false);
                setDisplayPopUp(false);
              }}
              dialogTitle={
                isInstituteAdmin === true
                  ? currentScreen === "my-revenue"
                    ? "Participant Details"
                    : instituteType === "Instructor"
                    ? "Instructor Details"
                    : "Participant Details"
                  : "Student Details"
              }
            >
              {(isInstituteAdmin === false ||
                (isInstituteAdmin === true &&
                  currentScreen === "my-revenue")) && (
                <StudentProfile
                  studentId={studentId}
                  screenName={"spStudents"}
                />
              )}
              {isInstituteAdmin === true &&
                currentScreen === "attendance-details" && (
                  <InstituteAttendance />
                )}
            </AautiDialog>
          ))}
    </AppContainer>
  );
};

export default DashBoardInfo;
