import React from "react";
import Lottie from "react-lottie-player";
import ErrorLottie from "../../assets/lotties/Animation-500.json";
import AautiText from "./AautiText";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ErrorAauti = () => {
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobileDevices || ipodDevices ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 15,
        width: "100%",
        height: "100vh",
        padding: (mobileDevices || ipodDevices) && 10,
      }}
    >
      <Lottie
        loop
        play
        animationData={ErrorLottie}
        // className={"lottie_styles"}
        style={{
          height: "50vh",
          width: mobileDevices || ipodDevices ? "100%" : "50%",
        }}
      />
      <div style={{ width: mobileDevices || ipodDevices ? "100%" : "45%" }}>
        <AautiText
          title={"Oops!"}
          size={"bigSize"}
          weight={"bold"}
          textStyle={{ fontSize: 55 }}
        />
        <AautiText
          size={"bigSize"}
          weight={"bold"}
          title={"Something went wrong at our end"}
          textStyle={{ marginBottom: "10px" }}
        />
        <AautiText
          title={"Error code: 500"}
          textStyle={{ marginBottom: "10px", color: "#626972" }}
        />
        <AautiText
          textStyle={{ marginBottom: 10, color: "#626972" }}
          title={
            "An error has occured and we're working to fix the problem! We'll be up and running shortly."
          }
        />
        <AautiText
          textStyle={{ marginBottom: 10, color: "#626972" }}
          title={"Try refreshing the page or contact support team"}
        />
        <div
          style={{
            display: "flex",
            gap: 5,
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <button
            onClick={() => {
              window.location.reload();
            }}
            style={{
              padding: "6px 15px",
              background: "transparent",
              cursor: "pointer",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "lightgrey",
              borderRadius: 4,
              color: "#626972",
            }}
          >
            Reload
          </button>
          <a href="/" style={{ textDecoration: "none" }}>
            <button
              onClick={() => {}}
              style={{
                padding: "6px 15px",
                background: "transparent",
                cursor: "pointer",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "lightgrey",
                borderRadius: 4,
                color: "#626972",
              }}
            >
              Back to Home
            </button>
          </a>
        </div>
        <a href="mailto:support@aauti.com">
          <AautiText
            title={"Contact support team"}
            size={"small"}
            textStyle={{
              marginTop: 10,
              cursor: "pointer",
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default ErrorAauti;
