import { configureStore } from "@reduxjs/toolkit";
import appReducerSlice from "./reducer/appReducer";
import responsiveReducer from "./reducer/responsiveReducer";
import PaymentSlice from "./reducer/PaymentSlice";
import homePageSlice from "../../src/components/homeScreen/reducer";
import chatSlice from "./reducer/chatSlice";
import socketMiddleware from "./socketMiddleware";
import courseAdhoc from "../components/course/courseAdhoc/courseAdhocSlice";
import { SocketEvents } from "../components/chat/constants";

const store = configureStore({
  reducer: {
    app: appReducerSlice,
    responsive: responsiveReducer,
    paymentReducer: PaymentSlice,
    homePageReducer: homePageSlice,
    chat: chatSlice,
    courseAdhoc: courseAdhoc,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["chat/setSocketHandler",'chat/setActiveRoom','chat/leaveRoom',SocketEvents.message.send,SocketEvents.message.edit,SocketEvents.room.clearConversation],
        ignoredActionPaths: ["payload.callBackFunc",'chat.activeRoom.user.exitDate'],
        ignoredPaths: ["chat.eventHandler"],
      },
    }).concat([socketMiddleware]);
  },
});
export default store;
