import React from "react";
import AccountTabs from "../../globalComponents/AccountTabs";
import AautiText from "../../globalComponents/AautiText";
import "../Style.scss";
import { useSelector } from "react-redux";
import EmptyData from "../../globalComponents/EmptyData";
import moment from "moment";
import { noImage } from "../../calendar/constants";
import { Skeleton, Tooltip } from "@mui/material";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { globalColors } from "../../../themes/GlobalColors";
import { checkNull } from "../../../utils/Helpers";
import AautiInfiniteScroll from "../../features/InfiniteScroll";
import RefundsScreenHooks from "./containers";
import AppContainer from "../../../layouts/AppContainer";

const tabs = [
  { id: 1, name: "Credits", value: "credits" },
  { id: 2, name: "Refunds", value: "netBanking" },
];
const Refunds = () => {
  const {
    loading,
    activeTab,
    refundsList,
    hasData,
    refundAmount,
    setActiveTab,
    getAllRefunds,
  } = RefundsScreenHooks();
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const { userDetails } = useSelector((state) => state.app);
  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: "Refunds",
            navigation: "/refunds",
            active: true,
          },
        }}
      />
      <div className="tabs-flex">
        {tabs?.map((each, index) => (
          <button
            className={`tab-refund ${
              activeTab === each?.value && "active-class"
            }`}
            key={index}
            onClick={() => {
              setActiveTab(each?.value);
            }}
          >
            <AautiText title={each?.name} />
          </button>
        ))}
      </div>
      <div className="list-main">
        {loading ? (
          <div className="tabs-flex ">
            {[1, 2, 3, 4, 5, 6]?.map((each, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={mobileDevices ? "100%" : 350}
                height={mobileDevices ? 80 : 100}
              />
            ))}
          </div>
        ) : checkNull(refundsList) ? (
          <EmptyData
            showExplore={false}
            text={activeTab === "credits" ? "No Credits Yet" : "No Refunds Yet"}
          />
        ) : (
          <>
            {activeTab === "credits" &&
              userDetails?.transactionCountrySymbol && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "4px 0px 4px 2px",
                  }}
                >
                  <IoIosInformationCircleOutline
                    size={16}
                    style={{
                      margintop: 2,
                      marginLeft: -3,
                      marginRight: 2,
                    }}
                  />
                  <AautiText
                    size={"semi"}
                    title={`1 Credit equals to ${userDetails?.transactionCountrySymbol}1.`}
                    textStyle={{
                      fontFamily: "sans-serif",
                      alignSelf: "baseline",
                    }}
                  />
                  <AautiText
                    size={"semi"}
                    title={`Available Credits:`}
                    textStyle={{
                      fontFamily: "Segoe UI",
                      marginLeft: 10,
                      marginRight: 5,
                      alignSelf: "baseline",
                    }}
                  />
                  <AautiText
                    weight={"bold"}
                    size={"semi"}
                    title={` ${
                      refundAmount
                        ? userDetails?.transactionCountrySymbol +
                          refundAmount?.toFixed(2)
                        : userDetails?.transactionCountrySymbol + 0.0
                    }`}
                    textStyle={{
                      fontFamily: "sans-serif",
                      color:
                        refundAmount < 0
                          ? globalColors.Aauti_Red
                          : globalColors.Aauti_Green,
                      alignSelf: "baseline",
                    }}
                  />
                </div>
              )}
            <AautiText
              weight={"bold"}
              title={`${
                activeTab === "credits"
                  ? "Credits History"
                  : "Transaction History"
              }`}
              textStyle={{ margin: "5px 0px 10px 0px" }}
            />
            <div className="refund-main" id="refundScroll">
              <AautiInfiniteScroll
                testId="refundScroll"
                cardsList={refundsList}
                {...{ hasData, handleLoadMore: getAllRefunds }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {refundsList?.map((each, index) => (
                    <div className="refund-card" key={index}>
                      <img
                        src={
                          each?.courseImage ||
                          each?.gigImage ||
                          each?.adhocImage ||
                          each.packageImage ||
                          noImage
                        }
                        className="refund-img2"
                        alt="refund-img"
                      />
                      <div className="refund-right-div ">
                        <div className="price-cont-New">
                          <Tooltip
                            title={
                              each?.courseName ||
                              each?.gigName ||
                              each?.adhocName ||
                              each?.packageName ||
                              "N/A"
                            }
                          >
                            <div style={{ maxWidth: "78%" }}>
                              <AautiText
                                title={
                                  each?.courseName ||
                                  each?.gigName ||
                                  each?.adhocName ||
                                  each?.packageName ||
                                  "N/A"
                                }
                                textStyle={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 1,
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                }}
                              />
                            </div>
                          </Tooltip>
                          <AautiText
                            textStyle={{
                              fontFamily: "sans-serif",
                              paddingTop: 1,
                            }}
                            title={`${
                              (activeTab === "credits"
                                ? userDetails?.transactionCountrySymbol
                                : each?.currencySymbol) +
                              (activeTab === "credits"
                                ? each?.EUTransactionAmount?.toFixed(2) || 0
                                : each?.localPurchasedPrice?.toFixed(2) || 0)
                            }`}
                            weight={"medium"}
                            size={"semi"}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: 5,
                            }}
                          >
                            <AautiText
                              textStyle={{ color: globalColors.GREY_TEXT }}
                              size={"semi"}
                              weight={"normal"}
                              title={`Ref ID:`}
                            />
                            <AautiText
                              weight={"normal"}
                              size={"semi"}
                              title={`${each?.orderId}`}
                              textStyle={{
                                maxWidth:
                                  activeTab !== "credits" &&
                                  ipodDevices &&
                                  "75%",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 1,
                                textOverflow: "ellipsis",
                              }}
                            />
                          </div>
                          {activeTab === "credits" ? null : (
                            <AautiText
                              weight={"normal"}
                              size={"small"}
                              textStyle={{
                                color:
                                  each.status === "processing"
                                    ? globalColors.Aauti_Red
                                    : globalColors.Aauti_Green,
                              }}
                              title={
                                each?.status
                                  ? `${
                                      each.status.charAt(0).toUpperCase() +
                                      each.status.slice(1)
                                    }`
                                  : " "
                              }
                            />
                          )}
                        </div>
                        <div className="price-cont-New">
                          {checkNull(each?.createdAt) ? null : (
                            <AautiText
                              weight={"normal"}
                              size={"semi"}
                              title={moment(each?.createdAt).format(
                                "DD MMM YYYY hh:mm A"
                              )}
                            />
                          )}
                          <AautiText
                            title={
                              (each?.refundAmount < 0 ? "-" : "+") +
                              (activeTab === "credits"
                                ? userDetails?.transactionCountrySymbol
                                : each?.currencySymbol) +
                              (Math.abs(each?.refundAmount).toFixed(2) || 0)
                            }
                            weight={"medium"}
                            size={"semi"}
                            textStyle={{
                              color:
                                each?.refundAmount < 0
                                  ? globalColors.Aauti_Red
                                  : globalColors.Aauti_Green,
                              fontFamily: "sans-serif",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </AautiInfiniteScroll>
            </div>
          </>
        )}
      </div>
    </AppContainer>
  );
};

export default Refunds;
