import { Divider, Grid, Skeleton } from "@mui/material";
import React from "react";

function SkeletonChatListItem(item, index) {
  return (
    <Grid
      key={`${item}-${index}`}
      container
      columnGap={2}
      sx={{ maxHeight: 70, width: "100%" }}
      alignItems={'center'}
    >
      <Grid
        item={true}
        xs={12}
        container
        direction={"row"}
        flexWrap={"nowrap"}
        columnGap={3}
      >
        <Grid container direction={"column"} item xs={1}>
          <Skeleton variant="circular" height={45} width={45} animation="wave" />
        </Grid>

        <Grid container direction={"column"} item xs={11}>
          <Skeleton variant="text" sx={{ fontSize: "1.4rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.4rem", width: "100%" }} />
        </Grid>
      </Grid>
      <Divider/>
    </Grid>
  );
}

export default SkeletonChatListItem;
