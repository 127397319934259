import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import DeletionAlert from "../../assets/images/DeletionAlert.png";
import { useSelector } from "react-redux";
import AautiText from "./AautiText";
import { globalColors } from "../../themes/GlobalColors";

const ConfirmBacthDialog = ({
  onClose,
  open,
  onClick,
  dialogTitle = "Are you sure you want to delete?",
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);

  const handleCancel = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: mobileDevices ? "75%" : "50%",
          maxWidth: "320px",
          maxHeight: 435,
        },
        // padding: 2,
      }}
      maxWidth="xs"
      open={open}
      onClose={handleCancel}
    >
      <DialogContent>
        <AautiText title={dialogTitle} size={"extraMedium"} />
        <AautiText
          size={"semi"}
          title={"Would you like to replace with your current batch selection?"}
        />
      </DialogContent>
      <DialogActions sx={{ p: 1, gap: 3 }}>
        <AautiText
          title={"NO"}
          onClick={onClose}
          textStyle={{
            color: globalColors.AAUTI_THEME_COLOR,
            cursor: "pointer",
          }}
        />
        <AautiText
          title={"YES"}
          onClick={onClick}
          textStyle={{
            color: globalColors.AAUTI_THEME_COLOR,
            cursor: "pointer",
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmBacthDialog;
