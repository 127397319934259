import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

export function updateNotificationSettings(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/updateUserData",
    method: ApiMethods?.PATCH,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
