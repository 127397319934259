import React from "react";
import AautiText from "./AautiText";
import { IoIosArrowForward, IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const CommingSoon = ({ showExplore }) => {
  const { mobileDevices } = useSelector((state) => state.responsive);

  const navigate = useNavigate();
  return (
    <div
      style={{
        // marginTop: "50px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        // justifyContent: "center",
        padding: 16,
        height: "72vh",
        alignItems: "center",
      }}
    >
      {mobileDevices && (
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: "10px 0px 10px 5px",
            alignItems: "center",
            flexDirection: "row",
            borderBottom: "1px solid #5C5B5733",
            columnGap: 10,
          }}
        >
          {" "}
          <IoIosArrowRoundBack
            onClick={() => navigate(-1)}
            size={30}
            style={{ cursor: "pointer", color: "#000000" }}
          />
          <AautiText weight={"bold"} title={"Back"} />
        </div>
      )}
      <div
        style={{
          // marginTop: "50px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          padding: 16,
          height: "72vh",
          alignItems: "center",
        }}
      >
        <lottie-player
          src="https://lottie.host/5b29736b-deb6-4f29-8d4a-d382cb9dfb36/50pXE0LtwG.json"
          background="transparent"
          speed="1"
          style={{
            width: "100%",
            height: "190px",
            flexDirection: "column",
            alignItems: "center",
          }}
          loop
          autoplay
        />

        {showExplore && (
          <button
            style={{
              border: "none",
              backgroundColor: "#1e98d7",
              width: "auto",
              padding: "10px",
              borderRadius: "10px",
              cursor: "pointer",
              marginTop: 20,
            }}
            onClick={() => navigate("/")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AautiText
                title={"Keep Exploring"}
                size={"normal"}
                weight={"extraBold"}
                textStyle={{
                  color: "#fff",
                  marginRight: "10px",
                  marginTop: "2px",
                }}
              />
              <IoIosArrowForward
                fill="#fff"
                style={{
                  borderRadius: "50%",
                  fontSize: "23px",
                  border: "1px solid rgba(255, 255, 255, 0.27)",
                  backgroundColor: "rgba(255, 255, 255, 0.27)",
                  fill: "#fff",
                }}
              />
            </div>
          </button>
        )}
      </div>
    </div>
  );
};
export default CommingSoon;
