import React from "react";
import {
  MatchCourses,
  MatchGigs,
  MatchProviders,
  MatchSessions,
} from "./MatchCourses";
import AautiText from "../../globalComponents/AautiText";
import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import EmptyData from "../../globalComponents/EmptyData";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CardLoader from "../../globalComponents/CardLoader";

const MatchMyScheduleListView = ({
  activeType,
  setActiveType,
  searchValue,
  makeSearchApi,
  apiLoading,
  scrollRef,
  hasData,
  moreResultsData,
  moreResults,
  setRefresh,
  refresh,
  activeState,
}) => {
  const tabsList = ["Courses", "Gigs", "Adhoc Sessions", "Service Providers"];
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mobileDevices, ipodDevices, lapy, miniLapys, desktop } = useSelector(
    (state) => state.responsive
  );

  const returnDataListing = (title, list, ref, type) => {
    switch (activeType) {
      case "Courses":
        return (
          <MatchCourses
            activeFilters={activeState}
            list={list}
            {...{
              title,
              type,
              navigate,
              activeType,
              hasData,
              moreResultsData,
              makeSearchApi,
            }}
          />
        );
      case "Gigs":
        return (
          <MatchGigs
            activeFilters={activeState}
            {...{
              list,
              setRefresh,
              refresh,
              title,
              type,
              navigate,
              activeType,
              hasData,
              makeSearchApi,
              moreResultsData,
            }}
          />
        );
      case "Adhoc Sessions":
        return (
          <MatchSessions
            activeFilters={activeState}
            {...{
              list,
              title,
              type,
              navigate,
              activeType,
              hasData,
              moreResultsData,
              makeSearchApi,
            }}
          />
        );
      case "Service Providers":
        return (
          <MatchProviders
            activeFilters={activeState}
            {...{
              list,
              title,
              type,
              navigate,
              activeType,
              hasData,
              moreResultsData,
              makeSearchApi,
            }}
          />
        );
      default:
        break;
    }
  };
  const returnCards = (title, list, ref, type) => {
    return returnDataListing(title, list, ref, type);
  };
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {/* <AautiText title={"Showing Results"} /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          gap: 6,
          paddingTop: 4,
          marginBottom: 10,
          overflowX: "scroll",
          flexWrap: "nowrap",
        }}
      >
        {tabsList?.map((item, index) => {
          const active = activeType == item;
          return (
            <button
              onClick={() => {
                // handleFilters(each?.title, item);
                setActiveType(item);
                makeSearchApi(searchValue, item, 0);
                navigate(".", {
                  replace: true,
                  state: {
                    ...state,
                    activeType: item,
                    searchKey: searchValue,
                  },
                });
              }}
              style={{
                padding: "6px 12px",
                border: active ? "none" : "1px solid lightgrey",
                background: "#fff",
                borderRadius: 4,
                cursor: "pointer",
                minWidth: "fit-content",
                width: "fit-content",
                backgroundColor: active ? "#3083EF" : "transparent",
              }}
              key={index}
            >
              <AautiText
                size={"semi"}
                title={item?.name || item}
                textStyle={{
                  color: active ? "#fff" : "#000",
                }}
              />
            </button>
          );
        })}
      </div>
      {apiLoading ? (
        <Grid container spacing={1.5}>
          {[1, 2, 3, 4]
            .slice(
              0,
              mobileDevices ? 1 : ipodDevices ? 2 : miniLapys ? 3 : lapy ? 3 : 4
            )
            .map((each, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                  <CardLoader />
                </Grid>
              );
            })}
        </Grid>
      ) : isEmpty(moreResults?.moreResults) &&
        isEmpty(moreResults?.sponsored) &&
        isEmpty(moreResults?.topRated) ? (
        <div>
          <EmptyData
            text={searchValue ? "No Search Results" : "No Results"}
            showExplore={false}
          />
        </div>
      ) : (
        <div
          style={{
            width: "99%",
            height: !mobileDevices ? "83vh" : "70vh",
            overflow: "scroll",
            // flex: mobileDevices && 1,
            paddingBottom: mobileDevices && 20,
          }}
          id="InfiniteSearchId"
          ref={scrollRef}
        >
          {!isEmpty(moreResults?.sponsored) &&
            returnCards("Sponsored", moreResults?.sponsored, "", "sponsored")}
          {!isEmpty(moreResults?.topRated) &&
            returnCards("Top Rated", moreResults?.topRated, "", "toprated")}
          {returnCards("More Results", moreResults?.moreResults, "", "more")}
        </div>
      )}
    </div>
  );
};

export default MatchMyScheduleListView;
