import { createSlice } from "@reduxjs/toolkit";
// const windowWidth = window.innerWidth;
const initialState = {
  mobileDevices: false,
  aboveLargeMobileDevices: false,
  ipodDevices: false,
  miniLapys: false,
  lapy: false,
  desktop: false,
};
const responsiveReducer = createSlice({
  name: "reponsive",
  initialState,
  reducers: {
    updateMediaQueries: (state, action) => {
      const {
        mobileDevices,
        aboveLargeMobileDevices,
        ipodDevices,
        miniLapys,
        lapy,
        desktop,
      } = action.payload;
      state.mobileDevices = mobileDevices;
      state.aboveLargeMobileDevices = aboveLargeMobileDevices;
      state.ipodDevices = ipodDevices;
      state.miniLapys = miniLapys;
      state.lapy = lapy;
      state.desktop = desktop;
    },
  },
});
export const { updateMediaQueries } = responsiveReducer.actions;
export default responsiveReducer.reducer;
