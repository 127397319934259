import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

const apiPaths = {
  GET_TRUSTEES_LIST_BY_USERID: "trustee/getByUserId",
  SAVE_TRUSTEE_INVITE: "trustee/saveTrustee",
  DELETE_TRUSTEE: "trustee/deleteTrustee",
  TRUSTEE_MEMBER_ELIGIBILITY: "/trustee/trusteeMemberEligibility",
};

export const getTrusteesListByUserId = (userId, onSuccess, onFailure) => {
  ApiRequest({
    url: apiPaths.GET_TRUSTEES_LIST_BY_USERID,
    method: ApiMethods.GET,
    params: { userId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const saveTrusteeInvitees = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: apiPaths?.SAVE_TRUSTEE_INVITE,
    method: ApiMethods?.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const deleteTrusteeByUserId = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: apiPaths?.DELETE_TRUSTEE,
    method: ApiMethods?.DELETE,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const checkTrusteeMemberEligibility = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: apiPaths?.TRUSTEE_MEMBER_ELIGIBILITY,
    method: ApiMethods?.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
