import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

function DialogCloseButton({
    id='dialog_close',
    onClick,
    size="medium",
    closeIcon=()=><Close fontSize='inherit'/>,
    variant='dialog'
}) {
  return (
    <IconButton
        size={size}
        id={`${id}_button`}
          aria-label={`${id}-button`}
          onClick={onClick}
          sx={{
            position: "absolute",
            right:0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
            zIndex: variant ==="dialog"?(theme) => theme.zIndex.modal+1:2
          }}
        >
          {closeIcon()}
        </IconButton>
  )
}

export default DialogCloseButton