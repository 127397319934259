import { CloudDownload } from '@mui/icons-material'
import {  Button, Divider } from '@mui/material'
import React from 'react'
import './styles.scss'
import AautiText from '../../../globalComponents/AautiText'
import { prettifyFileSize } from '../../../../utils/Helpers'

function FileInfo({file}) {
  return (
    <div
        id={`file-card-${file?._id ?? file.name}`}
        className="file_info_wrapper"
    >
        <div className='file_content_wrapper'>
            <AautiText
                title={file.name}
                weight='normal'
                size='small'
                className='file_name'
            />
            <AautiText
                title={prettifyFileSize(file.size)}
                weight='medium'
                size='semi'
                textStyle={{
                    color:'white'
                }}
            />
        </div>
        <Divider orientation="vertical" variant="middle" flexItem />
        <a {...{
            href: file.uri,
            target:"_blank",
            download: file.name,
        }}
        >
            <CloudDownload htmlColor='#ffffff'/>
        </a>
    </div>
  )
}

export default FileInfo