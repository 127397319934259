export const options = ["Edit", "Delete"];

export const items = [
  {
    label: null,
    items: [
      {
        id: 1,
        label: "Edit",
        icon: "pi pi-refresh",
      },
      {
        id: 2,
        label: "Delete",
        icon: "pi pi-upload",
      },
    ],
  },
];
