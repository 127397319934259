export const VerifyUserStrings = {
  legalNote:
    "Use the legal name exactly as it appears on your ID card including your second name.",
  legalHead: "Confirm Your Legal Information",
  preferMethod: "Choose Your Preferred Verification Method",
  IdVerify: "Verify Your ID",
  IdVerifyHelper:
    "A valid ID can be a driver’s license, a government-issued photo ID, or a passport.",
  IDVerifyHelperUpload:
    "Submit a clear, unaltered photo of your actual photo ID with all information visible.",
  idFrontSide: "Front Side of Your Identity Document",
  idBackSide: "Back Side of Your Identity Document",
};

export const idProofsList = [
  "Aadhaar Card",
  "Passport",
  "Voter ID",
  "Driving License",
  "PAN Card",
];
