import React, { useEffect, useState } from "react";
import { BuildDashboard } from "storybook-signup";
import { useNavigate } from "react-router-dom";
import {
  getInstituteDetails,
  getInstituteDashboardDetails,
  updateInstituteDashboard,
} from "./Services";
import { showToast } from "../globalComponents/Toast";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { baseUrl } from "../../service/api-constants";

const InstituteProfileEdit = (props) => {
  const params = useParams();
  const { countryObject } = useSelector((state) => state.app);
  const [instituteData, setInstituteData] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const cancelUpdate = () => {
    navigate(`/institute/profile/${params?.id}`);
  };

  useEffect(() => {
    setShowLoader(true);
    getInstituteDashboardDetails(
      params?.id,
      (res) => {
        if (res?.status === "Success" && res.result !== null) {
          setShowLoader(false);
          setDashboardData(res?.result);
        }
      },
      (err) => {
        setShowLoader(false);
        console.log(err);
      }
    );
    getInstituteDetails(
      params?.id,
      (res) => {
        if (res?.status === "Success") {
          setInstituteData(res.result);
        }
        setShowLoader(false);
      },
      (err) => {
        console.log(err);
        setShowLoader(false);
      }
    );
  }, []);

  const updateInstitute = (object) => {
    updateInstituteDashboard(
      { _id: params?.id, ...object },
      (res) => {
        if (res?.status == "Success") {
          navigate(`/institute/profile/${params?.id}`);
        } else {
          showToast("error", res?.message);
        }
      },
      (err) => {
        showToast("err", "Something went wrong");
      }
    );
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: 40,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <BuildDashboard
        instituteLogo={instituteData?.institute?.logo}
        instituteName={instituteData?.institute?.nameOfTheInstitution}
        showToast={showToast}
        instituteData={instituteData}
        categories={instituteData?.institute?.categories}
        mobile={instituteData?.institute?.mobile}
        editObject={dashboardData}
        onUpdateInstitute={updateInstitute}
        editId={params?.id}
        countryObject={countryObject}
        cancelUpdate={cancelUpdate}
        baseUrl={baseUrl}
      />
      <Backdrop
        open={showLoader}
        sx={(theme) => ({
          color: "#fff",
          position: "absolute",
          zIndex: theme.zIndex.drawer + 1,
        })}
        onClose={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default InstituteProfileEdit;
