import { Box, Rating } from "@mui/material";
import React from "react";
import "../styles.scss";
import { ReviewCard } from "../bpCardsHome/BpCards";
import { useSelector } from "react-redux";

const ReviewCards = ({ testimonialsData }) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <section id="WHAT_OTHERS_TALK">
      <Box className="slider_container_box flex_container">
        {testimonialsData?.map((slide, index) => (
          <ReviewCard
            {...{ slide, index }}
            cardWidth={mobileDevices ? "98%" : 320}
          />
        ))}
      </Box>
    </section>
  );
};

export default ReviewCards;
