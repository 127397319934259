import React, { } from "react";
import {  Box, Button, IconButton, Slider } from "@mui/material";
import { CloudDownload, Pause, PlayArrow } from "@mui/icons-material";
import { useAudioController } from "./audioController";
import FileInfo from '../../file_info'
import { StyledMsgContainer } from "../../styled_components";
import MessageText from "../text";
import Audio from "./audio";

function MessageAudio({ currentMessage, position, reply = false, searchText }) {
const { files, ...rest } = currentMessage;
const {_id:fileId,type,name,uri} = files[0]
const { audioRef, isPlaying, togglePlay,secondsElapsed } = useAudioController({
  mimeType:type,
});

  return (
    <StyledMsgContainer>
       <Audio
        {...{src:uri,type}}
       />
      <FileInfo
        key={fileId}
        file={files[0]}
      />
      {currentMessage.text && (<MessageText
        {...{
          currentMessage: rest,
          position,
          reply,
          searchText,
        }}
      />)}
     </StyledMsgContainer>
   );
}

export default MessageAudio;
