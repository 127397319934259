import { Box } from "@mui/material";
import { VectorUnderLineIcon } from "../container";
import "./easyToStart.scss";
import constants from "./easyToStartConstants.json";
import { BorderedTextSvgRenderer } from "./easyToStartUtils";

export default function EasyToStart(props) {
    const { borderedText = constants.default_bordered_text } = props;
    return (
        <section id={constants.easy_to_start_section_id}>
            <Box className="easy_to_start_frame">
                <p className="easy_to_start_label">
                    {/* {constants.easy_to_start_label} */}
                    It’s <span className="easy_to_start_label_span"> Easy to Start </span>
                </p>
                <Box className="text_underline">
                    <VectorUnderLineIcon />
                </Box>
                <Box className="easy_to_start_Learning_box">
                    <p className="easy_to_start_Learning_label">
                        <i>{borderedText}</i>
                    </p>
                </Box>
                <Box className="easy_to_start_description_box">
                    <p className="easy_to_start_description">
                        {constants.sign_in_process_description}
                    </p>
                </Box>
            </Box>
            <Box className="easy_to_start_store_container_steps">
                <BorderedTextSvgRenderer borderedText={borderedText} />
            </Box>
        </section>
    );
}
