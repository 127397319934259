import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdCameraAlt } from "react-icons/md";
import { Cropper } from "react-cropper";
import { Backdrop, CircularProgress, Menu, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import AautiTextInput from "../../../globalComponents/AautiTextInput";
import Lottie from "react-lottie-player";

import {
  SocketEvents,
  chatConstants,
  emptyImageUploadStyles,
  maxImageSizeInBytes,
} from "../../constants";

import AautiAvatar from "../AautiAvatar";
import {BootstrapDialog} from "../styled_components";
import DialogCloseButton from "./DialogCloseButton";
import { showToast } from "../../../globalComponents/Toast";
import { convertBase64ToFile } from "../../utils";
import { convertMultipleSpacesToSingleSpace } from "../../../../utils/Helpers";
import { CapitalizeFirstLetter } from "../../../CommonFunctions";
import { emptyImage } from "../../animations";
import { apiServices } from "../../../../service/apiService";

import "./styles.scss";
import "cropperjs/dist/cropper.css";
import ChatHeader from "../chat_header";


function EditChatGroupDialog({ open, onClose, activeRoom }) {
  const imageInputId = "edit_chat_room_avatar";
  const { mobileDevices, aboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const [roomData, setRoomData] = useState({ ...activeRoom });
  const [showLoader, setShowLoader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cropImage, setCropImage] = useState(false);
  // const [imageFile, setImageFile] = useState(undefined);
  const openEditProfileMenu = Boolean(anchorEl);
  const cropperRef = useRef(null);

  const editMenuId = "chat_room_profile_edit";

  const { name, description, avatar, roomId } = roomData;

  const isBtnDisabled =
    !(
      activeRoom.name !== name ||
      activeRoom.description !== description ||
      activeRoom.avatar !== avatar
    ) || name.length < 5;
  const hasProfile = !avatar ? false : true;
  const isMobileAndMediumDevice = mobileDevices || aboveLargeMobileDevices;

  const getEditImageOptions = () => {
    let options;
    if (hasProfile) {
      options = ["Upload Image", "Remove Image"];
    } else options = ["Upload Image"];
    return options;
  };

  const editImageOptions = getEditImageOptions();

  const dispatch = useDispatch();

  useEffect(() => {
    setRoomData({ ...activeRoom });
  }, [open]);

  const onPressSave = async () => {
    const payload = { roomId };
    if (!name?.length || name === " ") {
      return showToast("info", "Add group name");
    }

    if (activeRoom.name !== name?.trim()) {
      payload.name = name?.trim();
    }
    if (activeRoom.description !== description) {
      payload.description = description;
    }

    try {
      let image = avatar;
      setShowLoader(true);
      if (typeof image !== "string" && avatar && avatar?.size) {
        image = await apiServices.uploadImage(avatar);
      }
      if (activeRoom.avatar !== image) {
        payload.avatar = image;
      }
      setShowLoader(false);
      dispatch({
        type: SocketEvents.room.updateInfo,
        payload,
      });
    } catch (err) {
      setShowLoader(false);
      showToast("info", err.message);
    }
  };
  const onImageSelected = async (e) => {
    const files = e.target.files;
    if (!files.length) {
      return showToast("info", "Unable to pick image. Please try again.");
    }
    if (files.length && files[0].size <= maxImageSizeInBytes) {
      setCropImage(files[0]);
    } else {
      showToast("info", "File size <= 5mb");
    }
  };

  const onSaveCroppedImage = () => {
    const cropper = cropperRef.current?.cropper;
    const croppedImage = convertBase64ToFile(
      cropper.getCroppedCanvas().toDataURL(cropImage.type),
      `${cropImage.name}-cropped`
    );
    setRoomData((prev) => ({ ...prev, avatar: croppedImage }));
    closeCrop();
  };

  const closeCrop = () =>setCropImage(false);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    onPressMenuItem(editImageOptions[index]);
    setAnchorEl(null);
  };

  const pickImage = () => document.getElementById(imageInputId).click();

  const onClickRemove = () => {
    const userSelection = window.confirm(chatConstants.removeGroupIcon);
    if (userSelection) {
      setRoomData((prev) => ({
        ...prev,
        avatar: '',
      }));
    }
  };

  const onChangeRoomInfo = (event, key) => {
    let value = event.target.value;
    if (key === "name") {
      value = convertMultipleSpacesToSingleSpace(value);
    }
    if (key === "name" || key === "description") {
      value = CapitalizeFirstLetter(value);
    }
    setRoomData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onCloseDialog = () => onClose();

  const onPressMenuItem = (title) => {
    if (title === "Upload Image") return pickImage();
    if (title === "Remove Image") return onClickRemove();
  };

  const id = "edit-chat-room-profile-dialog";
  return (
    <BootstrapDialog onClose={onCloseDialog} aria-labelledby={id} open={open}
      fullScreen={isMobileAndMediumDevice}
    >
      <Backdrop
        open={showLoader}
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
          <ChatHeader
            {...{
              title: chatConstants.editGroup,
              onClickBackIcon: onCloseDialog,
              isMobile: isMobileAndMediumDevice,
            }}
          />
       </DialogTitle>
      {!isMobileAndMediumDevice && (
        <DialogCloseButton 
          onClick={onCloseDialog}
        />
      )}
      <DialogContent>
        <div className={"chat-group-create-form-container"}>
          <div className={"chat-group-image-wrapper"}>
            {hasProfile ? (
              <AautiAvatar
                src={avatar?.size ? URL.createObjectURL(avatar) : avatar}
                alt={name ?? "avatar"}
                height={90}
                width={90}
              />
            ) : (
              <Lottie
                loop
                animationData={emptyImage}
                play
                style={emptyImageUploadStyles}
                onClick={pickImage}
              />
            )}
            <label htmlFor={imageInputId}>
              <input
                type="file"
                id={imageInputId}
                accept="image/*"
                style={{ display: "none" }}
                onChange={onImageSelected}
              />
            </label>
            <IconButton
              disableFocusRipple
              className="edit-avatar-icon"
              size="small"
              id={`${editMenuId}_button`}
              aria-controls={open ? `${editMenuId}_menu` : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup={true}
              onClick={handleClickListItem}
            >
              <MdCameraAlt fill="#ffffff" />
            </IconButton>
            <Menu
              id={`${editMenuId}_menu`}
              anchorEl={anchorEl}
              open={openEditProfileMenu}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": `${editMenuId}_button`,
                role: "listbox",
              }}
            >
              {editImageOptions.map((option, index) => (
                <MenuItem
                  key={option}
                  onClick={(event) => handleMenuItemClick(event, index)}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 12,
                    fontWeight: "500",
                    width: "100%",
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <AautiTextInput
            important={true}
            text={chatConstants.groupName}
            value={name}
            onChange={(e) => onChangeRoomInfo(e, "name")}
            style={{ width: "100%" }}
            helperText={`${name?.length}/100 (minimum 5 characters)`}
            maxLength={100}
          />
          <AautiTextInput
            rows={4}
            multiline={true}
            text={chatConstants.groupDescription}
            value={description}
            onChange={(e) => onChangeRoomInfo(e, "description")}
            style={{ width: "100%", marginBottom: "5px" }}
            helperText={`${description?.length}/200 (minimum 5 characters)`}
            maxLength={200}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Cancel</Button>
        <Button disabled={isBtnDisabled} autoFocus onClick={onPressSave}>
          {chatConstants.save}
        </Button>
      </DialogActions>
      <BootstrapDialog
        aria-label={`${id}-nested-image-cropper-dialog`}
        open={Boolean(cropImage?.size)}
        onClose={closeCrop}
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.modal + 5 })}
      >
        <Cropper
          src={
            cropImage?.size ? URL.createObjectURL(cropImage) : null
          }
          style={{ height: 300, width: "100%" }}
          initialAspectRatio={16 / 9}
          guides={false}
          ref={cropperRef}
        />
        <DialogActions>
          <Button onClick={closeCrop}>Cancel</Button>
          <Button autoFocus onClick={onSaveCroppedImage}>
            {"Save"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </BootstrapDialog>
  );
}

export default EditChatGroupDialog;
