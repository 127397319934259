import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAo6qkrOX2jFxQdz-LlTuVY9VEJxOp0PT8",
  authDomain: "aauti-d1b72.firebaseapp.com",
  projectId: "aauti-d1b72",
  storageBucket: "aauti-d1b72.appspot.com",
  messagingSenderId: "292354427329",
  appId: "1:292354427329:web:d7d7ff0132ed0a3cc4c740",
  measurementId: "G-MCGMH4L3CV",

  //personal keys
  // apiKey: "AIzaSyBaChUdXmmcEHwazAdoRs_uDiduJfOocNY",
  // authDomain: "project-mine-88957.firebaseapp.com",
  // projectId: "project-mine-88957",
  // storageBucket: "project-mine-88957.appspot.com",
  // messagingSenderId: "994369644520",
  // appId: "1:994369644520:web:ad1e2abd87829785e1665d",
  // measurementId: "G-P384NYWKDT",
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
var FacebookProvider = new firebase.auth.FacebookAuthProvider();
var provider = new firebase.auth.GoogleAuthProvider();
var GoogleProvider = new firebase.auth.GoogleAuthProvider().addScope("email");
GoogleProvider.setCustomParameters({
  prompt: "select_account",
});
export { auth, provider, FacebookProvider, GoogleProvider };
