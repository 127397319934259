import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const LoadingBackdrop = ({ loading = false }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
