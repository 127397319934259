import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";

export function getCourseDetails(
  loggedIn,
  id,
  endUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "myCourse/getCompleteCourseDetails",
    method: ApiMethods.GET,
    params: loggedIn ? { courseId: id, endUserId } : { courseId: id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getRefundConditions(data, onSuccess, onFailure) {
  ApiRequest({
    url: "refund/getRefundConditionsByType?",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function DeleteCart(data, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/deleteCart",
    method: ApiMethods.DELETE,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getServiceproviderCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesByUserId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getCourseReviews(courseId, sortState, onSuccess, onFailure) {
  ApiRequest({
    url: "ratingsReviews/getRatingsReviewsByCourseId",
    method: ApiMethods.GET,
    params: { courseId, sortBy: sortState },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getCoursesBySameCategory(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesListByCategory",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getAllCategories(onSuccess, onFailure) {
  ApiRequest({
    url: "category/getAllCategories",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getCoursesCountByCategoryAndSubcategory(
  category,
  subcategory,
  onSuccess,
  onFailure
) {
  const data = {
    categoryName: [category],
    subCategoryName: [subcategory],
  };
  ApiRequest({
    url: `myCourse/getCoursesCountByCategoryAndSubcategory`,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getCourseAdhocEvents(params, onSuccess, onFailure) {
  ApiRequest({
    url: `/mySchedule/getMySchedulesByUserId?`,
    method: ApiMethods?.GET,
    params: params,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
