import React from "react";
import { Avatar } from "@mui/material";
import AautiText from "../../globalComponents/AautiText";
import CloseButton from "../../globalComponents/CloseButton";

const InviteeCard = ({ user, handleRemove }) => {
  return (
    <div
      key={user?._id}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        columnGap: 5,
        border: "1px solid #ccc",
        padding: "6px",
        borderRadius: "5px",
        marginBottom: 4,
        position: "relative",
        width: "100%",
      }}
    >
      <div style={{ position: "absolute", right: 2 }}>
        <CloseButton
          title="Remove"
          onClick={() => {
            handleRemove(user);
          }}
        />
      </div>
      <Avatar src={user?.profileImage} />

      <div style={{ width: "100%" }}>
        <AautiText
          textId={"invitee"}
          textStyle={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 1,
            textOverflow: "ellipsis",
            maxWidth: "80%",
          }}
          title={user?.mobile || user?.email}
          size={"semi"}
        />
      </div>
    </div>
  );
};

export default InviteeCard;
