import React, {
  useRef,
  memo,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Cancel } from "@mui/icons-material";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";
import { BsChevronDown } from "react-icons/bs";
import { Divider } from "@mui/material";

import Message from "./Message";
import SearchBar from "../../components/searchBar";
import { messageTypes } from "../../constants";
import "./styles.scss";
import { debounce } from "lodash";

function MessagesContainer({
  forwardRef,
  messages,
  showSearchBar,
  toggleSearchBarVisibility,
  sender,
  updatePageNo,
  deleteMsgIds,
  searchCount,
  fetchMessageTextCount,
  clearSearchCount,
  ...rest
}) {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const listRef = useRef([]);
  const [highlightedIndex, setHigHlightedIndex] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const highlightedIndices = useMemo(() => {
    return messages.reduce((result, msg, i) => {
      if (
        searchInput.length > 1 &&
        [messageTypes.TEXT, messageTypes.REPLY].includes(msg.type) &&
        msg?.text?.toLowerCase().includes(searchInput?.toLowerCase())
      ) {
        result.push(i);
      }
      return result;
    }, []);
  }, [messages, searchInput]);

  const totalSearchCount = searchCount ? searchCount: highlightedIndices.length 

  const handleSearchScroll = (direction) => {
    setHigHlightedIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0) return prevIndex;
      if (newIndex >= highlightedIndices.length) {
        updatePageNo();
        return prevIndex;
      }
      return newIndex;
    });
  };

  useEffect(() => {
    if (
      highlightedIndices.length &&
      listRef.current[highlightedIndices[highlightedIndex]]
    ) {
      listRef.current[highlightedIndices[highlightedIndex]]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightedIndex, highlightedIndices]);

  const onCloseSearchBar = () => {
    setSearchInput("");
    setHigHlightedIndex(0);
    toggleSearchBarVisibility();
    clearSearchCount()
  };


  const onChangeSearchInput = (e) => {
    const text = e.target.value;
    setSearchInput(text);
    if (text.length === 0) {
      setHigHlightedIndex(0);
      clearSearchCount(0)
    }
    if(text.length) fetchMessageTextCount(text)
  };

  const scrollToBottom = () =>
    forwardRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });

  function renderScrollToBottomWrapper() {
    return (
      <button className={"scroll_btn"} onClick={scrollToBottom}>
        <BsChevronDown fontSize={24} />
      </button>
    );
  }

  const onScroll = () => {
    const offset = 15;
    const container = forwardRef.current;
    const height =
      container.scrollHeight - container.clientHeight + container.scrollTop;
    if (height < offset) updatePageNo();
    if (container.scrollTop === 0) setShowScrollButton(false);
    if (
      container.scrollTop !== 0 &&
      container.scrollTop + (container.clientHeight - offset) <= 0
    ) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const handleScroll = debounce(onScroll, 300);

  const handleUpDownArrow = (e) => {
    const arrowDirection = e.target.dataset.arrow;
    if(!arrowDirection) return;
    handleSearchScroll(parseInt(arrowDirection));
  };

  const renderMessage = useCallback(
    (message, index) => {
      if (!message.sender) {
        message.sender = { _id: 0 };
      }
      if (messages && sender) {
        const previousMessage = messages[index + 1] || {};
        const nextMessage = messages[index - 1] || {};
        const isSelected = deleteMsgIds?.includes(message?._id);
        const showSelectIcon = deleteMsgIds?.length > 0;
        const messageProps = {
          ...rest,
          sender,
          currentMessage: { ...message },
          previousMessage,
          nextMessage,
          position: message?.sender?._id === sender?._id ? "right" : "left",
          showSelectIcon,
          isSelected,
          searchText: searchInput?.length > 0 ? searchInput : undefined,
          highlighted:
            highlightedIndices?.[highlightedIndex] === index ||
            ((highlightedIndices.length === 1 || highlightedIndex === 0) &&
              index === 0),
        };
        return (
          <Message
            ref={(ele) => (listRef.current[index] = ele)}
            key={message._id}
            {...messageProps}
          />
        );
      }
      return null;
    },
    [highlightedIndex, highlightedIndices]
  );

  const activeSearchIndex = useMemo(
    () => highlightedIndex + (highlightedIndices.length ? 1 : 0),
    [highlightedIndex, highlightedIndices]
  );

  return (
    <div className="messages-container-wrapper">
      {showSearchBar && (
        <div className="div-wrapper searchbar">
          <SearchBar
            {...{
              value: searchInput,
              showBackIcon: false,
              onChangeSearchInput,
              onPressClear: () => setSearchInput(""),
            }}
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <div className="highlighted-search-msgs-count-container">
            <span>
              {activeSearchIndex} / {totalSearchCount}
            </span>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem />
          <div className="div-wrapper" onClick={handleUpDownArrow}>
            <CiCircleChevUp size={24} data-arrow="1" />
            <CiCircleChevDown size={24} data-arrow="-1" />
          </div>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Cancel onClick={onCloseSearchBar} />
        </div>
      )}
      <div
        ref={forwardRef}
        className="messages-container column-reverse-div"
        onScroll={handleScroll}
      >
        {showScrollButton && renderScrollToBottomWrapper()}
        <ul className="column-reverse-div gap">
          {messages.map(renderMessage)}
        </ul>
      </div>
    </div>
  );
}

export default memo(MessagesContainer);
