import React from 'react'
import SkeletonChatListItem from './ChatListItem'
import { Box } from '@mui/material'

function SkeletonChatsList({size = 10}) {
  const list = new Array(size).fill(0)
  return (
    <ul className="overflow-container chats-list-container">
       {list.map(SkeletonChatListItem)}
    </ul>
  )
}

export default SkeletonChatsList