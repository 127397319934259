import { chatUrl } from "../service/api-constants.js";
import {
  MaxChatResultsCount,
  SocketEvents,
} from "../components/chat/constants";
import {
  setSocketOpenStatus,
  setActiveRoom,
  setActiveUser,
  updateUnreadCount,
} from "../redux/reducer/chatSlice.js";
import AMPWebSocket from "../components/chat/AMPWebSocket.js";

const MAX_SOCKET_RECONNECT_ATTEMPTS = 5;
const MAX_QUEUED_EVENTS = 10;

const isConnecting = (socket) => socket?.readyState === WebSocket.CONNECTING;

const socketMiddleware = (store) => {
  let socket = null;
  let timerId;
  let isConnected = false;

  function closeSocket(error = true) {
    if(typeof error === "boolean" && error){
      isConnected = false;
      socket?.close();
      socket = null;
    }
  }

  function onOpenSocket() {
    timerId && clearInterval(timerId);
    getUnreadCount();
  }

  function onMessage(msgEvent) {
    try {
      const { event, ...rest } = JSON.parse(msgEvent.data);
      if (!isConnected && !isConnecting(socket)) {
        store.dispatch(setSocketOpenStatus(true));
        isConnected = true;
      }

      switch (event) {
        case SocketEvents.ping:
          send({ event: SocketEvents.pong });
          break;
        case SocketEvents.connected:
          onOpenSocket();
          break;
        case SocketEvents.users.getUnreadCount:
          store.dispatch(updateUnreadCount(rest.unreadCount));
          break;
        case SocketEvents.users.status:
          store.dispatch(setActiveUser(rest.data));
          break;
        case SocketEvents.users.getSupport:
        case SocketEvents.room.getRoom:
          store.dispatch(setActiveRoom({ data: rest.data }));
          break;

        default: {
          const eventHandler = getChatPropFromStore("eventHandler");
          if (typeof eventHandler === "function") {
            eventHandler({ event, ...rest });
          } else {
            handleEventsInMiddleWare({ event, ...rest });
          }
          break;
        }
      }
    } catch (error) {
      console.log("CHAT", error.message);
    }
  }

  function getChatPropFromStore(prop) {
    const chatState = store.getState().chat;
    return chatState?.[prop];
  }

  function getUnreadCount() {
    send({
      event: SocketEvents.users.getUnreadCount,
    });
  }

  function handleEventsInMiddleWare(props) {
    if (
      props?.data?.hasOwnProperty("message") &&
      (!props?.batchId || !props?.sessionId)
    ) {
      getUnreadCount();
    }
  }

  function send(data) {
    if (isConnected) {
      const stringifiedData = JSON.stringify(data);
      socket?.send(stringifiedData);
    }
  }

  const connectSocket = (userId) => {
    try {
      if (isConnected || isConnecting(socket) || !userId) return;

      socket = new AMPWebSocket(`${chatUrl}${userId}`, [], {
        maxRetries: MAX_SOCKET_RECONNECT_ATTEMPTS,
        maxEnqueuedMessages: MAX_QUEUED_EVENTS,
      });
      socket.onopen = onOpenSocket;
      socket.onmessage = onMessage;
      socket.onerror = closeSocket;
      socket.onclose = closeSocket;
    } catch (error) {
      console.log("something went wrong");
    }
  };

  return (next) => (action) => {
    switch (action?.type) {
      case SocketEvents.connect:
        if (!isConnected) {
          connectSocket(action.payload);
        }
        break;

      case SocketEvents.room.getConversations:
        getUnreadCount();
      case SocketEvents.getContacts:
      case SocketEvents.getCourseSubscribers:
      case SocketEvents.room.getRoom:
        send({
          event: action.type,
          ...action.payload,
          limit: MaxChatResultsCount,
        });
        break;

      case SocketEvents.users.getUnreadCount:
        getUnreadCount();
        break;

      case SocketEvents.room.getMessagesByRoom:
        send({
          event: action.type,
          roomId: action.roomId,
          pageNo: action.pageNo,
          limit: MaxChatResultsCount,
        });
        break;

      case SocketEvents.users.status:
        send({ event: action.type, userId: action.payload });
        break;

      case SocketEvents.room.updateInfo:
      case SocketEvents.room.deleteConversation:
      case SocketEvents.room.freeze:
      case SocketEvents.room.unFreeze:
      case SocketEvents.room.updateLastSeen:
      case SocketEvents.room.clearConversation:
      case SocketEvents.room.getMembers:
      case SocketEvents.room.makeAdmin:
      case SocketEvents.room.makeModerator:
      case SocketEvents.room.removeModerator:
      case SocketEvents.room.removeAsAdmin:
      case SocketEvents.room.removeUser:
      case SocketEvents.room.leave:
      case SocketEvents.room.blockUser:
      case SocketEvents.room.unblockUser:
      case SocketEvents.room.updateTimeFreeze:
      case SocketEvents.room.createRoom:
      case SocketEvents.message.searchCount:
      case SocketEvents.message.send:
      case SocketEvents.message.edit:
      case SocketEvents.message.delete:
      case SocketEvents.message.messageDeleteForMe:
      case SocketEvents.aautiFeed.getFeedsAccess:
      case SocketEvents.aautiFeed.updateFeedsAccess:
      case SocketEvents.aautiFeed.recentFeed:
      case SocketEvents.users.getSupport:
      case SocketEvents.room.addUsers:
      case SocketEvents.call.saveSessionCall:
      case SocketEvents.call.updateSessionCall:
        send({
          event: action.type,
          ...(action?.payload ?? {}),
        });
        break;
      default:
        break;
    }
    next(action);
  };
};

export default socketMiddleware;
