import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

const categoriesSectionApis = {
  getTrendingCategories: "user/getTrendingCategories",
  getCategoriesSpecialDeals: "user/getCategoriesByOfferPercentage",
  getCategoriesByOfferPercentage: "user/getCategoriesByOfferPercentage",
  getBlockBusterCategories: "user/getBlockBusterCategories",
  getNewCategories: "user/getNewCategories",
};

function getTrendingCategories(params, onSuccess, onFailure) {
  ApiRequest({
    url: categoriesSectionApis.getTrendingCategories,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getCategoriesSpecialDeals(onSuccess, onFailure) {
  ApiRequest({
    url: categoriesSectionApis.getCategoriesSpecialDeals,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getBlockBusterCategories(onSuccess, onFailure) {
  ApiRequest({
    url: categoriesSectionApis.getBlockBusterCategories,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getCategoriesByOfferPercentage(params, onSuccess, onFailure) {
  ApiRequest({
    url: categoriesSectionApis.getCategoriesByOfferPercentage,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getNewCategories(onSuccess, onFailure) {
  ApiRequest({
    url: categoriesSectionApis.getNewCategories,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export const HomeCatServices = {
  getTrendingCategories,
  getCategoriesSpecialDeals,
  getBlockBusterCategories,
  getCategoriesByOfferPercentage,
  getNewCategories,
};
