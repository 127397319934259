import React from "react";
import AccountTabs from "../globalComponents/AccountTabs";
import "./Styles.scss";
import { useSelector } from "react-redux";
import NotificationsContainer from "./Container";
import { NotificationConstants } from "./Constants";
import AautiText from "../globalComponents/AautiText";
import Notifications from "../home/notifications/index";
import AppContainer from "../../layouts/AppContainer";

let role = ["Read", "Action"];

const Notification = () => {
  const { onSwitchRoleTab, activeTab } = NotificationsContainer();
  const { mobileDevices } = useSelector((state) => state.responsive);
  const ActiveRoleTab = ({ titles, onHandleTitleClick }) => {
    return (
      <div
        className="notification-titles"
        style={{
          width: mobileDevices ? "100%" : "30%",
        }}
      >
        {titles?.map((title, index) => (
          <AautiText
            title={title}
            textStyle={{
              color: activeTab === title ? "#ffffff" : "#000000",
              border: `1px solid ${
                activeTab === title ? "#078dda" : "#dedede"
              }`,
              backgroundColor: activeTab === title ? "#3083ef" : "#ffffff",
              padding: mobileDevices ? "8px 10px" : "10px 15px",
            }}
            key={index}
            onClick={() => activeTab !== title && onHandleTitleClick(title)}
            className="notification-text"
            size={"normal"}
            weight={"bold"}
          />
        ))}
      </div>
    );
  };

  return (
    <AppContainer
      style={{
        padding: mobileDevices ? "2% 4%" : "1% 2%",
      }}
    >
      <AccountTabs
        {...{
          tabObj: {
            label: NotificationConstants?.notifications,
            navigation: "/notifications",
            active: true,
          },
        }}
      />
      <ActiveRoleTab titles={role} onHandleTitleClick={onSwitchRoleTab} />
      <Notifications responseType={activeTab} />
    </AppContainer>
  );
};

export default Notification;
