import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconButton } from "@mui/material";
import "./style.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ChevronRight } from "@mui/icons-material";

const CauroselList = ({
  settings,
  children,
  style,
  sliderRef,
  handleSliderDirec,
  showButtons = true,
  arrowsWidth = "86%",
  disabledLeftButton,
  disabledRight,
  from,
  ...rest
}) => {
  const defaultStyle = {
    ...style,
    zIndex: 10,
    padding: "0px 0px !important",
    "&& .slickList": {
      padding: "0px !important",
    },
    "&& .slickSlider": {
      marginTop: 20,
      width: "100%",
    },
    "&& .slickInitialized": {
      marginTop: 10,
    },
  };

  const homeStyles = {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    gap: 15,
    top: -25,
    right: 10,
    justifyContent: "flex-end",
    alignSelf: "flex-end",
  };

  const bpStyles = {
    width: arrowsWidth,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const renderArrows = () => {
    return (
      <div
        className="slider-arrow"
        style={from === "businessPage" ? bpStyles : homeStyles}
      >
        <IconButton
          disabled={disabledLeftButton}
          size="medium"
          // className="arrow-btn prev"
          aria-label="left-arrow"
          // disabled={currentIndex === 0}
          onClick={(e) => {
            e.stopPropagation();
            handleSliderDirec("left");
          }}
          sx={{
            padding: 0,
            zIndex: 100,
            background: disabledLeftButton ? "#ffffff" : "#3997eadb",
            boxShadow: "2px 2px 10px 2px #dddddd",
            // top: "46%",
            left: "3px",
            color: "#fff",
            "&:hover": { backgroundColor: !disabledLeftButton && "#3997eadb" },
          }}
        >
          <ChevronLeftIcon
            fontSize="medium"
            sx={{ color: disabledLeftButton ? "#ddddd" : "#fff" }}
          />
        </IconButton>
        <IconButton
          size="medium"
          disabled={disabledRight}
          // className="arrow-btn next"
          onClick={(e) => {
            e.stopPropagation();
            handleSliderDirec("right");
          }}
          sx={{
            padding: 0,
            zIndex: 100,
            background: disabledRight ? "#f2f2f2" : "#3997eadb",
            boxShadow: "2px 2px 10px 2px #dddddd",
            // top: "45%",
            right: 0,
            "&:hover": { backgroundColor: !disabledRight && "#3997eadb" },
          }}
        >
          <ChevronRight
            fontSize="medium"
            sx={{ color: disabledRight ? "#ddd" : "#fff" }}
          />
        </IconButton>
      </div>
    );
  };

  return (
    <div
      style={{
        // width: windowDimensions?.width < 1000 ? "60%" : "90%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        // marginTop: 10,
        position: !from && "relative",
        // paddingBottom: "10px",
      }}
    >
      {showButtons && renderArrows()}
      <Slider {...settings} style={defaultStyle} ref={sliderRef} {...rest}>
        {React.Children?.map(children, (child, index) => (
          <div key={index}>{child}</div>
        ))}
      </Slider>
    </div>
  );
};

export default CauroselList;
