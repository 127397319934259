import { Avatar, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { FaAngleRight } from "react-icons/fa6";
import JoinButton from "../globalComponents/JoinButton";
import { showToast } from "../globalComponents/Toast";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { useSelector } from "react-redux";
import { AuthService } from "./service";
import { getFirstTwoChars, returnColorbasedName } from "../CommonFunctions";
import "./index.scss";
import moment from "moment";
import VirtualInPerson from "../globalComponents/icons/VirtualInPersonIcon";
import VirtualIcon from "../globalComponents/icons/VirtualIcon";
import InPerson from "../globalComponents/icons/InPerson";
import { globalColors } from "../../themes/GlobalColors";

const StudentProfile = ({
  studentCourseData,
  studentDetailsId,
  setStudentProfile,
  setStudentDetailsId,
  classDetails,
  setClassDetails,
  studentId,
  screenName,
}) => {
  const [studentDetails, setStudentDetails] = useState("");
  const [pageType, setPageType] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackTypeState, selectedFeedbackTypes] = useState([]);
  const [reportValue, SetReportValue] = useState("");
  const [reportTypes, selectedReportTypes] = useState([]);
  const { loggedinUserId, userDetails, UserRole } = useSelector(
    (state) => state.app
  );
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [reportReason, setReportReason] = useState(null);
  const [skeletonState, setSkeletonState] = useState(true);
  const feedbackType = ["Uninterested", "Provocative", "Not Discliplined"];
  const [dateValue, setDateValue] = useState("");
  useEffect(() => {
    if (studentDetails?.dateOfBirth) {
      const isoDate = studentDetails.dateOfBirth;
      const originalDate = new Date(isoDate);
      const istOffsetMinutes = 330;
      originalDate.setMinutes(originalDate.getMinutes() + istOffsetMinutes);
      setDateValue(originalDate);
    }
  }, [studentDetails?.dateOfBirth]);
  const returnIcon = () => {
    if (studentDetails?.virtual && studentDetails?.inPerson) {
      return <VirtualInPerson />;
    } else if (studentDetails?.virtual) {
      return <VirtualIcon />;
    } else if (studentDetails?.inPerson) {
      return <InPerson />;
    } else {
      return "N/A";
    }
  };
  const studentData = [
    {
      leftContent: "Date of Birth",
      RightContent: studentDetails?.dateOfBirth
        ? moment(dateValue)?.format("DD-MM-YYYY")
        : "N/A",
      privacyData:
        studentDetails?.privacy?.dateOfBirth === "Everyone" ? "flex" : "none",
    },
    {
      leftContent: "Gender",
      RightContent: studentDetails?.gender || "N/A",
      privacyData:
        studentDetails?.privacy?.gender === "Everyone" ? "flex" : "none",
    },
    {
      leftContent: "Country",
      RightContent: studentDetails?.country || "N/A",
      privacyData:
        studentDetails?.privacy?.location === "Everyone" ? "flex" : "none",
    },
    {
      leftContent: "Languages",
      RightContent: studentDetails?.preferredLanguages?.length
        ? studentDetails?.preferredLanguages?.map((language, index) => (
            <span key={index}>
              {language}{" "}
              {index < studentDetails?.preferredLanguages?.length - 1 && ", "}
            </span>
          ))
        : "N/A",

      privacyData:
        studentDetails?.privacy?.languages === "Everyone" ? "flex" : "none",
    },
    {
      leftContent: "Class Type",
      RightContent: returnIcon(),
      privacyData: studentDetails?.virtual
        ? "flex"
        : studentDetails?.inPerson
        ? "flex"
        : "none",
    },
    {
      leftContent: "About",
      RightContent: studentDetails?.bio || "N/A",
      privacyData:
        studentDetails?.privacy?.aboutYou === "Everyone" ? "flex" : "none",
    },
  ];

  const onSubmitData = () => {
    setPageType("");
    setPageType("");
    setFeedbackText("");
    selectedFeedbackTypes([]);
    SetReportValue("");
    setReportReason("");
    selectedReportTypes([]);
    setStudentProfile(false);
  };
  const addFeedbackTypes = (newValue) => {
    if (feedbackTypeState?.includes(newValue)) {
      const r = feedbackTypeState?.filter((each) => {
        return each !== newValue;
      });
      selectedFeedbackTypes(r);
    } else {
      selectedFeedbackTypes([...feedbackTypeState, newValue]);
    }
  };
  useEffect(() => {
    reportAPI();
    getUserDetails();
  }, []);
  const getUserDetails = () => {
    AuthService.GetuserDetailsById(
      studentDetailsId?._id || studentId,
      onSuccessProfileDetails,
      onFailure
    );
  };
  const onSuccessProfileDetails = (response) => {
    if (response.status === "Success") {
      setSkeletonState(false);
      setStudentDetails(response?.result);
    } else {
      setSkeletonState(false);
      showToast("error", response?.message);
    }
  };

  const feedbackDataAPI = () => {
    const feedbackData = {
      serviceProviderId: loggedinUserId,
      endUserId: studentDetailsId?._id,
      serviceProviderName: userDetails?.displayName,
      endUserName: studentDetailsId?.displayName,
      serviceProviderImage: userDetails?.profileImage,
      endUserImage: studentDetailsId?.profileImage,
      comment: feedbackText,
      suggestions: feedbackTypeState,
    };
    if (studentCourseData?.myCourse?._id) {
      feedbackData.courseId = studentCourseData?.myCourse?._id;
      feedbackData.name = studentCourseData?.myCourse?.courseName;
      feedbackData.batchName = studentCourseData?.myCourse?.batch?.batchName;
      feedbackData.batchId = studentCourseData?.myCourse?.batch?._id;
    } else {
      feedbackData.sessionId = studentCourseData?.sessionId;
      feedbackData.name = studentCourseData?.adhocName;
    }
    AuthService.saveFeedback(feedbackData, onSuccessFeedback, onFailure);
  };
  const onSuccessFeedback = (response) => {
    if (response?.data?.status === "Success") {
      showToast("success", response?.data?.message);
      const newSubscribers = classDetails.subscribers;
      const filteredSubscribers = newSubscribers.map((each) =>
        each?._id === studentDetailsId._id
          ? { ...each, hasFeedback: true }
          : each
      );
      setClassDetails({ ...classDetails, subscribers: filteredSubscribers });
      onSubmitData();
    } else {
      showToast("info", response?.data?.message);
    }
  };
  const complaintAPI = () => {
    const complaintDataList = {
      description: reportValue,
      userName: userDetails?.displayName,
      userId: loggedinUserId,
      courseId: studentDetails?.myCourse?._id,
      gigId: null,
      sessionId: null,
      serviceProviderId: loggedinUserId,
      endUserId: studentDetails?._id,
      type: reportTypes,
    };
    AuthService.complaintSave(
      complaintDataList,
      onSuccessComplaintPost,
      onFailure
    );
  };
  const onSuccessComplaintPost = (response) => {
    if (response?.data?.status === "Success") {
      showToast("success", response?.data?.message);
      onSubmitData();
    } else {
      showToast("info", response?.message);
    }
  };
  const addReportTypes = (newValue) => {
    if (reportTypes?.includes(newValue)) {
      const r = reportTypes?.filter((each, index) => {
        return each !== newValue;
      });
      selectedReportTypes(r);
    } else {
      selectedReportTypes([...reportTypes, newValue]);
    }
  };
  const reportAPI = () => {
    const type = "End User";
    AuthService.getReports(type, onSuccessReport, onFailure);
  };
  const onSuccessReport = (response) => {
    if (response?.status === "Success") {
      setReportReason(response?.result);
    } else {
      showToast("error", response?.message);
    }
  };
  const onFailure = (error) => {
    console.log(error, "error");
  };
  const displayDiv =
    studentDetails?.privacy?.aboutYou === "Everyone" ||
    studentDetails?.privacy?.languages === "Everyone" ||
    studentDetails?.privacy?.location === "Everyone" ||
    studentDetails?.privacy?.gender === "Everyone" ||
    studentDetails?.privacy?.dateOfBirth === "Everyone" ||
    studentDetails?.inPerson ||
    studentDetails?.virtual;

  return (
    <>
      {skeletonState ? (
        <div className="student-profile-main-div" style={{ height: "70vh" }}>
          <Skeleton
            variant="rounded"
            sx={{ m: "15px 0px", width: "100%", height: 70 }}
          />
          <Skeleton
            variant="rounded"
            sx={{ m: "15px 0px", width: "100%", height: "70vh" }}
          />
          <Skeleton
            variant="rounded"
            sx={{ m: "15px 0px", width: "100%", height: 40 }}
          />
        </div>
      ) : (
        <div className="student-profile-main-div">
          <div className="student-profile-pageType">
            {screenName !== "spStudents" &&
              studentDetailsId?._id !== loggedinUserId && (
                <AautiText
                  onClick={() => {
                    if (pageType) {
                      setPageType("");
                    }
                  }}
                  weight={"bold"}
                  size={"medium"}
                  title={"Profile"}
                  textStyle={{
                    cursor: pageType && "pointer",
                    color: "#1e1e1e",
                  }}
                />
              )}
            {pageType && (
              <>
                <FaAngleRight
                  size={15}
                  style={{ margin: "0px 3px", color: "#1e1e1e" }}
                />
                <AautiText
                  weight={"bold"}
                  size={"medium"}
                  title={pageType}
                  textStyle={{ color: "#1e1e1e" }}
                />
              </>
            )}
          </div>
          {pageType === "Report Enduser" ? (
            <div className="ReportDialogDivRR">
              <AautiText
                weight={"medium"}
                title={"We won’t let the person know who is reported them"}
                size={"medium"}
                textStyle={{ color: "#1e1e1e" }}
                required
              />
              <div className="SPnewreportDiv">
                {reportReason?.map((each, index) => (
                  <div
                    className="SPnewReportSelect"
                    key={index}
                    style={{
                      border: !reportTypes?.includes(each)
                        ? "1px solid #E0E0E0"
                        : `1px solid ${globalColors.AAUTI_THEME_COLOR}`,
                      backgroundColor: reportTypes?.includes(each)
                        ? globalColors.AAUTI_THEME_COLOR
                        : globalColors.BackGround_Ligh_Color,
                    }}
                    onClick={() => {
                      addReportTypes(each);
                    }}
                  >
                    <AautiText
                      weight={"normal"}
                      title={each?.name}
                      size={"semi"}
                      textStyle={{
                        color: reportTypes?.includes(each)
                          ? "#ffffff"
                          : globalColors.HEADING_COLOR,
                      }}
                    />
                  </div>
                ))}
              </div>
              <AautiTextInput
                style={{ width: "100%", margin: "5px 0px 20px 0px" }}
                text={"Reason"}
                multiline
                rows={3}
                maxLength={300}
                important={true}
                value={reportValue}
                onChange={(e) => {
                  SetReportValue(e.target.value);
                }}
                helperText={`${
                  reportValue?.trim()?.length
                }/300 (minimum 15 characters)`}
              />
              <div className="student-profile-length-div">
                <JoinButton
                  disabled={
                    reportValue?.trim()?.length < 15 || reportTypes?.length < 1
                  }
                  onClick={() => {
                    complaintAPI();
                  }}
                  buttonStyle={{ width: 100, height: 40 }}
                  title={"Submit"}
                />
              </div>
            </div>
          ) : pageType === "Add Feedback" ? (
            <div className="ReportDialogDivRR" style={{ width: "100%" }}>
              <AautiText
                weight={"bold"}
                title={"We won’t let the person know who is reported them"}
                size={"medium"}
                textStyle={{ color: "#1e1e1e" }}
                required
              />
              <div className="SPnewreportDiv">
                {feedbackType?.map((each, index) => (
                  <div
                    className="SPnewReportSelect"
                    key={index}
                    style={{
                      border: !feedbackTypeState?.includes(each)
                        ? "1px solid #E0E0E0"
                        : `1px solid ${globalColors.AAUTI_THEME_COLOR}`,
                      backgroundColor: feedbackTypeState?.includes(each)
                        ? globalColors.AAUTI_THEME_COLOR
                        : globalColors.BackGround_Ligh_Color,
                    }}
                    onClick={() => {
                      addFeedbackTypes(each);
                    }}
                  >
                    <AautiText
                      weight={"normal"}
                      size={"semi"}
                      title={each}
                      textStyle={{
                        color: feedbackTypeState?.includes(each)
                          ? "#ffffff"
                          : globalColors.HEADING_COLOR,
                      }}
                    />
                  </div>
                ))}
              </div>
              <AautiTextInput
                style={{ width: "100%", margin: "5px 0px 20px 0px" }}
                text={"Feedback"}
                multiline
                important={true}
                rows={3}
                maxLength={500}
                value={feedbackText}
                onChange={(e) => {
                  setFeedbackText(e.target.value);
                }}
                helperText={`${
                  feedbackText?.trim()?.length
                }/500 (minimum 50 characters)`}
              />
              <div className="student-profile-length-div">
                <JoinButton
                  onClick={() => feedbackDataAPI()}
                  disabled={
                    feedbackText?.trim()?.length < 50 ||
                    feedbackTypeState?.length < 1
                  }
                  buttonStyle={{ width: 170, height: 40 }}
                  title={"Submit Feedback"}
                />
              </div>
            </div>
          ) : (
            pageType === "" && (
              <div className="student-profile-page-div">
                <div className="student-profile-avatar-div">
                  <div className="student-profile-avatar-div2">
                    {(studentDetails?.privacy?.profileImage &&
                      studentDetails?.privacy?.profileImage === "Everyone") ||
                    studentDetails?.profileImage ? (
                      <Avatar
                        sx={{ height: 50, width: 50, mr: 1.5 }}
                        alt={`${studentDetails?.displayName}`}
                        src={studentDetails?.profileImage}
                      />
                    ) : (
                      <Avatar
                        disabled={true}
                        sx={{
                          bgcolor: returnColorbasedName(
                            studentDetails?.displayName
                          ),
                          height: 50,
                          width: 50,
                          mr: 1.5,
                        }}
                      >
                        {studentDetails?.displayName &&
                          getFirstTwoChars(studentDetails?.displayName)}
                      </Avatar>
                    )}
                    <div className="student-profile-div">
                      <AautiText
                        weight={"bold"}
                        size={"semi"}
                        title={studentDetails?.displayName || "No Name"}
                      />
                      <AautiText
                        textStyle={{ color: "#5C5B57" }}
                        size={"small"}
                        title={
                          studentDetailsId?.isAdmin
                            ? "Service Provider"
                            : "End User"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="student-profile-screename-div"
                  style={{
                    height: screenName !== "spStudents" && "45vh",
                    display: displayDiv ? "flex" : "none",
                  }}
                >
                  {studentData.map((each, index) => (
                    <div
                      key={index}
                      className="student-profile-disaply-div"
                      style={{
                        display: each?.privacyData,
                        borderBottom:
                          index !== studentData?.length - 1
                            ? "1px solid #E0E0E0"
                            : null,
                      }}
                    >
                      <AautiText
                        textStyle={{
                          color: "#5C5B57",
                          marginRight:
                            each?.leftContent === "About"
                              ? 20
                              : each?.leftContent === "Home"
                              ? 20
                              : 0,
                        }}
                        weight={"bold"}
                        size={"semi"}
                        title={each?.leftContent}
                      />
                      <AautiText
                        size={"semi"}
                        title={each?.RightContent}
                        style={{ width: "100%" }}
                        textStyle={{
                          overflow: "scroll",
                          WebkitLineClamp: 1,
                          maxWidth: mobileDevices ? "75%" : "80%",
                        }}
                      />
                    </div>
                  ))}
                </div>
                {screenName !== "spStudents" &&
                  studentDetailsId?._id !== loggedinUserId && (
                    <div
                      className="student-profile-useRole-div"
                      style={{
                        display:
                          UserRole === "serviceprovider" ? "flex" : "none",
                        marginTop: !displayDiv && 30,
                      }}
                    >
                      <AautiText
                        onClick={() => {
                          setPageType("Report Enduser");
                        }}
                        textStyle={{
                          color: globalColors.AAUTI_THEME_COLOR,
                          cursor: "pointer",
                        }}
                        weight={"bold"}
                        size={"semi"}
                        title={"Report Enduser"}
                      />
                      <AautiText
                        onClick={() => {
                          setPageType("Add Feedback");
                        }}
                        textStyle={{
                          color: globalColors.AAUTI_THEME_COLOR,
                          cursor: "pointer",
                        }}
                        size={"semi"}
                        weight={"bold"}
                        title={"Add Feedback"}
                      />
                    </div>
                  )}
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};
export default StudentProfile;
