import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiRequest } from "../../service/api-request";

function getAllNotificationsByUserId(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_NOTIFICATIONS_USER,
    method: ApiMethods.GET,
    params: { userId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function updateUserDetails(userDetailsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPADTE_USER_DETAILS,
    method: ApiMethods.PUT,
    data: userDetailsData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getProfilePercentage(
  userId,
  role = "serviceprovider",
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "user/getProfilePercentage",
    method: ApiMethods.GET,
    params: { userId, role },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllCartsByUser(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_CARTS_BY_USER + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export const Service = {
  getAllNotificationsByUserId,
  updateUserDetails,
  getProfilePercentage,
  getAllCartsByUser,
};
