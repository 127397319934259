import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Style.scss";
import AautiText from "../globalComponents/AautiText";
import AnimateButton from "../../utils/AnimateButton";
import { IoMdTime } from "react-icons/io";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { cardTitles } from "./Constants";
import ClassJoinButton from "../globalComponents/classJoinButton/ClassJoinButton";

const EventCard = ({ event, key, userDetails, UserRole, loggedinUserId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      key={key}
      className="today-event-card"
      onClick={() => {
        event?.scheduleType != "offTime" &&
          navigate("/join_class", { state: event });
      }}
    >
      <Avatar src={event?.courseImage} />

      <div style={{ marginLeft: 10 }}>
        <AautiText title={event?.courseName} />
        <AautiText
          title={`Batch : ${event?.batchName}`}
          size={"semi"}
          textStyle={{
            display:
              (event?.scheduleType == "offTime" ||
                event?.scheduleType == "adhoc") &&
              "none",
          }}
        />
        <div className="time-container">
          <IoMdTime style={{ marginRight: 7, color: "#000000" }} />
          <AautiText
            size={"small"}
            title={
              moment(event?.startTime).format("hh:mm A") +
              " to " +
              moment(event?.endTime).format("hh:mm A")
            }
          />
        </div>
        {event?.scheduleType == "offTime" ? (
          <AautiText
            title="Offtime"
            size={"semi"}
            textStyle={{ color: "#3083EF" }}
          />
        ) : (
          <ClassJoinButton event={event} />
        )}
      </div>
    </div>
  );
};

const TodaysEvents = () => {
  const { todayEvents, userDetails, UserRole, loggedinUserId } = useSelector(
    (state) => state.app
  );

  return (
    <div className="day-events-cards">
      <AautiText
        title={
          todayEvents?.length == 1
            ? cardTitles?.upcomingSession
            : cardTitles?.upcomingSessions
        }
      />
      {todayEvents?.map((each, index) => {
        return (
          <EventCard
            event={each}
            key={index}
            {...{ userDetails, loggedinUserId, UserRole }}
          />
        );
      })}
    </div>
  );
};

export default TodaysEvents;
