import React, { useEffect, useState } from "react";
import { showToast } from "../../../globalComponents/Toast";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import {
  conversationTypes,
  SocketEvents,
  emptyImageUploadStyles,
  maxImageSizeInBytes,
  chatConstants,
  ChatRoomAccess,
} from "../../constants";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  DialogTitle,
} from "@mui/material";

import { IoIosCloseCircleOutline } from "react-icons/io";
import Lottie from "react-lottie-player";
import AautiTextInput from "../../../globalComponents/AautiTextInput";
import AautiText from "../../../globalComponents/AautiText";
import SearchBar from "../searchBar";
import EmptyData from "../../../globalComponents/EmptyData";
import {BootstrapDialog} from "../styled_components";
import AautiAvatar from "../AautiAvatar";

import { apiServices } from "../../../../service/apiService";
import {
  updateContacts,
  getContacts,
} from "../../../../redux/reducer/chatSlice";
import { emptyImage } from "../../animations";
import { convertMultipleSpacesToSingleSpace } from "../../../../utils/Helpers";
import { CapitalizeFirstLetter } from "../../../CommonFunctions";
import ChatHeader from "../chat_header";
import DialogCloseButton from "./DialogCloseButton";

const SearchGroupType = {
  regular: "Regular",
  institute: "Institute",
};

const initialState = {
  selectedContacts: [],
  searchInput: undefined,
  group: {
    name: "",
    avatar: "",
    description: "",
  },
  contacts: [],
  groupType: SearchGroupType.regular,
};

function CreateChatGroupDialog({
  open,
  onClose,
  role,
  instituteId,
  isInstituteAdmin,
}) {
  const contactsList = useSelector(getContacts);
  const { mobileDevices, aboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const [state, setState] = useState(initialState);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();

  const isMobileAndMediumDevice = mobileDevices || aboveLargeMobileDevices;

  const {
    group: { name, avatar, description },
    selectedContacts,
    searchInput,
    contacts,
    groupType,
  } = state;

  useEffect(() => {
    if (open) {
      setState({
        ...initialState,
        contacts: [],
        groupType: isInstituteAdmin
          ? SearchGroupType.institute
          : SearchGroupType.regular,
        selectedContacts: [],
      });
    } else {
      setState({ ...initialState, selectedContacts: [] });
    }
  }, [open]);

  const onPressCreateGroup = async () => {
    if (!name) {
      return showToast("info", "Add group name");
    }
    if (selectedContacts.length === 0) {
      return showToast("info", "Add group description");
    }

    try {
      let image;
      if (!!avatar) {
        setShowLoader(true);
        image = await apiServices.uploadImage(avatar);
      }

      let data = {
        name: name?.trim(),
        members: selectedContacts,
        avatar: image,
        description: description?.trimEnd(),
        type: conversationTypes.GROUP,
        access: ChatRoomAccess.EVERYONE,
      };
      if (groupType === SearchGroupType.institute || isInstituteAdmin) {
        data.instituteId = instituteId;
      }
      dispatch({ type: SocketEvents.room.createRoom, payload: data });
      setShowLoader(false);
    } catch (err) {
      showToast("info", err.message);
      setShowLoader(false);
    }
  };

  const onPressCard = (isSelected, item) => {
    item = item?.type ? item.user : item;
    let { contacts, selectedContacts, groupType } = state;
    if (isSelected) {
      selectedContacts = selectedContacts.filter(
        (userId) => userId !== item._id
      );
      contacts = contacts.filter((user) => user._id !== item._id);
    } else {
      if (groupType === SearchGroupType.regular) delete item.instituteId;
      contacts.push(item);
      selectedContacts.push(item?._id);
    }
    setState((prev) => ({
      ...prev,
      contacts,
      selectedContacts,
    }));
  };

  const pickImage = async (e) => {
    const files = e.target.files;
    if (!files.length) {
      return showToast("info", "Unable to pick image. Please try again.");
    }
    if (files.length && files[0].size <= maxImageSizeInBytes) {
      setState((prev) => ({
        ...prev,
        group: { ...prev.group, avatar: files[0] },
      }));
    } else {
      showToast("info", "File size <= 5mb");
    }
  };

  const onClickDelete = () => {
    const userSelection = window.confirm("Do you want to remove group icon?");
    if (userSelection) {
      setState((prev) => ({
        ...prev,
        group: { ...prev.group, avatar: "" },
      }));
    }
  };

  const onChangeGroupData = (event, key) => {
    let value = event.target.value;
    if (key === "name") {
      value = convertMultipleSpacesToSingleSpace(value);
    }
    if (key === "name" || key === "description") {
      value = CapitalizeFirstLetter(value);
    }
    setState((prev) => ({
      ...prev,
      group: { ...prev.group, [key]: value },
    }));
  };

  const resetSearchInput = () => {
    setState((prev) => ({
      ...prev,
      searchInput: undefined,
    }));
    dispatch(updateContacts([]));
  };

  const onChangeSearchInput = (event) => {
    let text = event.target.value;
    setState((prev) => ({
      ...prev,
      searchInput: text,
    }));
    if (text?.length) {
      let payload = {
        name: text?.trim(),
        role,
        roomType: conversationTypes.ONE_TO_ONE,
      };
      if (groupType === SearchGroupType.institute || isInstituteAdmin) {
        payload.instituteId = instituteId;
      }
      dispatch({
        type: SocketEvents.getContacts,
        payload,
      });
    } else {
      resetSearchInput();
    }
  };

  const getMainContactsList = () => {
    if (searchInput?.length > 0) {
      return contactsList;
    }
    return contacts.filter((user) =>
      groupType === SearchGroupType.institute
        ? user?.instituteId
        : !user?.instituteId
    );
  };

  const renderGroupTypeRadioBtn = (type, i) => {
    const isActive = type === groupType;
    return null;
  };

  const renderUser = (value) => {
    const labelId = `checkbox-list-secondary-label-${value}`;
    let isSelected = state.selectedContacts.includes(
      value?.type ? value?.user?._id : value._id
    );
    const name = value?.displayName ?? value?.user?.displayName;
    const profileImage = value?.profileImage ?? value?.user?.profileImage;
    const onClick = () => onPressCard(isSelected, value);
    return (
      <ListItem
        key={value._id}
        secondaryAction={
          <Checkbox
            checked={isSelected}
            onChange={onClick}
            inputProps={{ "aria-label": `${name}` }}
          />
        }
        disablePadding
        className={"list-item-add-group"}
      >
        <ListItemButton>
          <ListItemAvatar>
            <AautiAvatar alt={name} src={profileImage} width={40} height={40} />
          </ListItemAvatar>
          <ListItemText id={labelId} primary={name || "N/A"} />
        </ListItemButton>
      </ListItem>
    );
  };

  const mainContactsList = getMainContactsList();
  const groupNameLength = name?.length;
  const isBtnDisabled =
    !groupNameLength ||
    !(groupNameLength >= 5 && groupNameLength <= 100) ||
    !selectedContacts?.length
      ? true
      : false;
  const hasProfile = !avatar ? false : true;

  const onCloseDialog = (e, reason) => {
    if (reason === "backdropClick") return;
    onClose();
  };

  return (
    <BootstrapDialog
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={isMobileAndMediumDevice}
    >
      <Backdrop
        open={showLoader}
        sx={(theme) => ({
          color: "#fff",
          position: "absolute",
          zIndex: theme.zIndex.drawer + 1,
        })}
        onClose={() => {}}
      >
        {<CircularProgress color="inherit" />}
      </Backdrop>
        <DialogTitle>
          <ChatHeader
            {...{
              title: chatConstants.createGroup,
              onClickBackIcon: onCloseDialog,
              isMobile: isMobileAndMediumDevice,
            }}
          />
       </DialogTitle>
      {!isMobileAndMediumDevice && (
        <DialogCloseButton 
          onClick={onClose}
        />
      )}
      <DialogContent>
          <div className={"chat-group-create-form-container"}>
            {hasProfile ? (
              <div className={"chat-group-image-wrapper"}>
                <img
                  src={URL.createObjectURL(avatar)}
                  alt={name ?? "avatar"}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "50px",
                  }}
                  className="chat-group-image"
                />
                <IoIosCloseCircleOutline
                  onClick={onClickDelete}
                  size={20}
                  color="red"
                  style={{
                    position: "relative",
                    bottom: 70,
                    left: 75,
                    cursor: "pointer",
                  }}
                />
              </div>
            ) : (
              <div className={"chat-group-image-wrapper"}>
                <label htmlFor="imageInput" className={"upload-icon-on-hover"}>
                  <AddPhotoAlternateOutlinedIcon
                    className={"upload-image-icon"}
                  />
                </label>
                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={pickImage}
                />
                <Lottie
                  loop
                  animationData={emptyImage}
                  play
                  style={emptyImageUploadStyles}
                />
              </div>
            )}

            <AautiTextInput
              important={true}
              text={chatConstants.groupName}
              value={name}
              onChange={(e) => onChangeGroupData(e, "name")}
              style={{ width: "100%" }}
              helperText={`${name?.length}/100 (minimum 5 characters)`}
              maxLength={100}
            />
            <AautiTextInput
              rows={2}
              multiline={true}
              text={chatConstants.groupDescription}
              value={description}
              onChange={(e) => onChangeGroupData(e, "description")}
              style={{ width: "100%", marginBottom: "5px" }}
              helperText={`${description?.length}/200 (minimum 5 characters)`}
              maxLength={200}
            />
            {renderGroupTypeRadioBtn()}
          </div>
          <AautiText title={chatConstants.addUsers}/>
          <SearchBar
            {...{
              id: "filled-adornment-amount",
              placeholder: chatConstants.searchForPeopleToAdd,
              value: state.searchInput,
              onChangeSearchInput,
              onPressClear: resetSearchInput,
              showBackIcon: false,
              focused: true,
            }}
          />
          <AautiText
            size={"small"}
            weight={"medium"}
            title={`(Added ${selectedContacts.length} participants)`}
            textAlign={"right"}
            textStyle={{ marginTop: "2px" }}
          />
          <div id={"list_container_add_group"}>
            {!mainContactsList?.length && searchInput?.length > 0 && (
              <EmptyData
                {...{
                  text: chatConstants.noResults,
                  showExplore: false,
                  height: "100%",
                }}
              />
            )}
            <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
              {mainContactsList?.map(renderUser)}
            </List>
          </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={isBtnDisabled} autoFocus onClick={onPressCreateGroup}>
          {chatConstants.save}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default CreateChatGroupDialog;
