import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { removePhoneCode } from "../../components/CommonFunctions";
import { idProofsList } from "./VerifyUserStrings";
import { UploadBaseUrl } from "../../service/api-constants";
import { uploadUrl } from "../../service/api-constants";
import { ApiPaths } from "../../service/api-constants";
import { showToast } from "../../components/globalComponents/Toast";

export const Container = () => {
  const { countryObject, userDetails } = useSelector((state) => state.app);
  const mainContainerRef = useRef();
  const [legalInformation, setLegalInformation] = useState({
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    mobile: userDetails?.mobile,
    email: userDetails?.email,
    country: countryObject,
    isMobileVerified: userDetails?.isMobileVerified,
    isEmailVerified: userDetails?.isEmailVerified,
  });

  const [verificationMethod, setVerificationMethod] = useState("otp");
  const [loader, setLoader] = useState(false);
  const [verificationDetails, setVerificationDetails] = useState({
    country: "",
    idType: idProofsList[0],
    idNumber: "",
    idFrontSide: "",
    idBackSide: "",
  });

  const alterMobile = useCallback(() => {
    return removePhoneCode(`+${userDetails?.mobile}`, countryObject?.phoneCode);
  }, [userDetails, countryObject]);

  useEffect(() => {
    setLegalInformation((prev) => ({
      ...prev,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      mobile: alterMobile(),
      email: userDetails?.email,
      country: countryObject,
      isMobileVerified: userDetails?.isMobileVerified,
      isEmailVerified: userDetails?.isEmailVerified,
    }));
    setVerificationDetails((prev) => ({
      ...prev,
      country: userDetails?.country,
    }));
  }, [userDetails, countryObject]);

  const handleChangeInputs = (value, base) => {
    setLegalInformation((prev) => ({
      ...prev,
      [base]: value,
      ...(base === "email" && { isEmailVerified: false }),
      ...(base === "mobile" && { isMobileVerified: false }),
    }));
  };

  const handleChangeVerification = (value, base) => {
    setVerificationDetails((prev) => ({
      ...prev,
      [base]: value,
    }));

    const baseList = ["idBackSide", "idFrontSide"];
    if (baseList?.includes(base)) {
      mainContainerRef.current.scrollTop =
        mainContainerRef.current.scrollHeight;
    }
  };

  const handleOpen = () => {
    setLoader(true);
  };
  const handleClose = () => {
    setLoader(false);
  };

  const fileUpload = async (file) => {
    handleOpen();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let responseJson = await response.json();
    handleClose();
    return responseJson;
  };

  const handleUploadeImages = async (e, base) => {
    const { files } = e.target;
    const invalidFiles = Array.from(files).filter(
      (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
    );
    if (invalidFiles.length > 0) {
      showToast("error", "Only JPEG,JPG, PNG files are allowed.");
      e.target.value = null; // Clear input value
      return;
    }

    const json = await fileUpload(files[0]);
    handleChangeVerification(json.result, base);
  };

  return {
    legalInformation,
    handleChangeInputs,
    verificationMethod,
    setVerificationMethod,
    verificationDetails,
    handleChangeVerification,
    handleUploadeImages,
    loader,
    mainContainerRef,
  };
};
