const globalClassWindows = new Map();

function restoreWindowsFromLocalStorage(name) {
  try {
    const savedWindows = JSON.parse(
      localStorage.getItem("classWindowRefs") || "{}"
    );

    Object.entries(savedWindows).forEach(([key, windowInfo]) => {
      if (key.includes(name)) {
        const existingWindow = window.open("", key);
        if (existingWindow && !existingWindow.closed) {
          globalClassWindows.set(key, existingWindow);
        } else {
          globalClassWindows.delete(key);
        }
      }
    });
  } catch (error) {
    localStorage.removeItem("classWindowRefs");
  }
}

function updateLocalStorage() {
  const savedWindows = {};
  globalClassWindows.forEach((win, uniqueName) => {
    if (win && !win.closed) {
      savedWindows[uniqueName] = { name: uniqueName, timestamp: Date.now() };
    }
  });
  localStorage.setItem("classWindowRefs", JSON.stringify(savedWindows));
}

function openClassWindow({ path = "", room = "" }) {
  let newWindow;
  let interval;
  const strWindowFeatures = "location=yes,scrollbars=yes,status=yes";
  const joinClassPath = `${path}/join_class/aautimeet`;
  const uniqueName = `${room}`;
  const existingWindow = globalClassWindows.get(uniqueName);

  if (existingWindow && !existingWindow.closed) {
    existingWindow.focus();
  } else {
    newWindow = window.open(joinClassPath, uniqueName, strWindowFeatures);

    if (newWindow) {
      globalClassWindows.set(uniqueName, newWindow);
      updateLocalStorage();
      newWindow.onload = () => {
        newWindow.document.title = room;
      };
    }
    const savedWindows = JSON.parse(
      localStorage.getItem("classWindowRefs") || "{}"
    );
    if (Object.keys?.(savedWindows)?.length > 0) {
      interval = setInterval(() => {
        if (newWindow && newWindow.closed) {
          Object.entries(savedWindows).forEach(([key, windowInfo]) => {
            if (key === newWindow.document.title) {
              globalClassWindows.delete(key);
            }
          });
          updateLocalStorage();
          clearInterval(interval);
        }
      }, 2000);
    } else {
      clearInterval(interval);
    }
  }
  return () => clearInterval(interval);
}

export { openClassWindow, restoreWindowsFromLocalStorage };
