import { Avatar, Button } from "@mui/material";
import { showToast } from "../globalComponents/Toast";
import { loginAuth } from "./service";
import Spinner from "../globalComponents/Spinner";
import { AautiStrings } from "../globalComponents/AautiStrings";
import AautiTextInput from "../globalComponents/AautiTextInput";
import AautiText from "../globalComponents/AautiText";
import { getFirstTwoChars, returnColorbasedName } from "../CommonFunctions";
import { isEmpty } from "lodash";
import { useState } from "react";
import AautiDialog from "../globalComponents/AautiDialog";

const PasswordDialog = ({
  selectedUser,
  loadingVerification,
  setLoadingVerification,
  onSuccess,
  dialogType,
  makeTypeEmpty,
}) => {
  const [password, setPassword] = useState("");

  return (
    <AautiDialog
      open={dialogType === "enterPassword"}
      onClose={() => {
        makeTypeEmpty();
      }}
      dialogTitle={AautiStrings?.passwordVerificationString}
      titlePosition="left"
      dialogWidth="500px"
      sxStyles={{ padding: "5px 30px 30px 30px !important" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          src={
            !isEmpty(selectedUser?.profileImage)
              ? selectedUser?.profileImage
              : undefined
          }
          alt="profile"
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: isEmpty(selectedUser?.profileImage)
              ? returnColorbasedName(
                  selectedUser?.displayName || selectedUser?.userName
                )
              : "transparent",
          }}
        >
          {isEmpty(selectedUser?.profileImage) &&
            getFirstTwoChars(
              selectedUser?.displayName || selectedUser?.userName
            )}
        </Avatar>
        <AautiText
          title={
            AautiStrings?.forYourSecurityPleaseReEnterYourPasswordOrOTPToContinueString
          }
        />
        <AautiText
          title={selectedUser?.email || selectedUser?.userName}
          weight={"bold"}
          style={{ textAlign: "center", marginTop: "10px" }}
        />
      </div>
      <AautiTextInput
        important
        text={AautiStrings?.passwordString}
        value={password}
        maxLength={14}
        onChange={(e) => {
          const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
          setPassword(valueWithoutSpaces);
        }}
        type="password"
        style={{ width: "100%", marginTop: "10px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          position: "relative",
        }}
      >
        {loadingVerification ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          >
            <Spinner />
          </div>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              if (!password) {
                showToast("error", "Please enter password.");
                return;
              }
              setLoadingVerification(true);
              loginAuth(
                {
                  username: selectedUser.email || selectedUser.userName,
                  password,
                },
                (response) => {
                  setLoadingVerification(false);
                  if (response.result) {
                    onSuccess();
                  } else {
                    showToast("error", "Invalid password. Please try again.");
                  }
                },
                (err) => {
                  setLoadingVerification(false);
                  console.log(err);
                  showToast("error", "An error occurred. Please try again.");
                }
              );
            }}
            disabled={password.length < 8}
            style={{
              textTransform: "unset",
              color: "white",
              backgroundColor: "#3083EF",
            }}
          >
            Verify
          </Button>
        )}
      </div>
    </AautiDialog>
  );
};

export default PasswordDialog;
