import { useSelector } from "react-redux";
import { AuthService } from "../Service";
import { showToast } from "../../../globalComponents/Toast";
import { Container } from "../Container";
import { constants } from "../Constants";

export const useNotificationButtonsHooks = (props) => {
  const {
    responseType,
    AllNot,
    setAlNot,
    readNotifications,
    setReadNotifications,
    setLoadingNotifi,
    setOpen,
  } = props;

  const functionDealTrustee = (object, status) => {
    let params = {
      trusteeId: object?.userId,
      userId: object?.serviceProviderId,
      userStatus: status,
    };
    AuthService.updateTrusteeStatus(
      params,
      (response) => {
        if (response?.status == "Success") {
          const newList = (
            responseType === "Read" ? readNotifications : AllNot
          )?.map((each) => {
            if (each?._id == object?._id) {
              return {
                ...each,
                status: "1",
                userStatus: status,
              };
            }
            return each;
          });
          if (responseType == "Read") {
            setReadNotifications(newList);
          } else {
            setAlNot(newList);
          }
        }
      },
      (err) => {
        showToast("info", err?.message);
      }
    );
  };

  const onFailure = (error) => {
    setLoadingNotifi(false);
    setOpen(false);
    showToast(constants?.ERROR, error?.message);
  };

  const rejectInvite = (data) => {
    delete data.inviteCourse;
    AuthService.updateRejectData(
      data,
      (res) => {
        if (res?.status == "Success") {
          const newList = (
            responseType === "Read" ? readNotifications : AllNot
          )?.map((each) => {
            if (each?._id == data.id) {
              return {
                ...each,
                status: "1",
                userStatus: "Rejected",
              };
            }
            return each;
          });
          if (responseType === "Read") {
            setReadNotifications(newList);
          } else {
            setAlNot(newList);
          }
        }
      },
      onFailure
    );
  };

  const memberAccept = (data, actionDetails) => {
    const reqData = {
      _id: data?.id,
      userStatus: actionDetails,
    };
    const memberTransferParams = {
      id: data?.id,
      userStatus: actionDetails,
    };
    // delete data.inviteCourse;
    // delete data.id;
    switch (data?.type) {
      case "member Transfer":
        AuthService.childTransferUpdateStatus(
          memberTransferParams,
          (response) => {
            if (response?.status == "Success") {
              const newList = (
                responseType === "Read" ? readNotifications : AllNot
              )?.map((each) => {
                if (each?._id == memberTransferParams.id) {
                  return {
                    ...each,
                    status: "1",
                    userStatus: actionDetails,
                  };
                }
                return each;
              });
              if (responseType === "Read") {
                setReadNotifications(newList);
              } else {
                setAlNot(newList);
              }
            } else {
              showToast("info", response?.message);
            }
          }
        );
        break;
      case "invite Member":
        AuthService.updateUserInviteStatus(
          memberTransferParams,
          (response) => {
            if (response?.status?.toLowerCase() === "success") {
              const newList = (
                responseType === "Read" ? readNotifications : AllNot
              )?.map((each) => {
                if (each?._id === memberTransferParams.id) {
                  return {
                    ...each,
                    status: "1",
                    userStatus: memberTransferParams?.userStatus,
                  };
                }
                return each;
              });
              if (responseType === "Read") {
                setReadNotifications(newList);
              } else {
                setAlNot(newList);
              }
            }
          },
          onFailure
        );
        break;
      case "invite moderator":
        AuthService.moderatorUpdateStatus(
          reqData,
          (response) => {
            if (response?.status == "Success") {
              const newList = (
                responseType === "Read" ? readNotifications : AllNot
              )?.map((each) => {
                if (each?._id == reqData?._id) {
                  return {
                    ...each,
                    status: "1",
                    userStatus: actionDetails,
                  };
                } else {
                  return each;
                }
              });
              if (responseType === "Read") {
                setReadNotifications(newList);
              } else {
                setAlNot(newList);
              }
            } else {
              showToast("info", response?.message);
            }
          },
          onFailure
        );
        break;
      default:
        break;
    }
  };

  return { functionDealTrustee, memberAccept, rejectInvite };
};
