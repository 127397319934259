import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

export function getAllCategories(onSuccess, onFailure) {
  ApiRequest({
    url: "category/getAllCategories",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function uploadFile(formData, onSuccess, onFailure) {
  ApiRequest({
    url: "file/upload",
    method: ApiMethods.POST,
    data: formData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveMyLessonPlan(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myLessonPlan/saveLessonPlan",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function updateLessonPlan(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myLessonPlan/updateLessonPlan",
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getLessonPlanById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "myLessonPlan/getLessonPlan?",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
// }
