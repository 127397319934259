import React from "react";
import AautiTextInput from "../../../components/globalComponents/AautiTextInput";
import "../index.scss";
import HelperText from "../../../components/globalComponents/HelperText";
import { VerifyUserStrings } from "../VerifyUserStrings";
import { Divider, IconButton, InputAdornment, Tooltip } from "@mui/material";
import verifiedPng from "../../../assets/images/verified_png.png";
import { useSelector } from "react-redux";
import { removePhoneCode } from "../../../components/CommonFunctions";
import Layout from "../Layout";
import {
  emailCount,
  firstNameCount,
  lastNameCount,
} from "../../../components/globalComponents/helpers.variables";

const LegalInformation = ({ handleChangeInputs, legalInformation }) => {
  const { userDetails, countryObject } = useSelector((state) => state.app);
  const renderInput = (
    value,
    maxLength,
    text,
    onChange,
    endAdornment = null,
    additionalProps = {}
  ) => (
    <div className="legal-inputs">
      <AautiTextInput
        value={value}
        maxLength={maxLength}
        style={{ width: "100%" }}
        text={text}
        placeholder={text}
        important={true}
        onChange={onChange}
        endAdornment={endAdornment}
        {...additionalProps}
      />
    </div>
  );

  return (
    <Layout title={VerifyUserStrings.legalHead}>
      <div className="verify-flex-items">
        {renderInput(
          legalInformation?.firstName,
          firstNameCount,
          "First Name",
          (e) => handleChangeInputs(e.target.value, "firstName")
        )}
        {renderInput(
          legalInformation?.lastName,
          lastNameCount,
          "Last Name",
          (e) => handleChangeInputs(e.target.value, "lastName")
        )}
        {renderInput(
          legalInformation?.mobile,
          50,
          "Mobile",
          (e) => {
            const mobileNumber = e.target.value;
            if (!isNaN(mobileNumber))
              handleChangeInputs(mobileNumber, "mobile");
          },
          legalInformation?.isMobileVerified ||
            removePhoneCode(
              `+${userDetails?.mobile}`,
              countryObject?.phoneCode
            ) === legalInformation?.mobile ? (
            <InputAdornment sx={{ mr: -1.5 }} position="end">
              <Tooltip title="Verified">
                <IconButton>
                  <img src={verifiedPng} alt="verified" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ) : null,
          {
            style: { width: "100%", paddingRight: "0px !important" },
            inputStyle: {
              "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                paddingRight: "0px !important",
              },
            },
            leftContent: true,
            defaultCountry: legalInformation.country,
            setDefaultCountry: (obj) => handleChangeInputs(obj, "country"),
          }
        )}
        {renderInput(
          legalInformation?.email,
          emailCount,
          "Email",
          (e) => handleChangeInputs(e.target.value, "email"),
          (legalInformation?.isEmailVerified ||
            userDetails?.email === legalInformation?.email?.toLowerCase()) && (
            <InputAdornment sx={{ mr: -1.5 }} position="end">
              <Tooltip title="Verified">
                <IconButton>
                  <img src={verifiedPng} alt="verified" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        )}
      </div>
      <HelperText showDot={false} title={VerifyUserStrings.legalNote} />
    </Layout>
  );
};

export default LegalInformation;
