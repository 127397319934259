import React, { useEffect, useState } from "react";
import { BuildDashboard } from "storybook-signup";
import { useNavigate } from "react-router-dom";
import { saveInstituteDashboard, getInstituteDetails } from "./Services";
import { showToast } from "../globalComponents/Toast";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl } from "../../service/api-constants";

const InstituteWebDesign = (props) => {
  const params = useParams();
  const [instituteData, setInstituteData] = useState(null);
  const navigate = useNavigate();
  const { countryObject } = useSelector((state) => state.app);

  useEffect(() => {
    getInstituteDetails(
      params?.id,
      (res) => {
        if (res?.status === "Success") {
          setInstituteData(res.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const onSaveInstituteDashboard = (dashboardObj) => {
    saveInstituteDashboard(
      { instituteId: params?.id, ...dashboardObj },
      (res) => {
        if (
          res?.status == "Success" &&
          res?.message == "Institute data saved"
        ) {
          navigate(`/institute/profile/${params?.id}`);
          window.location.reload();
        } else {
          showToast("error", res?.message);
        }
      },
      (err) => {
        showToast("err", "Something went wrong");
      }
    );
  };

  const skipFnct = (dashboardObj) => {
    onSaveInstituteDashboard(dashboardObj);
  };

  return (
    <div className="instituteWD-div1">
      <BuildDashboard
        skipFnct={skipFnct}
        categories={instituteData?.institute?.categories}
        mobile={instituteData?.institute?.mobile}
        onSaveInstituteDashboard={onSaveInstituteDashboard}
        instituteLogo={instituteData?.institute?.logo}
        instituteName={instituteData?.institute?.nameOfTheInstitution}
        showToast={showToast}
        instituteData={instituteData}
        countryObject={countryObject}
        baseUrl={baseUrl}
      />
    </div>
  );
};

export default InstituteWebDesign;
