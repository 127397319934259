import React from 'react'
import ParsedText from '../../ParsedText'
import HighlightText from '../../highlighted_text'

const textStyle = {
    fontSize:14,
    lineHeight:1.4,
    flexWrap:'wrap',
    whiteSpace:'pre-wrap',
    wordWrap: 'break-word',
  }

const styles = {
  left: {
    container: {
      borderRadius:8,
      borderTopLeftRadius: 0,
      maxWidth:'100%',
    },
    text: {
      color: '#333333',
      ...textStyle,
    }
  },
  right: {
    container: {
      borderRadius: 8,
      borderTopRightRadius: 0,
      maxWidth:'100%',
    },
    text: {
      color: '#1e1e1e',
      ...textStyle,
    }
  },
}


function MessageText({
  currentMessage = {},
  position = 'left',
  textProps={},
  reply = false,
  searchText,
  highlighted = false
}) {
  const onUrlPress = (url) => {}
  const onPhonePress = (phone) => {}
  const onEmailPress = (email) =>{}
  return (
    <div style={styles[position].container}>
      <ParsedText
        style={styles[position].text}
        parse={[
          { type: 'url', onPress: onUrlPress },
          { type: 'phone', onPress: onPhonePress },
          { type: 'email', onPress: onEmailPress },
        ]}
        childrenProps={{ ...textProps }}
      >
         <HighlightText
          {...{
            id: currentMessage._id,
            searchWords: [searchText],
            textToHighlight: currentMessage.text,
            highlightStyle:{
              backgroundColor: highlighted ?"#d6b85a":"#3083EF",
              color:'white',
            }
          }}
        />
      </ParsedText>
    </div>
  )
}

export default MessageText;