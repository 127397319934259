import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";

const apiUrls = {
  getBatchesByCourseId: "myCourse/getBatchesByCourseId?",
  saveBatches: "myCourse/rescheduleBatch",
  getBatchDetailsByBatchId:
    "reScheduleBatch/getAcceptAndRejectedUsersByBatchId?",
  saveApproveRejectStatus: "rescheduleBatch/acceptOrRejectRescheduleBatch?",
  changeBatch: "reScheduleBatch/changeBatch?",
};

export function getResheduleBatches(courseId, onSuccess, onFailure) {
  ApiRequest({
    url: apiUrls.getBatchesByCourseId,
    method: ApiMethods.GET,
    params: { courseId },
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
}

export const saveRescheduleBatches = (data, onSuccess, onFailure) => {
  ApiRequest({
    url: apiUrls.saveBatches,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};

export const getBatchDetailsByBatchId = (data, onSuccess, onFailure) => {
  const { courseId, batchId } = data;
  ApiRequest({
    url: apiUrls.getBatchDetailsByBatchId,
    method: ApiMethods.GET,
    params: { courseId, batchId },
  }).then(
    (response) => {
      onSuccess && onSuccess(response?.data);
    },
    (error) => {
      onFailure && onFailure(error);
    }
  );
};
