import { Skeleton } from "@mui/material";
import React from "react";

function SkeletonMessageListItem(item, index) {
  const getRandomInBetween = (max, min) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
  const isMyMessage = !(getRandomInBetween(1, 20) % 2);
  const randomHeight = getRandomInBetween(40, 150);
  return (
    <div
      key={`${item}-${index}`}
      style={{
        padding: 8,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: isMyMessage ? "flex-end" : "flex-start",
        alignItems: "flex-start",
        columnGap: "4px",
        width: "100%",
      }}
    >
      {!isMyMessage && (
        <Skeleton variant="circular" height={25} width={25} animation="wave" />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 250,
          justifyContent: "flex-start",
          height:'100%',
          alignSelf:'stretch',
          rowGap:'4px'
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.5rem",
            alignSelf: isMyMessage ? "flex-end" : "flex-start",
            width: 60,
            margin:0, }}
        />
        <Skeleton
          variant="rectangular"
          height={randomHeight}
          sx={{ width: "100%",alignSelf:'stretch',borderRadius:1}}
        />
      </div>
    </div>
  );
}

export default SkeletonMessageListItem;
