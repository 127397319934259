import React from "react";
import { PackageStrings } from "../../Constants";
import JoinButton from "../../../globalComponents/JoinButton";
import AautiText from "../../../globalComponents/AautiText";
import { globalColors } from "../../../../themes/GlobalColors";
import "../Styles.scss";
import { Container } from "../Container";
import { constants } from "../Constants";
import { useNotificationButtonsHooks } from "./NotificationButtonsHooks";

const NotificationButtons = ({
  item,
  responseType,
  childId,
  handleCloseNot,
  showMorebtns,
  AllNot,
  setAlNot,
  readNotifications,
  setReadNotifications,
  setLoadingNotifi,
  setOpen,
}) => {
  const { courseBuyApi } = Container({
    responseType,
    childId,
    handleCloseNot,
    showMorebtns,
  });
  const { functionDealTrustee, memberAccept, rejectInvite } =
    useNotificationButtonsHooks({
      responseType,
      AllNot,
      setAlNot,
      readNotifications,
      setReadNotifications,
      setLoadingNotifi,
      setOpen,
    });
  const memberData = {
    id: item?._id,
    type: item?.notificationType,
  };
  const sendId = {
    courseId: item?.courseId,
    userId: item?.userId,
    batchId: item?.batchId,
    inviteCourse: true,
    id: item?._id,
    type: item?.notificationType,
    serviceProviderId: item?.serviceProviderId,
  };
  switch (item?.notificationType) {
    case PackageStrings?.inviteCourse:
      return (
        <>
          {item?.userStatus ? (
            <AautiText
              title={item?.userStatus}
              size={"small"}
              textStyle={{
                color:
                  item?.userStatus === "Rejected"
                    ? "red"
                    : globalColors.AAUTI_THEME_COLOR,
                marginTop: 3,
              }}
              weight={"medium"}
            />
          ) : (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <JoinButton
                className="buttonStyleNew"
                onClick={(e) => {
                  e.stopPropagation();
                  rejectInvite(sendId);
                }}
                outlined
                color={globalColors?.AAUTI_THEME_COLOR}
                height=""
                width="fit-content"
                padding={"3px"}
                fontSize="small"
                title={"Reject"}
              />
              <JoinButton
                className="buttonStyleNew"
                onClick={(event) => {
                  event.stopPropagation();
                  event.stopPropagation();
                  courseBuyApi(sendId);
                }}
                height=""
                width="fit-content"
                padding={"3px"}
                fontSize="small"
                title={"Buy Now"}
              />
            </div>
          )}
        </>
      );
    case PackageStrings?.inviteMember:
    case PackageStrings?.inviteModerator:
    case PackageStrings?.memberTransfer:
      return (
        <>
          {item?.userStatus ? (
            <AautiText
              textStyle={{
                color:
                  item?.userStatus === "Rejected"
                    ? "red"
                    : globalColors?.AAUTI_THEME_COLOR,
                marginTop: 3,
              }}
              title={`${
                item?.userStatus === "Rejected"
                  ? "Invitation Rejected"
                  : "Invitation Accepted"
              }`}
              size={"small"}
              weight={"medium"}
            />
          ) : (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <JoinButton
                className="buttonStyleNew"
                onClick={(e) => {
                  e.stopPropagation();
                  memberAccept(memberData, "Rejected");
                }}
                outlined
                color={globalColors?.AAUTI_THEME_COLOR}
                height=""
                width="fit-content"
                padding={"3px"}
                fontSize="small"
                title={"Reject"}
              />
              <JoinButton
                className="buttonStyleNew"
                onClick={(e) => {
                  e.stopPropagation();
                  memberAccept(memberData, "approved");
                }}
                height=""
                width="fit-content"
                padding={"3px"}
                fontSize="small"
                title={"Accept"}
              />
            </div>
          )}
        </>
      );
    case constants?.TRUSTEE:
      return (
        <>
          {item?.userStatus ? (
            <AautiText
              textStyle={{
                color:
                  item?.userStatus === "Rejected"
                    ? "red"
                    : globalColors?.AAUTI_THEME_COLOR,
                marginTop: 3,
              }}
              title={`${
                item?.userStatus === "Rejected"
                  ? "Invitation Rejected"
                  : "Invitation Accepted"
              }`}
              size={"small"}
              weight={"medium"}
            />
          ) : (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <JoinButton
                className="buttonStyleNew"
                onClick={(e) => {
                  e.stopPropagation();
                  functionDealTrustee(item, "Rejected");
                }}
                outlined
                color={globalColors?.AAUTI_THEME_COLOR}
                height=""
                width="fit-content"
                padding={"3px"}
                fontSize="small"
                title={"Reject"}
              />
              <JoinButton
                className="buttonStyleNew"
                onClick={(e) => {
                  e.stopPropagation();
                  functionDealTrustee(item, "approved");
                }}
                height=""
                width="fit-content"
                padding={"3px"}
                fontSize="small"
                title={"Accept"}
              />
            </div>
          )}
        </>
      );

    default:
      break;
  }
};

export default NotificationButtons;
