import { ApiMethods } from "../../service/api-constants";
import { ApiRequest } from "../../service/api-request";

export function AddAdhocEvent(data, onSuccess, onFailure) {
  ApiRequest({
    url: "mySchedule/saveMySchedule",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveAdhocEvent(data, onSuccess, onFailure) {
  ApiRequest({
    url: "mySchedule/save",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getAdhocClassSuggestions(onSuccess, onFailure) {
  ApiRequest({
    url: "adhocClassSuggestions/getAll",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getAdhocClassSuggestionsBySpId(params, onSuccess, onFailure) {
  ApiRequest({
    url: "adhocClassSuggestions/getAdhocClassSuggestionsByServiceProviderId",
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getUserAdhocSuggestedPackages(onSuccess, onFailure) {
  ApiRequest({
    url: "package/getAdminPackages",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function aautiProperties(onSuccess, onFailure) {
  ApiRequest({
    url: "aautiProperties/getAautiProperties",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function UpdateAdhocEvent(data, onSuccess, onFailure) {
  ApiRequest({
    url: "mySchedule/updateMySchedule",
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function GetAllCountries(onSuccess, onFailure) {
  ApiRequest({
    url: "countryMapping/getAll",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function updateProfessions(userId, professions, onSuccess, onFailure) {
  ApiRequest({
    url: "user/updateUserData",
    method: ApiMethods?.PATCH,
    data: {
      _id: userId,
      professions: professions ?? [],
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const getAllTimeZones = (onSuccess, onFailure) => {
  ApiRequest({
    url: "timeZone/getAllTimeZones",
    method: ApiMethods?.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
};
