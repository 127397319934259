import React from "react";
import AautiText from "../../globalComponents/AautiText";
import BatchCard from "./BatchCard";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import { MyCoursesConstants } from "./MyCoursesConstants";

const BatchTransfer = ({
  setSelectedBatch,
  previousBatch,
  selectedBatch,
  batchesList,
  reason,
  setReason,
  handleCancel,
  handleSaveRequest,
  returnListWithouBatchId,
  batchTransfer = false,
}) => {
  return (
    <div
      style={{
        padding: 15,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 5,
          height: "90%",
        }}
      >
        <AautiText
          title={MyCoursesConstants?.requestTransfer}
          weight={"bold"}
        />
        <AautiText
          title={"From Batch"}
          weight={"bold"}
          textStyle={{ marginTop: 3, marginBottom: 3 }}
        />
        <BatchCard
          {...{
            previousBatch: previousBatch?.foundObject,
            setSelectedBatch,
            selectedBatch,
          }}
          type={"From"}
          slot={previousBatch?.foundObject?.type}
        />
        <AautiText
          title={"To Batch"}
          weight={"bold"}
          textStyle={{ marginTop: 3, marginBottom: 3 }}
        />
        <div
          style={{
            marginTop: 2,
            overflow: "scroll",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            flexShrink: 1,
          }}
        >
          {returnListWithouBatchId(
            batchesList?.groupDaily,
            previousBatch?.foundObject?._id
          )?.map((each, index) => (
            <BatchCard
              slot={"Daily"}
              key={index}
              previousBatch={each}
              type={"To"}
              {...{ setSelectedBatch, selectedBatch }}
            />
          ))}
          {returnListWithouBatchId(
            batchesList?.groupWeekly,
            previousBatch?.foundObject?._id
          )?.map((each, index) => (
            <BatchCard
              slot={"Weekly"}
              key={index}
              previousBatch={each}
              type={"To"}
              {...{ setSelectedBatch, selectedBatch }}
            />
          ))}
        </div>
        <div style={{ width: "100%" }}>
          <AautiTextInput
            style={{ width: "100%" }}
            fullWidth
            important
            text="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-around",
          gap: 10,
          marginTop: 20,
          justifySelf: "flex-end",
        }}
      >
        <button
          onClick={() => handleCancel()}
          style={{
            width: "35%",
            background: "transparent",
            borderStyle: "solid",
            borderColor: "rgb(48, 131, 239)",
            borderWidth: 1,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            padding: 10,
            justifyContent: "center",
            fontWeight: 500,
          }}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            handleSaveRequest();
          }}
          disabled={!selectedBatch || reason.length <= 0}
          style={{
            width: "35%",
            background:
              selectedBatch && !reason.length <= 0
                ? "rgb(48, 131, 239)"
                : "#efefef",
            borderWidth: 0,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
            padding: 10,
            justifyContent: "center",
            fontWeight: 500,
            color: selectedBatch && !reason.length <= 0 ? "#ffffff" : "#000000",
          }}
        >
          Submit
        </button>
      </div>
      <div style={{ height: 100 }} />
    </div>
  );
};

export default BatchTransfer;
