import React from "react";
import AautiText from "./AautiText";
import { useLocation, useNavigate } from "react-router-dom";
import { isArray } from "lodash";
//props --> {tabObj :{ label: "My Account", navigation: "/my-account", active: active }},
const AccountTabs = (props) => {
  const { tabObj, replace = false, style = {} } = props;
  const { pathname } = useLocation();
  const isAccountActive = [
    "/my-account",
    // "/select-profession",
    // "/interests",
    "/attendance-details",
    "/students",
    "/AdhocEvent",
    "/adhoc-price",
    "/adhoc-events",
    "/myLesson-plans",
    "/view-gigs",
    "/my-schedule",
    "/my-revenue",
    "/my-learnings",
    "/my-instructors",
    "/favourite-institutes",
    "/course-adhoc-events",
    "/wishlist",
    "/orders",
    "/my-packages",
    "/add-package",
    "/help-support",
    "/my-courses",
    "/members-list",
    "/refund-configuration",
    "/create-offTime",
    // "/refund-configuration",
    "/account-settings",
    "/refunds",
    "/reviews-ratings",
    "/bank-details",
    "/transfer-requests",
    "/payout-requests",
    "/reschedule-merge",
    "/coupons",
    "/profile/interests",
    "/select-profession",
    "/feedback",
    "/select-subCtegories",
    "/notifications",
    "/notification-settings",
    "/course-adhoc-creation",
    "/course-history",
    "/reschedule",
    "/reschedule-list",
    "/institute/branches",
    "/institute/instructors/instructors",
    "/institute/participants/participants",
    "/my-courses",
    "/institute/topInstructors",
    "/aboutInstitute",
    "/trustee-management",
    "/user-verification",
  ].includes(pathname);

  const isSettingsActive = [
    "/delete-deactivate",
    "/security",
    // "/account-settings",
    // "/refunds",
    // "/reviews-ratings",
    // "/bank-details",
    // "/transfer-requests",
    // "/payout-requests",
    // "/reschedule-merge",
    // "/coupons",
    // "/interests",
    // "/profile/interests",
    // "/select-profession",
    // "/feedback",
    // "/refund-configuration",
    // "/select-subCtegories",
  ].includes(pathname);

  const isProfileActive = ["/profile"].includes(pathname);

  const tabs = [];

  if (isAccountActive) {
    tabObj?.navigationLabel !== undefined
      ? tabs.push({
          label: tabObj?.navigationLabel,
          navigation: "/calendar",
          active: true,
        })
      : tabs.push({
          label: "My Account",
          navigation: "/my-account",
          active: true,
        });
  }

  if (isSettingsActive) {
    tabObj?.navigationLabel !== undefined
      ? tabs.push({
          label: tabObj?.navigationLabel,
          navigation: "/calendar",
          active: true,
        })
      : tabs.push({
          label: "Settings",
          navigation: "/account-settings",
          active: true,
        });
  }

  // if (isProfileActive) {
  //   tabs.push({
  //     label: "Profile",
  //     navigation: "/profile",
  //     active: true,
  //   });
  // }

  const renderTabs = props?.tabObj
    ? isArray(props?.tabObj)
      ? [...tabs, ...props?.tabObj]
      : [...tabs, props?.tabObj]
    : tabs;
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8,
        ...style,
      }}
    >
      {renderTabs?.map((each, index) => {
        return (
          <button
            onClick={() => {
              if (index !== renderTabs.length - 1) {
                navigate(each?.navigation);
                // window.location.pathname = each?.navigation;
              }
              // navigate(each?.navigation, {
              //   state: { screenName: each.label },
              //   replace: replace,
              // });
            }}
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 6,
              padding: 2,
              alignItems: "center",
              border: "none",
              background: "none",
              cursor: index !== renderTabs.length - 1 ? "pointer" : "text",
              textTransform: "capitalize",
            }}
          >
            <AautiText
              title={each.label}
              textStyle={{
                color:
                  index !== renderTabs.length - 1 ? "#3083EF" : "#575B5CCC",
              }}
            />
            {index !== renderTabs.length - 1 && <span> | </span>}
          </button>
        );
      })}
    </div>
  );
};

export default AccountTabs;
