import React from "react";
import { GoDotFill } from "react-icons/go";
import AautiText from "./AautiText";
import "./styles.scss";
import { AautiStrings } from "./AautiStrings";
import { IoInformationCircleOutline } from "react-icons/io5";
import { debounce } from "lodash";

const HelperText = ({
  title,
  handleClickGenerateAI = () => {},
  error,
  aiGeneration = false,
  info = false,
  textStyle,
  containerStyles = {},
  flexContainer = {},
  disabelAiGeneration = false,
  regenerate = false,
  showDot = true,
}) => {
  return (
    <div
      className="flex-container"
      style={{
        marginTop: 2,
        gap: 15,
        ...containerStyles,
      }}
    >
      <div
        className={`flex-container ${info && "alignFlex"}`}
        style={{ ...flexContainer }}
      >
        {showDot ? (
          info ? (
            <IoInformationCircleOutline color="#5C5B57" size={20} />
          ) : (
            <GoDotFill color="#5C5B57" size={12} />
          )
        ) : null}
        <AautiText
          title={title}
          size={"small"}
          textStyle={{ color: error ? "red" : "#5C5B57", ...textStyle }}
        />
      </div>
      <button
        disabled={disabelAiGeneration}
        id="generate-ai-button"
        onClick={debounce(
          () => {
            handleClickGenerateAI();
          },
          3000,
          {
            leading: true,
            trailing: false,
          }
        )}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          pointerEvents: disabelAiGeneration && "none",
        }}
      >
        <AautiText
          title={
            regenerate
              ? AautiStrings.reGenerateUsingAIString
              : AautiStrings?.GenerateUsingAIString
          }
          size={"small"}
          weight={"bold"}
          textStyle={{
            color: disabelAiGeneration ? "lightgrey" : "#2076E5",
            display: !aiGeneration && "none",
            cursor: "pointer",
            pointerEvents: disabelAiGeneration && "none",
          }}
        />
      </button>
    </div>
  );
};

export default HelperText;
