import { ApiRequest } from "../../../service";
import { ApiMethods, ApiPaths } from "../../../service/api-constants";
import { InstituteApiPaths } from "./institute-api-constants";

function getCompletedCourses(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_INSTITUTE_COMPLETED_COURSES,
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getUpcomingCourses(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_INSTITUTE_UPCOMING_COURSES,
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getOngoingCourses(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_INSTITUTE_ONGOING_COURSES,
    method: ApiMethods.GET,
    params: data

  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function GetuserDetailsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETUSERSBYID,
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getInstituteRecentgigs(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_INSTITUTE_RECENT_GIGS,
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getUnassignedCourses(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.UNASSIGNED_COURSES,
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getAssignedCourses(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.ASSIGNED_COURSES,
    method: ApiMethods.GET,
    params: data

  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

 function Institute_GetallBranchesbyId(id) {
  const res=  ApiRequest({
    url: InstituteApiPaths.INSTITUTES_GET_All_BRANCHES_BY_ID,
    method: ApiMethods.GET,
    params: id
  })
  return res
}
async function getAllLanguages(onSuccess, onFailure) {
  const response = ApiRequest({
    url: "languages/getAllLanguages",
    method: ApiMethods.GET,
  })
  return response
}

function Institute_GetParticipantsDetails(id, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.INSTITUTE_GET_PARTICIPANTS_Details_LIST,
    method: ApiMethods.GET,
    params: id
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function Institute_GetInstructorDetails(id, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.INSTITUTE_GET_INSTRUCTOR_Details_LIST,
    method: ApiMethods.GET,
    params: id
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function Institute_Signup(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.INSTITUTE_SIGN_UP,
    method: ApiMethods.POST,
    data: data
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function createInstituteBranch(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.CREATE_BRANCH,
    method: ApiMethods.POST,
    data: data
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function DeleteInstituteBranch(id, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.DELETE_BRANCH,
    method: ApiMethods.DELETE,
    params:id
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function updateInstituteBranch(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.UPDATE_BRANCH,
    method: ApiMethods.PUT,
    data: data
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function Institute_DashboardDetails(id, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.INSTITUTE_DASHBOARD_DETAILS,
    method: ApiMethods.GET,
    params: id
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
// ADD Institute Information services

function GETInstituteInformation_ByInstituteId(id, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_Institute_Information_By_InstituteId,
    method: ApiMethods.GET,
    params: id
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

//Completed courses
function GetInstituteInformationById(id, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_INSTITUTE_INFORMATION_BYID,
    method: ApiMethods.GET,
    params: id
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function updateInstituteInformation(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.UPDATE_INSTITUTE_INFORMATION,
    method: ApiMethods.PUT,
    data: data
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function createInstituteInformation(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.CREATE_INSTITUTE_INFORMATION,
    method: ApiMethods.POST,
    data: data
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function DeleteInstituteInformation(id, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.DELETE_INSTITUTE_INFORMATION,
    method: ApiMethods.DELETE,
    params:id
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function DeactivateInstructor( params,onSuccess, onFailure
) {
  ApiRequest({
    url: InstituteApiPaths.BLOCK_INSTRUCTOR,
    method: ApiMethods.DELETE,
    params
  }).then(
    (response) => {
      onSuccess(response.data)
    },
    (error) => {
      onFailure(error)
    }
  )
}
function ActivateInstructor(params,onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.UNBLOCK_INSTRUCTOR,
    method: ApiMethods.DELETE,
    params
    // params: {
    //   userId: instructorId,
    //   instituteId: institutionId,
    //   role: role,
    // },
  }).then(
    (response) => {
      onSuccess(response.data)
    },
    (error) => {
      onFailure(error)
    }
  )
}

function RemoveInstructor(params,onSuccess,onFailure) {
  ApiRequest({
    url: InstituteApiPaths.REMOVE_INSTRUCTOR,
    method: ApiMethods.DELETE,
    params
  }).then(
    (response) => {
      onSuccess(response.data)
    },
    (error) => {
      onFailure(error)
    }
  )
}
function GetTemplate(onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_TEMPLATE,
    method: ApiMethods.GET,
  }).then(
    (response) => {
  onSuccess(response.data)
    },
    (error) => {
     onFailure(error)
    }
  )
}
function getAssignedUserList(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_INSTRUCTORS_AND_PARTICIPANTS,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response)
    },
    (error) => {
      onFailure && onFailure(error)
    }
  )
}

function saveAssignCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.SAVE_ASSIGNED_COURSE,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response)
    },
    (error) => {
      onFailure && onFailure(error)
    }
  )
}

function fetchCourseBranches(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.GET_COURSE_BRANCHES,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response)
    },
    (error) => {
      onFailure && onFailure(error)
    }
  )
}

function saveOrUpdateInsructorOrParticipant(data, onSuccess, onFailure) {
  ApiRequest({
    url: InstituteApiPaths.SAVE_OR_UPDATE_INSTRUCTOR_OR_PARTICIPANT,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess && onSuccess(response)
    },
    (error) => {
      onFailure && onFailure(error)
    }
  )
}

export const InstituteAuthService = {
  Institute_Signup,
  Institute_GetallBranchesbyId,
  Institute_GetParticipantsDetails,
  getCompletedCourses,
  getUpcomingCourses,
  getOngoingCourses,
  GetuserDetailsById,
  getInstituteRecentgigs,
  getUnassignedCourses,
  getAssignedCourses,
  createInstituteBranch,
  DeleteInstituteBranch,
  updateInstituteBranch,
  Institute_DashboardDetails,
  Institute_GetInstructorDetails,
  GETInstituteInformation_ByInstituteId,
  GetInstituteInformationById,
  updateInstituteInformation,
  createInstituteInformation,
  DeleteInstituteInformation,
  ActivateInstructor,
  DeactivateInstructor,
  RemoveInstructor,
  GetTemplate,
  getAllLanguages,
  getAssignedUserList,
  saveAssignCourse,
  fetchCourseBranches,
  saveOrUpdateInsructorOrParticipant
}