import { Avatar } from "@mui/material";
import React from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { RescheduleConstants } from "./RescheduleConstants";

const BatchUserCard = ({ obj }) => {
  const returnStatus = (status) => {
    if (status == "accepted") {
      return (
        <AautiText
          title={AautiStrings?.acceptedString}
          weight={"bold"}
          size={"semi"}
          textStyle={{ color: "green" }}
        />
      );
    } else {
      return (
        <AautiText
          title={RescheduleConstants?.refundInitiated}
          weight={"bold"}
          size={"semi"}
          textStyle={{ color: "red" }}
        />
      );
    }
  };
  return (
    <div
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: 4,
        padding: 10,
        background: "#F7F7FA",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 15,
      }}
    >
      <div>
        <Avatar
          src={obj.profileImage}
          sizes="large"
          sx={{ height: "50px", width: "50px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <AautiText title={obj.displayName} weight={"medium"} />
          <AautiText title={obj.email} size={"semi"} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <AautiText
            title={moment(obj.time).format("DD MMM YYYY hh:mm A")}
            size={"semi"}
          />
          {returnStatus(obj.status)}
        </div>
      </div>
    </div>
  );
};

export default BatchUserCard;
