import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import InfoIcon from "@mui/icons-material/Info";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DeactivationReasonsScreen = ({
  dayjs,
  dialogType,
  setDialogType,
  customDate,
  setCustomDate,
  selectedReasons,
  customReason,
  deactivationDays,
  daysOptions,
  resetDeactivationData,
  setReactivateDate,
  reactivateDate,
  selectedValue,
  deactivationReasons,
  toggleReason,
  handleCustomReasonChange,
  handleInfoClick,
  showInfo,
  handleDeactivationDaysChange,
  getDate,
}) => {
  const isCustomDateValid =
    customDate?.isAfter(dayjs()) &&
    customDate?.isBefore(dayjs().add(1, "year"));

  const isButtonEnabled =
    customReason.length >= 15 &&
    selectedReasons.length > 0 &&
    (deactivationDays !== daysOptions[3].value || isCustomDateValid);
  return (
    <Dialog
      open={dialogType == "deactivateReasons"}
      onClose={resetDeactivationData}
      aria-labelledby="deactivation-reasons-title"
      sx={{
        "& .MuiDialog-paper": {
          width: "600px",
          maxWidth: "100%",
          padding: "20px",
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle id="deactivation-reasons-title">
        {selectedValue === "deActivate"
          ? "Deactivating your Aauti account"
          : "Deleting your Aauti account"}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          {deactivationReasons.map((question) => (
            <Box
              key={question._id}
              onClick={() => toggleReason(question.name)}
              sx={{
                padding: "8px",
                marginBottom: "3px",
                cursor: "pointer",
                backgroundColor: selectedReasons.includes(question.name)
                  ? "#E3F2FD"
                  : "transparent",
                borderRadius: "4px",
              }}
            >
              <Typography variant="body1">{question.name}</Typography>
            </Box>
          ))}
          <AautiTextInput
            text="Reason"
            important
            value={customReason}
            onChange={handleCustomReasonChange}
            multiline
            rows={3}
            variant="outlined"
            style={{ width: "100%" }}
            margin="normal"
            helperText={`${customReason.length} / 300 (minimum 15 characters)`}
          />
        </FormControl>
        {selectedValue === "deActivate" && (
          <>
            <Button
              onClick={handleInfoClick}
              startIcon={<InfoIcon />}
              color="info"
              size="small"
              style={{
                textTransform: "unset",
                color: "#3083EF",
              }}
            >
              Info
            </Button>
            {showInfo && (
              <Typography>
                <strong
                  style={{
                    fontSize: "14px",
                    display: "block",
                    marginTop: "15px",
                  }}
                >
                  Reactivate your account
                </strong>
                <span style={{ fontSize: "14px" }}>
                  Aauti automatically allows users to reactivate their accounts
                  seamlessly, ensuring that you can return to accessing courses
                  and resources without any manual intervention. Your account
                  will be reactivated automatically once the specified duration
                  mentioned below has passed.
                </span>
              </Typography>
            )}
          </>
        )}
        {selectedValue === "deActivate" && (
          <Box mt={2}>
            <FormControl fullWidth>
              <Select
                value={deactivationDays}
                onChange={handleDeactivationDaysChange}
                fullWidth
              >
                {daysOptions.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    {day.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {deactivationDays === daysOptions[3].value && (
              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DemoItem label="Custom Date">
                      <DatePicker
                        value={customDate}
                        onChange={(date) => {
                          if (date && date.isAfter(dayjs().add(1, "year"))) {
                            setCustomDate(dayjs().add(1, "year"));
                          } else if (date && date.isBefore(dayjs())) {
                            setCustomDate(dayjs());
                          } else {
                            setCustomDate(date);
                          }
                          setReactivateDate(getDate(deactivationDays, date));
                        }}
                        minDate={dayjs()}
                        maxDate={dayjs().add(1, "year")}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        disablePast
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            )}

            <Typography>
              Automatically reactivate my account on:{" "}
              {deactivationDays === daysOptions[3].value
                ? customDate.format("DD/MM/YYYY")
                : reactivateDate.format("DD/MM/YYYY")}{" "}
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            resetDeactivationData();
          }}
          color="primary"
          style={{ textTransform: "unset" }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            // handleCloseDeactivationReasons();
            setDialogType("finalConfirmation");
          }}
          variant="contained"
          disabled={!isButtonEnabled}
          sx={{
            textTransform: "unset",
            color: "white",
            backgroundColor: isButtonEnabled ? "#3083EF" : "#D3D3D3",
            "&:disabled": {
              backgroundColor: "#D3D3D3",
            },
          }}
        >
          {selectedValue === "deActivate"
            ? "Deactivate Account"
            : "Delete Account"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivationReasonsScreen;
