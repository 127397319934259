import { ApiRequest } from "../../../../../service";
import { ApiMethods, ApiPaths } from "../../../../../service/api-constants";
import { AssignmentApiPaths } from "./constants";

function getAssignmentCreationById(id, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.GET_ASSIGNMENT_CREATION_BY_ID + "?id=" + id,

    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getDraftAssignmentCreationById(id, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.GET_DRAFT_ASSIGNMENT_CREATION_BY_ID + "?id=" + id,

    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAssignmentDetailsBySessionIdType(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.GET_ASSIGNMENT_DETAILS_BY_BATCHID_SESSIONID,

    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAssignmentListByEndUserId(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.GET_ENDUSER_ASSIGNMENTS_LIST_BY_ID,

    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAssignmentDetailsBySpId(
  id,
  type,
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      AssignmentApiPaths.GET_ASSIGNMENTDETAILS_BY_SPID +
      "?id=" +
      id +
      "&type=" +
      type +
      "&serviceProviderId=" +
      serviceProviderId,

    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAssignmentDetailsByEuId(endUserId, id, type, onSuccess, onFailure) {
  ApiRequest({
    url:
      AssignmentApiPaths.GET_ASSIGNMENTDETAILS_BY_EUID +
      "?endUserId=" +
      endUserId +
      "&id=" +
      id +
      "&type=" +
      type,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAssignmentDetailsAnswersForEU(
  id,
  endUserId,
  type,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      AssignmentApiPaths.GET_ASSIGNMENTDETAILS_ANSWERS_EU +
      "?id=" +
      id +
      "&endUserId=" +
      endUserId +
      "&type=" +
      type,

    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function CreateAssignment(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.CREATE_ASSIGNMENT,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function SaveDraftAssignment(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.SAVE_DRAFT_ASSIGNMENT,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function UpdateAssignment(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.UPDATE_ASSIGNMENT,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function UpdateAssignmentCorrection(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.UPDATE_ASSIGNMENT_CORRECTION,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function UpdateDraftAssignment(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.UPDATE_DRAFT_ASSIGNMENT,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function endUserAssignmentSubmission(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.ENDUSER_ASSIGNMENT_SUBMISSION,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function DeleteAssignment(id, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.DELETE_ASSIGNMENT + "?id=" + id,
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function DeleteDraftAssignment(id, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.DELETE_DRAFT_ASSIGNMENT + "?id=" + id,
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAssignmentUserListById(id, type, onSuccess, onFailure) {
  ApiRequest({
    url:
      AssignmentApiPaths.GET_ASSIGNMENT_USERLIST_BY_ID +
      "?id=" +
      id +
      "&type=" +
      type,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllAssignmentDraftsBySessionIdSPId(data, onSuccess, onFailure) {
  ApiRequest({
    url: AssignmentApiPaths.GET_ALL_DRAFTS_BY_BATCHID_SPID_TYPE,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getCompleteAssignmentDetailsByEUId(
  id,
  endUserId,
  type,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      AssignmentApiPaths.GET_COMPLETE_ASSIGNMENT_BYEUID +
      "?id=" +
      id +
      "&endUserId=" +
      endUserId +
      "&type=" +
      type,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

export const AuthService = {
  getAssignmentDetailsBySessionIdType,
  getAssignmentDetailsBySpId,
  CreateAssignment,
  getAssignmentCreationById,
  UpdateAssignment,
  DeleteAssignment,
  getAssignmentUserListById,
  getAssignmentDetailsByEuId,
  endUserAssignmentSubmission,
  getAssignmentDetailsAnswersForEU,
  SaveDraftAssignment,
  getAllAssignmentDraftsBySessionIdSPId,
  getDraftAssignmentCreationById,
  UpdateDraftAssignment,
  DeleteDraftAssignment,
  UpdateAssignmentCorrection,
  getCompleteAssignmentDetailsByEUId,
  getAssignmentListByEndUserId,
};
