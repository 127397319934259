import { useEffect, useState } from "react";
import { getExploreDataService } from "./service";
import { useNavigate } from "react-router-dom";

export const ExploreMoreContainer = () => {
  const [exploreData, setExploreData] = useState({
    isLoading: true,
    data: [],
  });
  const navigate = useNavigate();

  const getExploreData = () => {
    const onSuccess = (data) => {
      if (data?.status === "Success") {
        setExploreData((prev) => ({
          ...prev,
          data: data?.result ?? [],
          isLoading: false,
        }));
      }
    };
    const onFailure = () => {
      setExploreData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    };
    getExploreDataService(onSuccess, onFailure);
  };

  useEffect(() => {
    getExploreData();
  }, []);

  const navigateToSubCategories = (cat, subCat) => {
    const newArray = {
      subCategory: subCat?.subCategoryName,
      category: cat?.categoryName,
    };
    navigate(`/${subCat?.subCategoryName}/courses`, {
      state: newArray,
    });
  };

  const navigateToAllCategories = () => {
    navigate("/explore-more-categories");
  };

  return {
    exploreData,
    navigateToSubCategories,
    navigateToAllCategories,
  };
};
