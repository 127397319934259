import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const showToast = (type, message, direction = "top-center") => {
  toast(message, {
    type: type ? type : "info",
    theme: "dark",
    position: direction,
    autoClose: 3000,
    hideProgressBar: false, // Show or hide the progress bar
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
