import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "styled-components";

export const FeedsToolTip = styled(
  ({ className, isOpen, onToggle, ...props }) => {
    return (
      <Tooltip
        open={isOpen}
        onClick={onToggle}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="bottom"
        arrow={false}
        enterDelay={0}
        {...props}
        classes={{ popper: className }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -15],
                },
              },
              {
                name: "arrow",
                enabled: false,
              },
            ],
          },
        }}
      />
    );
  }
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    textAlign: "left",
    // boxShadow: theme.shadows[1],
  },
}));
