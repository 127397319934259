import { createAsyncThunk } from "@reduxjs/toolkit";
import constants from "./constants.json";

import { createSlice } from "@reduxjs/toolkit";
import { ApiRequest, AuthService } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";
import moment from "moment";

export const getBlockBusterDeals = createAsyncThunk(
  "deals/getBlockBusterDeals",
  async (args, { rejectWithValue }) => {
    const funcObject = {
      url: constants.getBlockBusterDealsUri,
      method: ApiMethods.GET,
      params: { count: 15 },
    };

    try {
      const response = await ApiRequest(funcObject);
      console.log(response, "the response");
      return response.data.result;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getTrendingCourses = createAsyncThunk(
  "myCourse/getTrendingCourses",
  async (details, { rejectWithValue }) => {
    const params = { timeZone: moment().utcOffset(), count: 15 };

    try {
      const response = await ApiRequest({
        url: constants.getTrendingDealsUri,
        method: ApiMethods.GET,
        params: params,
      });
      return response.data.result;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getTopServiceProviders = createAsyncThunk(
  "serviceProviders/getTopServiceProviders",
  async (data, { rejectWithValue }) => {
    const startDate = new Date();
    const endDate = moment(startDate).add(3, "months");
    let funcObj = {};
    if (data?.type && data?.type !== "topRated") {
      funcObj = {
        url: "user/matchMySchedule",
        method: ApiMethods.POST,
        data: {
          categories: [data?.type],
          countries: [],
          ageGroup: [],
          levels: [],
          endDate: moment(endDate).format("YYYY-MM-DDTHH:mm:00"),
          languages: [],
          searchKey: "",
          startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:00"),
          endUserId: null,
          timeSlots: [],
          weekSlots: {},
          subCategories: [],
          timeZone: 330,
        },
      };
    } else {
      funcObj = {
        url:
          data?.type == "topRated"
            ? constants.highRatedSpUri
            : constants.topServiceProvidersUri,
        method: ApiMethods.GET,
        params: data?.params,
      };
    }
    try {
      const response = await ApiRequest(funcObj);
      if (data?.type && data?.type !== "topRated") {
        return response?.data?.result?.topRated;
      }
      return response.data.result;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "category/getAllCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiRequest({
        url: "category/getAllCategories",
        method: ApiMethods.GET,
      });
      return response?.data?.result;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getActiveCategory = createAsyncThunk(
  "category/getActiveCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiRequest({
        url: "category/getActiveCategories",
        method: ApiMethods.GET,
        // params: { type: "course" },
      });
      return response?.data?.result;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const getCoursesListByCategory = createAsyncThunk(
  "courses/getCoursesListByCategory",
  async (
    { selectedCategoryName = "Arts", offset = 0, count = 15 },
    { rejectWithValue }
  ) => {
    try {
      console.log(
        `Fetching courses for category: ${selectedCategoryName}, offset: ${offset}, count: ${count}`
      );
      const response = await ApiRequest({
        url: ApiPaths.GET_COURSES_BY_CATEGORY,
        method: ApiMethods.POST,
        data: {
          category: [selectedCategoryName],
          offset,
          count,
        },
      });
      if (response?.data?.result) {
        return response.data.result;
      } else {
        return rejectWithValue("Failed to fetch courses");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

const faqUrl = "questionAnswers/getAllQuestionAnswers";

export const getFaq = createAsyncThunk(
  "faq/getFaq",
  async ({ type = "regular" }, { rejectWithValue }) => {
    try {
      const response = await ApiRequest({
        url: faqUrl,
        method: ApiMethods.GET,
        params: { type },
      });

      if (response?.data) {
        console.log(response?.data);
        return response.data?.result;
      } else {
        return rejectWithValue("Failed to fetch FAQ");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

const testimonialsUrl = "testimonials/getAllTestimonials";

export const getAllTestimonials = createAsyncThunk(
  "testimonials/getAllTestimonials",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiRequest({
        url: testimonialsUrl,
        method: ApiMethods.GET,
      });

      if (response?.data) {
        return response?.data?.result;
      } else {
        return rejectWithValue("Failed to fetch testimonials");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

const aautiDetailsUrl = "user/getAautiDetails";

export const getAautiDetails = createAsyncThunk(
  "user/getAautiDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiRequest({
        url: aautiDetailsUrl,
        method: ApiMethods.GET,
      });

      if (response?.data) {
        console.log(response.data, "dtls");
        return response.data?.result;
      } else {
        return rejectWithValue("Failed to fetch Aauti details");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

const homePageSlice = createSlice({
  name: "homePage",
  initialState: {
    blockbusterDeals: [],
    trendingCourses: [],
    topServiceProviders: [],
    categories: [],
    activeCategories: [],
    coursesByCategory: [],
    faqData: [],
    testimonialsData: [],
    details: null,
    status: "idle",
    error: null,
    selectedSubCategories: [],
  },
  reducers: {
    setSelectedSubCategories: (state, action) => {
      state.selectedSubCategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Blockbuster Deals cases
      .addCase(getBlockBusterDeals.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBlockBusterDeals.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blockbusterDeals = action.payload;
      })
      .addCase(getBlockBusterDeals.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Trending Courses cases
      .addCase(getTrendingCourses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTrendingCourses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trendingCourses = action.payload;
      })
      .addCase(getTrendingCourses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Top Service Providers cases
      .addCase(getTopServiceProviders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopServiceProviders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.topServiceProviders = action.payload;
      })
      .addCase(getTopServiceProviders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(getAllCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getActiveCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activeCategories = action.payload;
      })
      .addCase(getActiveCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getCoursesListByCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCoursesListByCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.coursesByCategory = action.payload;
      })
      .addCase(getCoursesListByCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getFaq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFaq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.faqData = action.payload;
      })
      .addCase(getFaq.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAllTestimonials.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllTestimonials.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.testimonialsData = action.payload;
      })
      .addCase(getAllTestimonials.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAautiDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAautiDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.details = action.payload;
      })
      .addCase(getAautiDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setSelectedSubCategories } = homePageSlice.actions;
export default homePageSlice.reducer;
