// import AsyncStorage from "@react-native-async-storage/async-storage"
const storeData = async (key, value) => {
  try {
    await localStorage.setItem(`${key}`, `${value}`);
  } catch (error) {}
};
const removeItem = async (key) => {
  try {
    await localStorage.removeItem(`${key}`);
    return true;
  } catch (error) {}
};
const getItem = async (key) => localStorage.getItem(`${key}`);
export const appStorageServices = {
  storeData,
  getItem,
  removeItem,
};
