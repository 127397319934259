import { Skeleton } from "@mui/material";

const ExploreMoreSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: 10,
      }}
    >
      {Array.from({ length: 3 }, (_index) => (
        <div>
          {Array.from({ length: 3 }, (_, sIndex) => (
            <Skeleton
              key={sIndex}
              width={200}
              height={50}
              style={{
                marginRight: 10,
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default ExploreMoreSkeleton;
