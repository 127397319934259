import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";
const CardLoader = () => {
  return (
    <Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={250}
        width={"100%"}
      />
      <Skeleton animation="wave" variant="text" height={40} width={"100%"} />
      <Skeleton animation="wave" variant="text" height={40} width={"100%"} />
      <Grid container>
        <Grid item xs={4}>
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default CardLoader;
