import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import deletemember from "../../assets/images/deletemember.png";
import AautiText from "../globalComponents/AautiText";
import { showToast } from "../globalComponents/Toast";
import { courseConstant } from "../course/courseCreation/constants";
import { sendSms } from "./service";

const ConfirmDialog = ({
  mobileDevices,
  dialogType,
  handleCloseConfirmDialog,
  setDialogType,
  selectedUser,
  handleOpenOtpVerification,
}) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: mobileDevices ? "90%" : "350px",
          maxWidth: mobileDevices ? "90%" : "380px",
          maxHeight: mobileDevices ? "90%" : 435,
          height: mobileDevices ? "45%" : 300,
          padding: mobileDevices ? "20px" : "30px",
          borderRadius: mobileDevices ? "10px" : "12px",
        },
      }}
      maxWidth="xs"
      open={dialogType === "confirmation"}
      onClose={handleCloseConfirmDialog}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: mobileDevices ? "10px" : "0px",
        }}
      >
        <img
          src={deletemember}
          alt="Confirm"
          style={{
            width: mobileDevices ? "80px" : "100px",
            height: mobileDevices ? "80px" : "100px",
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: mobileDevices ? "10px 15px" : "5px 10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: mobileDevices ? "center" : "left",
        }}
      >
        <AautiText
          title={
            courseConstant?.allTheOngoingAndFutureSessionsWillBeCancelledDoYouWantToProceedString
          }
          textAlign="center"
          textStyle={{ fontSize: mobileDevices ? "14px" : "14px" }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: mobileDevices ? "10px" : "5px",
          padding: mobileDevices ? "10px" : "8px",
        }}
      >
        <Button
          onClick={() => {
            handleCloseConfirmDialog();
            if (selectedUser?.email || selectedUser?.userName) {
              setDialogType("enterPassword");
            } else if (selectedUser?.mobile !== null) {
              sendSms(
                selectedUser?.mobile,
                (response) => {
                  if (response.status === "Success") {
                    handleOpenOtpVerification();
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            } else {
              showToast(
                "error",
                "The selected user/member does not have valid contact information."
              );
            }
          }}
          variant="outlined"
          color="primary"
          sx={{
            width: mobileDevices ? "auto" : "auto",
            textTransform: "unset",
          }}
        >
          Yes
        </Button>
        <Button
          onClick={handleCloseConfirmDialog}
          autoFocus
          variant="contained"
          sx={{
            width: mobileDevices ? "auto" : "auto",
            textTransform: "unset",
            color: "white",
            backgroundColor: "#3083EF",
          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
