import { Pause, PlayArrow } from "@mui/icons-material";
import { IconButton, Slider } from "@mui/material";
import React from "react";
import { useAudioController } from "./audioController";

function Audio({ id='audio-player', src, type }) {
  const { audioRef, isPlaying, togglePlay, secondsElapsed,seek } =
    useAudioController({
      mimeType: type,
    });
  
  const onSliderMove = (e,val)=>seek(val);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <audio ref={audioRef} style={{ display: "none" }} preload="metadata">
        <source {...{ src, type }} />
      </audio>
      <IconButton
        id={`${id}-play-pause-button`}
        onClick={togglePlay}
        sx={{ width: 38 }}
      >
        {isPlaying ? <Pause /> : <PlayArrow />}
      </IconButton>
      <div style={{ flexGrow: 1, margin: 2, marginLeft:10 }}>
        <Slider
          size="small"
          value={secondsElapsed}
          onChange={onSliderMove}
          max={audioRef?.current?.duration}
          aria-label={`${id}-slider`}
          valueLabelDisplay="auto"
          step={0.01}
        />
      </div>
    </div>
  );
}

export default Audio;
