export const MyCoursesConstants = {
  weWillSendAnInvitationToConnectTheirAccountWithYours:
    "We will send an invitation to connect their account with yours.",
  emailOrDisplayName: "Email or Mobile",
  invite: "Invite",
  inviteeList: "Invitee List",
  noInvitees: "No Invitees",
  requestTransfer: "Request Transfer",
  changeBatch: "Change batch",
  confirmationOnDelete: "Confirmation On Delete",
  studentsList: "Students List",
  noneRegisteredYet: `| None registered yet!`,
  batches: "Batches",
  history: "History",
  internalTransfer: "Internal Transfer",
  endUsersSelection: "End Users Selection",
};
