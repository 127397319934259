import React, { forwardRef } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CiSearch } from "react-icons/ci";
import { chatConstants } from "../../constants";
import "./styles.scss";

function searchBar(
  {
    onPressBack = () => {},
    onPressClear = () => {},
    onChangeSearchInput = () => {},
    value = "",
    showBackIcon = false,
    textInputProps = {},
  },
  ref
) {
  const onClickClear = ()=>{
    if(value.length === 0 && showBackIcon){
      return onPressBack()
    }
    onPressClear()
  }
  return (
    <div className={"text-input-clear-btn-wrapper"}>
      <button className={"search-box-btn"}>
        <CiSearch size={20}/>
      </button>
      <input
        {...{
            ref,
            value,
            className:"search-box-input",
            placeholder:chatConstants.search,
            onChange:onChangeSearchInput,
            ...textInputProps
        }}
      />
      {(value?.length > 0|| showBackIcon) && (
        <button
          className={"search-box-btn"}
          onClick={onClickClear}
        >
          <CloseSharpIcon />
        </button>
      )}
    </div>
  );
}

export default forwardRef(searchBar);
