import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUsingUserData } from "./authSubmitButtonServices";
import { showToast } from "../../../../../globalComponents/Toast";
import {
  appStorageServices,
  StorageConstants,
} from "../../../../../../storage";
import {
  addOrUpdateFCMToken,
  getUserByMail,
  saveMultiCarts,
  updatePasswordAuth,
} from "../../../../../instituteSetup/Services";
import { isEmpty } from "lodash";
import {
  loginUser,
  setLoggedInUserId,
  setSignupScreenIndex,
  setUserdetails,
  toggleSignInPop,
} from "../../../../../../redux/reducer/appReducer";
import constants from "./authSubmitButtonConstants.json";
import { AuthService } from "../../../../../../service";
import { auth, GoogleProvider } from "../../../../../../firebase";

export const postFCMDeviceTokenObject = async (loginEmailId) => {
  const FCMToken = await appStorageServices.getItem(
    StorageConstants.DEVICETOKEN
  );

  if (!FCMToken) return;

  const deviceDetailsObj = {
    email: loginEmailId,
    deviceId: FCMToken,
    isEnabled: true,
  };

  addOrUpdateFCMToken(
    deviceDetailsObj,
    this.handleSuccessFCMDetails,
    this.handleFailureFCMDetails
  );
};

export const useAuthSubmitButtonHooks = (props) => {
  const { screenName, inputType } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { cartsDataBeforeLogin } = useSelector((state) => state.app);
  const [userRole, setUserRole] = useState(null);
  const [socialLogins, setSocialLogins] = useState({});

  const authSubmitButtonTextSources = {
    serviceProviderSignUp: "SEND OTP",
    endUserSignUp: "SEND OTP",
    signInScreen: inputType === "phone" ? "SEND OTP" : "SIGN IN",
    signUpOtpScreen: "VERIFY",
    forgotPasswordOtpScreen: "VERIFY",
    forgotPassword: "SUBMIT",
    resetPassword: "SUBMIT",
    changePassword: "SUBMIT",
    socialSignInScreen: "SUBMIT",
    signInOtpScreen: "SIGN IN",
  };

  const authSubmitButtonText = authSubmitButtonTextSources[screenName] || null;

  const store = (response) => {
    appStorageServices.storeData(
      StorageConstants.USER_ID,
      response?.result?._id
    );
    appStorageServices.storeData(StorageConstants.siteVisited, true);
    if (response?.result?.role?.includes("serviceprovider")) {
      appStorageServices.storeData(StorageConstants.ROLE, "serviceprovider");
    } else if (response?.result?.role?.includes("instituteAdmin")) {
      appStorageServices.storeData(
        StorageConstants.InstituteId,
        response?.result?.instituteId
      );
      appStorageServices.storeData(StorageConstants.ROLE, "instituteAdmin");
    } else {
      appStorageServices.storeData(StorageConstants.ROLE, "endUser");
    }
    appStorageServices.storeData(
      StorageConstants.FIRSTNAME,
      `${response?.result?.firstName}`
    );
    appStorageServices.storeData(
      StorageConstants.LASTNAME,
      response?.result?.lastName
    );
    appStorageServices.storeData(
      StorageConstants.EMAIL,
      response?.result?.email
    );
    appStorageServices.storeData(
      StorageConstants.COUNTRY,
      response?.result?.country ?? ""
    );
    appStorageServices.storeData(StorageConstants.IS_LOGGED_IN, true);
    dispatch(setLoggedInUserId(response?.result?._id));
    const admin =
      response?.result?.role[0] === "serviceprovider" ? true : false;
    appStorageServices.storeData(StorageConstants.IS_SP, admin);
    dispatch(loginUser(true, admin));
    dispatch(setUserdetails(response?.result));
  };

  const saveCartData = (response) => {
    const id = response?.result?._id;
    let updatedData = cartsDataBeforeLogin.map((each) => {
      if (!isEmpty(each?.courseId)) {
        return {
          batchId: each?.batchId,
          courseId: each?.courseId,
          parentId: id,
        };
      } else {
        return {
          parentId: id,
          gigId: each?.gigId,
        };
      }
    });
    const data = {
      userId: id,
      cartData: updatedData,
    };

    saveMultiCarts(
      data,
      (res) => {
        if (res?.status === "Success") {
          localStorage?.removeItem("CartDataBeforeLogin");
          store(response);
          dispatch(toggleSignInPop(false));
          window.location.reload();
        }
      },
      (err) => {
        console.log(err, "---getting errror while saving carts to user");
      }
    );
  };

  const defaultPath = constants.defaultPath;
  const replacePaths = defaultPath.some((path) => pathname.includes(path));
  const setScreenIndex = (val) => {
    dispatch(setSignupScreenIndex(val));
  };
  const dataEmpty = () => {
    setUserRole(null);
    setSocialLogins({});
    setScreenIndex(0);
  };

  // const googleSignInForWeb = async (profile, type) => {
  //     try {
  //         getUserByMail(
  //             profile?.email,
  //             (response) => {
  //                 if (response?.status === "Success") {
  //                     if (!isEmpty(response?.result)) {
  //                         storeUserLogin(response);
  //                     } else {
  //                         const data = {
  //                             firstName:
  //                                 profile?.given_name ||
  //                                 profile?.first_name + profile?.middle_name ||
  //                                 "",
  //                             email: profile?.email,
  //                             lastName: profile?.family_name || profile?.last_name || "",
  //                             authToken: profile?._id || profile?.id,
  //                             registrationType: type,
  //                             isEmailVerified: true,
  //                             profileImage: profile?.picture || "",
  //                         };
  //                         console.log(data);
  //                         navigate("/social-sign-in", { state: data });
  //                         // setSocialLogins(data);
  //                     }
  //                 } else {
  //                     showToast("Social  signin failed.");
  //                 }
  //             },
  //             (error) => {
  //                 console.log("Google signin failed", error);
  //             }
  //         );
  //     } catch (error) {
  //         alert(error.message);
  //     }
  // };
  const storeUserLogin = (response) => {
    if (cartsDataBeforeLogin && cartsDataBeforeLogin?.length > 0) {
      saveCartData(response);
    } else if (
      pathname.includes("course-details") ||
      pathname.includes("sp-profile")
    ) {
      store(response);
      // dispatch(toggleSignInPop(false));
      window.location.reload();
    } else {
      dataEmpty();
      dispatch(loginUser(true));
      if (replacePaths) {
        store(response);
        dispatch(toggleSignInPop(false));
        navigate("/", { replace: true });
        window.location.reload();
      } else {
        store(response);
        // dispatch(toggleSignInPop(false));
        navigate("/", { replace: true });
        window.location.reload();
      }
    }
  };

  const handleSuccessGoogleLogin = (response) => {
    storeUserLogin(response);
  };

  const onfailureGoogleLoginApi = (error) => {
    console.log(error, "failed google login");
  };

  const proceedGooglLogin = (loginData) => {
    // const requestData = {
    // 	...socialLogins,
    // 	displayName: loginData?.displayName,
    // 	role: userRole === "endUser" ? ["endUser"] : ["serviceprovider", "endUser"],
    // 	password: loginData?.password,
    // 	country: loginData?.country,
    // };
    // console.log(loginData,requestData,"requestData",socialLogins);
    AuthService.socialLogin(
      loginData,
      handleSuccessGoogleLogin,
      onfailureGoogleLoginApi
    );
  };

  // const googleSignup = () => {
  // 	auth
  // 	  .signInWithPopup(GoogleProvider)
  // 	  .then((result) => {
  // 		if (result) {
  // 		  googleSignInForWeb(result?.additionalUserInfo?.profile, "google");
  // 		}
  // 	  })
  // 	  .catch((alert) => {
  // 		console.log(alert, "alert-error");
  // 	  });
  //   };

  const onClickEvent = async (data) => {
    // if (screenName === "signInScreen" && inputType !== "phone") {
    // 	if (inputType === "email") {
    // 		try {
    // 			const { userName, password } = data;
    // 			if (!userName || !password) {
    // 				return showToast("error", "Please Enter The Required Fields");
    // 			}
    // 			const userData = { username: userName, password };
    // 			const loginApiResponse = await loginUsingUserData({ userData });
    // 			if (loginApiResponse?.result) {
    // 				const { email } = loginApiResponse.result;
    // 				showToast("success", loginApiResponse.message);
    // 				if (email) postFCMDeviceTokenObject(email);
    // 				storeUserLogin(loginApiResponse);
    // 			} else {
    // 				showToast("error", loginApiResponse?.message || "Login failed");
    // 			}
    // 		} catch (error) {
    // 			console.error("Login error:", error);
    // 			showToast("error", "An error occurred while logging in. Please try again.");
    // 		}
    // 	} else {
    // 		showToast("error", "Invalid Email Or Password");
    // 	}
    // }

    // else
    if (screenName === "resetPassword") {
      const { userInfo = {} } = location?.state;
      const password = data.password;
      const confirmPassword = data.confirmPassword;
      // const updatePassword = () => {
      if (password === confirmPassword) {
        updatePasswordAuth(
          userInfo.email,
          password,
          (response) => {
            if (
              response.status === "Success" &&
              response.message !== "Password cannot be empty"
            ) {
              showToast("success", response?.message);
              navigate("/login", { replace: true });
            } else {
              showToast("info", response.message);
            }
          },
          (err) => {
            console.log(err, "errr");
          }
        );
      } else {
        // alert("passowrd and confirm should be same ");
        showToast("error", "password and confirm password should be same");
      }
    } else if (screenName === "socialSignInScreen") {
      // console.log(location, "location");
      const role = data.isServiceProvider
        ? ["serviceprovider", "endUser"]
        : ["endUser"];
      const data1 = { ...location?.state, ...data, role };
      !data?.isServiceProvider
        ? navigate("/register/select-intrests", {
            state: { signUpData: data1, socialLogin: true },
            replace: true,
          })
        : navigate("/register/select-profession", {
            state: { signUpData: data1, socialLogin: true },
            replace: true,
          });
      // proceedGooglLogin(data1);
      // googleSignup();
    }
  };

  return {
    authSubmitButtonText,
    onClickEvent,
  };
};
