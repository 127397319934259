import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyData from "../../globalComponents/EmptyData";
import { CircularProgress } from "@mui/material";
import { isEmpty } from "lodash";
import AautiText from "../../globalComponents/AautiText";
import { useSelector } from "react-redux";
import CourseFilters from "../../home/upcomming/CourseFilters";
import moment from "moment";
import { AuthService } from "../../../service";
import { FetchServices } from "./FetchServices";
import Coursecard from "../../appCards/Coursecard";
import GigCard from "../../appCards/GigCard";
import AdhocCard from "../../appCards/AdhocCard";
import TopProvidersCard from "../../home/topProviders/TopProvidersCard";
import { transformedObject } from "../../CommonFunctions";
import { checkNull } from "../../../utils/Helpers";
import AautiInfiniteScroll from "../../features/InfiniteScroll";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import AppContainer from "../../../layouts/AppContainer";

const MoreResults = () => {
  const { state } = useLocation();
  // console.log(state?.filterKeys, "state");
  const { mobileDevices, ipodDevices, lapy, miniLapys } = useSelector(
    (state) => state.responsive
  );

  const navigate = useNavigate();

  const { loggedinUserId } = useSelector((state) => state.app);
  const [searchValue, setSearchValue] = useState(state?.searchValue || "");
  const [apiLoading, setApiLoading] = useState(false);
  const [moreResults, setMoreResults] = useState([]);
  const [offSet, setOffset] = useState(0);
  const [hasData, setHasData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [resultsFilters, setResultsFilters] = useState(state?.filterKeys || {});
  const count = AautiStrings?.count;
  // const onSuccess = (resp) => {
  //   setApiLoading(false);
  //   if (offSet == 0) {
  //     if (checkNull(resp?.result)) {
  //       setMoreResults([]);
  //       setHasData(false);
  //     } else if (resp?.result?.length < count) {
  //       setMoreResults(resp?.result);
  //       setHasData(false);
  //     } else {
  //       setHasData(true);
  //       setMoreResults(resp?.result);
  //       setOffset(offSet + 1);
  //     }
  //   } else {
  //     if (checkNull(resp?.result)) {
  //       setHasData(false);
  //     } else if (resp?.result?.length < count) {
  //       setMoreResults([...moreResults, ...resp?.result]);
  //       setHasData(false);
  //     } else {
  //       setMoreResults([...moreResults, ...resp?.result]);
  //       setHasData(true);
  //       setOffset(offSet + 1);
  //     }
  //   }
  // };
  const onFailure = (error) => {
    setApiLoading(false);
    console.error(error, "error-getting more results");
  };
  const handleFetchApi = (data) => {
    const onSuccess = (resp) => {
      setApiLoading(false);
      if (data.offset == 0) {
        // alert("hi");
        if (checkNull(resp?.result)) {
          setMoreResults([]);
          setHasData(false);
        } else if (resp?.result?.length < count) {
          setMoreResults(resp?.result);
          setHasData(false);
        } else {
          setHasData(true);
          setMoreResults(resp?.result);
          setOffset(data.offset + 1);
        }
      } else {
        if (checkNull(resp?.result)) {
          setHasData(false);
        } else if (resp?.result?.length < count) {
          setMoreResults([...moreResults, ...resp?.result]);
          setHasData(false);
        } else {
          setMoreResults([...moreResults, ...resp?.result]);
          setHasData(true);
          setOffset(data.offset + 1);
        }
      }
    };
    switch (state?.activeType) {
      case "Courses":
        AuthService.getTimeslotsCourseSchedules(data, onSuccess, onFailure);
        break;
      case "Gigs":
        FetchServices.gigSMatchMySchedule(data, onSuccess, onFailure);
        break;
      case "Adhoc Sessions":
        FetchServices.adhocSessionsMatchMySchedule(data, onSuccess, onFailure);
        break;
      case "Service Providers":
        FetchServices.getSpMatchMySchedule(data, onSuccess, onFailure);
        break;
      default:
        break;
    }
  };

  const makeSearchApi = (
    value = searchValue,
    filtersData = state?.filterKeys,
    offsetKey = offSet
  ) => {
    if (offsetKey == 0 && value == "") {
      setApiLoading(true);
    }
    let cat = [];
    let data = {
      // categories: filtersData?.categories,
      countries: [],
      dateSlots: {},
      endDate: moment(new Date()).add(3, "month").format("YYYY-MM-DDTHH:mm:00"),
      languages: [],
      searchKey: value,
      startDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:00"),
      endUserId: loggedinUserId ? loggedinUserId : null,
      timeSlots: [],
      weekSlots: {},
      offset: offsetKey,
      subCategories: [],
      timeZone: moment().utcOffset(),
      ageGroup: [],
      levels: [],
      count: count,
      type: state?.type == "toprated" ? "TopRated" : "Sponsored",
      ...state?.activeFilters?.activeFields,
    };
    if (!checkNull(value)) {
      data.searchKey = value;
      if (value != "") {
        setOffset(0);
        data.offset = 0;
      }
    }
    if (filtersData != {}) {
      let filters = transformedObject(filtersData ? filtersData : {});
      // data.offset = 0;
      // if (!isEmpty(filtersData)) {
      // alert("hey-buddy");
      // setOffset(0);
      // data.offset = 0;

      data = { ...data, ...filters };
    }
    // console.log(offsetKey, "offset");
    handleFetchApi(data);
  };
  useEffect(() => {
    makeSearchApi("", {}, 0);
  }, [state?.type, state?.activeType]);

  const returnListing = (each, index) => {
    switch (state?.activeType) {
      case "Courses":
        return (
          <Coursecard
            key={index}
            title={"searchResults"}
            {...{ each }}
            cardWidth={mobileDevices ? "98%" : 269}
          />
        );
        break;
      case "Gigs":
        return (
          <GigCard
            {...{ each }}
            cardWidth="282px"
            getGigsList={() => {
              setRefresh(!refresh);
            }}
          />
        );
        break;
      case "Adhoc Sessions":
        return <AdhocCard {...{ each, index }} />;
        break;
      case "Service Providers":
        return (
          <TopProvidersCard
            key={index}
            {...{ each, index }}
            cardwidth={"278px"}
          />
        );
        break;
      default:
        break;
    }
  };

  const returnScreenName = () => {
    switch (state?.activeType) {
      case "Courses":
        return "topRatedInCourses";
        break;
      case "Gigs":
        return "topRatedInGigs";
        break;
      case "Adhoc Sessions":
        return "topRatedInAdhoc";
        break;
      case "Service Providers":
        return "serviceProviderCourses";
        break;
      default:
        break;
    }
  };

  return (
    <AppContainer>
      <div
        style={{
          width: "100%",
          paddingLeft: "1.5%",
          display: "flex",
          flexDirection: mobileDevices ? "column" : "row",
          marginTop: mobileDevices ? 2 : 15,
          gap: 15,
        }}
      >
        <div
          style={{
            width: mobileDevices ? "99%" : "20%",
            maxWidth: mobileDevices ? "99%" : "20%",
            minWidth: mobileDevices ? "99%" : "20%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            borderRadius: 4,
            padding: mobileDevices ? 0 : 10,
            background: mobileDevices && "transparent",
            height: "fit-content",
          }}
        >
          <CourseFilters
            actFilters={resultsFilters}
            handleClickApply={(data) => {
              setOffset(0);
              setResultsFilters(data);
              makeSearchApi("", data, 0);
              console.log(data, "data");
              navigate(".", {
                state: {
                  ...state,
                  filterKeys: data,
                  searchValue,
                },
              });
              // navigate(".", { state: { ...state, filtersData: data } });
            }}
            searchValue={searchValue}
            onCloseSearch={() => {
              setOffset(0);
              setSearchValue("");
              makeSearchApi("", resultsFilters, 0);
            }}
            onChangeSearchValue={(e) => {
              const value = e.target.value.trimStart().replace(/\s+/g, " ");
              setOffset(0);
              setSearchValue(value);
              makeSearchApi(value, resultsFilters, 0);
              // navigate(".", { state: { ...state, searchValue: value } });
            }}
            screenName={returnScreenName()}
          />
        </div>
        <div style={{ width: mobileDevices ? "100%" : "78%" }}>
          <div
            style={{
              width: "100%",
              height: "80vh",
              overflow: "scroll",
            }}
            id="MoreScroll"
          >
            <AautiText
              title={"Showing Results"}
              textStyle={{ marginBottom: 10 }}
            />

            {apiLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  height: "50vh",
                  width: "100% !important",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : isEmpty(moreResults) ? (
              <div>
                <EmptyData text="No Results" showExplore={false} />
              </div>
            ) : (
              <AautiInfiniteScroll
                testId="MoreScroll"
                cardsList={moreResults}
                {...{ hasData, handleLoadMore: makeSearchApi }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    flexWrap: "wrap",
                    marginBottom: 15,
                    alignItems: "flex-start",
                  }}
                >
                  {moreResults?.map((each, index) =>
                    returnListing(each, index)
                  )}
                </div>
              </AautiInfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default MoreResults;
