import React from 'react'

import TextExtraction from './TextExtraction'

export const PATTERNS = {
  url: /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]*[-a-zA-Z0-9@:%_\+\]~#?&\/=])*/i,
  phone: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}/,
  email: /\S+@\S+\.\S+/,
}


class ParsedText extends React.Component {
  static displayName = 'ParsedText'

  static defaultProps = {
    parse: null,
    childrenProps: {},
  }

  setNativeProps(nativeProps) {
    this._root.setNativeProps(nativeProps)
  }

  getPatterns() {
    return this.props.parse.map((option) => {
      const { type, ...patternOption } = option
      if (type) {
        if (!PATTERNS[type]) {
          throw new Error(`${option.type} is not a supported type`)
        }
        patternOption.pattern = PATTERNS[type]
      }

      return patternOption
    })
  }

  getParsedText() {
    if (!this.props.parse) {
      return this.props.children
    }
    if (typeof this.props.children !== 'string') {
      return this.props.children
    }

    const textExtraction = new TextExtraction(
      this.props.children,
      this.getPatterns()
    )

    return textExtraction.parse().map((props, index) => {
      const { style: parentStyle } = this.props
      const { style, ...remainder } = props
      return (
        <span
          key={`parsedText-${index}`}
          style={{
            ...parentStyle,
            display: "inline-block",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
          {...this.props.childrenProps}
          {...remainder}
        />
      );
    })
  }

  render() {
    const { parse, childrenProps, ...remainder } = { ...this.props }

    return (
      <p ref={(ref) => (this._root = ref)} {...remainder}>
        {this.getParsedText()}
      </p>
    )
  }
}

export default ParsedText
