import React from "react";
import AautiText from "../../../../globalComponents/AautiText";
import { messageTypes } from "../../../constants";
import MessageImage from "../image";
import MessageText from "../text";
import MessageAudio from "../audio";
import MessageVideo from "../video";
import MessageFile from "../file";
import { MdNotInterested } from "react-icons/md";
import { LuReply } from "react-icons/lu";
import './styles.scss'
import { dateToFromNowDaily } from "../../../../CommonFunctions";
import { RxCross2 } from "react-icons/rx";

function AbstractReply({
  onDismissReply = null,
  message,
  searchText
}) {
  const userId = localStorage.getItem("USER_ID");
  const isMe = userId === message?.sender?._id;

  function renderMessageText() {
    if (message && message.text) {
        return (
          <MessageText
          key={`reply-text-${message._id}`}
            {...{
              reply: true,
              currentMessage: message,
              textProps: {
                numberOfLines: 3,
              },
              searchText,
            }}
          />
        )
    }
    return null;
  }
  function renderMessageImage() {
    if (message && message?.files?.length) {
      return (
        <MessageImage
          key={`reply-image-${message._id}`}
          {...{
            currentMessage: message,
            reply: true,
            searchText,
          }}
        />
      );
    }
    return null;
  }
  function renderMessageVideo() {
    if (message && message?.files?.length) {
        return (
          <MessageVideo
            key={`reply-video-${message._id}`}
            {...{
              currentMessage: message,
              reply: true,
              searchText,
            }}
          />
        )
    }
    return null;
  }
  function renderMessageAudio() {
    if (message && message.files?.length) {
      return (
        <MessageAudio
        key={`reply-audio-${message._id}`}
          {...{
            currentMessage: message,
            reply: true,
            searchText,
          }}
        />
      )
    }
    return null;
  }

  function renderMessageMedia() {
    if (message && message.files?.length) {
      return (
        <MessageFile
          key={`reply-file-${message._id}`}
          {...{
            currentMessage: message,
            reply: true,
            searchText,
          }}
        />
      )
    }
    return null;
  }

  function renderBubbleContent() {
    switch (message?.type) {
      case messageTypes.IMAGE:
        return renderMessageImage();
      case messageTypes.AUDIO:
        return renderMessageAudio();
      case messageTypes.OTHER:
      case messageTypes.DOCUMENT:
        return renderMessageMedia();
      case messageTypes.VIDEO:
        return renderMessageVideo();
      default:
        return renderMessageText();
    }
  }

  const isReplyDeleted =
    (message?.deleted || message?.deletedFor?.includes(userId)) &&
    message?.type !== "TEXT";
  let replyingToTime = `${message?.sender?.displayName}, ${dateToFromNowDaily(message.createdAt)}`
  return (
    <div className={'abstract_reply_wrapper'}>
      <div className="abstract_reply_to_wrapper">
        <LuReply  className="abstract_reply_icon" onClick={onDismissReply} />
        {isReplyDeleted ? (
          <MdNotInterested/>
        ) : renderBubbleContent()}
        <AautiText
          className={'abstract_reply_to_text'}
          title={replyingToTime}
        />
      </div>
      {!!onDismissReply && (<RxCross2  className="abstract_reply_icon" onClick={onDismissReply} />)}
    </div>
  );
}

export default AbstractReply;
