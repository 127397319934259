export const chevronLeft =
  "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/chevronLeft.png";
export const course =
  "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/course.png";
export const attendance =
  "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/selectedUser.png";
export const rating =
  "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/star.png";
export const users =
  "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/users.png";
export const noImage =
  "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/noimage.jpeg";

const w = window.innerWidth;
const h = window.innerHeight;

export const isTimeInRange = (startTime, endTime) => {
  const currentDate = new Date();
  const fifteenMinutesBeforeStartTime = new Date(startTime);
  fifteenMinutesBeforeStartTime.setMinutes(
    fifteenMinutesBeforeStartTime.getMinutes() - 15
  );

  return (
    currentDate >= fifteenMinutesBeforeStartTime &&
    currentDate <= new Date(endTime)
  );
};

export const FileUploadMaxCount = 10;

export const FeedAllowedTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
];

export const ClassMediaAllowedTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "video/mp4",
];
