import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Badge from "@mui/material/Badge";

export const StyledMsgContainer = styled.div`
    display: flex;
    flex-direction:column;
    row-gap: 4px;
    column-gap: 4px;
    width:100%;
`

export const BootstrapDialog = muiStyled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root":{
    width:'100%',
    [theme.breakpoints.up('sm')]:{
      width:420
    }
  }
}));

export const StyledBadge = muiStyled(Badge)(({ onlineStatus }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: onlineStatus ? "#44b700" : "#ff0000",
    color: onlineStatus ? "#44b700" : "#ff0000",
    boxShadow: `0 0 0 2px #ffffff`,
    "&::after": {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: 'none',
      content: '""',
    },
  },
}));