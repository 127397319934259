import React from "react";
import { Box, Skeleton } from "@mui/material";

const TrusteeLoader = () => {
  const renderSkeletonCard = () => (
    <Box display="flex" alignItems="center" mb={2} flexDirection={"row"}>
      <Skeleton variant="rounded" width={40} height={40} />
      <Box ml={2} width="100%">
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
      </Box>
    </Box>
  );

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
      {Array.from(new Array(4)).map((_, index) => (
        <div key={index} style={{ width: "100%" }}>
          {renderSkeletonCard()}
        </div>
      ))}
    </div>
  );
};

export default TrusteeLoader;
