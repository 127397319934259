import { ApiMethods, ApiPaths } from "../../../../../service/api-constants";
import { ApiRequest } from "../../../../../service/api-request";

function savePoll(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_POLL,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function savePollDraft(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_POLL_DRAFT,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getSpIdPollDetails(serviceProviderId, pollId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_SP_POLL_DETAILS +
      "?serviceProviderId=" +
      serviceProviderId +
      "&pollId=" +
      pollId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllPollDrafts(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_DRAFT_SP_POLL_DETAILS,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getEndUserPollDetails(endUserId, pollId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ENDUSER_POLL_DETAILS +
      "?endUserId=" +
      endUserId +
      "&pollId=" +
      pollId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function updatePoll(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_POLL,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function updatePollDraft(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_POLL_DRAFT,
    method: ApiMethods.PUT,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function submitPoll(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SUBMIT_POLL,
    method: ApiMethods.POST,
    data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function deletePoll(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_POLL + "?id=" + id,
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteDraftPoll(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_DRAFT_POLL + "?id=" + id,
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getPollDetailsBySessionIdBatchId(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_POLLDETAILS_BY_BATCHID_SESSIONID,

    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

function getDraftsSpIdPollDetails(
  sessionId,
  batchId,
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url:
      ApiPaths.GET_DRAFT_SP_POLL_DETAILS +
      "?sessionId=" +
      sessionId +
      "&batchId=" +
      batchId +
      "&serviceProviderId=" +
      serviceProviderId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

export const AuthService = {
  savePoll,
  getSpIdPollDetails,
  updatePoll,
  deletePoll,
  getPollDetailsBySessionIdBatchId,
  getEndUserPollDetails,
  submitPoll,
  savePollDraft,
  getAllPollDrafts,
  getDraftsSpIdPollDetails,
  deleteDraftPoll,
  updatePollDraft,
};
