import { IconButton, Tooltip } from "@mui/material";
import React from "react";

import Icon from "../../../assets/images/wishlist.svg";
import WishActive from "../../../assets/images/wishlist_active.svg";

const WishIcon = ({
  onClick,
  active = false,
  height = "18px",
  width = "18px",
  id = "wishlist",
}) => {
  return (
    <Tooltip title={active ? "Remove form wishlist" : "Add to wishlist"}>
      <IconButton
        id={active ? "wishlisted" : "wishlist" || id}
        onClick={onClick}
        sx={{
          height: height,
          width: width,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          //   backgroundColor: "#fff",
          p: 0,
          //   "&:hover": { background: "#fff" },
        }}
      >
        <img
          src={active ? WishActive : Icon}
          alt="heart"
          style={{
            zIndex: 100,
            height: "100%",
            width: "100%",
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default WishIcon;
