import React from "react";
import AautiText from "./AautiText";
import { useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa6";
import { Divider } from "@mui/material";

const ComponentHeader = ({
  title,
  handleClickBtn = () => {},
  showButton = false,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 6,
          alignItems: "center",
          width: "100%",
          margin: "8px 0px",
        }}
      >
        {(mobileDevices || showButton) && (
          <FaArrowLeft
            onClick={handleClickBtn}
            size={18}
            style={{ cursor: "pointer" }}
          />
        )}
        <AautiText title={title} size={"medium"} />
      </div>
      <Divider />
    </>
  );
};

export default ComponentHeader;
