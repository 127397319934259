import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute.js";
import TransferRequests from "../components/transferRequests/TransferRequests.js";
import React, { Suspense, lazy } from "react";
import Spinner from "../components/globalComponents/Spinner.js";
import MyLearnings from "../components/course/myCourses/MyLearnings.js";
import InstituteWebDesign from "../components/instituteSetup/InstituteWebDesign.js";
import CourseCategories from "../components/courseCategories/index.js";
import InstituteProfile from "../components/instituteSetup/InstituteProfile.js";
import PartTypeComponent from "../components/partTypeComponent/index.js";
import PartTypeViewComponent from "../components/partTypeViewComponent/index.js";
import InstituteInfo from "../components/institution/intituteInformation/instituteInfo.js";
import AcccountManagement from "../components/accountManagement/AcccountManagement.js";
import Feedback from "../components/feedback/index.js";
import Refunds from "../components/ordersScreen/refunds/index.js";
import Reschedule from "../components/course/reschedule/index.js";
import RescheduleList from "../components/course/reschedule/RescheduleList.js";
import ReschedulePage from "../components/home/notifications/reschedulePage.js";
import CourseHistory from "../components/course/myCourses/CourseHistory.js";
import Chatbot from "../components/chatbot/index.js";
import PayoutRequest from "../components/payoutRequests/index.js";
import HomePageNew from "../components/homeScreen/index.js";
import TeachWithAauti from "../components/homeScreen/teachWithAauti/";
import Institutions from "../components/homeScreen/institutions/index.js";
import DeleteOrDeactivate from "../components/deleteOrDeactivate/index.js";
import MoreResults from "../components/adhoc/matchMySchedule/MoreResults.js";
import CourseAdhocEvent from "../components/course/courseAdhoc/index.js";
import CoursesByCategory from "../components/course/CoursesByCategory.js";
import MoreServiceProviders from "../components/homeScreen/serviceprovidersSection/MoreServiceProviders.js";
import PublicRoute from "./PublicRoute.js";
import MoreListing from "../components/homeScreen/whatOthersTalkAboutUs/MoreListing.js";
import DashBoardInfo from "../components/dashBoardInfo/DashBoardInfo.js";
import Notification from "../components/notifications/Notification.js";
import NotificationSettings from "../components/notificationSettings/Component.js";
import UserSecurity from "../components/userSecurity/index.js";
import InstituteProfileEdit from "../components/instituteSetup/InstituteProfileEdit.js";
import TrusteeComponent from "../components/trusteeModules/index.js";
import VerifyUser from "../features/verifyUser/index.js";

const TwoFactorAuth = lazy(() =>
  import(
    "../components/homeScreen/authentication/twoFactorAuthentication/index.js"
  )
);

const SignInScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignIn };
});

const GigDetails = lazy(async () => {
  const module = await import("../components/gig/gigDetails");
  return { default: module.GigDetails };
});

const SignInOtpScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignInOtpScreen };
});

const RegisterScreenServiceProvider = lazy(async () => {
  const module = await import("../components/homeScreen/authentication/signUp");
  return { default: module.SignUpServiceProvider };
});

const ForgotPasswordScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ForgotPassword };
});

const SelectUserTypeScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SelectUserType };
});

const SignUpOtpScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignUpOtpScreen };
});

const SignUpEndUser = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SignUpEndUser };
});

const ForgotPasswordOtpScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ForgotPasswordOtpScreen };
});

const ResetPassword = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ResetPassword };
});

const ChangePassword = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.ChangePassword };
});

const SocialSignInScreen = lazy(async () => {
  const module = await import("../components/homeScreen/authentication");
  return { default: module.SocialSignInScreen };
});

const SelectProfession = lazy(async () => {
  const module = await import("../components/homeScreen/selectionScreens");
  return { default: module.SelectProfession };
});

const SelectSubCategories = lazy(async () => {
  const module = await import("../components/homeScreen/selectionScreens");
  return { default: module.SelectSubCategories };
});

const SelectIntrests = lazy(async () => {
  const module = await import("../components/homeScreen/selectionScreens");
  return { default: module.SelectIntrests };
});
const InstituteSignup = lazy(() =>
  import("../components/instituteSetup/InstituteSignup.js")
);

const Home = lazy(() => import("../components/home/Home.js"));
const SimilarPackages = lazy(() =>
  import("../components/home/package/SimilarPackages.js")
);
const JitsiVideoCall = lazy(() =>
  import("../components/calendar/join_class/Jitsi/index.js")
);
const AboutAauti = lazy(() =>
  import("../components/globalComponents/CommingSoon.js")
);
const CalendarComponent = lazy(() => import("../components/calendar/index"));
const JoinClassComponent = lazy(() =>
  import("../components/calendar/join_class/index")
);
const JitsiComponent = lazy(() =>
  import("../components/calendar/join_class/Jitsi/index.js")
);
const JitsiPipMode = lazy(() => import("../jitsiContainer/index.js"));
const MoreTopProvidersCards = lazy(() =>
  import("../components/home/topProviders/MoreTopProviders.js")
);
const CartNew = lazy(() => import("../components/cart/CartNew.js"));
const NotFound = lazy(() => import("../components/notFound/NotFound.js"));
const SearchResult = lazy(() =>
  import("../components/adhoc/matchMySchedule/SearchResult.js")
);
const CourseDetails = lazy(() =>
  import("../components/course/courseDetails/index.js")
);

const InstitutionCourseDetails = lazy(() =>
  import("../components/institution/courseDetails/index.js")
);
const SPdetailsScreen = lazy(() =>
  import("../components/serviceProviderDetails/index.js")
);
const InstituteDetails = lazy(() =>
  import("../components/institution/instituteDetails/index.js")
);
const CreateLessonPlan = lazy(() =>
  import("../components/createLessonPlan/index.js")
);
const FavorateServiceProviders = lazy(() =>
  import("../components/home/topProviders/FavoriteServiceProviders.js")
);
const OfftimeSchedule = lazy(() =>
  import("../components/adhoc/matchMySchedule/offtimeSchedule/index.js")
);
const Profile = lazy(() => import("../components/profile/Profile.js"));
const AllGigs = lazy(() => import("../components/gig/AllGigs.js"));
const MoreBlockDeals = lazy(() =>
  import("../components/home/blockbuster/MoreBlockDeals.js")
);
const MorePackages = lazy(() =>
  import("../components/home/package/MorePackages.js")
);
const NewSessionCardView = lazy(() =>
  import("../components/home/upcomming/NewSessionCardView.js")
);
const WishList = lazy(() => import("../components/home/wishlist/index.js"));
const Chat = lazy(() => import("../components/chat/index.js"));
const MyCourses = lazy(() => import("../components/course/myCourses/index.js"));
const MyLessonPlans = lazy(() => import("../components/lessonPlan/index.js"));

const MyOrders = lazy(() => import("../components/ordersScreen/index.js"));
const AddPackage = lazy(() =>
  import("../components/home/package/AddPackage.js")
);

const CommingSoon = lazy(() =>
  import("../components/globalComponents/CommingSoon.js")
);
const RescheduleAndMerge = lazy(() =>
  import("../components/rescheduleAndMerge/index.js")
);
const AdhocProce = lazy(() =>
  import("../components/adhoc/adhocPrice/index.js")
);
const SPCoupons = lazy(() => import("../components/cart/coupons/SPCoupons.js"));
const AddCoupons = lazy(() =>
  import("../components/cart/coupons/AddCoupons.js")
);
const AdhocDetails = lazy(() =>
  import("../components/adhoc/adhocDetails/index.js")
);

const TermsAndConditions = lazy(() =>
  import("../components/conditions/TermsAndConditions.js")
);
const ReturnPolicy = lazy(() =>
  import("../components/conditions/ReturnPolicy.js")
);
const ViewAllGigs = lazy(() => import("../components/gig/ViewAllGigs.js"));
const MembersList = lazy(() => import("../components/members/index.js"));
const BankDetails = lazy(() => import("../components/bankDetails/index.js"));

// const GigPreview = lazy(() => import("../components/gig/GigPreview.js"));

const MyAvailability = lazy(() =>
  import("../components/availability/index.js")
);
const BranchesScreen = lazy(() =>
  import("../components/institution/branches/BranchesScreen.js")
);
const AssigningInstructorsAndParticipants2 = lazy(() =>
  import("../components/institution/assignInstructorsAndParticipants/index.js")
);
const UploadedInformation = lazy(() =>
  import("../components/institution/UploadedInformation.js")
);
const SimilarCourses = lazy(() =>
  import("../components/course/SimilarCourses.js")
);

const SimilarGigs = lazy(() => import("../components/gig/SimilarGigs.js"));

const PrivacyPolicy = lazy(() =>
  import("../components/conditions/PrivacyPolicy.js")
);
const ContactUs = lazy(() => import("../components/conditions/ContactUs.js"));
const CreateOrEditBranch = lazy(() =>
  import("../components/institution/branches/CreateOrEditBranch.js")
);
const Payment = lazy(() => import("../components/paymentsGateway/Payments.js"));
const AllTrendingCourses = lazy(() =>
  import("../components/home/upcomming/AllTrendingCourses.js")
);
const AdminCourses = lazy(() =>
  import("../components/home/InstitutesHomePage/AdminCourses.js")
);
const MyPackages = lazy(() =>
  import("../components/home/package/MyPackages.js")
);
const OrderStatus = lazy(() =>
  import("../components/paymentsGateway/OrderStatus.js")
);
const PackageDetails = lazy(() =>
  import("../components/home/package/PackageDetails.js")
);
const HelpandSupport = lazy(() =>
  import("../components/help&support/index.js")
);
const AdhocEvents = lazy(() =>
  import("../components/adhoc/adhocEvents/index.js")
);
const MySchedule = lazy(() => import("../components/mySchedule/index.js"));
const SubCourses = lazy(() =>
  import("../components/course/courseDetails/SubCourses.js")
);

const ExploreMoreCategories = lazy(() =>
  import("../components/homeScreen/exploreMoreCategories/index.js")
);

const RefundConfig = lazy(() =>
  import("../components/refundConfiguration/Component.js")
);

const ExpandGigs = lazy(() => import("../components/gig/ExapandGig.js"));

const CourseAdhocCreationLazy = lazy(() =>
  import("../components/course/courseAdhoc/Create/index.js")
);

const RescheduleDetails = lazy(() =>
  import("../components/home/notifications/RescheduleDetails/Component.js")
);

const MoreInstitutes = lazy(() =>
  import("../components/home/InstitutesHomePage/MoreInstitutes.js")
);

const InstituteCourses = lazy(() =>
  import("../components/home/InstitutesHomePage/InstituteCourses.js")
);

const FavouriteInstitutes = lazy(() =>
  import("../components/home/InstitutesHomePage/FavouriteInstitutes.js")
);

const CourseCreation = lazy(() =>
  import("../components/course/courseCreation/index.js")
);

const getHome = (role) => {
  let data;
  if (role?.includes("instituteAdmin")) {
    data = { home: <Home />, path: "/" };
  } else {
    data = { home: <Home />, path: "/" };
  }
  return data;
};

const Router = () => {
  const { isUserLoggedIn, userDetails } = useSelector((state) => state.app);
  const home = isUserLoggedIn ? (
    getHome(userDetails?.role).home
  ) : (
    <HomePageNew />
  );
  const routes = [
    { path: "/", element: home },
    {
      path: "/part-types/:category/:subCategory/:id/:noOfSessions/:isCourseId/:isDraftId",
      element: <PartTypeComponent />,
    },
    {
      path: "/part-type-view/:id/:isCourseId/:userId",
      element: <PartTypeViewComponent />,
    },
    { path: "/create-offTime", element: <OfftimeSchedule /> },
    { path: "/whyAauti", element: <AboutAauti /> },
    { path: "/categories", element: <ExploreMoreCategories /> },
    { path: "/course-details/:id", element: <CourseDetails /> },

    { path: "/sp-profile/:id", element: <SPdetailsScreen /> },
    { path: "/institute-profile/:id", element: <InstituteDetails /> },
    { path: "/top-serviceproviders", element: <MoreTopProvidersCards /> },
    { path: "/gigs", element: <AllGigs /> },
    { path: "/payments", element: <CommingSoon /> },
    { path: "/Reviews", element: <CommingSoon /> },
    { path: "/search-result", element: <SearchResult /> },
    { path: "/search-results/:type", element: <MoreResults /> },

    { path: "/similar-gigs", element: <SimilarGigs /> },
    { path: "/similar-courses", element: <SimilarCourses /> },
    { path: "/similar-packages", element: <SimilarPackages /> },

    { path: "institute/profile/:id", element: <InstituteProfile /> },
    { path: "institute/profile/edit/:id", element: <InstituteProfileEdit /> },
    { path: "/add-package", element: <AddPackage /> },
    { path: "/NewSessionCardView", element: <NewSessionCardView /> },
    { path: "/block-deals", element: <MoreBlockDeals /> },
    { path: "/more-packages", element: <MorePackages /> },
    { path: "/more-institutes", element: <MoreInstitutes /> },
    { path: "/institute-courses/:id", element: <InstituteCourses /> },
    { path: "/select-profession", element: <SelectProfession /> },
    { path: "/select-subCtegories", element: <SelectSubCategories /> },
    { path: "/sp-coupons", element: <SPCoupons /> },
    { path: "/add-coupon", element: <AddCoupons /> },
    { path: "/termsandconditions", element: <TermsAndConditions /> },
    { path: "/privacypolicy", element: <PrivacyPolicy /> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: "/refundpolicy", element: <ReturnPolicy /> },
    { path: "/gig-preview/:id", element: <GigDetails /> },
    { path: "/newletter", element: <CommingSoon /> },
    { path: "/blog", element: <CommingSoon /> },
    { path: "/careers", element: <CommingSoon /> },
    { path: "/features", element: <CommingSoon /> },
    { path: "/solutions", element: <CommingSoon /> },
    { path: "/overview", element: <CommingSoon /> },

    { path: "/what-others-talk-about", element: <MoreListing /> },
    { path: "/more-serviceproviders", element: <MoreServiceProviders /> },
    // ******** Institute Screens paths **********
    {
      path: "/institute/course/details/:id",
      element: <InstitutionCourseDetails />,
    },
    { path: "/institute/branches", element: <BranchesScreen /> },
    { path: "/institute/branches/create", element: <CreateOrEditBranch /> },
    { path: "/Institute-Information", element: <InstituteInfo /> },
    {
      path: "/institute/instructors/instructors",
      element: <UploadedInformation />,
    },
    {
      path: "/institute/participants/participants",
      element: <UploadedInformation />,
    },
    { path: "/coming-soon", element: <CommingSoon /> },
    { path: "/reschedule-merge", element: <RescheduleAndMerge /> },
    {
      path: "/institute/assigning/instructorsAndParticipants",
      element: <AssigningInstructorsAndParticipants2 />,
    },
    { path: "/institute/termsAndConditions", element: <TermsAndConditions /> },
    // ******** instructorScreen paths **********
    {
      path: "/instructors-details/instructor-participants-details",
      element: <UploadedInformation />,
    },
    { path: "/upcoming-courses", element: <AdminCourses /> },
    { path: "/on-going-courses", element: <AdminCourses /> },
    { path: "/completed-courses", element: <AdminCourses /> },
    { path: "/trending-courses", element: <AllTrendingCourses /> },
    { path: "/my-packages", element: <MyPackages /> },
    { path: "/help-support", element: <HelpandSupport /> },
    { path: "/sponsered-courses", element: <CommingSoon /> },
    { path: "/:subCtegory/courses", element: <SubCourses /> },
    { path: "/:subCtegory/gigs", element: <SubCourses /> },
    { path: "*", element: <NotFound /> },
    { path: "/cart", element: <CartNew /> },
    { path: "/adhoc-details/:id", element: <AdhocDetails /> },
    { path: "/institute/sign-up", element: <InstituteSignup /> },
    { path: "institute/profile/:id", element: <InstituteProfile /> },
    { path: "/newletter", element: <CommingSoon /> },
    { path: "/blog", element: <CommingSoon /> },
    { path: "/careers", element: <CommingSoon /> },
    { path: "/features", element: <CommingSoon /> },
    { path: "/solutions", element: <CommingSoon /> },
    { path: "/overview", element: <CommingSoon /> },
    { path: "/expand-gig/:id", element: <ExpandGigs /> },
    { path: "/package-details/:id", element: <PackageDetails /> },
    { path: "/user/profile/change-password", element: <ChangePassword /> },
    { path: "/social-sign-in", element: <SocialSignInScreen /> },
    { path: "courses/:category", element: <CoursesByCategory /> },
    { path: "/profile/interests", element: <SelectIntrests /> },
    { path: "/explore-more-categories", element: <ExploreMoreCategories /> },
    { path: "/institute/webDesign/:id", element: <InstituteWebDesign /> },
    { path: "/CourseCategories", element: <CourseCategories /> },
  ];
  const protectedRoutes = [
    { path: "/sp-create-lesson", element: <CreateLessonPlan /> },
    { path: "/chat", element: <Chat /> },
    { path: "/wishlist", element: <WishList /> },

    { path: "/buyNow", element: <CartNew /> },
    { path: "/profile", element: <Profile /> },
    { path: "/students", element: <DashBoardInfo /> },
    { path: "/myLesson-plans", element: <MyLessonPlans /> },
    { path: "/payments-gateway/status", element: <OrderStatus /> },
    { path: "/calendar", element: <CalendarComponent /> },
    { path: "/help", element: <Chatbot /> },
    { path: "/payout-requests", element: <PayoutRequest /> },

    { path: "/JitsiComponent", element: <JitsiComponent /> },
    { path: "/join_class", element: <JoinClassComponent /> },
    { path: "/create-lesson-plan", element: <CreateLessonPlan /> },
    { path: "/reviews-ratings", element: <DashBoardInfo /> },
    { path: "/adhoc-price", element: <AdhocProce /> },
    { path: "/my-courses", element: <MyCourses /> },
    { path: "/my-learnings", element: <MyLearnings /> },
    { path: "/coupons", element: <SPCoupons /> },
    { path: "/my-revenue", element: <DashBoardInfo /> },
    { path: "/members-list", element: <MembersList /> },
    { path: "/bank-details", element: <BankDetails /> },
    { path: "/add-package", element: <AddPackage /> },
    { path: "/orders", element: <MyOrders /> },
    { path: "/edit-gig", element: <CourseCreation /> },
    { path: "/gig-creation", element: <CourseCreation /> },
    { path: "/edit-course", element: <CourseCreation /> },
    { path: "/attendance-details", element: <DashBoardInfo /> },
    { path: "/view-gigs", element: <ViewAllGigs /> },
    {
      path: "/my-instructors",
      element: <FavorateServiceProviders />,
    },
    {
      path: "/my-instructors",
      element: <FavorateServiceProviders />,
    },
    { path: "/join_class/aautimeet", element: <JitsiVideoCall /> },
    { path: "/refunds", element: <Refunds /> },
    { path: "/account-settings", element: <AcccountManagement /> },
    { path: "/adhoc-events", element: <AdhocEvents /> },
    { path: "/AdhocEvent", element: <MyAvailability /> },
    { path: "my-account", element: <AcccountManagement /> },
    { path: "/feedback", element: <Feedback /> },
    { path: "/course-creation", element: <CourseCreation /> },
    { path: "/reschedule", element: <Reschedule /> },
    { path: "/course-history", element: <CourseHistory /> },
    { path: "/reschedule-refund", element: <ReschedulePage /> },
    { path: "/reschedule-details", element: <RescheduleDetails /> },
    {
      path: "/payments-gateway/payment-checkout/:orderId",
      element: <Payment />,
    },
    { path: "/reschedule-list", element: <RescheduleList /> },
    { path: "/my-schedule", element: <MySchedule /> },
    { path: "/overview", element: <CommingSoon /> },
    { path: "/favourite-institutes", element: <FavouriteInstitutes /> },
    { path: "/transfer-requests", element: <TransferRequests /> },
    { path: "/delete-deactivate", element: <DeleteOrDeactivate /> },
    { path: "/course-adhoc-events", element: <CourseAdhocEvent /> },
    { path: "/refund-configuration", element: <RefundConfig /> },
    { path: "/security", element: <UserSecurity /> },
    {
      path: "/course-adhoc-creation",
      element: <CourseAdhocCreationLazy />,
    },
    { path: "/notifications", element: <Notification /> },
    { path: "/notification-settings", element: <NotificationSettings /> },
    { path: "/trustee-management", element: <TrusteeComponent /> },
    { path: "/user-verification", element: <VerifyUser /> },
  ];

  const publicRoutes = [
    { path: "/teach-with-Aauti", element: <TeachWithAauti /> },
    { path: "/institutes", element: <Institutions /> },
    { path: "/login", element: <SignInScreen /> },
    { path: "/register/user-type", element: <SelectUserTypeScreen /> },
    {
      path: "/register/service-provider",
      element: <RegisterScreenServiceProvider />,
    },
    { path: "/register/end-user", element: <SignUpEndUser /> },
    { path: "/login/sign-in-otp", element: <SignInOtpScreen /> },
    { path: "/forgot-password", element: <ForgotPasswordScreen /> },
    {
      path: "/forgot-password/otp-screen",
      element: <ForgotPasswordOtpScreen />,
    },
    { path: "/register/otp-screen", element: <SignUpOtpScreen /> },
    { path: "/forgot-password/reset-password", element: <ResetPassword /> },
    {
      path: "/register/select-subcategories",
      element: <SelectSubCategories />,
    },
    { path: "/register/select-intrests", element: <SelectIntrests /> },
    { path: "/register/select-profession", element: <SelectProfession /> },
    {
      path: "/institute/sign-up",
      element: <InstituteSignup />,
    },
  ];
  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 10000000000,
          }}
        >
          <Spinner />
        </div>
      }
    >
      <Routes>
        {routes.map((each) => (
          <Route key={each.path} path={each.path} element={each.element} />
        ))}

        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicRoute isUserLoggedIn={isUserLoggedIn}>
                {route.element}
              </PublicRoute>
            }
          />
        ))}

        <Route
          element={
            <PublicRoute
              path={window.location.pathname}
              isUserLoggedIn={isUserLoggedIn}
            />
          }
        >
          {publicRoutes.map((each) => (
            <Route key={each.path} path={each.path} element={each.element} />
          ))}
        </Route>
        <Route
          element={
            <ProtectedRoute
              path={window.location.pathname}
              isUserLoggedIn={isUserLoggedIn}
            />
          }
        >
          {protectedRoutes.map((each) => (
            <Route key={each.path} path={each.path} element={each.element} />
          ))}
        </Route>
      </Routes>
      <JitsiPipMode />
      <TwoFactorAuth />
    </Suspense>
  );
};
export default Router;
