import React, { useState } from "react";
import { chatConstants } from "../../../constants";
import AautiDialog from "../../../../globalComponents/AautiDialog";
import { BsFileEarmarkText } from "react-icons/bs";
import FileInfo from "../../file_info";
import { StyledMsgContainer } from "../../styled_components";
import FilePreview from "../../file_preview";

function MessageFile({ currentMessage : { files }, position, reply = false,searchText }) {
  const [selectedFile, setSelectedFile] = useState(undefined);
  return (
    <StyledMsgContainer>
      <BsFileEarmarkText style={{alignSelf:'center'}} size={40} onClick={()=>setSelectedFile(files[0])} /> 
      <FileInfo file={files[0]}/>
      <AautiDialog
        open={Boolean(selectedFile)}
        onClose={() => {
          setSelectedFile(undefined);
        }}
        dialogTitle={chatConstants?.previewFile}
      >
        <FilePreview {...{file:files[0]}}/>
      </AautiDialog>
    </StyledMsgContainer>
  );
}

export default MessageFile;
