import { Component, forwardRef } from "react";
import { Day } from "./Day";
import { SystemMessage } from "./SystemMessage";
import Bubble from "./Bubble";
import { messageTypes } from "../../constants";
import { isSameUser } from "../../utils";

class Message extends Component {
  shouldComponentUpdate(nextProps) {
    const next = nextProps.currentMessage;
    const {
      currentMessage: current,
      previousMessage,
      nextMessage,
      isSelected,
      showSelectIcon,
      searchText,
      highlighted,
    } = this.props;
    const nextPropsMessage = nextProps.nextMessage;
    const nextPropsPreviousMessage = nextProps.previousMessage;

    return (
      next.createdAt !== current.createdAt ||
      next.text !== current.text ||
      next.files?.length !== current.files?.length ||
      next.type !== current.type ||
      previousMessage !== nextPropsPreviousMessage ||
      nextMessage !== nextPropsMessage ||
      isSelected !== nextProps.isSelected ||
      searchText !== nextProps.searchText ||
      highlighted !== nextProps.highlighted ||
      showSelectIcon !== nextProps.showSelectIcon
    );
  }

  renderDay() {
    if (this.props.currentMessage && this.props.currentMessage.createdAt) {
      return <Day {...this.props} />;
    }
    return null;
  }
  renderBubble() {
    return (
      <Bubble
        key={`bubble-${this.props.currentMessage?._id}`}
        {...this.props}
      />
    );
  }
  renderSystemMessage() {
    return <SystemMessage {...this.props} />;
  }

  render() {
    const {innerRef, currentMessage, nextMessage, position } = this.props;
    if (currentMessage) {
      const sameUser = isSameUser(currentMessage, nextMessage);
      return (
        <li ref={innerRef} id={currentMessage._id}>
          {this.renderDay()}
          {currentMessage.type === messageTypes.SYSTEM ? (
            this.renderSystemMessage()
          ) : (
              <div
                className={`bubble ${position}`}
                style={{
                  marginBottom: sameUser ? 2 : 8,
                }}
              >
                {this.renderBubble()}
              </div>
          )}
        </li>
      );
    }
    return null;
  }
}
Message.defaultProps = {
  position: "left",
  currentMessage: {},
  nextMessage: {},
  previousMessage: {},
  user: {},
  showUserAvatar: false,
  inverted: true,
  shouldUpdateMessage: undefined,
};

export default forwardRef((props, ref) => (
  <Message innerRef={ref} {...props} />
));
