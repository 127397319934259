import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  endUserAccount,
  endUserSettings,
  InstituteSideItems,
  spAccountItems,
  spSettingsList,
} from "./constants";
import AautiText from "../globalComponents/AautiText";
import LockResetIcon from "@mui/icons-material/LockReset";
import { ConfirmDialog } from "primereact/confirmdialog";
import BottomSheet from "../globalComponents/BottomSheet";
import MenuBar from "../menuBar/MenuBar";
import ComponentHeader from "../globalComponents/ComponentHeader";
import { MdOutlineAttachMoney } from "react-icons/md";
import { GoPersonAdd } from "react-icons/go";
import { HeaderNotificationsInActive } from "../homeScreen/header/pageHeader/pageHeaderUtils";
import CourseInPerson from "../addressAndLocation/CourseInperson";
import AppContainer from "../../layouts/AppContainer";

const AccountManagement = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loggedinUserId, userDetails, UserRole, trusteeMembers } = useSelector(
    (state) => state.app
  );
  const { mobileDevices } = useSelector((state) => state.responsive);

  const [openAddress, setOpenAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(
    userDetails?.addressList?.find((item) => item?.isDefault)
  );

  const getActiveList = () => {
    if (["/my-account"].includes(pathname)) {
      if (UserRole === "instituteAdmin") return [...InstituteSideItems];
      if (UserRole === "endUser") {
        return userDetails?.parentId
          ? [
              ...endUserAccount.filter((each) => each.id !== 7),
              {
                id: 86,
                label: "Notifications",
                icon: HeaderNotificationsInActive,
                screenName: "/notifications",
              },
            ]
          : [
              ...endUserAccount,
              {
                id: 85,
                label: "My Orders",
                icon: MdOutlineAttachMoney,
                screenName: "/orders",
              },
              {
                id: 86,
                label: "Notifications",
                icon: HeaderNotificationsInActive,
                screenName: "/notifications",
              },
            ];
      }
      return [
        ...spAccountItems,
        ...(!isEmpty(trusteeMembers)
          ? []
          : [
              {
                id: 130,
                label: "My Trusted Accounts",
                icon: GoPersonAdd,
                screenName: "/trustee-management",
              },
            ]),
      ];
    }

    if (UserRole === "endUser") {
      return userDetails?.createdByParent && userDetails?.parentId
        ? endUserSettings.filter((each) => each.id !== 220)
        : userDetails?.isEmailVerified && userDetails?.email
        ? [
            ...endUserSettings,
            {
              id: 11,
              label: userDetails?.isPassword
                ? "Change Password"
                : "Create Password",
              icon: LockResetIcon,
              screenName: "",
            },
          ]
        : endUserSettings;
    }

    return userDetails?.isEmailVerified && userDetails?.email
      ? [
          ...spSettingsList,
          {
            id: 11,
            label: userDetails?.isPassword
              ? "Change Password"
              : "Create Password",
            icon: LockResetIcon,
            screenName: "",
          },
        ]
      : spSettingsList;
  };

  const activeList = getActiveList();
  const screenName = ["/my-account"].includes(pathname)
    ? "My Account"
    : "Settings";

  return (
    <>
      <AppContainer style={{ width: "100%", height: !mobileDevices && "90vh" }}>
        <ComponentHeader
          title={screenName}
          handleClickBtn={() => navigate(-1)}
        />
        <div
          style={{
            width: "100%",
            flex: 1,
            height: "96%",
            paddingBottom: mobileDevices && "80px",
            overflow: "scroll",
          }}
        >
          <Grid container sx={{ mt: 1 }} gap={1}>
            {activeList.map((each, index) => (
              <Grid
                key={index}
                id={`${each.label}-myaccount`}
                onClick={() => {
                  if (
                    each.label === "Change Password" ||
                    each.label === "Create Password"
                  ) {
                    navigate("/user/profile/change-password", {
                      state: { createPassword: userDetails?.isPassword },
                    });
                  } else if (each.label === "Delete Account") {
                    navigate("/delete-deactivate");
                  } else if (each.screenName === "address") {
                    setOpenAddress(true);
                  } else {
                    navigate(each.screenName, {
                      state: { screenName: each.label },
                    });
                  }
                }}
                item
                xs={5.8}
                sm={3.8}
                md={3}
                lg={2}
                xl={1.5}
                borderRadius={1}
                sx={{
                  cursor: "pointer",
                  border: "1px solid #d2d2d2",
                  p: 1,
                  display:
                    each.label === "Add Member" &&
                    userDetails?.parentId &&
                    "none",
                }}
              >
                <each.icon size={18} />
                <AautiText title={each.label} weight="bold" size="semi" />
              </Grid>
            ))}
            <ConfirmDialog />
          </Grid>
        </div>
        <BottomSheet open={openAddress} onClose={() => setOpenAddress(false)}>
          <CourseInPerson
            clientLocation={false}
            handleSelectAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            handleSetSelectAddress={() => setOpenAddress(false)}
          />
        </BottomSheet>
      </AppContainer>
      <MenuBar />
    </>
  );
};

export default AccountManagement;
