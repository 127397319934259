import { AiOutlineBranches, AiOutlineSchedule } from "react-icons/ai";
import {
  BsFileEarmarkPlay,
  BsPersonAdd,
  BsPersonCheck,
  BsPersonHeart,
} from "react-icons/bs";
import { FaListCheck, FaRepeat } from "react-icons/fa6";
import { LuPackagePlus } from "react-icons/lu";
import {
  MdOutlineSecurity,
  MdOutlineAttachMoney,
  MdOutlineGroups,
  MdOutlinePlayLesson,
  MdOutlineReviews,
  MdOutlineVideoLibrary,
} from "react-icons/md";
import { PiBriefcase } from "react-icons/pi";
import {
  RiBankLine,
  RiCalendarTodoLine,
  RiCoupon2Fill,
  RiSettings3Line,
} from "react-icons/ri";
import { SlBookOpen } from "react-icons/sl";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { FiInfo } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { TbAddressBook } from "react-icons/tb";
import { HeaderNotificationsInActive } from "../homeScreen/header/pageHeader/pageHeaderUtils";

const instituteId = localStorage.getItem("instituteId");

const deactivateOrDelete = {
  id: 220,
  label: instituteId
    ? "Delete or Deactivate Institute"
    : "Deactivate or Delete Account",
  icon: FiInfo,
  screenName: "/delete-deactivate",
};

const useSecurity = {
  id: 221,
  label: "Security",
  icon: MdOutlineSecurity,
  screenName: "/security",
};

const commonItems = {
  feedback: {
    id: 1989,
    label: "Feedback",
    icon: FiInfo,
    screenName: "/feedback",
  },
  myAddress: {
    id: 1953,
    label: "My Address",
    icon: TbAddressBook,
    screenName: "address",
  },
  notificationSettings: {
    id: 221,
    label: "Notification Settings",
    icon: RiSettings3Line,
    screenName: "/notification-settings",
  },
  notifications: {
    id: 50,
    label: "Notifications",
    icon: HeaderNotificationsInActive,
    screenName: "/notifications",
  },
};

export const spAccountItems = [
  {
    id: 4,
    label: "My Courses",
    icon: BsFileEarmarkPlay,
    screenName: "/my-courses",
  },
  {
    id: 690,
    label: "My Schedule",
    icon: AiOutlineSchedule,
    screenName: "/my-schedule",
  },
  {
    id: 8,
    label: "My Gigs",
    icon: MdOutlineVideoLibrary,
    screenName: "/view-gigs",
  },
  {
    id: 6,
    label: "Lesson Plans",
    icon: MdOutlinePlayLesson,
    screenName: "/myLesson-plans",
  },
  {
    id: 56,
    label: "Adhoc Events",
    icon: RiCalendarTodoLine,
    screenName: "/adhoc-events",
  },
  {
    id: 56,
    label: "Course Adhoc Events",
    icon: RiCalendarTodoLine,
    screenName: "/course-adhoc-events",
  },
  {
    id: 1094,
    label: "Adhoc Class Pricing",
    icon: SlBookOpen,
    screenName: "/adhoc-price",
  },
  {
    id: 5,
    label: "My Packages",
    icon: LuPackagePlus,
    screenName: "/my-packages",
  },
  {
    id: 139,
    label: "Students",
    icon: MdOutlineGroups,
    screenName: "/students",
  },
  {
    id: 39,
    label: "Attendance",
    icon: BsPersonCheck,
    screenName: "/attendance-details",
  },
  {
    id: 11,
    label: "Revenue",
    icon: MdOutlineAttachMoney,
    screenName: "/my-revenue",
  },
  {
    id: 86,
    label: "Refund Configuration",
    icon: MdOutlineAttachMoney,
    screenName: "/refund-configuration",
  },
  commonItems.feedback,
  commonItems.myAddress,
  {
    id: 11,
    label: "Reviews & Ratings",
    icon: MdOutlineReviews,
    screenName: "/reviews-ratings",
  },
  {
    id: 71,
    label: "Manage Bank Details",
    icon: RiBankLine,
    screenName: "/bank-details",
  },
  {
    id: 101,
    label: "Payout Requests",
    icon: MdOutlineAttachMoney,
    screenName: "/payout-requests",
  },
  {
    id: 111,
    label: "Batch Transfer Requests",
    icon: MdOutlineVideoLibrary,
    screenName: "/transfer-requests",
  },
  {
    id: 13,
    label: "Coupons & Discounts",
    icon: RiCoupon2Fill,
    screenName: "/coupons",
  },
  {
    id: 120,
    label: "Sponsored Courses",
    icon: FaListCheck,
    screenName: "/sponsored-courses",
  },
  {
    id: 102,
    label: "Reschedule & Merge",
    icon: FaRepeat,
    screenName: "/reschedule-merge",
  },
  {
    id: 14,
    label: "My Interests",
    icon: InterestsOutlinedIcon,
    screenName: "/profile/interests",
  },
  {
    id: 94,
    label: "My Professions",
    icon: PiBriefcase,
    screenName: "/select-profession",
  },
  commonItems.notifications,
  commonItems.notificationSettings,
];

export const endUserAccount = [
  {
    id: 4,
    label: "My Learnings",
    icon: BsFileEarmarkPlay,
    screenName: "/my-learnings",
  },
  {
    id: 8,
    label: "My Gigs",
    icon: MdOutlineVideoLibrary,
    screenName: "/view-gigs",
  },
  {
    id: 33,
    label: "My Favourite Service Providers",
    icon: BsPersonHeart,
    screenName: "/my-instructors",
  },
  {
    id: 39,
    label: "Attendance",
    icon: BsPersonCheck,
    screenName: "/attendance-details",
  },
  { id: 7, label: "Members", icon: BsPersonAdd, screenName: "/members-list" },
  {
    id: 10,
    label: "Wishlist",
    icon: FavoriteBorderIcon,
    screenName: "/wishlist",
  },
  {
    id: 2342,
    label: "My Favourite Institutes",
    icon: BsPersonHeart,
    screenName: "/favourite-institutes",
  },
  commonItems.feedback,
  commonItems.myAddress,
  {
    id: 1,
    label: "Refunds",
    icon: MdOutlineAttachMoney,
    screenName: "/refunds",
  },
  commonItems.notificationSettings,
];

export const spSettingsList = [deactivateOrDelete, useSecurity];
export const endUserSettings = [deactivateOrDelete, useSecurity];

export const InstituteSideItems = [
  {
    id: 3,
    label: "Branches",
    icon: AiOutlineBranches,
    screenName: "/institute/branches",
  },
  {
    id: 9,
    label: "Instructors",
    icon: LiaChalkboardTeacherSolid,
    screenName: "/institute/instructors/instructors",
  },
  {
    id: 10,
    label: "Participants",
    icon: HiOutlineUsers,
    screenName: "/institute/participants/participants",
  },
  {
    id: 23,
    label: "Courses",
    icon: BsFileEarmarkPlay,
    screenName: "/my-courses",
  },
];
