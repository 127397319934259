import React from "react";
export function SystemMessage({ currentMessage }) {
  if (currentMessage == null || currentMessage.system == false) {
    return null;
  }
  return (
    <div className="system-message-container">
      <p className="system-message-text">{currentMessage.text}</p>
    </div>
  );
}
