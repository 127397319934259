export const globalColors = {
  AAUTI_THEME_COLOR: "#3083EF",
  BORDER_COLOR: "#f2f2f6",
  RED_COLOR: "red",
  BORDER_GREY: "#dedede",
  GREY_TEXT: "#5C5B57",
  Border_light_grey: "#e0e0e0",
  Aauti_Green: "#3acc6c",
  Aauti_Red: "#dc3545",
  Aauti_White: "#ffffff",
  Aauti_Orange:"#F88442",
  //Avatar colors
  avatarOrange: "#e67e22",
  avatarGreen: "#2ecc71",
  avatarPrimary: "#3498db",
  avatarPink: "#8e44ad",
  avatarRed: "#e74c3c",
  avatarInfo: "#1abc9c",
  avatarDefault: "#2c3e50",
  darkGrey: "#9A9A9A",
  HEADING_COLOR: "#1E1E1E",
  PARAGRAPH_COLOR: "#626972",
  TAG_BACKGROUND: "#F9EF99",
  BackGround_Ligh_Color: "#F7F7FA",
  light_Text_Grey: "#626262",
};
