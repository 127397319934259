import moment from "moment";
import { BsPersonVideo3 } from "react-icons/bs";
import { IoMdPerson } from "react-icons/io";
import { LuMonitor } from "react-icons/lu";
import { MdGroups } from "react-icons/md";

export const courseTypes = [
  {
    id: 1,
    icon: <IoMdPerson size={25} />,
    title: "Individual",
    text: "Selecting Individual allows for personalized training, with flexible scheduling and pacing to meet specific learning needs.",
    slotKeys: ["individualDaily", "individualWeekly"],
  },
  {
    id: 2,
    icon: <MdGroups size={25} />,
    title: "Group",
    text: "Selecting the Group option allows you to teach multiple participants at once.",
    slotKeys: ["groupDialy", "groupWeekly"],
  },
  {
    id: 3,
    icon: <BsPersonVideo3 size={25} />,
    title: "Gig",
    text: "Allows you to showcase your skills and services with engaging videos.",
  },
];

export const ClassTypes = [
  {
    id: 1,
    name: "Virtual",
    icon: <LuMonitor />,
  },
  { id: 2, name: "In Person", icon: <IoMdPerson /> },
];

export const tabsList = [
  {
    id: 1,
    name: "Title",
    text: "Course creation offers you the flexibility to select categories such as Levels, Category, Type, Location, Age, Description, and Tags, and allows you to divide the sessions accordingly. ",
  },
  {
    id: 2,
    name: "Lesson Plan",
    text: "You can either create a Lesson Plan or choose from existing options, with additional ways to personalize your selection. ",
  },

  {
    id: 4,
    name: "Course Type & Pricing",
    text: "Set pricing and discounts for individual or group slots. Adjust rates based on the number of participants and the type of session to offer flexible pricing options. ",
  },
];

export const subTopicObject = {
  subTopicName: "",
  subTopicDescription: "",
  contentData: "",
  uploadContent: [],
  uploadDocument: [],
};
export const topicObject = {
  type: "Lesson",
  topicName: "",
  topicDescription: "",
  data: [],
};

export const sessionObject = {
  sessionName: "Session 1",
  sessionDescription: "",
  topics: [topicObject],
};

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function isUrl(input) {
  try {
    new URL(input); // Check if it's a valid URL
    return !input.startsWith("data:image/"); // Ensure it's not a Base64 string
  } catch {
    return false; // Not a valid URL
  }
}

export function isUrlOrBase64(input) {
  const base64Regex = /^data:image\/[a-zA-Z]+;base64,[a-zA-Z0-9+/]+={0,2}$/;

  try {
    new URL(input);
    return true; // Valid URL
  } catch {
    return base64Regex.test(input); // Check if Base64
  }
}

export const levelStrings = ["Beginner", "Intermediate", "Advanced", "Expert"];

const date = new Date();
const today = moment(date).format("YYYY-MM-DDT00:00:00");
let endDateFormat = moment(date)?.format("YYYY-MM-DDT23:59:59");

const todayDate = new Date();
const currentHour = todayDate.getHours();
const nextStartingHour = (currentHour + 1) % 24;
todayDate.setHours(nextStartingHour, 0, 0, 0);
const formattedDate = todayDate.toISOString();

const nextHour = new Date(formattedDate);
nextHour.setHours(nextHour.getHours() + 1);
const formattedDate2 = nextHour.toISOString();

export function getNextTimeSlot() {
  const presentTime = moment();
  const currentTime = presentTime.add(1, "hour");
  // const currentDate = new Date();
  // const date = new Date();
  // const currentMinutes = currentDate.getMinutes();
  // let nextHours = currentDate.getHours();
  // let nextMinutes;
  // if (currentMinutes < 20) {
  //   nextMinutes = 30;
  // } else {
  //   nextMinutes = 0;
  //   nextHours++;
  // }
  // nextHours = nextHours % 24;
  // const formattedHours = String(nextHours).padStart(2, "0");
  // const formattedMinutes = String(nextMinutes).padStart(2, "0");
  // date.setHours(formattedHours);
  // date.setMinutes(formattedMinutes);
  // const utcTimeString = moment(date)?.format("YYYY-MM-DDTHH:mm:ss");
  const utcTimeString = moment(currentTime).format("YYYY-MM-DDTHH:00:00");
  return utcTimeString;
}

const nextTimeSlot = getNextTimeSlot();

export const classesType = ["Virtual", "Inperson"];

export const targetedAgeGroup = ["Below 6Y", "6Y-18Y", "19Y-30Y", "Above 30Y"];

export const chooseLessonPlans = [
  {
    id: 0.12321498,
    head: "Aauti Content ",
    desc: "Find pre-existing content for the course you want to teach. You can edit and adapt this material to fit your lesson plan.",
  },
  {
    id: 456789.23,
    head: "Third Party Content",
    desc: "Access third-party content for your course here. You can edit and adapt this material to fit your lesson.",
  },
];

export const weekName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const weekMTObj = {
  instructors: [],
  instructorDetails: [],
  batchName: "",
  startDate: today,
  endDate: endDateFormat,
  excludedDates: [],
  discountStartDate: today,
  discountEndDate: endDateFormat,
  discountType: "Percentage",
  inPerson: false,
  virtual: false,
};

export const dailyMTObj = {};

export const commonBatchObject = {
  instructors: [],
  instructorDetails: [],
  batchName: "",
  startDate: today,
  endDate: today,
  discountStartDate: today,
  discountEndDate: endDateFormat,
  discountType: "Percentage",
  inPerson: false,
  virtual: false,
};
export const createCourseStrings = {
  coverImage: "Cover Image",
  uploadGigCoverImage: "Upload Gig Cover Image*",
  onlyImagesWithTheFollowingRatioAreAllowed4IsTo3:
    "Only images with 4:3  ratio  are allowed",
  previewVideo: "Preview Video",
  uploadPreviewVideo: "Upload Preview Video*",
  uploadGig: "Upload Gig",
  uploadGigImp: "Upload Gig*",
  uploadMediaAndFiles: "Upload Media & Files",
  price: "Price",
  discount: "Discount Percentage",
  discountValue: "Discount Value",
  uploadVideoImp: "Upload video*",
  pasteURLHere: "Paste url here",
  videoTitle: "Video Title",
  saveAddressAs: "Save Address as",
  addressName: "Address Name",
  completeAddress: "Complete Address",
  floorOptional: "Floor(Optional)",
  nearByLandMarkOptional: "Near by LandMark (Optional)",
  saveAddress: "Save Address",
  addNewAddress: "Add New Address",
  myAddress: "My Address",
  noAddressesFound: "No Addresses Found",
  clientLocation: "Client Location",
  topicDuration: "Topic Duration",
  topicDescription: "Topic Description",
  topicContent: "Topic Content ",
  videoUrl: "Video Url",
  subtopicDescription: "Subtopic Description",
  subTopicContent: "SubTopic  Content",
  chooseLessonPlan: "Choose Lesson Plan",
  selectedLessonPlan: "Selected Lesson Plan",
  createLessonPlan: "Create Lesson Plan",
  enterSessionManually: "Enter Session Manually",
  selectedImageMustHaveA4IsTo3AspectRatio:
    "Selected image must have a 4:3 aspect ratio.",
  onlyImagesWithTheFollowingRatioAreAllowed16IsTo9:
    "Only images with 16:9  ratio  are allowed",
  previewImage: "Preview Image",
  uploadCoverImageImp: "Upload Cover Image",
  uploadRelatedImgs: "Upload Related Images",

  relatedImages: "Related Images",
  uploadMin2RelatedImages: "Upload Min 2 Related Images",
  uploadMin2RelatedImagesImp: "Upload Min 2 Related Images*",
  uploadMediaFiles: "Upload Media Files",
  coverPage: "Cover Page",
  uploadCoverPageOfTheBook: "Upload Cover Page of the book",
  PlusSymAddTopic: "+ Add Topic",
};

export const CourseAllowedTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "video/mp4",
];

export const courseConstant = {
  levels: "Levels",
  category: "Category",
  courseType: "Course Type",
  coursePricing: "Course Pricing",
  location: "Location",
  age: "Age",
  searchTags: "Search Tags",
  selectALocation: "Select a location",
  addALocation: "Add a location",
  pickARadiusFromTheDesiredLocation: "Pick a radius from the desired location",
  editLocationAndFixRadius: "Edit Location & fix Radius",
  courseOverview: "Overview",
  courseTitle: "Title",
  Allowsyoutoteachusersacrossdifferentskilllevels:
    "Allows you to teach users across different skill levels.",
  categoryAndSubcategory: "Category and Subcategory",
  categoryAndSubCategoryRequired: "Category & subcategory required",
  courseTypeRequired: "Course Type required",
  selectGroupLanguage: "Select Group language",
  selectLanguages: "Select Languages",
  classType: "Class Type",
  Picktheagegroupyoudliketoteach: "Pick the age group you'd like to teach.",
  courseTags: "Tags",
  addUptTo10KeywordsThatItWillHelpPeopleToDiscoverTheCourse:
    "Add up to 10 keywords to enhance the visibility and searchability of your course.",
  upTo10Tags: "Up to 10 tags - (minimum 3 characters)",
  shortDescription: "Short Description",
  courseSummary: "Course Summary",
  enterNoOfSessions: "No of Sessions",
  addAtHowManySessionsYourCourseIsGoingToEnd:
    "add at how many sessions your course is going to end",
  selectedDays: "Selected Days",
  selectedDates: "Selected Dates",
  step3Pricing: "Step:3 Pricing",
  selectBatchType: "Select Batch Type",
  conflicts: "Conflicts",
  AddMoreFamilyMembers: "Add More Family Members",
  Getadiscountforeveryadditionalmember:
    "Discount for each additional member beyond the first.",
  discountType: "Discount Type",
  selectDiscountValidDates: "Select Discount Dates",
  addToExceptionsList: "Add to Excluded Dates",
  Createacoursewithclearobjectivesandengaginginteractivecontenttoenhanceskills:
    "Create a course with clear objectives and engaging, interactive content to enhance skills.",
  Createagigwithclearobjectivesanddesignedcontentaimedtoenhanceskill:
    "Create a gig with clear objectives and designed content aimed to enhance skill.",
  previewCourse: "Preview",
  discard: "Discard",
  onceSubmittedItWillBeUnderReview: "Once Submitted it will be under review",
  Oncethecourseispurchased: "Once the course is purchased",
  allTheOngoingAndFutureSessionsWillBeCancelledDoYouWantToProceedString:
    "All ongoing and upcoming sessions will be canceled. Are you sure you want to continue?",
  deactivationOrDeletion: "Deactivate or Delete",
  createYourOwnLessonPlan: "Create Your Own Lesson Plan",
};
