import { ApiRequest } from "../../../service";
import { ApiMethods } from "../../../service/api-constants";

const getExploreDataService = (onSuccess, onFailure) => {
  ApiRequest({
    url: "category/getAllCategories",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
};
export { getExploreDataService };
