import React from "react";
import AautiText from "./AautiText";
import "./styles.scss";
import { CircularProgress } from "@mui/material";
import { debounce } from "lodash";

const NavButton = ({
  disabled = false,
  text,
  handleClick = () => {},
  testId,
  loading = false,
  outlined,
  style,
  containerStyles = {},
  type = "button",
}) => {
  return (
    <div className="flex-container buttons-cont" style={{ ...containerStyles }}>
      <button
        type={type}
        id={testId || text}
        disabled={disabled || loading}
        className={`save-button-main ${outlined && "outlined-nav-btn"}`}
        style={{
          background: disabled && !loading && !outlined && "#d2d2d2",
          ...style,
        }}
        onClick={debounce(
          () => {
            handleClick();
          },
          3000,
          { leading: true, trailing: false }
        )}
      >
        {loading ? (
          <CircularProgress size={20} sx={{ color: "#fff" }} />
        ) : (
          <AautiText title={text} weight={"medium"} />
        )}
      </button>
    </div>
  );
};

export default NavButton;
