import React, { useEffect, useState } from "react";
import { InstituteDashboard } from "storybook-signup";
import { getInstituteDashboardDetails, getInstituteDetails } from "./Services";
import { useNavigate } from "react-router-dom";
import "./Style.scss";
import { showToast } from "../globalComponents/Toast";
import { useParams } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import { baseUrl } from "../../service/api-constants";

function InstituteProfile(props) {
  const { countryObject, isUserLoggedIn, instituteId } = useSelector(
    (state) => state.app
  );
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [instituteDetails, setInstituteDetails] = useState(null);
  const params = useParams();
  useEffect(() => {
    getInstituteDashboardDetails(
      params?.id,
      (res) => {
        if (res?.status === "Success" && res.result !== null) {
          setDashboardData(res?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );

    getInstituteDetails(
      params?.id,
      (res) => {
        if (res?.status === "Success") {
          setInstituteDetails(res.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [params?.id]);

  return (
    <div className="instituteProfile-div1">
      <div className="instituteProfile-div2">
        {isUserLoggedIn && params?.id == instituteId && (
          <div
            onClick={() => {
              navigate(`/institute/profile/edit/${params?.id}`);
            }}
            className="instituteProfile-div3"
          >
            <AiFillEdit size={18} className="IP-icon1" />
          </div>
        )}
      </div>

      <InstituteDashboard
        isUserLoggedIn={isUserLoggedIn}
        dashboardData={dashboardData}
        instituteDetails={instituteDetails}
        countryObject={countryObject}
        showToast={showToast}
        baseUrl={baseUrl}
      />
    </div>
  );
}

export default InstituteProfile;
