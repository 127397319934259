import React from "react";
import "./styles.scss";

const AutoScroll = ({ children, speed = "10s" }) => {
  const childrenCount = React.Children.count(children);

  return (
    <div className="scrolling-container">
      <div
        className={`${
          childrenCount < 2 ? "no-scrolling" : "scrolling-content"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default AutoScroll;
