export const HeaderStrings = {
  serviceProvider: "Service Provider",
  endUser: "End User",
  role: "ROLE",
  role2: "role",
  endUser2: "endUser",
  serviceProvide2: "serviceprovider",
  success: "Success",
  error: "error",
  simplePopOver: "simple-popover",
  paymentGateway: "/payments-gateway",
  institution: "/institutions",
  whyAauti: "/whyAauti",
  svk: "/SVK",
  instituteAdmin: "instituteAdmin",
  instituteAdminCap: "Institute Admin",
  HelpSupport: "Help & Support",
  altImage:
    // "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/logo-header.png",
    "https://aautilargefileuploads.blob.core.windows.net/aautipremium/aautie/logo-header.png",
  poweredByAauti: "Powered by Aauti",
  SelectCurrency: "Select Currency",
  confirmation: "Confirmation",
  SureText: "Are you sure you want to",
  logout: "logout",
  logoutCap: "Logout",
  delete: "Delete",
  deleteAccount: "delete this Aauti account",
  provideCourse: "provide courses",
  cancel: "Cancel",
  okay: "OK",
  user: "user",
  myAccount: "My Account",
  profileSettings: "Profile",
  instituteInfo: "Institute Info",
  notAvailable: "N/A",
  myDashboard: "My Dashboard",
  AddTrustee: "My Trusted Accounts",
};

export const TabsData = [
  { id: 0, label: "Home", screenName: "/", value: 0 },
  { id: 1, label: "Why Aauti", screenName: "/whyAauti", value: 1 },
  { id: 2, label: "Teach With Aauti", screenName: "/SVK", value: 2 },
  { id: 3, label: "Institutions", screenName: "/institutions", value: 3 },
];

export const paths = [
  "/my-courses",
  "/course-creation",
  "/add-package",
  "/create-gig",
  "/create-lesson-plan",
  "/lesson-plan",
  "/sp-create-lesson",
  "/AdhocEvent",
  "/update-gig",
  "/my-orders",
  "/my-packages",
  "/adhoc-price",
  "/attendance-details",
  "/my-learnings",
  "/adhoc-events",
  "/transfer-requests",
  "/select-profession",
  "/myLesson-plans",
  "/refunds",
  "/reviews-ratings",
  "/students",
  "/my-packages",
  "/bank-details",
  "/coupons",
  "/orders",
  "/members-list",
  "/search-result",
  "/my-schedule",
  "/wishlist",
  "/students",
  "/my-instructors",
  "/my-revenue",
  "/join_class",
];
