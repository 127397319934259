import React, { useState, useEffect } from "react";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import AautiDialog from "../../globalComponents/AautiDialog";
import { isEmpty } from "lodash";
import { RxCross2 } from "react-icons/rx";
import RescheduleWeek from "./RescheduleWeek";
import { useLocation, useParams } from "react-router-dom";
import {
  addOneHourOrEndOfDay,
  formatDatesAndTimes,
  returnisPresentDay,
} from "../../CommonFunctions";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { RescheduleConstants } from "./RescheduleConstants";
import { show12hr } from "../../globalComponents/helpers.variables";
import { checkNull, formatDate } from "../../../utils/Helpers";
import moment from "moment-timezone";
import AautiTimePicker from "../../globalComponents/timepickers/AautiTimePicker";
import { courseConstant, getNextTimeSlot } from "../courseCreation/constants";
import HelperText from "../../globalComponents/HelperText";
import NavButton from "../../globalComponents/NavButton";
import CustomSlotSelection from "../courseCreation/coursePricingModules/CustomSlotSelection";
import "./Style.scss";

const validateDateForBatchReschedule = (batch, create) => {
  // const start = dayjs().add(2, "day")
  let isWeekly =
    batch?.type === "groupWeekly" || batch?.type === "individualWeekly";
  if (create) {
    if (isWeekly) {
      batch.recurringDays = {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      };
    }
    return {
      ...batch,
      startDate: dayjs().add(2, "days").startOf("day"),
      startTime: dayjs().startOf("day"),
      endDate: dayjs()
        .add(isWeekly ? 8 : 2, "days")
        .startOf("day"),
      endTime: dayjs().startOf("day").add(1, "h"),
      batchName: "",
    };
  }

  let modified = { ...batch };
  let start = dayjs(batch?.startDate);
  let end = dayjs(batch?.endDate);
  let nextEnabled = dayjs().add(2, "days");
  if (start.isBefore(nextEnabled, "days")) {
    modified.startDate = nextEnabled;
    if (end.isBefore(nextEnabled, "days")) {
      modified.endDate = nextEnabled;
    }
  }

  return {
    ...modified,
  };
};

const UpdateBatch = ({
  dailyObj,
  setWeeklyObj,
  setDailyObj,
  activeBatchType,
  batchObj,
  handleSaveRescheduleApi,
  isCreate,
}) => {
  const [batchObject, setBatchObject] = useState(
    validateDateForBatchReschedule(batchObj, isCreate)
  );
  const isWeekly =
    batchObj?.type === "groupWeekly" || batchObj?.type === "individualWeekly";

  const isCustom =
    !checkNull(batchObj?.excludedDates) ||
    (!checkNull(batchObj?.customDates) &&
      Object.keys(batchObj?.customDates)?.length > 0);

  const [dateRanges, setDateRanges] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let courseId = searchParams.get("courseId");
  let today = new Date();
  const moment = require("moment-timezone");
  const [editItem, setEditItem] = useState(null);
  const [activeBatch, setActiveBatch] = useState(
    isWeekly ? 20 : isCustom ? 30 : 10
  );

  const isIndividual =
    batchObj?.type === "individualDaily" ||
    batchObj?.type === "individualWeekly";
  const [editBatch, setEditBatch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState("");
  const activeObj = batchObject;

  const onChangetimeSlotObj = (val, slot) => {
    let dateFormat = moment(val?.$d)?.format("YYYY-MM-DDT00:00:00");
    let timeFromat = moment(val?.$d || val)?.format("YYYY-MM-DDTHH:mm:00");
    let newOnHour = addOneHourOrEndOfDay(timeFromat);

    if (slot === "startDate") {
      const before = dayjs(batchObject?.endDate).isBefore(val, "date");
      setBatchObject((prev) => ({
        ...prev,
        [slot]: dateFormat,
        endDate: before ? dateFormat : prev.endDate,
      }));
    } else if (slot === "endDate") {
      setBatchObject((prev) => ({
        ...prev,
        [slot]: dateFormat,
      }));
    } else if (slot === "startTime") {
      // !isBefore
      //   ? setDailyObj((prev) => ({
      //       ...prev,
      //       startTime: val,
      //       endTime: newOnHour,
      //     }))
      //   :
      setBatchObject((prev) => ({
        ...prev,
        [slot]: timeFromat,
        endTime: newOnHour,
        reScheduleStartTime: activeObj.startTime,
        reScheduleEndTime: activeObj?.endTime,
      }));
    } else if (slot === "endTime") {
      setBatchObject((prev) => ({
        ...prev,
        [slot]: timeFromat,
        reScheduleEndTime: activeObj?.endTime,
      }));
    } else {
      setWeeklyObj((prev) => ({
        ...prev,
        [slot]: val,
      }));
    }
  };

  const changeEditItem = (val, feild) => {
    let dateFormat = moment(val?.$d).format("YYYY-MM-DDT00:00:00");
    const timeFromat = moment(val?.$d || val).format("YYYY-MM-DDTHH:mm:00");
    const val2 = addOneHourOrEndOfDay(timeFromat);

    const isPresentDay = returnisPresentDay(dateFormat);

    let newItem = null;
    if (feild == "startDate") {
      newItem = {
        ...editItem.each,
        startDate: dateFormat,
        endDate: dateFormat,
      };
    } else if (feild == "startTime") {
      newItem = {
        ...editItem.each,
        startTime: timeFromat,
        endTime: val2,
      };
    } else if (feild == "endTime") {
      newItem = {
        ...editItem.each,
        endTime: timeFromat,
      };
    } else if (feild == "endDate") {
      newItem = {
        ...editItem.each,
        endDate: timeFromat,
      };
    } else {
      newItem = { ...editItem.each, [feild]: val };
    }
    setEditItem((prev) => ({
      ...prev,
      each: newItem,
    }));
  };

  const nextTimeSlot = getNextTimeSlot();

  const returnMinTimeValue = (timeVal) => {
    const formatedEndTime =
      moment().format("YYYY-MM-DDT") +
      moment(timeVal).add(30, "minutes").format("HH:mm:ss");
    return formatedEndTime;
  };

  const returnBatchType = () => {
    const endTime = moment(dailyObj?.startTime)
      ?.add(1, "hour")
      ?.format("YYYY-MM-DDTHH:mm:ss");
    switch (activeBatch) {
      case 10:
        return (
          <div className="start-end-time">
            <div className="eachDateStart">
              <AautiTimePicker
                label={AautiStrings?.StartTimeString}
                minTime={
                  moment(
                    activeObj?.startDate?.$d || activeObj?.startDate
                  ).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                    ? nextTimeSlot
                    : moment().startOf("day")
                }
                timerValue={
                  // editItem
                  //   ? editItem?.each?.startTime
                  //     ? dayjs(editItem?.each?.startTime)
                  //     : null
                  //   : dayjs(activeObj?.startTime)
                  //   ? dayjs(activeObj?.startTime)
                  //   : dayjs(nextTimeSlot)
                  activeObj?.startTime
                }
                onChangeTimePicker={(newVal) => {
                  // const val2 = moment(newVal)
                  //   ?.add(1, "hour")
                  //   ?.format("YYYY-MM-DDTHH:mm:ss");
                  // if (editItem) {
                  //   changeEditItem(newVal, "startTime");
                  // } else {
                  //   onChangetimeSlotObj(newVal, "startTime");
                  //   // onChangetimeSlotObj(val2, "endTime");
                  // }
                  onChangetimeSlotObj(newVal, "startTime");
                }}
              />
            </div>
            <div className="eachDateStart">
              <AautiTimePicker
                disabled={
                  moment(activeObj?.endTime)?.format("hh:mm A") ===
                  moment()?.endOf("day")?.format("hh:mm A")
                }
                sx={{ width: "100%" }}
                label={AautiStrings?.EndTimeString}
                minTime={returnMinTimeValue(activeObj?.startTime)}
                timerValue={activeObj?.endTime}
                onChangeTimePicker={(newVal) => {
                  editItem
                    ? changeEditItem(newVal, "endTime")
                    : onChangetimeSlotObj(newVal, "endTime");
                }}
                slotProps={{ field: { readOnly: true } }}
                minutesStep={30}
              />
            </div>
          </div>
        );
      case 20:
        const recurringDays = activeObj?.recurringDays;
        const WeekArray = Object.keys(recurringDays).filter(
          (day) => recurringDays[day].length > 0
        );

        return (
          <div style={{ width: "80%" }}>
            {isEmpty(WeekArray) ? (
              <button
                className="day-button"
                onClick={() => {
                  setDialogOpen("weekly");
                }}
              >
                Select Days
              </button>
            ) : (
              <>
                <AautiText
                  title={RescheduleConstants?.selectedDays}
                  size={"small"}
                />
                <div
                  className="tags-container"
                  style={{
                    gap: 6,
                    overflow: "scroll",
                    flexWrap: "nowrap",
                    marginTop: 1,
                  }}
                  onClick={() => {
                    setDialogOpen("weekly");
                  }}
                >
                  {WeekArray.map((each, index) => (
                    <button key={index} className="day-button">
                      <AautiText size={"small"} key={index} title={each} />
                      <RxCross2 />
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        );
      case 30:
        const { startDate, endDate } = activeObj;
        const selectedRange = `${moment(activeObj?.startDate).format(
          "DD MMM YYYY"
        )}-${moment(activeObj?.endDate).format("DD MMM YYYY")}`;
        return (
          <div style={{ width: "100%", marginTop: -8 }}>
            {activeObj?.customDates ? (
              <>
                <AautiText
                  title={RescheduleConstants?.selectedDates}
                  size={"small"}
                />
                <div
                  onClick={() => {
                    const obj = { startDate, endDate };
                    handleSelection(obj);
                    setDialogOpen("custom");
                  }}
                  className="tags-container"
                  style={{ width: "100%", padding: "8px 10px", marginTop: 1 }}
                >
                  <AautiText title={selectedRange} />
                </div>
              </>
            ) : (
              <button>
                <AautiText
                  title={RescheduleConstants?.selectDates}
                  onClick={() => {
                    const obj = { startDate, endDate };
                    handleSelection(obj);
                    setDialogOpen("custom");
                  }}
                />
              </button>
            )}
          </div>
        );
      default:
        break;
    }
  };

  const formatStartAndEndTimes = (date, start, end) => {
    return {
      startTime:
        dayjs(date).format("YYYY-MM-DDT") + dayjs(start).format("HH:mm:ss"),
      endTime:
        dayjs(date).format("YYYY-MM-DDT") + dayjs(end).format("HH:mm:ss"),
    };
  };

  const formatCustomDates = (dates) => {
    let keys = Object.keys(dates);
    keys.forEach((each) => {
      dates[each] = formatStartAndEndTimes(
        each,
        dates[each].startTime,
        dates[each].endTime
      );
    });
    return dates;
  };

  const formatDaily = (dailyBatch) => {
    const formattedStartEndTimes = formatStartAndEndTimes(
      dailyBatch.startDate,
      dailyBatch.startTime,
      dailyBatch.endTime
    );
    const dailyStartDate =
      formatDate({
        date: new Date(dailyBatch.startDate),
        format: "YYYY-MM-DD",
      }) +
      "T" +
      "00:00:00";
    const dailyEndDate =
      formatDate({ date: new Date(dailyBatch.endDate), format: "YYYY-MM-DD" }) +
      "T" +
      "00:00:00";
    const batch = {
      batchName: dailyBatch.batchName,
      startDate: dailyStartDate,
      endDate: dailyEndDate,
      startTime: formattedStartEndTimes.startTime,
      endTime: formattedStartEndTimes.endTime,
      discountStartDate: moment(new Date()).format("YYYY-MM-DDT00:00:00"),
      discountEndDate: moment(new Date(dailyEndDate))
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:00"),
      discountType: dailyBatch.discountType,
      excludedDates: !checkNull(dailyBatch?.excludedDates)
        ? dailyBatch?.excludedDates?.map((each) =>
            moment(each).format("YYYY-MM-DDTHH:mm:00")
          )
        : [],
      customDates:
        (dailyBatch.customDates && formatCustomDates(dailyBatch.customDates)) ??
        {},
      timeZone: moment().utcOffset(),
      inPerson: dailyBatch?.inPerson,
      virtual: dailyBatch?.virtual,
    };
    if (isIndividual) {
      batch.individualPrice = dailyBatch?.individualPrice ?? "";
      batch.individualDiscountPrice = dailyBatch?.individualDiscountPrice ?? "";
      batch.individualDiscountValue = dailyBatch?.individualDiscountValue ?? "";
      batch.localIndividualPrice = dailyBatch?.localIndividualPrice ?? "";
      batch.localIndividualDiscountPrice =
        dailyBatch?.localIndividualDiscountPrice ?? "";
      batch.localIndividualDiscountValue =
        dailyBatch?.localIndividualDiscountValue ?? "";
      batch.allowMembers = dailyBatch?.allowMembers ?? false;
      batch.membersDiscount = dailyBatch?.membersDiscount ?? [];
    } else {
      batch.groupPrice = dailyBatch?.groupPrice ?? "";
      batch.maxNoOfStudents = dailyBatch?.maxNoOfStudents ?? "";
      batch.allowWaitingList = dailyBatch?.allowWaitingList ?? false;
      batch.groupDiscountPrice = dailyBatch?.groupDiscountPrice ?? "";
      batch.groupDiscountValue = dailyBatch?.groupDiscountValue ?? "";
      batch.localGroupPrice = dailyBatch?.localGroupPrice ?? "";
      batch.localGroupDiscountPrice = dailyBatch?.localGroupDiscountPrice ?? "";
      batch.localGroupDiscountValue = dailyBatch?.localGroupDiscountValue ?? "";
    }
    return batch;
    // }
  };

  const formatWeekly = (weeklyBatch) => {
    const dailyStartDate =
      formatDate({
        date: new Date(weeklyBatch.startDate),
        format: "YYYY-MM-DD",
      }) +
      "T" +
      "00:00:00";
    const dailyEndDate =
      formatDate({
        date: new Date(weeklyBatch.endDate),
        format: "YYYY-MM-DD",
      }) +
      "T" +
      "00:00:00";
    var weekBatch = {
      batchName: weeklyBatch.batchName,
      discountType: weeklyBatch.discountType,
      timeZone: moment().utcOffset(),
      inPerson: weeklyBatch?.inPerson,
      virtual: weeklyBatch?.virtual,
      startDate: dailyStartDate,
      endDate: dailyEndDate,
    };

    let selectedDaySlots = {};
    let selectedDays = new Set();
    Object.keys(weeklyBatch.recurringDays).forEach((each, index) => {
      const daySlot = weeklyBatch.recurringDays[each].map((slot) => {
        selectedDays.add(each);
        return formatStartAndEndTimes(
          weeklyBatch.startDate,
          slot.startTime,
          slot.endTime
        );
      });
      selectedDaySlots[each] = daySlot;
    });
    let firstDay = "";
    let lastDay = "";
    for (let i = 0; i < 7; i++) {
      if (firstDay === "") {
        const day = moment(weekBatch.startDate).add(i, "day");
        if (selectedDays.has(day.format("dddd"))) {
          firstDay = day;
        }
      }
      if (lastDay === "") {
        const day = moment(weekBatch.endDate).subtract(i, "day");
        if (selectedDays.has(day.format("dddd"))) {
          lastDay = day;
        }
        if (lastDay !== "" && firstDay !== "") {
          break;
        }
      }
    }
    const weekStartDate =
      formatDate({ date: firstDay, format: "YYYY-MM-DD" }) +
      "T" +
      moment(selectedDaySlots[firstDay.format("dddd")][0].startTime).format(
        "00:00:00"
      );
    const weekEndDate =
      formatDate({ date: lastDay, format: "YYYY-MM-DD" }) +
      "T" +
      moment(selectedDaySlots[lastDay.format("dddd")][0].endTime).format(
        "00:00:00"
      );
    weekBatch.startDate = weekStartDate;
    weekBatch.endDate = weekEndDate;
    weekBatch.discountStartDate = moment(new Date()).format(
      "YYYY-MM-DDT00:00:00"
    );
    weekBatch.discountEndDate = moment(new Date(weekEndDate)).format(
      "YYYY-MM-DDTHH:mm:00"
    );
    weekBatch.recurringDays = selectedDaySlots;

    if (isIndividual) {
      weekBatch.individualPrice = weeklyBatch?.individualPrice;
      weekBatch.individualDiscountValue = weeklyBatch?.individualDiscountValue;
      weekBatch.individualDiscountPrice = weeklyBatch?.individualDiscountPrice;
      weekBatch.localIndividualPrice = weeklyBatch?.localIndividualPrice;
      weekBatch.localIndividualDiscountValue =
        weeklyBatch?.localIndividualDiscountValue;
      weekBatch.localIndividualDiscountPrice =
        weeklyBatch?.localIndividualDiscountPrice;
      weekBatch.allowMembers = weeklyBatch.allowMembers;
      weekBatch.membersDiscount = weeklyBatch.membersDiscount;
    } else {
      weekBatch.groupPrice = weeklyBatch?.groupPrice;
      weekBatch.groupDiscountValue = weeklyBatch?.groupDiscountValue;
      weekBatch.groupDiscountPrice = weeklyBatch?.groupDiscountPrice;
      weekBatch.maxNoOfStudents = weeklyBatch?.maxNoOfStudents;
      weekBatch.allowWaitingList = weeklyBatch?.allowWaitingList;
      weekBatch.localGroupPrice = weeklyBatch?.localGroupPrice;
      weekBatch.localGroupDiscountValue = weeklyBatch?.localGroupDiscountValue;
      weekBatch.localGroupDiscountPrice = weeklyBatch?.localGroupDiscountPrice;
    }

    // }
    weekBatch.discountStartDate = weekStartDate;
    return weekBatch;
  };

  const handleRescheduleBatch = () => {
    let reObj = {};
    if (isCreate) {
      if (isWeekly) {
        reObj = formatWeekly(batchObject);
      } else {
        reObj = formatDaily(batchObject);
      }
    } else {
      reObj = {
        ...batchObject,
        isRescheduled: true,
        endDate: dayjs(batchObject?.endDate).format("YYYY-MM-DDT00:00:00"),
        startDate: dayjs(batchObject?.startDate).format("YYYY-MM-DDT00:00:00"),
        reScheduleEndDate: batchObj?.endDate,
        reScheduleStartDate: batchObj?.startDate,
        reScheduleStartTime: batchObj?.startTime,
        reScheduleEndTime: batchObj?.endTime,
        startTime: dayjs(batchObject?.startTime).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: dayjs(batchObject?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        reScheduleBatchName: batchObj?.batchName,
        maxNoOfStudents: batchObj?.maxNoOfStudents,
      };
      if (batchObj?.recurringDays) {
        reObj.reScheduleRecurringDays = batchObj.recurringDays;
      }
    }
    const slot = batchObj?.type;
    let saveObj = { courseId, [slot]: [reObj] };
    saveObj[slot] = formatDatesAndTimes(saveObj[slot]);
    handleSaveRescheduleApi(saveObj);
    setBatchObject(validateDateForBatchReschedule(batchObj, isCreate));
  };

  const onChangeFields = (val, field) => {
    setBatchObject((prevData) => ({
      ...prevData,
      [field]: val,
    }));
  };

  const addSlotValidations = () => {
    const presentTime = new Date();
    const ipDate = new Date(activeObj?.startDate);
    const newErrors = {};
    if (activeObj?.batchName?.length < 5)
      newErrors.username = "title is required";
    if (!(activeObj?.individualPrice || activeObj?.groupPrice))
      newErrors.price = "Price is required";
    // if (presentTime > ipDate) {
    //   newErrors.startTime = "start time cannot be present time";
    // }
    if (Object.keys(newErrors)?.length == 0) {
      return false;
    } else {
      return true;
    }
  };
  const returnRangeDates = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const dates = {};
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const date = moment(currentDate).format("YYYY-MM-DD");
      dates[date] = {};
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const updateSlots = (object) => {
    const reObj = {
      ...batchObject,
      recurringDays: object,
    };
    setBatchObject(reObj);
    setDialogOpen("");
  };
  const handleSelection = (obj) => {
    const { startDate, endDate } = obj;

    const { excludedDates } = activeObj;
    let startDateFormat = moment(startDate).format("YYYY-MM-DDT00:00:00");
    let endDateFormat = moment(endDate).format("YYYY-MM-DDT00:00:00");
    let nextEnabled = dayjs().add(2, "days");

    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const nextEnabledMoment = moment(nextEnabled);
    if (startDateMoment.isBefore(nextEnabledMoment, "days")) {
      startDateFormat = nextEnabledMoment.format("YYYY-MM-DDT00:00:00");
      if (endDateMoment.isBefore(nextEnabledMoment, "days")) {
        endDateFormat = nextEnabledMoment.format("YYYY-MM-DDT00:00:00");
      }
    }
    setState([
      {
        startDate: startDateFormat,
        endDate: endDateFormat,
        key: "selection",
      },
    ]);

    const arrayOfDates = returnRangeDates(startDateFormat, endDateFormat);
    excludedDates?.forEach((element) => {
      const newDate = moment(element).format("YYYY-MM-DD");
      delete arrayOfDates[newDate];
    });

    const isPresentDay = returnisPresentDay(startDateFormat);
    const addedEndTime = addOneHourOrEndOfDay(nextTimeSlot);

    setBatchObject((prev) => ({
      ...prev,
      startDate: startDateFormat,
      endDate: endDateFormat,
      startTime: isPresentDay ? nextTimeSlot : prev.startTime,
      endTime: isPresentDay ? addedEndTime : prev.endTime,
      discountStartDate: startDateFormat,
      discountEndDate: endDateFormat,
    }));

    setDateRanges(arrayOfDates);
  };

  const handleSaveCustomSlot = (object) => {
    setBatchObject(object);
    setDialogOpen("");
  };

  return (
    <div className="tab">
      <div
        className="flex-container"
        style={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <div style={{ width: "100%" }}>
          <div>
            <AautiText
              title={AautiStrings?.step1BatchString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <button
              className={`level-item course-types center-content active-class`}
            >
              <AautiText title={activeBatchType} size="normal" textStyle={{}} />
            </button>
          </div>
          <div className="level-main">
            <AautiText
              title={AautiStrings?.step2DetailsString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  gap: 10,
                  marginBottom: 15,
                }}
              >
                <div style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label={AautiStrings?.StartDateString}>
                        <DatePicker
                          // disabled={
                          //   activeObj?._id &&
                          //   !isEmpty(CreateCourseObj?.endUserList)
                          // }
                          value={
                            // editItem
                            //   ? editItem?.each?.startDate
                            //     ? dayjs(editItem?.each?.startDate)
                            //     : null
                            //   : activeObj?.startDate
                            //   ? dayjs(activeObj?.startDate)
                            //   : null
                            dayjs(activeObj?.startDate)
                          }
                          onChange={(e) => {
                            onChangetimeSlotObj(e, "startDate");
                          }}
                          defaultValue={dayjs(today)}
                          format="DD/MM/YYYY"
                          minDate={dayjs().add(2, "days")}
                          slotProps={{ field: { readOnly: true } }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label={AautiStrings?.EndDateString}>
                        <DatePicker
                          // disabled={
                          //   activeObj?._id &&
                          //   !isEmpty(CreateCourseObj?.endUserList)
                          // }
                          sx={{ minWidth: "100% !important" }}
                          value={
                            editItem
                              ? editItem?.each?.endDate
                                ? dayjs(editItem?.each?.endDate)
                                : null
                              : activeObj?.endDate
                              ? dayjs(activeObj?.endDate)
                              : null
                          }
                          onChange={(newDate) => {
                            editItem
                              ? changeEditItem(newDate, "endDate")
                              : onChangetimeSlotObj(newDate, "endDate");
                          }}
                          defaultValue={dayjs(today)}
                          format="DD/MM/YYYY"
                          minDate={dayjs(activeObj?.startDate)}
                          maxDate={dayjs(activeObj?.startDate).add(6, "month")}
                          slotProps={{ field: { readOnly: true } }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <AautiTextInput
                  text={AautiStrings?.BatchTitleString}
                  onChange={(e) => {
                    onChangeFields(e.target.value, "batchName");
                  }}
                  important
                  maxLength={50}
                  value={activeObj?.batchName}
                  style={{ width: "100%" }}
                />
                <HelperText
                  title={`${
                    activeObj?.batchName?.length || 0
                  }/50 (minimum 5 Characters)`}
                />
              </div>
            </div>
          </div>

          <div className="level-main">
            <AautiText
              title={courseConstant?.selectBatchType}
              //   required={true}
              //   weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div className="flex-container">
              <FormControl sx={{ minWidth: "20%" }} size="medium">
                <InputLabel id="demo-simple-select-label">
                  Batch Type
                </InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  autoWidth
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-label"
                  value={activeBatch}
                  label={AautiStrings?.BatchTypeString}
                  onChange={(event) => {
                    setActiveBatch(event.target.value);
                  }}
                >
                  <MenuItem
                    value={10}
                    disabled={
                      activeBatch?.title !== "groupDaily" &&
                      activeBatch?.title !== "individualDaily"
                    }
                  >
                    Daily
                  </MenuItem>
                  <MenuItem
                    value={20}
                    disabled={
                      activeBatch?.title !== "groupWeekly" ||
                      activeBatch?.title !== "individualWeekly" ||
                      !activeObj?.recurringDays
                    }
                  >
                    Weekly
                  </MenuItem>
                  <MenuItem value={30} disabled={!activeObj?.customDates}>
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
              {returnBatchType()}
            </div>
          </div>

          <div className="flex-container buttons-cont">
            <NavButton
              disabled={addSlotValidations()}
              text={isCreate ? "Create Batch" : AautiStrings?.updateBatchString}
              handleClick={() => {
                // const inTime = moment(activeObj?.startTime);
                // const now = moment();
                // const hasPassed =
                //   inTime.isBefore(now) &&
                //   inTime.isSame(activeObj?.startDate, "day");
                // if (hasPassed && activeBatch !== 30) {
                //   showToast("info", "Please check start date & time");
                // } else {
                //   handleRescheduleBatch();
                // }
                // const currentDate = moment();
                // const start = moment(activeObj?.startDate);
                // const end = moment(activeObj?.endDate);
                // if (currentDate.isBefore(start) || currentDate.isAfter(end)) {
                //   showToast("info", "Please check Start & End Dates");
                // } else {
                handleRescheduleBatch();
                // }
              }}
            />
          </div>
        </div>
      </div>

      <AautiDialog
        overflow="inherit"
        titlePosition="left"
        dialogTitle={AautiStrings?.selectDayAndTimeString}
        open={!isEmpty(dialogOpen)}
        onClose={() => {
          setDialogOpen("");
        }}
      >
        {dialogOpen == "weekly" && (
          <RescheduleWeek {...{ batchObject, updateSlots }} />
        )}
        {dialogOpen == "custom" && (
          <CustomSlotSelection
            customCourseBatch={batchObject}
            closePop={() => {
              setDialogOpen("");
            }}
            {...{
              state,
              handleSelection,
              dateRanges,
              setDateRanges,
              handleSaveCustomSlot,
              setCustomCourseBatch: setBatchObject,
            }}
          />
        )}
      </AautiDialog>
    </div>
  );
};

export default UpdateBatch;
