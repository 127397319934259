
import React from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import AautiText from '../../../globalComponents/AautiText'
import './styles.scss'

function ChatHeader({title,isMobile,onClickBackIcon,containerStyle={}}) {
  return (
    <div className={`header-text-container ${isMobile?'header-border':''}`}
        style={containerStyle}
    >
      {isMobile && <FaArrowLeft
        onClick={onClickBackIcon}
        className={"chat-header-icon"}
        size={18}
      />}
      <AautiText title={title} size={"medium"} />
    </div>
  )
}

export default ChatHeader