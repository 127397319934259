import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseData: {},
  availableBatches: {},
};

const courseAdhoc = createSlice({
  name: "courseAdhoc",
  initialState,
  reducers: {
    setAdhocCourseData(state, action) {
      state.courseData = action.payload;
    },
    setAdhocBatches(state, action) {
      state.availableBatches = action.payload;
    },
  },
});

export const { setAdhocBatches, setAdhocCourseData } = courseAdhoc.actions;

export default courseAdhoc.reducer;
