import getStartIconActive from "./icons/get_start_icon_active.svg";
import userIcon from "./icons/user_icon.svg";
import allCourseIcon from "./icons/all_course_icon.svg";
import teachWithAautiIcon from "./icons/teach_with_aauti_icon.svg";
import institutionsIcon from "./icons/institutions_icon.svg";
import globalIcon from "./icons/global_icon.svg";

export const constants = {
  sideOptions: [
    {
      id: 1,
      label: "Get started",
      activeIcon: getStartIconActive,
      inActiveIcon: getStartIconActive,
      route: "/register/user-type",
      navigate: true,
    },
    {
      id: 2,
      label: "Sign In",
      activeIcon: userIcon,
      inActiveIcon: userIcon,
      route: "/login",
      navigate: true,
    },
    {
      id: 3,
      label: "All Courses",
      activeIcon: allCourseIcon,
      inActiveIcon: allCourseIcon,
      route: "/teach-with-Aauti",
      expandLabel: "allCourse",
      needExpand: true,
      navigate: false,
      children: [
        {
          id: 3.1,
          label: "EXPLORE COURSES",
          expandLabel: "exploreCourses",
          needExpand: true,
          navigate: false,
          children: [
            {
              id: 3.11,
              label: "Trending",
              expandLabel: "trendingCourses",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.12,
                  label: "Trending Courses",
                  needExpand: false,
                  navigate: true,
                  route: "/trending-courses",
                },
              ],
            },
            {
              id: 3.13,
              label: "Popular",
              expandLabel: "PopularCourses",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.14,
                  label: "Courses Certified By Aauti",
                  needExpand: false,
                  navigate: true,
                  route: `/trending-courses`,
                },
              ],
            },
            {
              id: 3.15,
              label: "Popular Searches",
              expandLabel: "popularSearches",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.16,
                  label: "Popularly Searched Courses Throughout Globe",
                  needExpand: false,
                  navigate: true,
                  route: `/trending-courses`,
                },
              ],
            },
            {
              id: 3.17,
              label: "Explore All Courses",
              expandLabel: "SearchCourses",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.18,
                  label: "Search For Courses You Wish For",
                  needExpand: false,
                  navigate: true,
                  route: "/search-result",
                },
              ],
            },
          ],
        },
        {
          id: 3.2,
          label: "CATEGORY BASED",
          expandLabel: "categoryBased",
          needExpand: true,
          navigate: false,
          children: [
            {
              id: 3.21,
              label: "Popular Categories",
              expandLabel: "trendingCategories",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.22,
                  label: "Trending Courses",
                  needExpand: false,
                  navigate: true,
                  route: "/CourseCategories",
                },
              ],
            },
            {
              id: 3.23,
              label: "Categories We Picked For You",
              expandLabel: "CategoriesWePicked",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.24,
                  label: "Courses Certified By Aauti",
                  needExpand: false,
                  navigate: true,
                  route: "/",
                },
              ],
            },
          ],
        },
        {
          id: 3.3,
          label: "TEACHERS",
          expandLabel: "teachers",
          needExpand: true,
          navigate: false,
          children: [
            {
              id: 3.31,
              label: "Trending Teachers",
              expandLabel: "trendingTeachers",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.32,
                  label: "Trending Teachers",
                  needExpand: false,
                  navigate: true,
                  route: "/top-serviceproviders",
                },
              ],
            },
            {
              id: 3.33,
              label: "Top Teachers",
              expandLabel: "topTeachers",
              needExpand: true,
              navigate: false,
              children: [
                {
                  id: 3.34,
                  label: "Courses Certified By Aauti",
                  needExpand: false,
                  navigate: true,
                  route: "/",
                },
              ],
            },
          ],
        },
        {
          id: 3.4,
          label: "RECENT SEARCHES",
          expandLabel: "recentSearches",
          needExpand: true,
          navigate: false,
          children: [
            {
              id: 3.41,
              label: "Events based on your recent search",
              needExpand: false,
              navigate: true,
              route: "/",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      label: "Teach with Aauti",
      activeIcon: teachWithAautiIcon,
      inActiveIcon: teachWithAautiIcon,
      route: "/teach-with-Aauti",
      navigate: true,
    },
    {
      id: 5,
      label: "Institutions",
      activeIcon: institutionsIcon,
      inActiveIcon: institutionsIcon,
      route: "/institutes",
      navigate: true,
    },
    {
      id: 6,
      label: "₹-INR",
      activeIcon: globalIcon,
      inActiveIcon: globalIcon,
      route: "/institutes",
      navigate: true,
    },
  ],
};
