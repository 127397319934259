export const levelStrings = ["Beginner", "Intermediate", "Advanced", "Expert"];
export const AautiContents = [
  {
    title: "Aauti Content",
    rating: "4.7",
    description:
      "Find pre-existing aauti content here for the curse you want to teach. you ca edit and adopt this content for your lesson plan....",
  },
  {
    title: "Third Party Content",
    rating: "3.7",
    description:
      "Find the content given by the third party here for the course you want to teach. you can edit and adopt thos content for our lesson plan....",
  },
  {
    title: "Create Your Own Lesson Plan",
    // rating: "4.7",
    description:
      "Create your own content and lesson plan here for the course you want to teach.",
  },
];
export const topicDurations = [30, 60, 90];
export const newLessonPlan = {
  name: "",
  description: "",
  category: "",
  subCategory: "",
  serviceProviderId: "",
  categoryDescription: null,
  subCategoryDescription: null,
  level: [],
  lessons: [
    {
      lessonNumber: 0,
      topic: [
        {
          topicName: "",
          topicDescription: "",
          contentData: "",
          uploadContent: [],
          uploadDocuments: [],
          videoUrl: "",
          duration: 60,
          subTopics: [],
        },
      ],
    },
  ],
};

export const createLessonPlanConstantants = {
  createLessonPlan: "Create Lesson Plan",
  YoucaneithercreateaLessonPlanorchoosefromexistingoptionswithadditionalwaystopersonalizeyourselection:
    "You can either create a Lesson Plan or choose from existing options, with additional ways to personalize your selection.",
  createYourOwnLessonPlan: "Create Your Own Lesson Plan",
};
