const IS_LOGGED_IN = "Logged In";
const IS_FIRST_LOGIN = "IS_FIRST_LOGIN";
const FCM_TOKEN = "FCM_TOKEN";
const APP_MODE = "APP_MODE";
const IS_SP = "IS_SERVICE_PROVIDER";
const USER_ID = "USER_ID";
const ROLE = "ROLE";
const FIRSTNAME = "FirstName";
const LASTNAME = "LastName";
const EMAIL = "Email";
const RATINGPOPUPCOUNT = "0";
const ImageURL = "ImageURL";
const RoutePath = "RoutePath";
const CurrentRole = "currentRole";
const currentScreen = "HomeScreen";
const siteVisited = "Site Visited";
const InstituteId = "instituteId";
const COUNTRY = "country";
export const StorageConstants = {
  IS_LOGGED_IN,
  IS_FIRST_LOGIN,
  FCM_TOKEN,
  APP_MODE,
  IS_SP,
  USER_ID,
  ROLE,
  FIRSTNAME,
  LASTNAME,
  EMAIL,
  RATINGPOPUPCOUNT,
  ImageURL,
  RoutePath,
  CurrentRole,
  currentScreen,
  siteVisited,
  InstituteId,
  COUNTRY,
};
export const RandomColorsArray = [
  "#8C67AC",
  "#477D95",
  "#B95D06",
  "#158A08",
  "#F69033",
  "#5F7F00",
  "#62033F",
  "#E8125C",
  "#0C73EC",
  "#E13300",
  "#8B1932",
  "#1E3264",
  "#5F7F00",
  "#477D95",
  "#158A08",
  "#B95D06",
  "#8C67AC",
  "#F69033",
  "#4F374F",
  "#B02896",
  "#A56752",
  "#8668A8",
  "#4eb5b7",
  "#a1c9ed",
];
export const RandomColorsArray1 = [
  "#62033F",
  "#E8125C",
  "#0C73EC",
  "#E13300",
  "#8B1932",
  "#1E3264",
];
export const RandomColorsArray2 = [
  "#5F7F00",
  "#477D95",
  "#158A08",
  "#B95D06",
  "#8C67AC",
  "#F69033",
];
export const RandomColorsArray3 = [
  "#4F374F",
  "#B02896",
  "#A56752",
  "#8668A8",
  "#4eb5b7",
  "#a1c9ed",
];
export const RandomColorsArray4 = [
  "#8C67AC",
  "#477D95",
  "#B95D06",
  "#158A08",
  "#F69033",
  "#5F7F00",
];
export function randomColor() {
  return RandomColorsArray[
    Math.floor(Math.random() * RandomColorsArray.length)
  ];
}
export function isPasswordValid(text) {
  let reg =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,14}$/;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
}

export const isEnterPressed = (key) => {
  return key === "Enter";
};

export const userRoles = {
  serviceProvider: 'serviceprovider',
  endUser: 'endUser',
  instituteAdmin: 'instituteAdmin',
  superAdmin: 'superadmin',
}

export const TwoFactorAuthType = {
  firstTime: 'FIRST_TIME',
  verify: 'VERIFY',
}

