const STRINGS = {
  cancelUpto:
    "Cancel up to within 24 hours before the start time for a full refund.",
  maxPriceConstant: 6,
  passwordConditions:
    "(mix of letters, numbers, and symbols in 8-14 characters)",
  addEmailInProfile:'Please add email in your profile.',
  enter2FaOtp:"Enter Two Factor Auth OTP",
  skip:"Skip",
  verify:"Verify",
  enable2FA:"Enable Two Factor Authentication"
};

export default STRINGS;
