import React from "react";
import NotificationSettingsContainer from "./Container";
import AautiText from "../globalComponents/AautiText";
import { Button, Switch } from "@mui/material";
import "./Styles.scss";
import JoinButton from "../globalComponents/JoinButton";
import AccountTabs from "../globalComponents/AccountTabs";

function NotificationSettings() {
  const { onPressSubmit, onChangeNotificationSettings, notificationSettings } =
    NotificationSettingsContainer();

  const RenderCard = ({ item, index }) => {
    return (
      <div className="card-container" key={index}>
        <AautiText title={item?.name} />
        <Switch
          checked={item?.enabled}
          onChange={() => onChangeNotificationSettings(item, index)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
    );
  };

  return (
    <div className="main-container-at">
      <AccountTabs
        {...{
          tabObj: {
            label: "Notification Settings",
            navigation: "/notification-settings",
            active: true,
          },
        }}
      />
      <div className="ns-main-container">
        <div className="ns-sub-container">
          {notificationSettings?.map((item, index) => {
            if (item?.show) {
              return <RenderCard item={item} index={index} />;
            }
          })}
        </div>
        {/* <Button
        style={{ backgroundColor: "#3166ba" }}
        onClick={() => onPressSubmit()}
      >
        <AautiText textStyle={{ color: "white" }} title={"Submit"} />
      </Button> */}
        <JoinButton onClick={() => onPressSubmit()} title={"Submit"} />
      </div>
    </div>
  );
}

export default NotificationSettings;
