import { useEffect, useRef, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import { MdEmojiEmotions } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { BsFileEarmarkText } from "react-icons/bs";
import { IoMic, IoMicOff } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { ReactMic } from "react-mic";

import { showToast } from "../../../globalComponents/Toast.js";
import { Box, Tooltip } from "@mui/material";

import {
  Audiotrack,
  ExpandCircleDownOutlined,
  HighlightOff,
  Movie,
  SendRounded,
} from "@mui/icons-material";

import AbstractReply from "../../components/messages/abstract_reply";
import { prettifyFileSize } from "../../../../utils/Helpers";
import AautiText from "../../../globalComponents/AautiText";
import {BootstrapDialog} from "../../components/styled_components.js";
import FilePreview from "../../components/file_preview";
import DialogCloseButton from "../../components/chat_dialogs/DialogCloseButton";
import {
  MAX_COMPOSER_HEIGHT,
  messageInputPlaceholder,
  SupportedMimeTypes,
} from "../../constants.js";

import "./styles.scss";


export default function InputToolBar({
  id = "input-tool-bar",
  // composerHeight,
  files,
  updateFiles,
  deleteFile,
  onSend,
  editingMsg,
  reply,
  text,
  onDismissReply,
  onTextChanged,
  textInputProps,
  isSending,
  cancelEditing,
  emojiPickerOpen,
  toggleEmojiPicker,
  onClickEmoji,
  renderDeleteForMeView = undefined,
}) {
  const [isRecording, setIsRecording] = useState(false);
  // const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(undefined);
  const emojiPickerRef = useRef(null);

  const isInvalidMessage = /^\s*$/.test(text ?? "") && files?.length === 0;
  const attachmentInputId = `${id}-attachment-input`;

  const onPressAttachment = () => {
    if (editingMsg?._id) {
      return showToast("info", `Adding files isn't allowed in editing`);
    }
    document.getElementById(attachmentInputId).click();
  };

  const onPickFiles = (e) => {
    let files = e.target.files;
    updateFiles(files);
  };

  const onStop = async (recordedBlob) => {
    const name = recordedBlob.blobURL.split("/").pop();
    const filename = name + "." + "webm";
    try {
      const binaryData = await recordedBlob.blob.arrayBuffer();
      const file = new File([binaryData], filename, {
        type: recordedBlob.blob.type,
      });
      updateFiles([file]);
    } catch (error) {
      showToast("error", "Error while picking file. Please try again");
    }
  };

  const handleOutsideClick = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      toggleEmojiPicker(false);
    }
  };

  const closePreviewFile = () => setPreviewFile(undefined);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const renderFileOnMimeType = (file) => {
    if (!file) return;
    const mimeType = file?.type;
    if (mimeType.includes("image"))
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          loading="lazy"
          key={file?._id ?? file.name}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      );

    if (mimeType.includes("video"))
      return <Movie className={"document_icon_styles"} />;

    if (mimeType.includes("audio")) {
      return <Audiotrack className={"document_icon_styles"} />;
    }
    return <BsFileEarmarkText className={"document_icon_styles"} />;
  };

  const renderFile = (file, index) => {
    return (
      <li
        key={`${file.name}-${index}`}
        className="file_wrapper"
        style={{ background: file.type.includes("image") ? file.uri : "none" }}
      >
        <DialogCloseButton
          id={`${id}-file-preview-dialog`}
          onClick={() => deleteFile(index)}
          closeIcon={()=><HighlightOff fontSize="inherit"/>}
          size="small"
          variant="close"
        />
        <div
          className="file_preview_container"
          onClick={() => setPreviewFile(file)}
        >
          {renderFileOnMimeType(file)}
        </div>
        <div className="file_texts_wrapper">
          <Tooltip title={file.name}>
            <span
              style={{
                fontSize: 9,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {file.name}
            </span>
          </Tooltip>
          <span
            style={{
              fontSize: 8,
              color: "#fff",
            }}
          >
            {prettifyFileSize(file.size)}
          </span>
        </div>
      </li>
    );
  };

  const micProps = {
    size: 20,
    color: "#3083EF",
    className:'cursor_pointer',
    style: { marginTop: 2 },
  };

  const colorStyle = (status) => (status ? "#5C5B57" : "#3083EF");

  const toggleRecordingStatus = () => {
    setIsRecording((prev) => !prev);
  };

  const onInputKeyDown = (event) => {
    if(isInvalidMessage) return;
    if (event.key === "Enter") {
      event.preventDefault();
      if(event.shiftKey){
        event.target.value = event.target.value+'\n'
        return onTextChanged(event)
      }
      return onSend(event);
    }
  };

  if (!!renderDeleteForMeView) {
    return <div className="toolbar_container">{renderDeleteForMeView()}</div>;
  }

  return (
    <div className={"toolbar_container"} ref={emojiPickerRef}>
      {isSending && !!files?.length && (
        <Box>
          <AautiText
            size={"medium"}
            weight={"medium"}
            textStyle={{
              color: "#000000",
            }}
            title={"Uploading..."}
          />
        </Box>
      )}
      {emojiPickerOpen && (
        <EmojiPicker
          height={"350px"}
          width={"100%"}
          onEmojiClick={onClickEmoji}
        />
      )}
      {!!reply?._id && (
        <AbstractReply
          key={`reply-${reply?._id}`}
          {...{ message: reply, onDismissReply }}
        />
      )}
      {!!files.length && (
        <ul className="toolbar_files_container">{files.map(renderFile)}</ul>
      )}
      <div className="toolbar_footer">
        <div className="toolbar_footer_left">
          <div className="toolbar_align_items_end_container">
          <button
            id={"toolbar_footer_rounded_button"}
            className="toolbar_footer_rounded_button"
            onClick={toggleRecordingStatus}
          >
            {isRecording ? <IoMicOff {...micProps} /> : <IoMic {...micProps} />}
          </button>
          </div>

          <div
            style={{
              display: isRecording ? "block" : "none",
              width: "100%",
              marginLeft: "20px",
            }}
          >
            <ReactMic
              record={isRecording}
              className="sound-wave"
              onStop={onStop}
              strokeColor="#000000"
              backgroundColor="transparent"
            />
          </div>
          <textarea
            onText
            rows={1}
            className="toolbar_footer_input"
            style={{
              display: !isRecording ? "block" : "none",
              height:'auto',
              maxHeight:MAX_COMPOSER_HEIGHT,
            }}
            value={text}
            autoFocus={true}
            placeholder={messageInputPlaceholder}
            onChange={onTextChanged}
            onKeyDown={onInputKeyDown}
            {...textInputProps}
          />
          <div className="toolbar_align_items_end_container">
          {!isRecording && (
            <button
              id={`${id}-emoji-open-close-btn`}
              onClick={()=>toggleEmojiPicker(!emojiPickerOpen)}
              className="toolbar-emoji-button"
            >
              {emojiPickerOpen ? (
                <ExpandCircleDownOutlined fontSize="medium" />
              ) : (
                <MdEmojiEmotions
                  size={23}
                  color={"#066cb5"}
                />
              )}
            </button>
          )}
          <input
            type="file"
            id={attachmentInputId}
            style={{ display: "none" }}
            onChange={onPickFiles}
            accept={SupportedMimeTypes}
            multiple
          />
          {!isRecording && (
            <button
              onClick={onSend}
              className="toolbar_footer_rounded_button"
              style={{ background: "transparent",padding:"4px 0px" }}
            >
              <SendRounded
                sx={{color:colorStyle(isInvalidMessage)}}
                fontSize="medium"
                className="cursor_pointer"
              />
            </button>
          )}
          </div>
        </div>

        <div className="toolbar_align_items_end_container"
          style={{paddingBottom:12}}
        >
        {!!editingMsg?._id ? (
          <RxCross2 size={24} onClick={cancelEditing} className="cursor_pointer" />
        ) : (
          <FiPlus
            size={24}
            color={colorStyle(!!editingMsg?._id)}
            onClick={onPressAttachment}
            className="cursor_pointer"
          />
        )}
        </div>
      </div>
      <BootstrapDialog
        aria-labelledby={`${id}-file-preview-dialog`}
        open={!!previewFile?.name}
        onClose={closePreviewFile}
      >
        <DialogCloseButton
          id={`${id}-file-preview-dialog`}
          onClick={closePreviewFile}
        />
        {!!previewFile && (
          <FilePreview
            key={`preview-file${previewFile.name}`}
            file={previewFile}
          />
        )}
      </BootstrapDialog>
    </div>
  );
}
