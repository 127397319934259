import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";

import STRINGS from "../globalComponents/Strings";
import { showToast } from "../globalComponents/Toast";
import {
  selectLoginUserDetails,
  toggleTwoFactorAuth,
} from "../../redux/reducer/appReducer";
import { checkNull } from "../../utils/Helpers";
import { SettingsLabels } from "./constants";

import "./styles.scss";
import AccountTabs from "../globalComponents/AccountTabs";
import AppContainer from "../../layouts/AppContainer";

function UserSecurity() {
  const userDetails = useSelector(selectLoginUserDetails);
  const initialState = [
    {
      label: SettingsLabels.twoFactorAuth,
      value: userDetails?.twoFactorAuthEnabled ?? false,
    },
  ];
  const [securityOptions, setSecurityOptions] = useState(initialState);

  const dispatch = useDispatch();

  const onChangeTFAStatus = (status) => {
    setSecurityOptions((prev) => {
      let newSettings = [...prev];
      prev[0].value = status;
      return newSettings;
    });
  };

  useEffect(() => {
    onChangeTFAStatus(userDetails?.twoFactorAuthEnabled ?? false);
  }, [userDetails?.twoFactorAuthEnabled]);

  const onToggleTwoFactorAuth = (value) => {
    if (checkNull(userDetails?.email)) {
      return showToast("info", STRINGS.addEmailInProfile);
    }
    dispatch(
      toggleTwoFactorAuth({
        enabled: value,
        email: userDetails.email,
      })
    );
  };

  const onToggleSwitch = (switchIndex) => {
    switch (securityOptions[switchIndex].label) {
      case SettingsLabels.darkAppearance:
        break;
      case SettingsLabels.twoFactorAuth:
        onToggleTwoFactorAuth(!securityOptions[switchIndex].value);
        break;
    }
  };

  const renderOption = ({ label, value }, index) => {
    return (
      <div className="security-options-item">
        <div className="security-options-item-wrapper">
          <p>{label}</p>
          <Switch
            {...{
              checked: value,
              onChange: (e) => onToggleSwitch(index),
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: "Security",
            navigation: "/security",
            active: true,
          },
        }}
      />
      <ul className="security-options-list">
        {securityOptions.map(renderOption)}
      </ul>
    </AppContainer>
  );
}

export default UserSecurity;
