import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../globalComponents/Toast";
import {
  isEmailRegisteredService,
  isMobileRegisteredService,
  loginWithUserNameAndPasswordService,
  sendOtpToUserMailService,
  sendOtpToUserMobileService,
  validateOTPSentToEmailService,
  validateOTPSentToMobileService,
} from "./authenticationHelperServices";
import { isEmpty, replace } from "lodash";
import {
  saveMultiCarts,
  wishlistWhilSign,
  wishSp,
} from "../../../instituteSetup/Services";
import { appStorageServices, StorageConstants } from "../../../../storage";
import {
  loginUser,
  setLoggedInUserId,
  setSpFollowId,
  setSpWishlistItem,
  setUserdetails,
} from "../../../../redux/reducer/appReducer";
import { useLocation, useNavigate } from "react-router-dom";
import constants from "./authenticationHelperConstants.json";
import { postFCMDeviceTokenObject } from "../authLayout/authForms/authSubmitButton/authSubmitButtonHooks";

// export function checkInputType(input) {

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phoneRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
//     if (emailRegex.test(input)) {
//         return "email";
//     } else if (phoneRegex.test(input)) {
//         return "mobile";
//     }else if(){
//         return "userName"
//     } else {
//         return "Username / Email / Mobile";
//     }

// }

export function checkInputType(input) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
  const usernameRegex = /^[a-zA-Z0-9]+$/;

  if (emailRegex.test(input)) {
    return "email";
  } else if (phoneRegex.test(input)) {
    return "mobile";
  } else if (usernameRegex.test(input)) {
    return "User Name";
  } else {
    return "Username / Email / Mobile";
  }
}

export const checkIsUserRegistered = async (userName, country) => {
  const isUserNameMobile = checkInputType(userName) === "mobile";
  const isUserNameEmail = checkInputType(userName) === "email";
  const userNameRegex = /^[a-zA-Z0-9]+$/;
  const isUserName = userNameRegex.test(userName);
  if (isUserNameMobile) {
    const mobile = userName;
    try {
      const response = await isMobileRegisteredService({
        mobile: country.phoneCode.slice(1) + mobile,
        countryCode: country?.countryCode,
      });
      if (response && response.status === "Success" && response.result) {
        return {
          userInfo: response?.result,
          isUserRegistered: true,
          otpSentTo: "mobile",
          message: response?.message,
        };
      } else {
        return {
          isUserRegistered: false,
          otpSentTo: "mobile",
          message: response?.message,
        };
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  } else if (isUserNameEmail || isUserName) {
    const email = userName;
    try {
      const response = await isEmailRegisteredService(email);
      if (response && response.status === "Success" && response.result) {
        return {
          userInfo: response?.result,
          isUserRegistered: true,
          otpSentTo: "email",
          message: response?.message,
        };
      } else {
        return {
          isUserRegistered: false,
          otpSentTo: "email",
          message: response?.message,
        };
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  }
};

export const sendOTPToUser = async (userName, defaultCountry) => {
  const isUserNameMobile = checkInputType(userName) === "mobile";
  const isUserNameEmail = checkInputType(userName) === "email";
  if (isUserNameMobile) {
    const response = await sendOtpToUserMobileService(
      `${encodeURIComponent(defaultCountry?.phoneCode)}${userName}`
    );
    if (
      response &&
      (response.status === "Success" || response?.status === "success")
    ) {
      return true;
    } else {
      showToast("error", response?.message);
      return false;
    }
  } else if (isUserNameEmail) {
    const email = userName;
    try {
      const response = await sendOtpToUserMailService(email);
      if (
        response &&
        (response.status === "Success" || response?.status === "success")
      ) {
        return true;
      } else {
        showToast("error", response?.message);
        return false;
      }
    } catch (error) {
      showToast("error", "Error Sending OTP");
    }
  }
};

export const validateOTPSentToEmail = async (email, otp) => {
  if (email && otp) {
    try {
      const response = await validateOTPSentToEmailService(email, otp);
      if (response && response.status === "Success") {
        // showToast("success", response?.message);
        return true;
      } else {
        showToast("error", response?.message);
        return false;
      }
    } catch (error) {
      showToast("error", "Error Validating OTP");
    }
  } else {
    showToast("error", "Please Provide Valid OTP");
  }
};

export const validateOTPSentToMobile = async (mobile, otp) => {
  if (mobile && otp) {
    try {
      const response = await validateOTPSentToMobileService(mobile, otp);
      if (response && response.status === "Success") {
        // showToast("success", response?.message);
        return true;
      } else {
        showToast("error", response?.message);
        return false;
      }
    } catch (error) {
      showToast("error", "Error Validating OTP");
    }
  } else {
    showToast("error", "Please Provide Valid OTP");
  }
};

export const loginStore = ({ props, dispatch, isSignup }) => {
  appStorageServices.storeData(StorageConstants.USER_ID, props?._id);
  appStorageServices.storeData(StorageConstants.siteVisited, true);
  if (props?.role?.includes("serviceprovider")) {
    appStorageServices.storeData(StorageConstants.ROLE, "serviceprovider");
  } else if (props?.role?.includes("instituteAdmin")) {
    appStorageServices.storeData(
      StorageConstants.InstituteId,
      props?.instituteId
    );
    appStorageServices.storeData(StorageConstants.ROLE, "instituteAdmin");
  } else {
    appStorageServices.storeData(StorageConstants.ROLE, "endUser");
  }
  appStorageServices.storeData(
    StorageConstants.FIRSTNAME,
    `${props?.firstName}`
  );
  appStorageServices.storeData(StorageConstants.LASTNAME, props?.lastName);
  appStorageServices.storeData(StorageConstants.EMAIL, props?.email);
  appStorageServices.storeData(StorageConstants.COUNTRY, props?.country ?? "");
  !isSignup &&
    appStorageServices.storeData(StorageConstants.IS_LOGGED_IN, true);
  dispatch(setLoggedInUserId(props?._id));
  const admin = props?.role[0] === "serviceprovider" ? true : false;
  appStorageServices.storeData(StorageConstants.IS_SP, admin);
  !isSignup && dispatch(loginUser(true, admin));
  dispatch(setUserdetails(props));
  return null;
};
export const initializeLoginSequence = ({
  props,
  dispatch,
  pathname,
  cartsDataBeforeLogin,
  navigate,
  spFollowId,
  spWishlistItem,
  isSignup,
  navigatePath,
  navigationRoutes,
  userData,
  userRole,
}) => {
  const prevPath = localStorage.getItem("prevPath") || "";

  const saveSpFollow = () => {
    if (spFollowId)
      wishSp(
        { ...spFollowId, description: "wishList", endUserId: props?._id },
        (res) => {
          showToast("success", res?.message);
          dispatch(setSpFollowId(null));

          navigate(prevPath, { replace: true });

          loginStore({ props, dispatch });
          window.location.reload();
        },
        (err) => {}
      );
  };

  const saveWishlist = () => {
    wishlistWhilSign(
      {
        ...spWishlistItem,
        parentId: props?.parentId ?? props?._id,
        endUserId: props?._id,
      },
      (res) => {
        showToast("success", res?.message);
        dispatch(setSpWishlistItem(null));
        navigate(prevPath, { replace: true });
        loginStore({ props, dispatch });
        window.location.reload();
      },
      (err) => {}
    );
  };
  if (isSignup) {
    // dispatch(loginUser(true));
    loginStore({ props, dispatch, isSignup });

    // navigate(navigatePath, { replace: true });
    // navigate(navigationRoutes);
    userRole === "endUser"
      ? navigate("/register/select-intrests", {
          state: { signUpData: userData },
          replace: true,
        })
      : navigate("/register/select-profession", {
          state: { signUpData: userData },
          replace: true,
        });
    // window.location.reload();
  } else if (spFollowId) {
    saveSpFollow();
  } else if (spWishlistItem) {
    saveWishlist();
  } else if (
    (cartsDataBeforeLogin && cartsDataBeforeLogin?.length > 0) ||
    prevPath == "/cart"
  ) {
    const id = props?._id;
    const cartData = cartsDataBeforeLogin.map((each) => {
      if (!isEmpty(each?.courseId)) {
        return {
          batchId: each?.batchId,
          courseId: each?.courseId,
          parentId: id,
        };
      } else {
        return {
          parentId: id,
          gigId: each?.gigId,
        };
      }
    });
    const data = {
      userId: id,
      cartData,
    };

    saveMultiCarts(
      data,
      (res) => {
        if (res?.status === "Success") {
          localStorage?.removeItem("CartDataBeforeLogin");
          if (prevPath) {
            navigate(prevPath, { replace: true });
          } else {
            navigate("/", { replace: true });
          }
          loginStore({ props, dispatch });
          localStorage.removeItem("prevPath");
          window.location.reload();
        }
      },
      (err) => {
        console.log(err, "---getting errror while saving carts to user");
      }
    );
  } else if (prevPath) {
    loginStore({ props, dispatch });
    navigate(prevPath, { replace: true });
    localStorage.removeItem("prevPath");
    window.location.reload();
  } else {
    dispatch(loginUser(true));
    loginStore({ props, dispatch });
    navigate("/", { replace: true });
    window.location.reload();
  }
  return null;
};

export const loginUsingUserNameAndPassword = async ({
  props: userCred,
  dispatch,
  pathname,
  cartsDataBeforeLogin,
  navigate,
  spFollowId,
  spWishlistItem,
}) => {
  const userData = {
    username: userCred?.userName,
    password: userCred?.password,
  };
  const loginApiResponse = await loginWithUserNameAndPasswordService({
    userData,
  });
  if (loginApiResponse?.result) {
    if (
      loginApiResponse?.result?.role?.includes("serviceprovider") ||
      loginApiResponse?.result?.role?.includes("enduser") ||
      loginApiResponse?.result?.role?.includes("serviceProvider") ||
      loginApiResponse?.result?.role?.includes("endUser") ||
      loginApiResponse?.result?.role?.includes("instituteAdmin")
    ) {
      const { email } = loginApiResponse.result;
      showToast("success", loginApiResponse.message);
      if (email) postFCMDeviceTokenObject(email);
      initializeLoginSequence({
        props: loginApiResponse?.result,
        dispatch,
        pathname,
        cartsDataBeforeLogin,
        navigate,
        spFollowId,
        spWishlistItem,
      });
    } else {
      showToast("error", "User not allowed");
    }
  } else {
    showToast("error", loginApiResponse?.message || "Login failed");
  }
};
