import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

function getMyCoursesByUserid(userId, onSuccess, onFailure, noOfDays = 100) {
  ApiRequest({
    url:
      ApiPaths.GET_MY_COURSES_BY_USERID +
      "?noOfDays=" +
      noOfDays +
      "&offset=" +
      0 +
      "&count=100&userId=" +
      userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getMyGigsForServiceProvider(id, data, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/getGigListByServiceProviderId?serviceProviderId=" + id,
    method: ApiMethods.GET,
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllSubscriptionsByUserID(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_SUBSCRIPTIONS_BY_USER_ID,
    method: ApiMethods.GET,
    params:data
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getMyGigsForEndUser(loggedInUserId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_MYGIGSBYUSERID_ENDUSER + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const AuthService = {
  getMyCoursesByUserid,
  getMyGigsForServiceProvider,
  getAllSubscriptionsByUserID,
  getMyGigsForEndUser,
};
