import React from "react";
import AautiText from "../../../globalComponents/AautiText";
import AautiAvatar from "../AautiAvatar";
import HighlightText from "../highlighted_text";
import "./styles.scss";

export default function ChatContact({
  id,
  onPress,
  bottomText,
  alt,
  src,
  searchText,
}) {
  return (
    <li className="chat-list-item-container" onClick={onPress}>
      <div className={"item-left-wrapper"}>
        <AautiAvatar {...{ height: 36, width: 36, alt, src }} />
      </div>
      <div className={"item-right-wrapper"}>
        <div className="item-content-wrapper">
          <div className={"texts-wrapper"}>
            <HighlightText
              className="item-text room-name"
              textToHighlight={alt}
              searchWords={[searchText]}
            />
          </div>
          <div className={"texts-wrapper"}>
            <span className="item-text message-text">{bottomText}</span>
          </div>
        </div>
        <hr className="chats-list-border" />
      </div>
    </li>
  );
}
