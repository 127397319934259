import axios from "axios";
import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

export function EnUserFeedback(endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "feedback/getFeedbacksByEndUserId",
    method: "GET",
    params: { endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function ServiceProviderFeedback(
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "feedback/getFeedbacksByServiceProviderId",
    method: "GET",
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
