import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const CloseButton = ({
  title = "Close",
  style = {},
  onClick = () => {},
  className = "",
}) => {
  return (
    <Tooltip title={title}>
      <IconButton
        className={className}
        sx={{
          ...style,
        }}
        onClick={onClick}
      >
        <IoIosCloseCircleOutline />
      </IconButton>
    </Tooltip>
  );
};

export default CloseButton;
