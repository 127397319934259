import { ApiRequest } from "../../../../service";
import { ApiMethods, ApiPaths } from "../../../../service/api-constants";
import { showToast } from "../../../globalComponents/Toast";

export const isEmailRegisteredService = async (email) => {
  try {
    const { data } = await ApiRequest({
      url: `${ApiPaths.GETUSERBYEMAIL}${email}`,
      method: ApiMethods.GET,
    });
    return data;
  } catch (error) {
    showToast("error", "Error fetching user details");
  }
};

export const isMobileRegisteredService = async (apidata) => {
  try {
    const { data } = await ApiRequest({
      url: "user/getUserByMobile",
      method: ApiMethods.GET,
      params: apidata,
    });
    return data;
  } catch (error) {
    showToast("error", "Error fetching user details");
  }
};

export const sendOtpToUserMailService = async (email) => {
  try {
    const { data } = await ApiRequest({
      url: `${ApiPaths.SEND_MAIL}${email}`,
      method: ApiMethods.GET,
    });
    return data;
  } catch (error) {
    showToast("error", "Error Sending OTP");
  }
};

export const sendOtpToUserMobileService = async (mobile) => {
  try {
    const { data } = await ApiRequest({
      url: `sms/sendSms?toPhoneNumber=${mobile}`,
      method: ApiMethods.GET,
    });
    return data;
  } catch (error) {
    showToast("error", "Error Sending OTP");
  }
};

export const validateOTPSentToEmailService = async (email, otp) => {
  try {
    const { data } = await ApiRequest({
      url: ApiPaths.VERIFY_OTP,
      method: ApiMethods.POST,
      params: { email, otp },
    });
    return data;
  } catch (error) {
    showToast("error", "Error Validating OTP");
  }
};

export const validateOTPSentToMobileService = async (mobile, otp) => {
  try {
    const { data } = await ApiRequest({
      url: `sms/verifySms?toPhoneNumber=${mobile}&otp=${otp}`,
      method: ApiMethods.POST,
      // params: { toPhoneNumber: mobile, otp },
    });
    return data;
  } catch (error) {
    showToast("error", "Error Validating OTP");
  }
};

export const loginWithUserNameAndPasswordService = async ({ userData }) => {
  try {
    const { data } = await ApiRequest({
      url: ApiPaths.LOGIN,
      method: ApiMethods.POST,
      data: userData,
    });
    return data;
  } catch (error) {
    showToast("error", "Error Logging In");
  }
};
