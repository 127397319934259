import axios from "axios";
import { ApiRequest } from "../../../service";
import { ApiMethods, baseUrl } from "../../../service/api-constants";
import { method } from "lodash";

const generateImagesUrl =
  "https://aautimpapi.azurewebsites.net/ai/generate-image";

export function generateImagesUsingAi(data, onSuccess, onFailure) {
  const Payload = {
    ...data,
    responseFormat: "b64_json",
    // project: "AAUTI_MRKTPLACE",
  };
  ApiRequest({
    url: generateImagesUrl,
    method: ApiMethods.POST,
    data: Payload,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function getAllCategories(onSuccess, onFailure) {
  ApiRequest({
    url: "category/getAllCategories",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getAautiClassPricing(
  serviceProviderId,
  category,
  subCategory,
  country,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "adhocPricing/getAdhocAautiSuggestedPrice",
    method: "GET",
    params: { serviceProviderId, category, subCategory, country },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getAllLanguages(onSuccess, onFailure) {
  ApiRequest({
    url: "languages/getAllLanguages",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

// export function getSpConflicts(data, onSuccess, onFailure) {
//   ApiRequest({
//     url: "/session/getConflictSPTimeSlots",
//     method: ApiMethods.POST,
//     data,
//   }).then(
//     (response) => {
//       onSuccess(response?.data);
//     },
//     (error) => {
//       onFailure();
//     }
//   );
// }

export function getUserDetails(id, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getUserById",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getUserExistingLessonPlans(
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "myLessonPlan/getLessonPlanByServiceProviderId",
    method: "GET",
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/saveCourse",
    method: "POST",
    data: data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveDraftCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: "draftCourse/saveDraftCourse",
    method: "POST",
    data: data,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function updateCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/updateCourse",
    method: "PUT",
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function updateDraftCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: "draftCourse/updateDraftCourse",
    method: "PUT",
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getCourseByCourseId(id, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCourse",
    method: "GET",
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getAllLessonsBySpId(serviceProviderId, onSuccess, onFailure) {
  ApiRequest({
    url: "myLessonPlan/getLessonPlanByServiceProviderId",
    method: "GET",
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function saveGig(data, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/saveGig",
    method: "POST",
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function saveDraftGig(data, onSuccess, onFailure) {
  ApiRequest({
    url: "draftGig/saveDraftGig",
    method: "POST",
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

export function updateGig(data, onSuccess, onFailure) {
  ApiRequest({
    url: "gig/updateGig",
    method: "PUT",
    data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

const ChatGpt = baseUrl + "ai/prompt";
export async function ChatGptFunc(prompt, onSuccess, onFailure) {
  axios
    .post(ChatGpt, {
      prompt,
      headers: {
        "Content-Type": "application/json",
      },
      model: "gpt-4",
    })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch((err) => {
      onFailure(err);
      console.log("err", err);
    });
}

export function getMembersDiscounts(onSuccess, onFailure) {
  ApiRequest({
    url: "app/getFamilyMemberDiscount",
    method: ApiMethods.GET,
  })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch((err) => {
      onFailure(err);
    });
}

//institute
export function getBranchesByInstituteId(data, onSuccess, onFailure) {
  ApiRequest({
    url: "branch/getAllBranchesByinstituteId?",
    method: ApiMethods.GET,
    params: data,
  })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch((err) => {
      onFailure(err);
    });
}

export function deleteBatch(params, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/deleteBatch",
    method: ApiMethods.DELETE,
    params: params,
  })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch((err) => {
      onFailure(err);
    });
}

//institute
export function saveCoursInstitute(data, onSuccess, onFailure) {
  ApiRequest({
    url: "mycourse/saveInstituteCourse",
    method: ApiMethods.POST,
    data: data,
  })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch((err) => {
      onFailure(err);
    });
}
