import React, { useEffect, useRef, useState } from "react";
import {
  AccordionSummary,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AautiText from "../../globalComponents/AautiText";
import "./Style.scss";
import { AuthService } from "./Services";
import FilterIcon from "../../../assets/images/FilterIcon.png";
import { showToast } from "../../globalComponents/Toast";
import _, { isEmpty } from "lodash";
import { PackageStrings } from "../Constants";
import { useSelector } from "react-redux";
import cancel from "./../../../assets/images/cancel.svg";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import AautiSearch from "../../globalComponents/AautiSearch";
import CommingSoon from "../../globalComponents/CommingSoon";
import { globalColors } from "../../../themes/GlobalColors";
import { IoIosCheckmark } from "react-icons/io";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&& .css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root": {
    padding: "0px !important",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CourseFilters = ({
  onChangeSearchValue,
  searchValue,
  handleClickApply,
  onCloseSearch,
  screenName = "",
  clearFilters,
  from,
  FilterIconShow = true,
  actFilters = {},
}) => {
  const [stateFilters, setFilterStates] = useState({});
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const filterRef = useRef();
  const [state, setState] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const filteredData = () => {
    clearAllFilters();
  };
  // console.log(stateFilters, "filtersData");
  const combinedArray = Object.keys(stateFilters)?.reduce((acc, key) => {
    return [...acc, ...stateFilters[key]];
  }, []);

  const clearAllFilters = () => {
    const clearedFilters = Object.keys(stateFilters).reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {});

    setFilterStates(clearedFilters);
    handleClickApply(clearedFilters);
  };
  // screenNames:-
  // "blockbusterDeals"
  // "startingInNext8Hours"
  // "gigs"
  // "upcomingCourses"
  // "packages"
  // "searchResults"
  // "serviceProviderCourses"
  // "sameCoursesByOtherServiceProviders"
  // "courseRelatedToCategory"
  // "gigsOffered"
  // "gigsPurchased"
  // "orders"
  // "myCourses"
  // "myLearnings"
  // "moreResultsInCourses"
  // "topRatedInCourses"
  // "moreResultsInGigs"
  // "topRatedInGigs"
  // "moreResultsInAdhoc"
  // "topRatedInAdhoc"
  // "users"
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    clearAllFilters();
  }, [clearFilters]);
  useEffect(() => {
    filterAPI();
  }, [screenName]);
  const screenNameFilter = screenName ? screenName : "searchResults";
  const filterAPI = () => {
    setBackdropOpen(true);
    AuthService.getFiltersByType(screenNameFilter, onSuccess, onFailure);
  };
  const [backdropOpen, setBackdropOpen] = useState(false);

  const pushOptionsToFilteres = (key, catValue) => {
    let newKeysObjects = {};
    Object.keys(stateFilters).forEach((each) => {
      if (key === each) {
        const isIncludedInList = stateFilters[each]?.some(
          (each) => each === catValue || each.name === catValue?.name
        );

        if (isIncludedInList) {
          newKeysObjects[each] = stateFilters[each].filter((item) => {
            return item?.name !== catValue?.name;
          });
        } else {
          newKeysObjects[each] = [...stateFilters[each], catValue];
        }
      } else {
        newKeysObjects[each] = stateFilters[each];
      }
    });
    setFilterStates(newKeysObjects);
    handleClickApply(newKeysObjects);
    const keyNotExists = !stateFilters[key]?.includes(catValue);
    setFilterData((prevFilterData) => {
      const filteredData = prevFilterData.filter(
        (item) => !(item.key === key && item.catValue === catValue)
      );
      if (keyNotExists) {
        return [...filteredData, { key, catValue }];
      }
      return filteredData;
    });
  };
  const onSuccess = (response) => {
    setBackdropOpen(false);
    setState(response?.result?.filters);
    let myobj = {};

    !isEmpty(response?.result?.filters) &&
      Object?.keys(response?.result?.filters)?.map(
        (each, index) => (myobj[each] = [])
      );

    if (!isEmpty(actFilters)) {
      let filterData = { ...actFilters };
      delete filterData.activeTab;
      setFilterStates(filterData);
    } else {
      setFilterStates(!isEmpty(myobj) ? myobj : {});
    }
  };
  const onFailure = () => {
    setBackdropOpen(false);
    // showToast(PackageStrings?.error, PackageStrings?.apiAlert);
  };
  const hasSelectedFilters = (filters) => {
    return Object.values(filters).some((filterArray) => filterArray.length > 0);
  };

  function formatKeys(text) {
    if (text === "recurrance") {
      return <AautiText title={"Recurrence"} size={"semi"} />;
    }
    const spacedText = text.replace(/([A-Z])/g, " $1").trim();
    let returnText = spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
    return <AautiText title={returnText} size={"semi"} />;
  }

  const onDeleteSelectedOne = (item) => {
    const newFilters = { ...stateFilters };

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key].includes(item)) {
        newFilters[key] = newFilters[key].filter((value) => value !== item);
      }
    });

    setFilterStates(newFilters);
    handleClickApply(newFilters);
  };

  const DisplayFilterData = () => {
    return (
      <div className="applied-filters-div">
        {combinedArray?.length > 0
          ? combinedArray?.map((category, index) => {
              return (
                <div key={index} className="applied-filters-button">
                  <AautiText
                    size={"semi"}
                    title={CapitalizeFirstLetter(category?.name) || ""}
                  />
                  <img
                    src={cancel}
                    alt={"cancel"}
                    className="closeIcon"
                    onClick={() => {
                      onDeleteSelectedOne(category);
                    }}
                  />
                </div>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <div
      className="courseFilter-div1"
      style={{
        // padding: mobileDevices ? "6px" : "15px",
        height: mobileDevices ? "auto" : "83vh",
        overflow: "scroll",
        border: mobileDevices ? "none" : "1px solid #E5E9ED",
        // borderBottom: "none",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 4,
          alignItems: "center",
          padding: mobileDevices ? "6px" : "10px",
        }}
      >
        <AautiSearch
          // inputRef={filterRef}
          searchValue={searchValue}
          onClose={onCloseSearch}
          handleSearchChange={onChangeSearchValue}
          addStyle={{
            minWidth: mobileDevices ? "85%" : "100%",
            paddingLeft: "0px !important",
            background: "#f2f2f2 !important",
            marginTop: "1px !important",
          }}
        />
        {FilterIconShow && (
          <Tooltip
            title="Filters"
            style={{ display: mobileDevices ? "flex" : "none" }}
          >
            <IconButton
              onClick={handleMenuClick}
              size="small"
              aria-controls={menuOpen ? "account-menuu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
            >
              <img src={FilterIcon} alt="filter" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Divider sx={{ display: mobileDevices && "none" }} />
      <div
        className="courseFilter-div2"
        style={{
          justifyContent: hasSelectedFilters(stateFilters)
            ? "space-between"
            : "flex-start",
          padding: "6px 10px",
          display: mobileDevices && "none",
        }}
      >
        <AautiText weight={"bold"} title={PackageStrings?.filters} />
      </div>
      <Divider sx={{ display: mobileDevices && "none" }} />
      <div
        className="courseFilter-div2"
        style={{
          justifyContent: !isEmpty(combinedArray)
            ? "space-between"
            : "flex-start",
          padding: mobileDevices ? "4px 6px" : "6px 10px",
          display: isEmpty(combinedArray) && "none",
        }}
      >
        <AautiText weight={"bold"} title={PackageStrings?.appliedFilters} />

        <AautiText
          size={"small"}
          className={"courseFilter-text1"}
          onClick={() => {
            filteredData();
          }}
          title={PackageStrings?.clearAll}
        />
      </div>
      {combinedArray?.length > 0 && <DisplayFilterData />}
      <Divider />
      <Menu
        anchorEl={menuOpen}
        id="account-menuu"
        open={menuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          className: "ProfileMenu-menu2",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          height: "auto !important",
          p: mobileDevices ? "6px !important" : "10px !important",
        }}
      >
        {!isEmpty(state) ? (
          Object?.keys(state)?.map((key) => {
            return (
              <MenuItem
                sx={{
                  minHeight: "auto !important",
                  width: "100% !important",
                }}
              >
                <Accordion
                  sx={{ width: "100%" }}
                  onChange={handleChange(key)}
                  expanded={expanded == key}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel1-${key}`}
                    id={`panel1-${key}`}
                  >
                    {formatKeys(key)}
                  </AccordionSummary>
                  <AccordionDetails sx={{ width: "100% !important" }}>
                    <div className="courseFilter-div4">
                      {state[key]?.map((cat, index) => {
                        const isActive = _.some(stateFilters[key], (item) =>
                          _.isEqual(item, cat)
                        );

                        return (
                          <button
                            className="courseFilter-button1"
                            style={{
                              backgroundColor: "#fff",
                              border: isActive
                                ? `1px solid ${globalColors.AAUTI_THEME_COLOR}`
                                : "1px solid #e5e9ed",
                              color: isActive
                                ? globalColors.AAUTI_THEME_COLOR
                                : "#000",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            key={index}
                            onClick={() => {
                              pushOptionsToFilteres(key, cat);
                            }}
                          >
                            {isActive && (
                              <IoIosCheckmark
                                style={{
                                  color: isActive
                                    ? globalColors.AAUTI_THEME_COLOR
                                    : "#000",
                                }}
                                size={24}
                              />
                            )}
                            <AautiText
                              title={cat?.name ?? cat?.key}
                              size={"semi"}
                            />
                          </button>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>
            <CommingSoon />
          </MenuItem>
        )}
      </Menu>
      {!mobileDevices && !isEmpty(state) ? (
        <div style={{ padding: mobileDevices ? "6px" : "10px" }}>
          {Object?.keys(state)?.map((key) => {
            return (
              <Accordion
                sx={{ width: "100%" }}
                onChange={handleChange(key)}
                defaultExpanded
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel1-${key}`}
                  id={`panel1-${key}`}
                >
                  {formatKeys(key)}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="courseFilter-div4">
                    {state[key]?.map((cat, index) => {
                      const isActive = _.some(stateFilters[key], (item) =>
                        _.isEqual(item, cat)
                      );
                      return (
                        <button
                          className="courseFilter-button1"
                          style={{
                            backgroundColor: "#fff",
                            border: isActive
                              ? `1px solid ${globalColors.AAUTI_THEME_COLOR}`
                              : "1px solid #e5e9ed",
                            color: isActive
                              ? globalColors.AAUTI_THEME_COLOR
                              : "#000",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          key={index}
                          onClick={() => {
                            pushOptionsToFilteres(key, cat);
                          }}
                        >
                          {isActive && (
                            <IoIosCheckmark
                              style={{
                                color: isActive
                                  ? globalColors.AAUTI_THEME_COLOR
                                  : "#000",
                              }}
                              size={24}
                            />
                          )}
                          <AautiText
                            title={cat.name ?? cat.key}
                            size={"semi"}
                          />
                        </button>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      ) : (
        !mobileDevices && !backdropOpen && <CommingSoon />
      )}
    </div>
  );
};

export default CourseFilters;
