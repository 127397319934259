import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React from "react";
import "@cyntler/react-doc-viewer/dist/index.css";
import FileViewer from "react-file-viewer";

function AautiPdfViewer({ pdfUrl, style }) {
  const fileType = pdfUrl.substring(pdfUrl.lastIndexOf(".") + 1);
  return (
    <div style={{ ...style, height: "fit-content" }}>
      {fileType === "doc" ? (
        <DocViewer
          documents={[
            {
              uri: pdfUrl,
            },
          ]}
          pluginRenderers={DocViewerRenderers}
          style={{ height: "100%" }}
        />
      ) : (
        <FileViewer
          fileType={fileType}
          filePath={pdfUrl}
          style={{ height: "100%", width: "100%" }}
        />
      )}
    </div>
  );
}

export default AautiPdfViewer;
