import { Outlet, Navigate } from "react-router-dom";
const ProtectedRoute = ({ isUserLoggedIn, path }) => {
  // let auth = { token: false };
  return isUserLoggedIn ||
    path === "/part-types" ||
    path === "/part-type-view" ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
export default ProtectedRoute;
